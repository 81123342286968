import React, { useEffect, useState } from "react";
import {
  Container,
  createStyles,
  SimpleGrid,
  Box,
  Textarea,
  Select,
  Title,
  Indicator,
  Flex,
  Button,
  Text,
} from "@mantine/core";
import TopBar from "../../../components/Topbar";
import AccordionCheck from "../../../components/AccordionCheck";
import { useLocation } from "react-router-dom";
// import moment from "moment";
import moment from "moment-timezone";
import { decrypt } from "../../../utils";
import { useSelector } from "react-redux";
import PreviousNotesComp from "../../../components/TaskTable/PreviousNotesComp";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import AppNavbar from "../../../components/AppNavbar/AppNavbar";
import { CommentDrawer } from "./CommentDrawer";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
    backgroundColor: "#F5F5F5",
    height: "100vh",
  },
  container: {
    borderRadius: "15px",
  },
  mainContainer: {
    padding: "40px 10px 10px 10px",
  },
  topContainer:{
    padding: "10px 20px 30px 8px",
  },
  checkBoxParent: {
    borderRadius: "15px",
    backgroundColor: "#ffffff",
    marginBottom: "20px",
  },
  taskContainer: {
    backgroundColor: "#ffffff",
    borderRadius: "15px",
    padding: "10px 10px 10px 10px",

  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  mainHeading: {
    margin: "0",
  },
  draft: {
    p: {
      margin: "0",
      border: "1px solid #fab005",
      padding: "2px 8px",
      borderRadius: "4px",
      color: "#fab005",
    },
  },
  completed: {
    p: {
      margin: "0",
      border: "1px solid green",
      padding: "2px 8px",
      borderRadius: "4px",
      color: "green",
    },
  },
  expired: {
    p: {
      margin: "0",
      border: "1px solid red",
      padding: "2px 8px",
      borderRadius: "4px",
      color: "red",
    },
  },
  detail: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  detail: {
    span: {
      color: "#A8A8A8",
    },
  },
  detail: {
    p: {
      margin: "0",
    },
  },
  info: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  info: {
    p: {
      margin: "0",
      textAlign: "center",
    },
  },
  btnFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0",
    gap: "20px",
  },
  divGap: {
    margin: "20px 0",
    alignItems: "center",
  },
  checkBoxContainer: {
    "@media (min-width:992px)": {
      padding: "0 140px",
    },
  },
  commentBtnStyle: {
    boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor:"#F4F3FF",
    color:"#6C63FF"
  },
  commentFlex: {
    "@media (max-width:600px)": {
      marginTop: "20px",
    },
  },
}));
const ViewTask = () => {
  const { classes } = useStyles();
  const isMoobileScreen = useMediaQuery("(max-width: 600px)");
  const isTabletScreen = useMediaQuery("(max-width: 1030px)");
  const { state } = useLocation();
  const [opened, { open, close }] = useDisclosure(false);
  const [checks, setChecks] = useState([]);
  const [comments, setComments] = useState([]);
  const [hasEnded, setHasEnded] = useState(false);

  const { allPatients } = useSelector((state) => state?.patient);
  const { allFacilities } = useSelector((state) => state?.facility);
  const { profile } = useSelector((state) => state.profile);
  let userType = profile?.user?.type;

  useEffect(() => {
    let fieldIds = state?.fieldsDone?.map((item) => {
      return {
        field: item?.field?._id,
        staff: item?.staff?._id,
      };
    });
    setChecks(fieldIds);
    setComments(state?.comments);
  }, [state]);

  const AuctionTimer = () => {
    const [remainingTime, setRemainingTime] = useState("");

    useEffect(() => {
      let interval;
      interval = setInterval(() => {
        // const now = moment();
        // const end = moment(state?.taskEndAt);

        const now = moment.tz(moment(), profile?.user?.timeZone);
        const end = moment.tz(
          moment(state?.taskEndAt),
          profile?.user?.timeZone
        );

        const duration = moment.duration(end.diff(now));

        // const duration = moment.duration(end.diff(now));
        if (duration.asSeconds() <= 0) {
          clearInterval(interval);
          setRemainingTime("Task Ended!");
          setHasEnded(true);
        } else {
          const days = Math.floor(duration.asDays());
          const hours = String(duration.hours()).padStart(2, "0");
          const minutes = String(duration.minutes()).padStart(2, "0");
          const seconds = String(duration.seconds()).padStart(2, "0");
          setRemainingTime(
            days === 0
              ? `${hours}h ${minutes}m ${seconds}s`
              : `${days}d ${hours}h ${minutes}m ${seconds}s`
          );
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }, [state?.taskEndAt]);

    return (
      <>
        <div style={{ minWidth: "130px" }}>
          <p
            style={{
              textTransform:
                remainingTime === "Task Ended!" ? "initial" : "lowercase",
              fontSize: remainingTime === "Task Ended!" ? "16px" : "18px",
            }}
          >
            {remainingTime || "-"}
          </p>
        </div>
      </>
    );
  };

  return (
    <Container fluid className={classes.content} px={0}>
      <AppNavbar title={"Task"} profile={profile} />
      {/* <TopBar title={"View Task"} /> */}
      <Title order={3} mt={10} ml={10}>
        View Task
      </Title>
      <Box className={classes.topContainer}>
        <Box className={`${classes.taskContainer}`}>
          <div className={classes.flex}>
            <h2 className={classes.mainHeading}>
              {state?.taskType?.name || ""}
            </h2>
            <Text c={"dimmed"}>
              {state?.dateTime &&
                moment(
                  state?.dateTime ? state?.dateTime : state?.createdAt
                ).format("dddd, MMMM Do, YYYY")}
            </Text>
            {/* {state?.status === "draft" && !hasEnded && (
              <div className={classes.draft}>
                <p>Draft</p>
              </div>
            )}
            {hasEnded && (
              <div className={classes.completed}>
                <p>Completed</p>
              </div>
            )} */}
            {/* {state?.status === "expired" && (
              <div className={classes.expired}>
                <p>Expired</p>
              </div>
            )} */}
          </div>
          <SimpleGrid
            cols={isMoobileScreen ? 1 : isTabletScreen ? 3 : 4}
            className={classes.divGap}
          >
            <div className={classes.detail}>
              <Select
                label="Staff Name"
                radius={"md"}
                placeholder={state?.selectedShift?.staff?.name}
                // placeholder={state?.startedBy?.name}
                disabled={true}
                data={[]}
              />
            </div>
            <div className={classes.info}>
              <Select
                label="Client Name"
                radius={"md"}
                value={state?.client?.id}
                placeholder={
                  state?.client ? decrypt(state?.client?.name, false) : "Client"
                }
                disabled={true}
                data={
                  allPatients?.map((data) => {
                    return {
                      value: data?.id,
                      label: data?.name,
                    };
                  }) || []
                }
                // {...form.getInputProps("client")}
              />
            </div>
            <div className={classes.info}>
              <Select
                label="Facitlity"
                radius={"md"}
                placeholder={state?.facility?.name}
                disabled={true}
                data={
                  allFacilities?.facilities?.map((data) => {
                    return {
                      value: data?.id,
                      label: data?.name,
                    };
                  }) || []
                }
                //   {...form.getInputProps("facility")}
              />
            </div>
            <div className={classes.info}>
              <Select
                label="Shift"
                radius={"md"}
                placeholder={state?.selectedShift?.name}
                // placeholder={state?.shift?.[state?.shift?.length - 1]?.name}
                disabled={true}
                data={["Morning", "Evening", "Night"]}
                //   {...form.getInputProps("shift")}
              />
            </div>
            {/* <div className={classes.detail}>
              <p>
                <strong>Date Created: </strong>{" "}
                {state?.dateTime &&
                  moment(
                    state?.dateTime ? state?.dateTime : state?.createdAt
                  ).format("MM-DD-YYYY")}{" "}
                at{" "}
                {state?.dateTime
                  ? moment(state?.dateTime).format("hh:mm a")
                  : "-"}
              </p>
            </div>
            <div className={classes.detail}>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <strong>Time Remaining: </strong>{" "}
                {state?.dateTime &&
                state?.taskEndAt &&
                state?.status !== "completed" ? (
                  <AuctionTimer />
                ) : (
                  "-"
                )}
              </p>
            </div> */}
          </SimpleGrid>
        </Box>

        <div>
          <h3>Task List</h3>
        </div>
        <Box className={`${classes.mainContainer} ${classes.checkBoxParent}`}>
          <SimpleGrid
            className={classes.checkBoxContainer}
            cols={isMoobileScreen ? 1 : isTabletScreen ? 2 : 3}
          >
            {state?.taskType?.subTasks?.map((tasks) => (
              <div>
                <AccordionCheck
                  Data={tasks?.fields}
                  checksDone={state?.fieldsDone || []}
                  Heading={tasks?.task?.name}
                  setChecks={setChecks}
                  checks={checks}
                  userType={userType}
                  userId={profile?.user?.id}
                  viewOnly={true}
                />
              </div>
            ))}
          </SimpleGrid>
          <Flex className={classes.commentFlex} justify="flex-end">
            <Indicator label={comments?.length} color="red" size={20}>
              <Button
                w={200}
                size="lg"
                radius={"md"}
                onClick={open}
                variant="light"
                className={classes.commentBtnStyle}
              >
                + Comments
              </Button>
            </Indicator>
          </Flex>
        </Box>

        <CommentDrawer
          title={"Comments"}
          opened={opened}
          close={close}
          comments={comments}
          setComments={setComments}
          data={profile}
          view={true}
        />
        {/* <Box className={classes.mainContainer}>
          <div>
            <h3>Additional Notes</h3>
            <div className={classes.divGap}>
              <Textarea
                defaultValue={
                  state?.notes?.find(
                    (obj) =>
                      obj?.staff?.id === state?.selectedShift?.staff?.id &&
                      obj.shift === state?.selectedShift?.name
                  )?.note || "-"
                }
                // defaultValue={state?.notes}
                disabled={true}
                minRows={4}
                style={{ height: "auto" }}
              />
            </div>
          </div>
        </Box>

        <Box className={classes.mainContainer}>
          <h3>Previous Notes</h3>
          <div className={classes.divGap}>
            <PreviousNotesComp notes={state?.notes} />
          </div>
        </Box> */}
      </Box>
    </Container>
  );
};

export default ViewTask;
