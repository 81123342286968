import React from "react";
import { useDisclosure, useCounter } from "@mantine/hooks";
import { Modal, Button, Group, Text, Badge } from "@mantine/core";
import useStyles from "./style";
import taskModalImg from "../../images/newTaskModal.png";
import { useNavigate } from "react-router-dom";
import { changeTaskStatus } from "../../services/task";

function NewTaskModal({ className, state, completePreviousTask }) {
  const navigate = useNavigate();
  const [opened, { close, open }] = useDisclosure(false);
  const [count, { increment, decrement }] = useCounter(3, { min: 0 });
  const { classes } = useStyles();

  
  const changeTaskHandler = async (task) => {
    changeTaskStatus(task?.id, "isShowNotification");
  }

  return (
    <>
      <Modal
        centered
        size={"md"}
        zIndex={1000}
        opened={opened}
        onClose={close}
        withCloseButton={false}
      >
        <img
          className={classes.taskImg}
          src={taskModalImg}
          alt="New Task Icon"
        />
        <h3 className={classes.modalHeading}>New Task</h3>
        <Text className={classes.modalText}>
          Starting a New Task will complete the previous task <br /> for this
          Client.
        </Text>

        <Group mt="xl">
          <Button className={classes.cancelBtn} onClick={close}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              navigate("/home/add-task", {
                state: { fromModal: true, ...state },
              });
              changeTaskHandler(state);
              completePreviousTask();
              close();
            }}
            className={classes.startBtn}
          >
            Start New Task
          </Button>
        </Group>
      </Modal>

      <Button
        className={className}
        onClick={open}
        variant="outline"
        color="green"
        radius="md"
        size="sm"
      >
        New Task
      </Button>
    </>
  );
}

export default NewTaskModal;
