import {
  Accordion,
  ActionIcon,
  Box,
  Button,
  Group,
  NumberInput,
  rem,
  Select,
  SimpleGrid,
  Switch,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { editPlan, getAllPlans, getPlans, getPlanTypes } from "../../services/supportplan";
import useStyles from "./style";
import { DropDownArrowSvg } from "../Svgs/DropDownArrowSvg";
import { useMediaQuery } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import { DateInput } from "@mantine/dates";
import { IconX } from "@tabler/icons-react";
import { IoClose } from "react-icons/io5";
import DiscontinueCarePlanModal from "./DiscontinueCarePlanModal";
import { useSelector } from "react-redux";
import { IconChevronDown } from "@tabler/icons-react";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { getNotes } from "../../services/note";
import { getAllFacilities } from "../../services";

const CarePlanUpdate = ({
  view,
  edit,
  client,
  rowData,
  segment,
  setRowData,
  currentPage,
  handleTableBoxClick,
  setShowCarePlanDetails,
}) => {
  const { classes } = useStyles();
  const [checkedSkillBuilding, setCheckedSkillBuilding] = useState();
  const [checkedIntensiveInHome, setCheckedIntensiveInHome] = useState([]);
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null)
  const [discontinueType, setDiscontinueType] = useState();
  const [showCustomOutcome, setShowCustomOutcome] = useState({});
  const [endDate, setEndDate] = useState(
    rowData?.endDate ? new Date(rowData?.endDate) : null
  );
  const [startDate, setStartDate] = useState(
    rowData?.startDate ? new Date(rowData?.startDate) : null
  );
  const { allFacilities } = useSelector((state) => state?.facility);
  const { allPatients } = useSelector((state) => state?.patient)
  const { updatePlanLoading } = useSelector((state) => state.supportPlan);
  const [openedAccordion, setOpenedAccordion] = useState(null);
  const [openedModal, setOpenedModal] = useState(null);
  const [activityId, setActivtyId] = useState(null);
  const [outcomeId, setOutcomeId] = useState(null);

  const icon = <DropDownArrowSvg style={{ width: rem(1), height: rem(1) }} />;
  const outcomeData = rowData?.outcomes;
  const buttonRef = useRef();

  const [types, setTypes] = useState([
    // {
    //   _id: "661510986a6068af0f4e06e6",
    //   name: "Individual Support Plan (ISP)"
    // }
  ]);

  const selectedClient = allPatients?.find((val) => val?.id === rowData?.client?._id)

  const getData = async () => {
    let data = await getPlanTypes();
    setTypes(data);
  };

  useEffect(() => {
    getData();
    setOpenedAccordion("0");
    getAllFacilities("all");
  }, []);

  useEffect(() => {
    if (types?.length > 0 && !selected) {
      setSelected(types[0]?.id);
    }
  }, [types]);

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const initialOutcome = {
    outcomeCategory: "",
    outcomeTitle: "",
    customOutcome: "",
    outcomeDescription: "",
    additionalInformation: "",

    activities: [
      {
        id: Date.now(),
        supportActivity: "",
        supportInstructions: "",
        skillBuilding: "no",
        intensiveInHome: "no",
        activityNumber: "1a",
      },
    ],

    // supportInstructions: "",
    // supportActivity: "",
    howOften: "",
    iNoLongerNeedThisOutcome: "",

    // errors: {
    //   outcomeTitle: undefined,
    //   outcomeCategory: undefined
    // },
    // required: ["outcomeTitle", "outcomeCategory"]
  };

  const [outcome, setOutcome] = useState([{ id: 1, ...initialOutcome }]);

  useEffect(() => {
    if (outcome) {
      const initialChecked = outcome?.map((outcomeItems) =>
        outcomeItems?.activities?.map(
          (activity) => activity?.skillBuilding === "yes"
        )
      )?.[0];
      setCheckedSkillBuilding(initialChecked || []);

      const initialCheckedIntensiveInHome = outcome?.map((outcomeItems) =>
        outcomeItems?.activities?.map(
          (activity) => activity?.intensiveInHome === "yes"
        )
      )?.[0];
      setCheckedIntensiveInHome(initialCheckedIntensiveInHome || []);
    }
  }, [outcome]);

  const alphabet = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  // const initialactivity = useForm({
  //   initialValues: {
  //     supportActivity:"",
  //     supportInstructions:"",
  //     skillBuilding: "no",
  //   },
  //   validate: {

  //   },
  // });

  // const [outcome, setOutcome] = useState(
  //   rowData?.outcomes?.map((value, index) => value?.outcomeCategory)
  // );

  // components
  const NoRecord = () => {
    return (
      <div
        style={{
          height: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="/assets/images/building.png"
          style={{
            height: "100px",
            width: "100px",
            objectFit: "contain",
          }}
          alt="No Record Found"
        />
        <Text style={{ opacity: 0.3 }} mt={10}>
          No Outcomes Found
        </Text>
      </div>
    );
  };

  // save function
  const onSave = async () => {
    try {
      if (!validateForm()) {
        return;
      }
      let obj = {
        id: rowData?._id,
        facility: selectedFacility,
        planType: selected,
        startDate: startDate,
        endDate: endDate,
        supportPlanName: rowData?.supportPlanName,
        // outcomes: outcome,
        outcomes: outcome?.map((item) => {
          return {
            ...item,
            activities: item?.activities?.map((activity, index) => {
              return {
                ...activity,
                activityNumber: `${item?.outcomeNumber}${alphabet[index]}`,
              };
            }),
          };
        }),
      };
      await editPlan(obj);
      setOutcome([{ id: Date.now(), ...initialOutcome, outcomeNumber: 1 }]);
      setShowCarePlanDetails(false);
      getPlans(currentPage, client, segment);
      getAllPlans();
      getNotes(1, client, false)
    } catch (error) {
      console.log(error);
    }

    //setOutcome(initialOutcome);
  };

  const handleOutcomeChange = (outcomeId, field, value) => {
    let current = outcome.find((v) => v?.id === outcomeId);
    // console.log("current?.required", current.required);
    setOutcome((prevOutcome) =>
      prevOutcome.map((outcome) =>
        outcome.id === outcomeId
          ? {
              ...outcome,
              [field]: value,
              // errors: {
              //   ...outcome.errors,
              //   [field]:
              //     current?.required?.includes(field) && value == ""
              //       ? "Required"
              //       : undefined
              // }
            }
          : outcome
      )
    );
  };

  const handleactivityChange = (outcomeId, field, value, activityId) => {
    let tempOutcome = outcome;

    const tempResult = tempOutcome.map((outcome) =>
      outcome.id === outcomeId
        ? {
            ...outcome,
            activities: outcome?.activities.map((activity) =>
              activity.id === activityId
                ? {
                    ...activity,
                    [field]: value,
                  }
                : activity
            ),
          }
        : outcome
    );

    setOutcome(tempResult);

    // setOutcome((prevOutcome) =>
    //   prevOutcome.map((outcome) =>
    //     outcome.id === outcomeId
    //       ? {
    //         ...outcome,
    //         activities: outcome.activities.map((activity) =>

    //           activity.id === activityId
    //             ? {
    //               ...activity,
    //               [field]: value,
    //             }
    //             : activity
    //         ),
    //       }
    //       : outcome
    //   )
    // );
  };

  const removeItem = (id) => {
    //setFields((p) => p?.filter((value) => value?.id !== id));
  };

  const addOutcome = () => {
    if (edit) {
      setOutcome([
        ...outcome,
        {
          id: Date.now(),
          ...initialOutcome,
          outcomeNumber: outcome?.slice(-1)[0]?.outcomeNumber + 1,
          activities: [
            {
              id: Date.now(),
              supportActivity: "",
              supportInstructions: "",
              skillBuilding: "no",
              intensiveInHome: "no",
              activityNumber:
                outcome?.map((value) => value.outcomeNumber)?.[0] + 1,
            },
          ],
        },
      ]);
    } else
      setOutcome([
        ...outcome,
        {
          id: Date.now(),
          ...initialOutcome,
          activities: [
            {
              id: Date.now(),
              supportActivity: "",
              supportInstructions: "",
              skillBuilding: "no",
              intensiveInHome: "no",
              activityNumber:
                outcome?.map((value) => value.outcomeNumber)?.[0] + 1,
            },
          ],
        },
      ]);
  };

  // out.activities
  const addactivity = (outcomeIndex, activityNumber, activityIndex) => {
    // console.log('outcomeIndex',outcomeIndex);

    const newActivityId = Date.now();

    if (edit) {
      // setOutcome(prevOutcome => {
      //   const newOutcome = [...prevOutcome];
      //   newOutcome[outcomeIndex].activities.push({ id: newActivityId, activityNumber:activityNumber });
      //   return newOutcome;

      // });
      setOutcome((prevOutcome) => {
        const newOutcome = JSON.parse(JSON.stringify(prevOutcome)); // Deep clone
        newOutcome[outcomeIndex].activities.push({
          id: newActivityId,
          // activityNumber: `${outcomeIndex + 1}${alphabet[activityIndex + 1]}`,
          activityNumber: `${
            outcome?.map((value) => value.outcomeNumber)?.[0]
          }${alphabet[activityIndex + 1]}`,
          supportActivity: "",
          supportInstructions: "",
          skillBuilding: "no",
          intensiveInHome: "no",
        });
        return newOutcome;
      });
    } else {
      setOutcome((prevOutcome) => {
        const newOutcome = [...prevOutcome];
        newOutcome[outcomeIndex].activities.push({
          id: newActivityId,
          // activityNumber: `${outcomeIndex + 1}${alphabet[activityIndex + 1]}`,
          activityNumber: `${
            outcome?.map((value) => value.outcomeNumber)?.[0]
          }${alphabet[activityIndex + 1]}`,
          supportActivity: "",
          supportInstructions: "",
          skillBuilding: "no",
          intensiveInHome: "no",
        });
        return newOutcome;
      });
    }
  };

  const removeOutcome = (id) => {
    if (outcome?.length > 1) setOutcome((p) => p?.filter((v) => v?.id != id));
  };

  const removeActivity = (id) => {
    setOutcome((prevOutcome) => {
      return prevOutcome.map((outcomeItem) => {
        return {
          ...outcomeItem,
          activities: outcomeItem.activities.filter(
            (activity) => activity.id !== id
          ),
        };
      });
    });
  };

  // useEffect(() => {
  //   let object = {};
  //   if (state?.outcomes) {
  //     console.log('state', state?.outcomes);
  //     console.log('new state',state?.outcomes[0].activities);

  //   let form = Array.isArray(state?.outcomes) ? state?.outcomes : [];
  //     setOutcome(form);
  //     console.log('outcome', outcome);
  //     form?.forEach((value) => {
  //       object[value?.id] = value?.outcomeCategory === "Other";
  //     });
  //   }
  // }, [state?.outcomes]);

  // useEffect(() => {
  //   if (view || edit) {
  //     // if (state?.outcomes[0]?.activities) {
  //       let form = state?.outcomes?.length > 0 ? state?.outcomes : [];
  //       //[{}]
  //       setOutcome(form);

  //   }
  // }, [state?.outcomes]);

  useEffect(() => {
    if (view || edit) {
      let form = rowData?.outcomes?.length > 0 ? rowData?.outcomes : [];

      form = form.map((outcome) => {
        if (!outcome?.activities || outcome?.activities.length === 0) {
          return {
            ...outcome,
            activities: [
              {
                id: Date.now(),
                supportActivity: outcome?.supportActivity || "",
                supportInstructions: outcome?.supportInstructions || "",
                activityNumber: `${outcome?.outcomeNumber}a`,
                intensiveInHome: outcome?.intensiveInHome || "no",
                skillBuilding: outcome?.skillBuilding || "no",
              },
            ],
          };
        }
        return outcome;
      });
      
      setOutcome(form);
      setSelectedFacility(rowData?.facility?._id)
    }
  }, [view, edit]);
  // const [selectedPlanType, setSelectedPlanType] = useState(null);

  const handleFacilityChange = (f) => {
    setSelectedFacility(f);
  };

  const handlePlanTypeChange = (t) => {
    setSelected(t);
  };

  const closeModal = () => {
    setOpenedModal(null);
  };

  const openCarePlanModal = (type, contentType) => {
    setDiscontinueType(type);
    setOpenedModal(contentType);
  };

  const validateForm = () => {
    const newErrors = {};

    outcome?.forEach((out, index) => {
      if (!out?.outcomeNumber)
        newErrors[`outcomeNumber${index}`] = "Outcome Number is required.";
      if (!out?.outcomeTitle)
        newErrors[`outcomeTitle${index}`] = "Outcome Title is required.";
      if (!out?.outcomeCategory)
        newErrors[`outcomeCategory${index}`] = "Outcome Category is required.";

      if (!out?.howOften)
        newErrors[`howOften${index}`] = "Outcome Frequency is required.";
      // if (showCustomOutcome[out?.id] && !out?.customOutcome) newErrors[`customOutcome${index}`] = "Custom Outcome is required.";
      // out?.activities?.forEach((act, actIndex) => {
      //   if (!act?.supportActivity) newErrors[`supportActivity${index}-${actIndex}`] = "Support Activity is required.";
      //   if (!act?.supportInstructions) newErrors[`supportInstructions${index}-${actIndex}`] = "Support Instructions are required.";
      // });
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Box className={classes.CarePlaneDetails_container}>
      <Box className={classes.header}>
        <Box className={classes.headerTitle}>
          <Text size={"md"} weight={600} ta={"center"}>
            Update Care Plan
          </Text>
          {segment !== "draft" && (
            <Button
              id="discontinue-care-plan"
              variant="light"
              shape="circle"
              color="red"
              onClick={() => openCarePlanModal("careplan", "careplan")}
              radius="xl"
              size="xs"
            >
              Discontinue Care Plan
            </Button>
          )}
        </Box>
        <Box className={classes.headerContent}>
          <Select
            id="select-support-plan"
            value={selectedFacility}
            onChange={(f) => handleFacilityChange(f)}
            label="Facility"
            placeholder="Select Facility"
            rightSectionPointerEvents="none"
            rightSection={icon}
            data={allFacilities?.facilities?.map((_f) => {
              return {
                value: _f?.id,
                label: _f?.name,
                disabled: selectedClient?.facilityId?.find(
                  (_fId) => _fId?.id == _f?.id
                )
                  ? false
                  : true,
              };
            })}
            className={classes.select}
            variant="filled"
            required
          />

          <Select
            value={selected}
            onChange={(t) => handlePlanTypeChange(t)}
            label="Plan Type"
            placeholder="Select Support Plan"
            rightSectionPointerEvents="none"
            rightSection={icon}
            data={types?.map((type) => ({
              value: type?.id,
              label: type?.name,
            }))}
            className={classes.select}
            variant="filled"
            required
          />

          <DateInput
            size="sm"
            clearable
            withAsterisk
            variant="unstyled"
            name="startDate"
            label="Start Date"
            value={startDate}
            onChange={(date) => setStartDate(date)}
            placeholder={"mm/dd/yyyy"}
            rightSection={
              <img
                style={{ width: "30px" }}
                src={"/assets/images/calendar-icon.png"}
                alt="Icon"
              />
            }
            style={{ borderBottom: "2px solid #aaaaaa" }}
          />
          <DateInput
            size="sm"
            clearable
            label="End Date"
            name="endDate"
            value={endDate}
            onChange={(date) => setEndDate(date)}
            variant="unstyled"
            minDate={new Date()}
            placeholder={"mm/dd/yyyy"}
            withAsterisk
            rightSection={
              <img
                style={{ width: "30px" }}
                src={"/assets/images/calendar-icon.png"}
                alt="Icon"
              />
            }
            style={{ borderBottom: "2px solid #aaaaaa" }}
          />
        </Box>
      </Box>

      <Box className={classes.header}>
        {outcome?.length > 0 ? (
          <Accordion
            value={openedAccordion}
            onChange={setOpenedAccordion}
            // key={out.id}
            chevron={<IconChevronDown size={34} color="#FBAA0D" />}
            chevronPosition="right"
            chevronSize={34}
            styles={{
              chevron: {
                transition: "transform 200ms ease",
                "&[data-active]": {
                  transform: "rotate(180deg)",
                },
              },
            }}
          >
            {outcome?.map((out, index) => (
              <Accordion.Item value={`${index}`}>
                <Accordion.Control>
                  <Text variant="text" fw={600}>
                    Outcome #{out?.outcomeNumber || index + 1}
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <form>
                    {!view && out?.isActive !== false && (
                      <Box className={classes.outcomeDeleteBTN}>
                        <Text onClick={() => removeOutcome(out?.id)}>
                          Delete
                        </Text>
                      </Box>
                    )}

                    <SimpleGrid
                      cols={isMobileScreen ? 1 : 4}
                      className={classes.supportPlaneTopSection}
                    >
                      <NumberInput
                        className={classes.myfont}
                        style={{ marginTop: "15px" }}
                        label="Outcome Number"
                        size="sm"
                        variant="filled"
                        value={out?.outcomeNumber || index + 1}
                        onChange={(value) => {
                          handleOutcomeChange(out?.id, "outcomeNumber", value);
                        }}
                        placeholder="Enter outcome number"
                        required
                        error={errors[`outcomeNumber${index}`]}
                        disabled={view || out?.isActive === false}
                        rightSection={
                          <Box className={classes.outcomeNumberSection}>
                            <ActionIcon
                              size="xs"
                              onClick={() =>
                                handleOutcomeChange(
                                  out?.id,
                                  "outcomeNumber",
                                  (out?.outcomeNumber || index + 1) - 1
                                )
                              }
                            >
                              <FaMinus size={10} color="#000" />
                            </ActionIcon>
                            <Text size="sm" c="dimmed">
                              |
                            </Text>
                            <ActionIcon
                              size="xs"
                              onClick={() =>
                                handleOutcomeChange(
                                  out?.id,
                                  "outcomeNumber",
                                  (out?.outcomeNumber || index + 1) + 1
                                )
                              }
                            >
                              <FaPlus size={10} color="#000" />
                            </ActionIcon>
                          </Box>
                        }
                      />
                      <TextInput
                        id="update-care-title"
                        required
                        size="sm"
                        variant="filled"
                        label="Outcome Title"
                        className={classes.myfont}
                        style={{ marginTop: "15px" }}
                        placeholder="Enter outcome title"
                        disabled={view || out?.isActive === false}
                        value={edit ? out?.outcomeTitle : ""}
                        error={errors[`outcomeTitle${index}`]}
                        onChange={(e) => {
                          handleOutcomeChange(
                            out?.id,
                            "outcomeTitle",
                            e?.target?.value
                          );
                          // updateOutcomeHead[index] = e.target.value;
                          // setOutcomeHead(updateOutcomeHead);
                          // setOutcomeTitle(e.target.value);
                        }}
                      />
                      <Select
                        id="update-care-category"
                        required
                        size="sm"
                        variant="filled"
                        rightSection={icon}
                        label="Outcome Category"
                        className={classes.myfont}
                        style={{ marginTop: "15px" }}
                        rightSectionPointerEvents="none"
                        data={[
                          "Community Living",
                          "Safety and Security",
                          "Healthy living",
                          "Citizenship & Advocacy",
                          "Daily Life & Employment",
                          "Social & Spirituality",
                          "Integrated Community Involvement",
                          "Other",
                        ]}
                        placeholder="Select outcome category"
                        value={edit ? out?.outcomeCategory : ""}
                        onChange={(value) => {
                          handleOutcomeChange(
                            out?.id,
                            "outcomeCategory",
                            value
                          );
                          // updateOutcomeCategory.pop();
                          // updateOutcomeCategory[index] = value;
                          // setOutcomeCategory(updateOutcomeCategory);
                          setShowCustomOutcome((p) => ({
                            ...p,
                            [out?.id]: value === "Other",
                          }));
                        }}
                        disabled={view || out?.isActive === false}
                        error={errors[`outcomeCategory${index}`]}
                      />
                      {segment !== "draft" && (
                        <Button
                          id="discontinue-outcome"
                          color="red"
                          radius="xl"
                          shape="circle"
                          variant="light"
                          onClick={() => {
                            openCarePlanModal("outcomes", "outcomes");
                            setOutcomeId(out?.id);
                          }}
                          disabled={out?.isActive === false}
                          size="xs"
                        >
                          Discontinue Outcome
                        </Button>
                      )}

                      {showCustomOutcome[out?.id] && (
                        <Textarea
                          size="sm"
                          autosize={view}
                          label="Add Custom Outcome"
                          placeholder="Enter outcome"
                          className={classes.textArea}
                          style={{ marginTop: "15px" }}
                          disabled={view || out?.isActive === false}
                          value={out?.customOutcome}
                          onChange={(e) => {
                            handleOutcomeChange(
                              out?.id,
                              "customOutcome",
                              e?.target?.value
                            );
                          }}
                        />
                      )}
                    </SimpleGrid>

                    <Text variant="text" fw={600} fz={20} mb={15}>
                      Support Details
                    </Text>

                    {out.activities?.length > 0 &&
                      out?.activities.map((act, actIndex) => (
                        <Group key={act.id}>
                          <Box style={{ width: "100%" }}>
                            <Box className={classes.activitiesBox}>
                              <Box mt={40} w={"20px"}>
                                <p disabled={view}>
                                  {`${out?.outcomeNumber}${alphabet[actIndex]}` ||
                                    index + 1}
                                </p>
                              </Box>
                              <Box
                                className={classes.textInputBox}
                                style={{ width: "35%" }}
                              >
                                <Text className="label">Support Activity</Text>
                                <Textarea
                                  id="update-care-activity"
                                  size="sm"
                                  maxRows={20}
                                  minRows={6}
                                  key={actIndex}
                                  autosize={view}
                                  disabled={
                                    view ||
                                    act?.isActive === false ||
                                    out?.isActive === false
                                  }
                                  placeholder="Enter support activity"
                                  value={edit ? act.supportActivity : ""}
                                  onChange={(e) => {
                                    handleactivityChange(
                                      out?.id,
                                      "supportActivity",
                                      e?.target?.value,
                                      act?.id
                                    );
                                  }}
                                />
                              </Box>
                              <Box
                                className={classes.textInputBox}
                                style={{ width: "35%" }}
                              >
                                <Text className="label">
                                  Support Instructions
                                </Text>

                                <Textarea
                                  id="update-support-instruction"
                                  autosize={view}
                                  minRows={6}
                                  maxRows={10}
                                  size="sm"
                                  placeholder="Enter support Instructions"
                                  disabled={
                                    view ||
                                    act?.isActive === false ||
                                    out?.isActive === false
                                  }
                                  value={edit ? act?.supportInstructions : ""}
                                  onChange={(e) => {
                                    handleactivityChange(
                                      out?.id,
                                      "supportInstructions",
                                      e?.target?.value,
                                      act?.id
                                    );
                                  }}
                                />
                              </Box>

                              <Box
                                className={classes.supportDetailRightButtons}
                              >
                                <Text variant="text" fw={500}>
                                  Skill-Building
                                </Text>
                                <Switch
                                  key={act.id}
                                  id={actIndex + 1}
                                  checked={act?.skillBuilding === "yes"}
                                  color="yellow"
                                  onChange={(event) => {
                                    const newChecked =
                                      event.currentTarget.checked;
                                    const updatedChecked = [
                                      ...checkedSkillBuilding,
                                    ];
                                    updatedChecked[actIndex] = newChecked;
                                    setCheckedSkillBuilding(updatedChecked);
                                    handleactivityChange(
                                      out?.id,
                                      "skillBuilding",
                                      newChecked ? "yes" : "no",
                                      act?.id
                                    );
                                  }}
                                  disabled={
                                    view ||
                                    act.isActive === false ||
                                    outcome[0].isActive === false
                                  }
                                />
                                <Text variant="text" fw={500}>
                                  Intensive in-home
                                </Text>
                                <Switch
                                  key={act.id}
                                  id={actIndex + 1}
                                  checked={act?.intensiveInHome === "yes"}
                                  color="yellow"
                                  onChange={(event) => {
                                    const newChecked =
                                      event.currentTarget.checked;
                                    const updatedChecked = [
                                      ...checkedIntensiveInHome,
                                    ];
                                    updatedChecked[actIndex] = newChecked;
                                    setCheckedIntensiveInHome(updatedChecked);
                                    handleactivityChange(
                                      out?.id,
                                      "intensiveInHome",
                                      newChecked ? "yes" : "no",
                                      act?.id
                                    );
                                  }}
                                  disabled={
                                    view ||
                                    act.isActive === false ||
                                    outcome[0].isActive === false
                                  }
                                />
                                {segment !== "draft" && (
                                  <Button
                                    id="discontinue-activity"
                                    variant="light"
                                    shape="circle"
                                    color="red"
                                    onClick={() => {
                                      openCarePlanModal(
                                        "activities",
                                        "activities"
                                      );
                                      setActivtyId(act?.id);
                                      setOutcomeId(out?.id);
                                    }}
                                    radius="xl"
                                    disabled={
                                      view ||
                                      act?.isActive === false ||
                                      out?.isActive === false
                                    }
                                    size="xs"
                                  >
                                    Discontinue Activity
                                  </Button>
                                )}
                              </Box>

                              <Box
                                className={classes.supportDetailRightButtons}
                              >
                                {out.activities?.length > 1 && (
                                  <ActionIcon
                                    onClick={() => removeActivity(act?.id)}
                                    size={"xl"}
                                    color="red"
                                    variant="light"
                                    disabled={
                                      view ||
                                      act?.isActive === false ||
                                      out?.isActive === false
                                    }
                                  >
                                    <IoClose />
                                  </ActionIcon>
                                )}
                              </Box>
                            </Box>
                          </Box>
                          <Box
                            className={classes.supportDetailAdditionButton}
                            style={{ marginRight: "250px" }}
                          >
                            {actIndex === out.activities.length - 1 && (
                              <ActionIcon
                                onClick={() =>
                                  addactivity(
                                    index,
                                    act.activityNumber
                                      ? act.activityNumber
                                      : "",
                                    actIndex
                                  )
                                }
                                size={"lg"}
                                ref={buttonRef}
                                color="yellow"
                                variant="filled"
                                // disabled={
                                //   view ||
                                //   act?.isActive === false ||
                                //   out?.isActive === false
                                // }
                              >
                                <IconPlus />
                              </ActionIcon>
                            )}
                          </Box>
                        </Group>
                      ))}

                    <SimpleGrid
                      cols={isMobileScreen ? 1 : 3}
                      className={classes.mwd}
                      sx={{
                        paddingBottom: 30,
                      }}
                    >
                      <Select
                        id="update-care-frequency"
                        className={classes.myfont}
                        style={{ marginTop: "15px" }}
                        label="Outcome Frequency"
                        required
                        size="sm"
                        data={[
                          "Daily",
                          "Weekly",
                          "Twice a Week",
                          "Monthly",
                          "Twice a Month",
                        ]}
                        placeholder="Select how often you do this activity."
                        value={edit ? out?.howOften : ""}
                        onChange={(value) => {
                          handleOutcomeChange(out?.id, "howOften", value);
                          // updateOutcomeFreq.pop();
                          // updateOutcomeFreq[index] = value;
                          // setOutcomeFrequency(updateOutcomeFreq);
                        }}
                        disabled={view || out?.isActive === false}
                        rightSectionPointerEvents="none"
                        rightSection={icon}
                        variant="filled"
                      />
                    </SimpleGrid>
                    <SimpleGrid
                      cols={isMobileScreen ? 1 : 2}
                      className={classes.mwd}
                      sx={{
                        paddingBottom: 30,
                      }}
                    >
                      <Box style={{ width: "80%" }}>
                        <Box className={classes.textInputBox}>
                          <Text className="label">
                            No longer need this outcome when ..
                          </Text>
                          <Textarea
                            id="update-care-reason"
                            autosize={view}
                            minRows={4}
                            maxRows={10}
                            className={classes.textArea}
                            size="sm"
                            placeholder="Enter response"
                            disabled={view || out?.isActive === false}
                            value={edit ? out?.iNoLongerNeedThisOutcome : ""}
                            // value={noLonger[index]}
                            onChange={(e) => {
                              handleOutcomeChange(
                                out?.id,
                                "iNoLongerNeedThisOutcome",
                                e?.target?.value
                              );
                              // updateNoLonger[index] = e.target.value;
                              // setNoLonger(updateNoLonger);
                            }}
                          />
                        </Box>
                      </Box>
                      <Box style={{ width: "80%" }}>
                        <Box className={classes.textInputBox}>
                          <Text className="label">Additional Note</Text>
                          <Textarea
                            id="update-care-note"
                            autosize={view}
                            minRows={4}
                            maxRows={10}
                            className={classes.textArea}
                            size="sm"
                            placeholder="Enter additional note"
                            disabled={view || out?.isActive === false}
                            value={edit ? out?.additionalInformation : ""}
                            // value={additionalNote[index]}
                            onChange={(e) => {
                              handleOutcomeChange(
                                out?.id,
                                "additionalInformation",
                                e?.target?.value
                              );
                              // updateAdditionalNote[index] = e.target.value;
                              // setAdditionalNote(updateAdditionalNote);
                            }}
                          />
                        </Box>
                      </Box>
                    </SimpleGrid>
                  </form>
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        ) : (
          <NoRecord />
        )}

        {!view && (
          <Button
            size="sm"
            color="yellow"
            onClick={addOutcome}
            style={{ float: "right", margin: "1rem" }}
          >
            Add New Outcome
          </Button>
        )}

        {!view && (
          <Box className={classes.buttonBox}>
            <Button
              size="sm"
              color="red"
              variant="light"
              onClick={handleTableBoxClick}
              disabled={view}
              leftIcon={<IconX />}
            >
              Cancel
            </Button>
            <Button
              id="update-care-plan"
              loading={updatePlanLoading}
              size="sm"
              color="yellow"
              onClick={() => {
                onSave();
              }}
              disabled={view}
            >
              Update
            </Button>
          </Box>
        )}
      </Box>
      <DiscontinueCarePlanModal
        discontinueType={discontinueType}
        outcomes={outcomeData}
        opened={!!openedModal}
        onclose={closeModal}
        client={client}
        contentType={openedModal}
        id={rowData?._id}
        segment={segment}
        currentPage={currentPage}
        setShowCarePlanDetails={setShowCarePlanDetails}
        setRowData={setRowData}
        activityId={activityId}
        outcomeId={outcomeId}
      />
    </Box>
  );
};

export default CarePlanUpdate;
