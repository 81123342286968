import { createStyles, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  title: {
    fontFamily: "Inter",
    fontSize: rem(18),
    fontWeight: 600,
    marginTop: rem(20),
  },
  header: {
    border: "10px solid #f1f3f5",
    paddingTop: rem(10),
  },
  headerTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: rem(10),
    margin: rem(40),
  },
  select: {
    background: "#f1f3f5",
    padding: "2px 10px",
    width: "320px",
  },
  supportDetailRightButtons: {
    background: "transparent",
    // height: "100px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "0 5px",
    gap: "10px",
  },

  supportDetailAdditionButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "250px",
  },

  headerContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: rem(70),
    marginTop: rem(20),
    marginBottom: rem(20),
    padding:"0 17px 0 17px",
    "& img": {
      width: rem(30),
    },
    "@media (max-width: 1024px)": {
      gap: rem(30),
    },
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  supportPlaneTopSection: {
    paddingBottom: 30,
    display: "flex",
    gap: 20,
    alignItems: "center",
    justifyContent: "space-around",
    paddingInline: 30,
    "@media (max-width: 600px)": {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingInline: 0,
      gap: 10,
    },
  },
  outcomeDeleteBTN: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
    color: "#fab005",
    textDecoration: "underline",
    cursor: "pointer",
  },
  activitiesBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingInline: 0,
      gap: 0,
    },
  },
  // activitiesTextBox: {
  //   width: "34%",
  //   "@media (max-width: 600px)": {
  //     width: "100%",
  //   },
  // },
  outcomeNumberSection: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "35px",
  },
  buttonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    margin: "2rem auto",
  },

  textInputBox: {
    width: "100%",
    border: "1px solid rgba(0,0,0,0.1)",
    padding: "5px 15px",
    borderRadius: "15px",
    position: "relative",
    "& textarea[disabled]": {
      backgroundColor: "#fff !important",
    },
    "& .mantine-Textarea-input": {
      border: "none",
      paddingInline: "0",
    },
    "& .label": {
      position: "absolute",
      top: -10,
      left: 15,
      zIndex: 100,
      color: "#000",
      margin: 0,
      backgroundColor: "#fff",
      display: "inline-block",
      fontSize: "14px",
      fontWeight: 500,
      paddingInline: "10px",
    },
  },
  // segment: {
  //   "& .mantine-p2ocmu": {
  //     borderRadius: "1rem 0 0 1rem !important",
  //   },
  // },
}));

export default useStyles;
