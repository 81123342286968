import React from "react";

import {
  ActionIcon,
  Button,
  Group,
  Popover,
  ScrollArea,
  Table,
  Text,
  createStyles,
} from "@mantine/core";

import { useState } from "react";
import { useSelector } from "react-redux";
import TableLoader from "../TableLoader";
// import moment from "moment";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { decrypt } from "../../utils";
import RecordNotFound from "../RecordNotFound";
import { IconEdit, IconEye, IconTrash, IconChecks } from "@tabler/icons-react";
import { changeTaskStatus, deleteTask } from "../../services/task";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#000",
      color: "#fff !important",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
}));

const TaskTable = () => {
  const { tasks, loadingTasks } = useSelector((state) => state?.task);
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.profile);
  // console.log("🚀 ~ TaskTable ~ profile:", profile);

  // console.log("all tasks", tasks);

  // let sorted = notes?.notes
  //   ?.slice()
  //   ?.sort((b, a) => new Date(a?.fields?.Date) - new Date(b?.fields?.Date));

  const DeleteComponent = ({ id, shift, onPressOk, disabled }) => {
    const [deleteModal, setDeleteModal] = useState(false);
    return (
      <Popover
        opened={deleteModal}
        width={300}
        position="bottom"
        withArrow
        shadow="md"
      >
        <Popover.Target>
          <ActionIcon
            color="red"
            variant="light"
            disabled={disabled}
            onClick={() => setDeleteModal(true)}
          >
            <IconTrash id="icon-delete" />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Text size="sm">Are you sure you want to delete this task entry</Text>

          <Group grow mt={10}>
            <Button
              onClick={() => setDeleteModal(false)}
              size={"xs"}
              color="yellow"
            >
              Cancel
            </Button>
            <Button
              id="delete"
              size={"xs"}
              color={"red"}
              onClick={() => onPressOk(id, shift)}
            >
              Delete
            </Button>
          </Group>
        </Popover.Dropdown>
      </Popover>
    );
  };
  const remove = async (taskId, shift) => {
    await deleteTask({ taskId, shift });
  };

  const disabledTaskHandler = async (task) => {
    changeTaskStatus(task?.id);
  };

  return (
    <ScrollArea
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      sx={{ marginTop: "30px" }}
    >
      {loadingTasks ? (
        <TableLoader />
      ) : (
        <Table
          withColumnBorders
          withBorder
          highlightOnHover
          enableStickyHeader
          sx={{ fontFamily: "Inter" }}
          fontSize={"sm"}
          verticalSpacing={"md"}
        >
          <thead
            className={cx(classes.header, { [classes.scrolled]: scrolled })}
          >
            <tr>
              {/* <th style={{ minWidth: "140px" }}>Task Name</th> */}
              <th style={{ minWidth: "200px" }}>Task Type</th>
              <th style={{ minWidth: "200px" }}>Staff Name</th>
              <th style={{ minWidth: "140px" }}>Shift</th>
              <th style={{ minWidth: "140px" }}>Date</th>
              <th style={{ minWidth: "120px" }}>Time</th>
              <th style={{ minWidth: "140px" }}>Facility Name</th>
              <th style={{ minWidth: "140px" }}>Client Name</th>
              <th style={{ minWidth: "180px" }}>Fields Done</th>
              <th style={{ minWidth: "140px" }}>Task Completed</th>
              {/* <th style={{ minWidth: "140px" }}>Notes</th> */}
              <th style={{ minWidth: "140px" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tasks?.length > 0 ? (
              tasks?.map((row, i) =>
                // console.log("dd" , moment.tz(moment(row?.taskEndAt) , profile?.user?.timeZone).diff(moment.tz(moment() , profile?.user?.timeZone)) <= 0))
                row?.shift?.length > 0 ? (
                  row?.shift?.map((item) => (
                    <tr style={{ cursor: "pointer" }} key={i}>
                      {/* <td className={classes.border}>{row?.name}</td> */}
                      <td className={classes.border}>{row?.taskType?.name}</td>
                      <td>{item?.staff?.name}</td>
                      <td>{item?.name}</td>
                      <td className={classes.border}>
                        {moment(row?.dateTime).format("MM-DD-YYYY")}
                      </td>
                      <td className={classes.border}>
                        {moment(row?.dateTime).format("hh:mm a")}
                      </td>
                      <td className={classes.border}>{row?.facility?.name}</td>
                      <td className={classes.border}>
                        {decrypt(row?.client?.name, false) || row?.client?.name}
                      </td>
                      <td className={classes.border}>
                        {row?.fieldsDone
                          ?.filter(
                            (fieldItem) =>
                              fieldItem?.staff?.id === item?.staff?.id &&
                              fieldItem?.shift === item?.name
                          )
                          ?.map((field) => field?.field?.name)
                          ?.join(", ")}
                        {/* <span>{field?.field?.name}, </span> */}
                      </td>

                      <td className={classes.border}>
                        {/* {moment
                          .duration(moment(row?.taskEndAt).diff(moment()))
                          .asSeconds() <= 0
                          ? "Completed"
                          : "Draft"} */}
                        {/* {row?.status === "completed"
                          ? "Completed"
                          : moment
                              .tz(
                                moment(row?.taskEndAt),
                                profile?.user?.timeZone
                              )
                              .diff(
                                moment.tz(moment(), profile?.user?.timeZone)
                              ) <= 0
                          ? "Completed"
                          : "Draft"} */}
                        {row?.status?.toLowerCase() === 'draft' ? 'Draft' : 'Completed'}
                      </td>
                      {/* <td className={classes.border}>
                        {row?.notes?.find(
                          (obj) =>
                            obj?.staff?.id === item?.staff?.id &&
                            obj.shift === item.name
                        )?.note || "-"}
                      </td> */}
                      <td>
                        <Group>
                          {profile?.user?.type !== "staff" && (
                            <ActionIcon
                              color="purple"
                              variant="light"
                              disabled={
                                // row?.status?.toLowerCase() === "completed" ||
                                // moment
                                //   .tz(
                                //     moment(row?.taskEndAt),
                                //     profile?.user?.timeZone
                                //   )
                                //   .diff(
                                //     moment.tz(moment(), profile?.user?.timeZone)
                                //   ) <= 0

                                // row?.status?.toLowerCase() === 'completed'  ? true
                                //   : false

                                row?.status?.toLowerCase() === 'completed' ? true
                                : (item?.staff?.id === profile?.user?.id) ||
                                profile?.user?.type !== "staff" ? false : true
                              }
                              onClick={() => {
                                // console.log('disbaled true')
                                disabledTaskHandler(row);
                              }}
                            >
                              <IconChecks id="task-check" />
                            </ActionIcon>
                          )}
                          <ActionIcon
                            color="yellow"
                            variant="light"
                            onClick={() => {
                              navigate("/home/view-task", {
                                state: { ...row, selectedShift: item },
                              });
                            }}
                          >
                            <IconEye id="task-view" />
                          </ActionIcon>
                          <ActionIcon
                            color="green"
                            variant="light"
                            disabled={
                              // (row?.status?.toLowerCase() === "completed" ||
                              //   moment
                              //     .tz(
                              //       moment(row?.taskEndAt),
                              //       profile?.user?.timeZone
                              //     )
                              //     .diff(
                              //       moment.tz(moment(), profile?.user?.timeZone)
                              //     ) <= 0) &&
                              // (item?.staff?.id === profile?.user?.id ||
                              //   profile?.user?.type !== "staff")
                              row?.status?.toLowerCase() === 'completed' ? true
                                : (item?.staff?.id === profile?.user?.id) ||
                                profile?.user?.type !== "staff" ? false : true
                            }
                            // disabled={
                            //   (row?.status?.toLowerCase() === "completed" ||
                            //     moment
                            //       .tz(
                            //         moment(row?.taskEndAt),
                            //         profile?.user?.timeZone
                            //       )
                            //       .diff(
                            //         moment.tz(moment(), profile?.user?.timeZone)
                            //       ) <= 0) &&
                            //   (item?.staff?.id === profile?.user?.id ||
                            //     profile?.user?.type !== "staff")
                            //     ? true
                            //     : false
                            // }
                            onClick={() => {
                              navigate("/home/edit-task", {
                                state: { ...row, selectedShift: item },
                              });
                            }}
                          >
                            <IconEdit id="task-edit-button" />
                          </ActionIcon>
                          <DeleteComponent
                            id={row?.id}
                            shift={item?.name}
                            onPressOk={remove}
                            disabled={
                              item?.staff?.id === profile?.user?.id ||
                              profile?.user?.type !== "staff" ||
                              (profile?.user?.type === "staff" &&
                                profile?.user?.role === "admin")
                                ? false
                                : true
                            }
                          />
                        </Group>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr style={{ cursor: "pointer" }} key={i}>
                    {/* <td className={classes.border}>{row?.name}</td> */}
                    <td className={classes.border}>{row?.taskType?.name}</td>
                    <td></td>
                    <td className={classes.border}>
                      {moment(row?.dateTime).format("YYYY-MMM-DD")}
                    </td>
                    <td className={classes.border}>
                      {moment(row?.dateTime).format("hh:mm a")}
                    </td>
                    <td className={classes.border}>{row?.facility?.name}</td>
                    <td className={classes.border}>
                      {decrypt(row?.client?.name, false) || row?.client?.name}
                    </td>
                    <td className={classes.border}></td>

                    <td className={classes.border}>{row?.status}</td>
                    {/* <td className={classes.border}>{row?.notes || "-"}</td> */}
                    {/* <td>
                      <Group>
                        <ActionIcon
                          color="yellow"
                          variant="light"
                          onClick={() => {
                            navigate("/home/view-task", { state: row });
                          }}
                        >
                          <IconEye />
                        </ActionIcon>
                        <ActionIcon
                          color="green"
                          variant="light"
                          onClick={() => {
                            navigate("/home/view-task", { state: row });
                          }}
                        >
                          <IconEdit />
                        </ActionIcon>
                        <DeleteComponent id={row?._id} onPressOk={remove} /> 
                      </Group>
                    </td> */}
                  </tr>
                )
              )
            ) : (
              <tr>
                <td colspan={1000}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      height: "75vh",
                      gap: "8rem",
                    }}
                  >
                    <RecordNotFound />
                    <p className={classes.notFoundText}>
                      You don&apos;t have any record for Tasks
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </ScrollArea>
  );
};

export default TaskTable;
