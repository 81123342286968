import {
  ActionIcon,
  Box,
  Button,
  Group,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  Textarea,
  Grid,
  rem,
  Checkbox,
  Badge,
  // Pill
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { decrypt, states } from "../../utils";
import useStyles from "./style";
import { DropDownArrowSvg } from "../Svgs/DropDownArrowSvg";
import { CloseIconSvg } from "../Svgs/CloseIconSvg";
import { FaChevronRight } from "react-icons/fa6";

const HealthForm = forwardRef(
  (
    {
      title,
      edit,
      functions,
      view,
      setState,
      setActive,
      state,
      active,
      handleFormSubmit,
      options,
      saveHandle,
      selectedTab,
      // classes,
    },
    ref
  ) => {
    // console.log("state inside health form", state);

    const { classes } = useStyles();
    const icon = <DropDownArrowSvg style={{ width: rem(1), height: rem(1) }} />;
    const [knownIllness, setKnownIllness] = useState([]);
    const [knownIllnessField, setKnownIllnessField] = useState("");

    const [knownAllergies, setKnownAllergies] = useState([]);
    const [allergiesField, setAllergiesField] = useState("");
    const [noAllergies, setNoAllergies] = useState(false);

    const [dietaries, setDietaries] = useState([]);
    const [dietariesField, setDietariesField] = useState("");
    const [noDietaryRestrictions, setNoDietaryRestrictions] = useState(false);

    useEffect(() => {
      if (active === 0 && state?.form1) {
        form.setValues({
          ...state?.form1,
        });
        setDietaries(state?.form1?.dietaries || []);
        setKnownIllness(
          state.form1?.knownIllness?.length > 0 &&
            state.form1?.knownIllness?.[0]?.illness
            ? state.form1?.knownIllness?.map((item) => item.illness)
            : state.form1?.knownIllness || []
        );
        setKnownAllergies(state?.form1?.knownAllergies || []);
      }
    }, [active, state]);

    const careLevelOptions = [
      "Memory Care",
      "Personal Care",
      "Independent Living",
      "Skilled Nursing",
      "Supervisory Care",
      "Directed Care",
      "Behavioral Health Care",
      "Custodial Care",
      "Medical Care",
      "Directed Care",
      "Hospice Care",
      "Transitional Care",
    ];
    const DNRCodeOptions = ["Level 1", "Level 2", "Level 3", "Level 4"];
    const form = useForm({
      initialValues: {
        levelOfCare: "",
        dnrCode: "",
        knownIllness,
        facility: null,
        summary: "",
        knownAllergies,
        dietaries,
        // noDietaryRestrictions,
        // noAllergies,
        // contagious: "no",
        // patientWounds: "no",
        // isKnownIllness: "no",
      },
      // validate: {
      //   levelOfCare: (value) => (value.trim() ? null : "Level of Care is required"),
      //   dnrCode: (value) => (value.trim() ? null : "DNR Code is required"),
      //   knownIllness: (value) => (value.trim() ? null : "Known Illness is required"),
      //   facility: (value) => (value ? null : "Facility is required"),
      //   summary: (value) => (value.trim() ? null : "Summary is required"),
      //   knownAllergies: (value) => (value.trim() ? null : "Known Allergies is required"),
      //   dietaries: (value) => (value.trim() ? null : "Dietary Restrictions are required"),
      //  },
    });

    useImperativeHandle(ref, () => ({
      confirm: () => {
        // console.log("Called");
        let values = form.values;
        let valid = form?.validate();
        if (Object.keys(valid?.errors).length === 0) {
          let lengthToMask = (values?.socialSecurityNumber?.length || 4) - 4;
          let obj = {
            ...state,
            form1: {
              ...values,
              knownIllness,
              dietaries,
              lastFourDigits: `${"*".repeat(
                lengthToMask
              )}${values?.socialSecurityNumber?.slice(-4)}`,
            },
          };

          setState(obj);
          setActive(1);
        }
      },
    }));

    const isMobileScreen = useMediaQuery("(max-width: 600px)");
                  
    const onSave = () => {
      const values = form.getTransformedValues();

      let tempForm1 = {
        ...state.form1,
        ...{
          knownIllness,
          knownAllergies,
          dietaries,
          levelofcare: values.levelofcare,
          dnrCode: values.dnrCode,
          summary: values.summary,
          dietaryRestrictions: "",
          isForm1: true,
          socialSecurityNumber : decrypt(state?.form1?.socialSecurityNumber, false)
        },
      };

      let obj = {
        ...state,
        form1: tempForm1,
      };

      let valid = form?.validate();
      handleFormSubmit("healthInfo", "careSupportInfo");
      if (Object.keys(valid?.errors).length === 0) {
        setState(obj);
        setActive(1);
        saveHandle(tempForm1, null, null);
      }
    };

    const onNext = (values, formName) => {
      let obj = {
        ...state,
        form1: {
          // ...(formName === "personalInfo" && { ...values }),
          ...state.form1,
          ...(formName === "healthInfo" && {
            // ...values,
            knownIllness,
            knownAllergies,
            dietaries,
            levelofcare: values.levelofcare,
            dnrCode: values.dnrCode,
            summary: values.summary,
            dietaryRestrictions: "",
            isForm1: true,
          }),
        },
      };

      let valid = form?.validate();
      handleFormSubmit("healthInfo", "careSupportInfo");
      if (Object.keys(valid?.errors).length === 0) {
        setState(obj);
        setActive(1);
      }
    };

    const handleChangeDiagnosis = (index, value) => {
      let temp = [...knownIllness];
      setKnownIllness([...temp, knownIllnessField]);
      setKnownIllnessField("");
    };

    const handleChangeAllergies = (index, value) => {
      let temp = [...knownAllergies];
      setKnownAllergies([...temp, allergiesField]);
      setAllergiesField("");
    };

    const handleChangeDietary = (index, value) => {
      let temp = [...dietaries];
      setDietaries([...temp, dietariesField]);
      setDietariesField("");
    };

    const addEmptyAllergy = () => {
      let temp = [...knownIllness];
      temp.push([]);
      setKnownIllness(temp);
    };
    const removeDiagnosis = (index) => {
      let temp = knownIllness?.filter((_, i) => i !== index);
      setKnownIllness(temp);
    };

    const removeAllergy = (index) => {
      let temp = knownAllergies?.filter((v, i) => i !== index);
      setKnownAllergies(temp);
    };
    const removeDietary = (index) => {
      let temp = dietaries?.filter((v, i) => i !== index);
      setDietaries(temp);
    };
    const handleNoAllergiesChange = (v) => {
      setNoAllergies(v?.target?.checked);
      if (v?.target?.checked === true) {
        setKnownAllergies([]);
      }
    };

    const handleNoDietaryRestrictionsChange = (v) => {
      setNoDietaryRestrictions(v?.target?.checked);
      if (v?.target?.checked === true) {
        setDietaries([]);
      }
    };

    return (
      <form onSubmit={form?.onSubmit((values) => onNext(values, "healthInfo"))}>
        <Box className={classes.healthFormContainer}>
          <Box>
            <Text fz={20} weight={500}>
              {title}
            </Text>
            <Grid className={classes.healthDropdowns}>
              <Grid.Col span={6}>
                <Select
                  id="level-of-care"
                  searchable
                  label="Level of Care"
                  data={careLevelOptions}
                  size="md"
                  placeholder="Memory Care"
                  {...form.getInputProps("levelofcare")}
                  disabled={view}
                  className={classes.select}
                  variant="filled"
                  required
                  rightSection={icon}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Select
                  id="d_n_r_code"
                  searchable
                  label="DNR Code"
                  data={DNRCodeOptions}
                  size="md"
                  placeholder="Select DNR Code"
                  {...form.getInputProps("dnrCode")}
                  disabled={view}
                  className={classes.select}
                  variant="filled"
                  required
                  rightSection={icon}
                />
              </Grid.Col>
            </Grid>
            <SimpleGrid cols={1} mt={20}>
              <Group className={classes.diagnosis}>
                <Group>
                  <TextInput
                    id="individual-diagnosis"
                    className={classes.myfont}
                    label="Diagnosis"
                    size="md"
                    placeholder="Entry Diagnosis"
                    value={knownIllnessField}
                    // {...form.getInputProps("diagnosis")}
                    onChange={(e) => setKnownIllnessField(e?.target?.value)}
                    disabled={view}
                  />

                  <ActionIcon
                    color="yellow"
                    variant="light"
                    mt={23}
                    size={20}
                    onClick={() => handleChangeDiagnosis()}
                  >
                    <IconPlus id="add-diagnoses" />
                  </ActionIcon>
                </Group>

                {knownIllness?.map((value, index) => (
                  <Group key={index}>
                    <Group className={classes.tags}>
                      <Badge
                        className={classes.diagnosisTags}
                        color="#6C63FF1A"
                        radius="xs"
                        size="lg"
                        value={
                          typeof value === "string"
                            ? value
                            : value?.illness?.length > 0
                            ? value?.illness
                            : ""
                        }
                      >
                        {typeof value === "string"
                          ? value
                          : value?.illness?.length > 0
                          ? value?.illness
                          : ""}
                      </Badge>
                      <CloseIconSvg
                        className={classes.close}
                        onClick={() => removeDiagnosis(index)}
                      />
                    </Group>
                  </Group>
                ))}
              </Group>
            </SimpleGrid>
            <Grid>
              <Grid.Col mt={20}>
                <Box className={classes.textInputBox}>
                  <Text className="label">Diagnosis Summary</Text>
                  <Textarea
                    id="diagnosis-summary"
                    autosize={view}
                    minRows={4}
                    maxRows={10}
                    size="sm"
                    disabled={view}
                    placeholder="Entry Diagnosis Summary"
                    {...form.getInputProps("summary")}

                    // value={act?.supportActivity}
                    // onChange={(e) => {
                    //   handleactivityChange(
                    //     out?.id,
                    //     "supportActivity",
                    //     e?.target?.value,
                    //     act?.id
                    //   );
                    // }}
                  />
                </Box>
              </Grid.Col>
            </Grid>
            <SimpleGrid cols={1} mt={20}>
              <Group className={classes.diagnosis}>
                <Group>
                  <Box>
                    <Box className={classes.inputLabel}>
                      <Text ff={"Inter"} className={classes.myfont}>
                        Allergies
                      </Text>
                      <Checkbox
                        size="xs"
                        color="yellow"
                        disabled={view}
                        label="No Allergies"
                        // {...form.getInputProps("noAllergies")}
                        onChange={handleNoAllergiesChange}

                      />
                    </Box>
                    {!noAllergies && (
                      <TextInput
                        id="individual-allergy"
                        className={classes.allergiesDietaryInput}
                        size="md"
                        placeholder="Enter Allergies"
                        value={allergiesField}
                        onChange={(e) => setAllergiesField(e?.target?.value)}
                        // {...form.getInputProps("knownAllergies")}
                      />
                    )}
                  </Box>
                  {!noAllergies && (
                    <ActionIcon
                      color="yellow"
                      opacity={0.8}
                      variant="light"
                      mt={23}
                      size={20}
                      onClick={() => handleChangeAllergies()}
                    >
                      <IconPlus id="add-allergy" size={"md"} />
                    </ActionIcon>
                  )}
                </Group>
                {knownAllergies?.map((value, index) => (
                  <Group key={index}>
                    <Group className={classes.tags}>
                      <Badge
                        className={classes.allergiesTags}
                        color="#1DA7061A"
                        radius="xs"
                        size="lg"
                        value={value}
                      >
                        {value}
                      </Badge>
                      <CloseIconSvg
                        className={classes.close}
                        onClick={() => removeAllergy(index)}
                      />
                    </Group>
                  </Group>
                ))}
              </Group>

              <Group>
                <Group className={classes.diagnosis}>
                  <Group>
                    <Box>
                      <Box className={classes.inputLabel}>
                        <Text ff={"Inter"} className={classes.myfont}>
                          Dietary Restrictions
                        </Text>
                        <Checkbox
                          size="xs"
                          color="yellow"
                          disabled={view}
                          label="No Dietary"
                          // {...form.getInputProps("noDietaryRestrictions")}
                          onChange={handleNoDietaryRestrictionsChange}
                        />
                      </Box>
                      {!noDietaryRestrictions && (
                        <TextInput
                          id="individual-restriction"
                          className={classes.allergiesDietaryInput}
                          size="md"
                          placeholder="Entry Dietary Restrictions"
                          value={dietariesField}
                          onChange={(e) => setDietariesField(e?.target?.value)}
                          // disabled={value.isChecked}
                        />
                      )}
                    </Box>
                    {!noDietaryRestrictions && (
                      <ActionIcon
                        opacity={0.8}
                        color="yellow"
                        variant="light"
                        mt={23}
                        size={20}
                        // disabled={value.isChecked}
                        // ref={addFieldRef}
                        onClick={() => handleChangeDietary()}
                      >
                        <IconPlus id="add-restriction" size={"md"} />
                      </ActionIcon>
                    )}
                  </Group>
                </Group>
                {dietaries?.map((value, index) => (
                  <Group key={index} className={classes.tags}>
                    <Badge
                      className={classes.dietariesTag}
                      color="#1DA7061A"
                      radius="xs"
                      size="lg"
                      value={value}
                    >
                      {value}
                    </Badge>
                    <CloseIconSvg
                      className={classes.close}
                      onClick={() => removeDietary(index)}
                    />
                  </Group>
                ))}
              </Group>

              {/* <Radio.Group
            name="isKnownIllness"
            label="Are there any known Illness?"
            {...form.getInputProps("isKnownIllness")}
            description="If 'Yes' Please tell Known Illness"
            onChange={(value) => {
              form?.setFieldValue("isKnownIllness", value);
              setIsKnownIllness(value === "yes");
            }}
          >
            <Group mt="xs">
              <Radio
                id="yes-radio-one"
                color="yellow"
                disabled={view}
                value="yes"
                label="Yes"
              />
              <Radio
                id="yes-radio-two"
                color="yellow"
                disabled={view}
                value="no"
                label="No"
              />
            </Group>
          </Radio.Group>
          {isknownIllness &&
            knownIllness?.map((value, index) => (
              // <Group grow>
              <Group align="center" grow key={index}>
                <span
                  style={{
                    marginTop: "20px",
                    flexGrow: 0.5,
                  }}
                >
                  {index + 1} .
                </span>

                <TextInput
                  className={classes.myfont}
                  label="Known Illness"
                  size="md"
                  icon={<img src="/assets/images/building.png" alt="address" />}
                  placeholder="Known Illness"
                  value={value?.illness}
                  onChange={(e) =>
                    handleChangeKnownIllness(index, e?.target?.value, "illness")
                  }
                  disabled={view}
                />

                <Radio.Group
                  // name={`contagious${index}`}
                  label="Is it Contagious?"
                  value={value?.contagious}
                  onChange={(value) => {
                    // console.log("index,value", index, value);
                    handleChangeKnownIllness(index, value, "contagious");
                  }}
                >
                  <Group mt="xs">
                    <Radio
                      color="yellow"
                      disabled={view}
                      value="yes"
                      label="Yes"
                    />
                    <Radio
                      color="yellow"
                      disabled={view}
                      value="no"
                      label="No"
                    />
                  </Group>
                </Radio.Group>
                <Group>
                  <ActionIcon
                    color="red"
                    variant="light"
                    // mt={23}
                    size={25}
                    opacity={0.9}
                    onClick={() => removeKnownIllness(index)}
                  >
                    <IconTrash size={"md"} />
                  </ActionIcon>
                  {index === knownIllness?.length - 1 && (
                    <ActionIcon
                      color="yellow"
                      variant="light"
                      // mt={23}
                      size={30}
                      onClick={addEmptyKnownIllness}
                    >
                      <IconPlus />
                    </ActionIcon>
                  )}
                </Group>
              </Group>
            ))}
          {isknownIllness && <Divider variant="dashed" />}

          <Radio.Group
            name="patientWounds"
            {...form.getInputProps("patientWounds")}
            label="Does the Client have any wounds?"
            onChange={(value) => {
              form.setFieldValue("patientWounds", value);
              setPatientWounds(value === "yes");
            }}
          >
            <Group mt="xs">
              <Radio color="yellow" disabled={view} value="yes" label="Yes" />
              <Radio color="yellow" disabled={view} value="no" label="No" />
            </Group>
          </Radio.Group>
          {patientWounds && (
            <Textarea
              autosize={view}
              className={classes.textArea}
              label="Wounds Information"
              size="md"
              icon={
                <img
                  src="/assets/images/linear-note.png"
                  alt="wounds"
                  className={classes.descriptionIcon}
                />
              }
              placeholder="Provide information about the client's wounds..."
              {...form.getInputProps("woundsInformation")}
              disabled={view}
            />
          )} */}
              {/* <div style={{ height: "180px", background: 'red' }} /> */}
            </SimpleGrid>
          </Box>
          <Box>
            {view ? (
              <div style={{ height: "30px" }} />
            ) : (
              <SimpleGrid cols={3} className={classes.btns}>
                <Button
                  variant="white"
                  color="yellow"
                  size="sm"
                  onClick={() => {
                    handleFormSubmit("", "personalInfo");
                  }}
                  styles={(theme) => ({
                    root: {
                      color: "#000",
                    },
                    fontFamily: "inter",
                  })}
                >
                  Go Back
                </Button>
                <Box className={classes.centerBTN}>
                  <Button
                    color="yellow"
                    size="sm"
                    variant="outline"
                    radius="md"
                    styles={(theme) => ({
                      root: {
                        color: "#000",
                        borderColor: "#FFBA45",
                      },
                    })}
                    onClick={() => {
                      handleFormSubmit("", "careSupportInfo");
                    }}
                  >
                    Add Later
                  </Button>
                  {edit && (
                    <Button
                      id="individual-next"
                      className="health-edit-save"
                      color="yellow"
                      radius="sm"
                      size="sm"
                      onClick={() => {
                        onSave();
                      }}
                    >
                      Save
                    </Button>
                  )}
                </Box>
                <Button
                  id="health-next"
                  radius="sm"
                  size="sm"
                  color="yellow"
                  type="submit"
                  styles={(theme) => ({
                    root: {
                      color: "#000",
                    },
                  })}
                >
                  Next
                  <FaChevronRight />
                </Button>
              </SimpleGrid>
            )}
          </Box>
        </Box>
      </form>
    );
  }
);

export default HealthForm;
