import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  modalHeading: {
    textAlign: "center",
    margin: "5px auto 3px auto",
    fontWeight: "500",
  },
  modalText: {
    textAlign: "center",
    color: "#737373",
    fontSize: "12px",
  },
  taskImg: {
    width: "40px",
    height: "40px",
    display: "block",
    margin: "0 auto",
  },
  cancelBtn: {
    width: "47%",
    border: "1px solid #D0D5DD",
    color: "#000000",
    backgroundColor: "transparent",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  startBtn: {
    width: "47%",
    color: "#000000",
    backgroundColor: "#FFAF4A",
    transition: "0.3s",
    "&:hover": {
      backgroundColor: "#FFAF4A",
    },
  },
}));

export default useStyles;
