import React, { useState } from "react";

import { ActionIcon, Menu, Table, Text, Title } from "@mantine/core";
import { IconDotsVertical, IconEdit } from "@tabler/icons-react";

import moment from "moment";
import useStyles from "./stylesComponents";
import { useDisclosure } from "@mantine/hooks";
import RenameFileModal from "./RenameFileModal";
import RecordNotFound from "../RecordNotFound";
import OpenFileModal from "./OpenFileModal";
import DeleteFileModal from "./DeleteFileModal";

import { HiOutlineDotsVertical } from "react-icons/hi";

function FileItem({ data, client }) {
  // console.log("🚀 ~ FileItem ~ data:", data);
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [Fileopened, { open: openFile, close: closeFile }] =
    useDisclosure(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [Deleteopened, { open: deleteOpen, close: deleteClose }] =
    useDisclosure(false);

  const [menu, setMenu] = useState(false);

  let extension =
    data?.fileUrl?.split(".")[data?.fileUrl?.split(".")?.length - 1];
  let imagePath =
    extension === "pdf"
      ? "/assets/images/pdf-svg.png"
      : ["jpeg", "JPEG", "JPG", "PNG", "GIF", "jpg", "png", "gif"].includes(
          extension
        )
      ? "/assets/images/picture-svg.png"
      : "/assets/images/document-svg.png";
  return (
    // <div className={classes.folderItem }>

    <div
      // className={`${classes.folderItem} ${
      //   data?.expired === true ? "" : ""
      // }`}
      className={`${classes.folderItem} ${data?.expired === true ? "" : ""}`}
      style={{
        // opacity: data?.expired === true ? 0.4 : 1,
        opacity: 1,
      }}
    >
      <div
        className={classes.column}
        // onClick={() => {
        //   setSelectedItem(data);
        //   openFile();
        // }}
      >
        <div className={classes.verticalIconContainer}>
          {/* <Menu shadow="md" width={200} disabled={data?.expired ? true : false}> */}
          <Menu
            shadow="md"
            width={200}
            // disabled={false}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   setMenu(!menu);
            // }}
            // opened={menu}
            // onChange={(e) => console.log("Yolooo", e)}
          >
            <Menu.Target>
              <HiOutlineDotsVertical size={20} style={{ cursor: "pointer" }} />
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item
                onClick={() => {
                  setSelectedItem(data);
                  openFile();
                }}
                icon={
                  <img
                    src="/assets/images/open.png"
                    alt="open"
                    className={classes.folderMenuIcon}
                  />
                }
                // disabled={data?.expired ? true : false}
                disabled={false}
              >
                Open
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  fetch(data?.fileUrl).then((response) => {
                    response.blob().then((blob) => {
                      const fileURL = window.URL.createObjectURL(blob);
                      let alink = document.createElement("a");
                      alink.href = fileURL;
                      alink.download = data?.name;
                      alink.click();
                    });
                  });
                }}
                icon={
                  <img
                    src="/assets/images/downloadFolder.png"
                    alt="download"
                    className={classes.folderMenuIcon}
                  />
                }
                // disabled={data?.expired ? true : false}
                disabled={false}
              >
                Download
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setSelectedItem(data);
                  open();
                }}
                icon={
                  <img
                    src="/assets/images/editFolder.png"
                    alt="edit"
                    className={classes.folderMenuIcon}
                  />
                }
              >
                Rename
              </Menu.Item>
              <Menu.Item
                onClick={async () => {
                  // await deleteFile(data?.id, data?.folder?.id);
                  deleteOpen();
                  setSelectedItem(data);
                }}
                icon={
                  <img
                    src="/assets/images/deleteFolder.png"
                    alt="delete"
                    className={classes.folderMenuIcon}
                  />
                }
                style={{ color: "red" }}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </div>
        <Title
          order={5}
          style={{
            alignSelf: "flex-start",
            flexWrap: "wrap",
            marginTop: -5,
            minHeight: 50,
            width: "85%",
            marginBottom: 20
          }}
          onClick={() => {
            setSelectedItem(data);
            openFile();
          }}
        >
          {data?.name?.length > 14
            ? data?.name?.slice(0, 14) + "..."
            : data?.name}
          {/* {data?.name?.slice(0, 15) + "..."} */}
        </Title>

        {/* {data?.expiry && (
          <Text className={classes.expiryDateText} fz="xs" weight={"bolder"}>
            Expiry : {moment(data?.expiry).format("MMMM DD, YYYY")}
          </Text>
        )} */}

        {data?.expired === true && moment(data?.expiry).isValid() ? (
          <Text className={classes.expiryDateText} fz="xs" weight={"bolder"}>
            Expired: {moment(data?.expiry).format("MMMM DD, YYYY")}
          </Text>
        ) : (
          data?.expiry &&
          moment(data?.expiry).isValid() && (
            <Text className={classes.expiryDateText} fz="xs" weight={"bolder"} mt={"-17px"}>
              Expiry: {moment(data?.expiry).format("MMMM DD, YYYY")}
            </Text>
          )
        )}

        <div
          className={classes.filePreview}
          onClick={() => {
            setSelectedItem(data);
            openFile();
          }}
        >
          {/* <DocViewer
            style={{ width: 150, height: 150 }}
            config={{
              header: { disableHeader: true },
            }}
            key={data?._id}
            docum<Popover width={200} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Button>Toggle popover</Button>
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="xs">This is uncontrolled popover, it is opened when button is clicked</Text>
      </Popover.Dropdown>
    </Popover>ents={[
              {
                uri: data?.fileUrl,
                fileType:
                  data?.fileUrl?.split(".")[
                    data?.fileUrl?.split(".")?.length - 1
                  ],
              },
            ]}
            pluginRenderers={DocViewerRenderers}
          /> */}
          <img src={imagePath} className={classes.folderBig} />
        </div>
        <Text
          onClick={() => {
            setSelectedItem(data);
            openFile();
          }}
          className={classes.folderDateText}
          fz="xs"
          weight={"bolder"}
        >
          {moment(data?.createdAt).format("MMMM DD, YYYY")}
        </Text>
      </div>
      <RenameFileModal
        opened={opened}
        onclose={() => {
          close();
        }}
        title={"Rename File"}
        data={selectedItem}
        client={client}
      />
      <OpenFileModal
        opened={Fileopened}
        onclose={() => {
          closeFile();
        }}
        title={""}
        data={selectedItem}
      />
      <DeleteFileModal
        opened={Deleteopened}
        onclose={() => {
          deleteClose();
        }}
        title={"Delete File"}
        data={selectedItem}
        client={client}
      />
    </div>
  );
}

const FileTable = ({ data, client }) => {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [Fileopened, { open: openFile, close: closeFile }] =
    useDisclosure(false);
  const [Deleteopened, { open: deleteOpen, close: deleteClose }] =
    useDisclosure(false);

  const ths = (
    <tr>
      <th style={{ minWidth: "400px" }}>Name</th>
      <th style={{ minWidth: "150px" }}>File Extension</th>
      <th style={{ minWidth: "200px" }}>Upload Date</th>
      <th style={{ minWidth: "200px" }}>Expiry Date</th>
      <th style={{ minWidth: "250px" }}>Location</th>
      <th style={{ minWidth: "250px" }}>Actions</th>
    </tr>
  );
  return (
    <>
      {data?.length > 0 ? (
        <Table striped fontSize={"md"}>
          <thead>{ths}</thead>
          <tbody>
            {data?.map((val, index) => (
              <tr key={val?._id}>
                <td>
                  <div className={classes.row}>
                    <img
                      src="/assets/images/file.png"
                      alt="file"
                      className={classes.fileImageRow}
                    />{" "}
                    <p>{val?.name}</p>
                    <ActionIcon
                      // disabled={userType === "staff"}
                      color="yellow"
                      variant="light"
                      onClick={(e) => {
                        setSelectedItem(val);
                        open(e);
                      }}
                    >
                      <IconEdit />
                    </ActionIcon>
                  </div>
                </td>
                <td>
                  {val?.fileUrl
                    ?.split(".")
                    [val?.fileUrl?.split(".")?.length - 1]?.toUpperCase()}
                </td>
                <td>{`${moment(val?.createdAt).format("MM/DD/YYYY")}`}</td>
                <td className={classes.expiryDateText}>
                  {val?.expiry
                    ? `${moment(val?.expiry).format("MM/DD/YYYY")}`
                    : "-"}
                </td>
                <td>{val?.folder ? val?.folder?.name : "-"}</td>
                <td>
                  <div className={classes.row} style={{ gap: 5 }}>
                    <img
                      onClick={() => {
                        setSelectedItem(val);
                        openFile();
                      }}
                      src="/assets/images/eye.png"
                      alt="view"
                      style={{ width: 30, cursor: "pointer" }}
                    />
                    <img
                      onClick={() => {
                        fetch(val?.fileUrl).then((response) => {
                          response.blob().then((blob) => {
                            const fileURL = window.URL.createObjectURL(blob);
                            let alink = document.createElement("a");
                            alink.href = fileURL;
                            alink.download = val?.name;
                            alink.click();
                          });
                        });
                      }}
                      src="/assets/images/downloadFile.png"
                      alt="download"
                      className={classes.actionsImageRow}
                    />
                    <img
                      onClick={async () => {
                        setSelectedItem(val);
                        deleteOpen();
                      }}
                      src="/assets/images/deleteFolder.png"
                      alt="delete"
                      style={{ width: 30, cursor: "pointer" }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: "60vh",
            gap: "8rem",
            textAlign: "center",
            margin: "0 auto",
          }}
        >
          <RecordNotFound />
          <p className={classes.notFoundText}>
            You don&apos;t have any files uploaded to this folder, click on
            “upload documents” to add.
          </p>
        </div>
      )}
      <RenameFileModal
        opened={opened}
        onclose={() => {
          close();
        }}
        title={"Rename File"}
        data={selectedItem}
        client={client}
      />
      <OpenFileModal
        opened={Fileopened}
        onclose={() => {
          closeFile();
        }}
        title={""}
        data={selectedItem}
      />
      <DeleteFileModal
        opened={Deleteopened}
        onclose={() => {
          deleteClose();
        }}
        title={"Delete File"}
        data={selectedItem}
        client={client}
      />
    </>
  );
};
export { FileItem, FileTable };
