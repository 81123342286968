import { ActionIcon, Group, Text, createStyles } from "@mantine/core";
import { IconEdit, IconEye, IconTrash, IconMobiledata } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { getPlans, movePlanToActive, removePlan } from "../../services/supportplan";
import { setAddPatientLoading } from "../../store/reducers/patient";
import moment from "moment";
import { getNotes } from "../../services/note";

const useStyles = createStyles((theme) => ({
  border: {
    borderLeft: "1px solid #F2F2F2",
  },
}));

const PlanTableRow = ({
  row,
  open,
  setView,
  openView,
  segment,
  currentPage,
  client,
}) => {
  const { classes } = useStyles();
  const { profile } = useSelector((state) => state.profile);
  // let userType = profile?.user?.type;

  const Parameters = ({ parameters }) => {
    if (parameters?.length > 1)
      return (
        <Text
          className={classes.cell}
          style={{ display: "flex", alignItems: "center", gap: 20 }}
        >
          {parameters?.[0]?.outcomeTitle}
          <div
            style={{
              background: "#F0F0F0",
              borderRadius: 5,
              padding: 5,
              width: 50,
              color: "#A8A8A8",
              fontSize: 14,
            }}
          >
            + {parameters?.length - 1}
          </div>
        </Text>
      );

    return (
      <Text className={classes.cell}>
        {parameters?.map((val) => val?.outcomeTitle)?.join("")}
      </Text>
    );
  };

  // remove function
  const moveToActive = async () => {
    let res;
    res = await movePlanToActive(row?._id);

    if (res?.status === 201) {
      getPlans(currentPage, client, segment?.toLowerCase());
      getNotes(1, client, false);
    } else if (res?.status === 200) {
      swal({
        title: res?.data?.message,
        text: "",
        icon: "info",
        dangerMode: false,
        buttons: ["Cancel", "Ok"],
      });
    }
  };

  // remove function
  const remove = async () => {
    const willDelete = await swal({
      title: `Are you sure?`,
      text: "This action cannot be undone.",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    });
    if (willDelete) removePlan(row?._id, row?.client?._id);
    getPlans(currentPage, client, segment?.toLowerCase());
    getNotes(1, client, false)

  };

  function handleRowClick(event) {
    setView(true);
    openView(row);
  }
  return (
    <>
      {segment === "active" ? (
        <tr
          style={{
            cursor: "pointer",
            background: row?.internalDiscontinue !== true ? "#fff" : "#F0F0F0",
          }}
        >
          <td onClick={handleRowClick}>
            {row?.planType?.name || <Text align="center">-</Text>}
          </td>
          <td onClick={handleRowClick}>
            {row?.client?.name || <Text align="center">-</Text>}
          </td>
          <td onClick={handleRowClick}>
            {row?.facility?.name || <Text align="center">-</Text>}
          </td>
          <td onClick={handleRowClick}>
            <Parameters
              parameters={row?.outcomes || <Text align="center">-</Text>}
            />
          </td>
          <td>
            <Group>
              <ActionIcon
                id="view-care-plan"
                title="view"
                color="yellow"
                variant="light"
                onClick={(e) => {
                  setView(true);
                  openView(row);
                }}
              >
                <IconEye />
              </ActionIcon>

              <ActionIcon
                id="update-care-plan"
                disabled={profile?.user?.type === "staff" && profile?.user?.role !== "admin"}
                color="green"
                title="edit"
                variant="light"
                onClick={(e) => {
                  open(row);
                  // dispatch(setAddPatientLoading(false));
                  // setView(false);
                }}
              >
                <IconEdit />
              </ActionIcon>
              <ActionIcon
                title="delete"
                disabled={profile?.user?.type === "staff" && profile?.user?.role !== "admin"}
                color="red"
                variant="light"
                onClick={remove}
              >
                <IconTrash />
              </ActionIcon>
            </Group>
          </td>
        </tr>
      ) : segment === "discontinued" ? (
        <tr style={{ cursor: "pointer" }}>
          <td>{row?.planType?.name || "-"}</td>
          <td>
            {moment(row?.startDate).format("DD/MM/YYYY") || (
              <Text align="center">-</Text>
            )}
          </td>
          <td>
            {moment(row?.discontinueDate).format("DD/MM/YYYY") || (
              <Text align="center">-</Text>
            )}
          </td>
          <td>{row?.discontinueReason || <Text align="center">-</Text>}</td>
          <td>
            <ActionIcon
              title="view"
              color="yellow"
              variant="light"
              onClick={(e) => {
                setView(true);
                openView(row);
              }}
            >
              <IconEye />
            </ActionIcon>
          </td>
        </tr>
      ) : segment === "draft" ? (
        <tr
          style={{
            cursor: "pointer",
            background: row?.internalDiscontinue !== true ? "#fff" : "#F0F0F0",
          }}
        >
          <td onClick={handleRowClick}>
            {row?.planType?.name || <Text align="center">-</Text>}
          </td>
          <td onClick={handleRowClick}>
            {row?.client?.name || <Text align="center">-</Text>}
          </td>
          <td onClick={handleRowClick}>
            {row?.facility?.name || <Text align="center">-</Text>}
          </td>
          <td onClick={handleRowClick}>
            <Parameters
              parameters={row?.outcomes || <Text align="center">-</Text>}
            />
          </td>
          <td>
            <Group>
              <ActionIcon
                title="setActive"
                color="blue"
                variant="light"
                onClick={moveToActive}
              >
                <IconMobiledata />
              </ActionIcon>
              <ActionIcon
                title="view"
                color="yellow"
                variant="light"
                onClick={(e) => {
                  setView(true);
                  openView(row);
                }}
              >
                <IconEye />
              </ActionIcon>

              <ActionIcon
                disabled={profile?.user?.type === "staff" && profile?.user?.role !== "admin"}
                color="green"
                title="edit"
                variant="light"
                onClick={(e) => {
                  open(row);
                  // dispatch(setAddPatientLoading(false));
                  // setView(false);
                }}
              >
                <IconEdit />
              </ActionIcon>
              <ActionIcon
                title="delete"
                disabled={profile?.user?.type === "staff" && profile?.user?.role !== "admin"}
                color="red"
                variant="light"
                onClick={remove}
              >
                <IconTrash />
              </ActionIcon>
            </Group>
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default PlanTableRow;
