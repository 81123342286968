import { https } from "../http";
import store from "../store";

import {
  setPlans,
  setAllPlans,
  setLoading,
  setAddPlanLoading,
  setUpdatePlanLoading
} from "../store/reducers/supportplan";
import { decrypt, showError } from "../utils/index";

// GET
const getPlans = async (page, client, segment , showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(
      client ? `/plan/${page}?client=${client}&segment=${segment}` : `/plan/${page}`
    );

    if (response?.status === 200) {
      let data = decrypt(response?.data?.data);

      const decrypted = data.plans?.map((v) => {
        if (v?.client) {
          if (
            (v?.client?.name).includes("+") ||
            (v?.client?.name).includes("/") ||
            (v?.client?.name).includes("=")
          ) {
            return {
              ...v,
              client: {
                ...v.client,
                name: decrypt(v.client?.name, false),
              },
            };
          } else return v;
        } else return v;
      });
      store.dispatch(
        setPlans({
          ...data,
          plans: decrypted,
        })
      );
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

const getAllPlans = async (showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(`/plan/all`);

    if (response?.status === 200) {
      let data = decrypt(response?.data?.data);

      const decrypted = data.plans?.map((v) => {
        if (v?.client) {
          if (
            (v?.client?.name).includes("+") ||
            (v?.client?.name).includes("/") ||
            (v?.client?.name).includes("=")
          ) {
            return {
              ...v,
              client: {
                ...v.client,
                name: decrypt(v.client?.name, false),
              },
            };
          } else return v;
        } else return v;
      });

      store.dispatch(
        setAllPlans({
          ...data,
          plans: decrypted,
        })
      );
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// POST
const addPlan = async (data) => {
  try {
    store.dispatch(setAddPlanLoading(true));
    let response = await https().post("/plan", data);

    if (response?.status === 200) {
      store.dispatch(setAddPlanLoading(false));
      // getPlans(1, data?.client, 'Active');
    }
  } catch (error) {
    store.dispatch(setAddPlanLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// PUT
const editPlan = async (data, showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setUpdatePlanLoading(true));
    let response = await https().put(`/plan/${data?.id}`, data);

    if (response?.status === 200) {
      store.dispatch(setUpdatePlanLoading(false));
      return true
    }
  } catch (error) {
    store.dispatch(setUpdatePlanLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// UPDATE DISCONTINUE
const updateSupportDiscontinue = async (data, showLoading = true) => {
  try {
    if (showLoading) store.dispatch(setAddPlanLoading(true));
    let response = await https().post("/plan/discontinue", data);

    if (response?.status === 200) {
      store.dispatch(setAddPlanLoading(false));
    }
    return response
  } catch (error) {
    store.dispatch(setAddPlanLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// MOVE PLAN TO ACTIVE
const movePlanToActive = async (planId) => {
  try {
    let response = await https().post(`/plan/move-to-active`, {id: planId});

    // 200: active plan exist, 201: plan move to active tab
    if (response?.status === 200 || response?.status === 201) {
      store.dispatch(setAddPlanLoading(false));
      return response
    }
  } catch (error) {
    store.dispatch(setAddPlanLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// DELETE
const removePlan = async (id, clientid, callback, data) => {
  try {
    let response = await https().delete(`/plan/${id}`);

    if (response?.status === 200) {
      store.dispatch(setAddPlanLoading(false));
      callback?.();
    }
  } catch (error) {
    store.dispatch(setAddPlanLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

// GET PLAN TYPES
const getPlanTypes = async () => {
  try {
    let response = await https().get(`/plan/types/get`);
    return response.data;
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export { addPlan, editPlan, getAllPlans, removePlan, getPlans, getPlanTypes , updateSupportDiscontinue , movePlanToActive};
