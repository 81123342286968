import {
  Box,
  Button,
  Center,
  Checkbox,
  Grid,
  Group,
  Image,
  PasswordInput,
  Text,
  TextInput,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { getCookie } from "../../utils";
import useStyles from "./style";
import { useMediaQuery } from "@mantine/hooks";
import { GoogleLogin } from "@react-oauth/google";
import { googleLogin, login } from "../../services/index";
import { useEffect } from "react";
import { TrackPageExit, TrackPageView } from "../../config/mixpanelConfig";

const Login = () => {
  const { loading } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);
  const isResponsive = useMediaQuery("(max-width: 992px)");

  const { classes } = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    TrackPageView("Login");
    TrackPageExit("Login");
  }, []);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      rememberme: false,
    },

    validate: {
      email: (value) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value)
          ? null
          : "Please enter a valid email address";
      },
      password: (value) => (value ? null : "Required"),
    },
  });

  if (getCookie("token")) {
    if (profile?.user?.type !== "staff") {
      if (profile?.business && profile?.subscription) {
        return <Navigate to="/home" />;
      } else if (profile?.user?.isEmailVerified && !profile?.business) {
        // return <Navigate to="/profile" />;
        return <Navigate to="/signup" state={{ token: getCookie("token") }} />;
      } else if (profile?.business && !profile?.subscription) {
        return <Navigate to="/selectprice" />;
      }
    } else return <Navigate to="/home" />;
  }

  return (
    <Grid className={classes.container}>
      <Grid.Col xs={12} sm={12} md={5}  className={`${classes.left_pane} ${classes.bgImg}`}>
        <Box className={classes.welcome}>
          <Link to={"/"}>
            <Image
              src="/assets/images/logo2.png"
              mr={"auto"}
              fit="fill"
              className={classes.logo}
            />
          </Link>
          <Box className={classes.leftSideTitle}>
            <p className={classes.titleText}>
              Simplifying <br /> Healthcare <br /> Documentation
            </p>
          </Box>
          <Text c="#000" ff={"Inter"} fz={20} fw={400}>
            Join us today to streamline your documentation process and enhance
            patient care.
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col xs={12} sm={12} md={7} className={classes.right_pane}>
        <Box className={classes.myform}>
          {isResponsive && (
            <Image
              src="assets/images/logo2.png"
              alt="logo"
              width={150}
              height={50}
              fit="fill"
            />
          )}
          <Text className={classes.title}>Log in</Text>
          <Text fz={20} ff={"Inter"} color="#262626">
            Let’s get you all set up so you can access your personal account.
          </Text>
          <form onSubmit={form.onSubmit((values) => login(values, navigate))}>
            <TextInput
              id="login-email"
              mt="xl"
              label="Email"
              width={100}
              variant="default"
              placeholder="EG.abc@gmail.com"
              {...form.getInputProps("email")}
              size={"lg"}
            />
            <PasswordInput
              id="login-password"
              mt="xl"
              label="Password"
              variant="default"
              placeholder="Eg.A*****"
              {...form.getInputProps("password")}
              size={"lg"}
            />
            <Box className={classes.rememberbox}>
              <Group>
                <Checkbox
                  color="yellow"
                  label="Remember me"
                  className={classes.rememberme}
                ></Checkbox>
                <Link to="/forget-password" className={classes.forgot}>
                  Forgot Password?
                </Link>
              </Group>
            </Box>
            <Button
              id="login-button"
              loading={loading}
              className={classes.login_btn}
              type="submit"
            >
              Log in
            </Button>
            <Box className={classes.toptitle}>
              <Text fw={500}>
                Don't have an account?{" "}
                <Link className={classes.signup} to={"/signup"}>
                  Sign up
                </Link>
              </Text>
            </Box>
            <Center>
              <GoogleLogin
                width={"250px"}
                size="large"
                text=""
                logo_alignment="center"
                onSuccess={(credentialResponse) => {
                  googleLogin(
                    {
                      client_id: credentialResponse?.clientId,
                      jwtToken: credentialResponse?.credential,
                    },
                    navigate
                  );
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </Center>
          </form>
        </Box>
      </Grid.Col>
    </Grid>
  );
};

export default Login;
