import {
  Divider,
  Group,
  Radio,
  ActionIcon,
  Box,
  Button,
  PasswordInput,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  Textarea,
  Pill,
  Grid,
  rem,
  NumberInput,
  Center,
  Image,
  Loader,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { decrypt, encrypt, states } from "../../utils";
import { PPUploadSvg } from "../Svgs/ppUploadSvg";
import useStyles from "./style";
import { DropDownArrowSvg } from "../Svgs/DropDownArrowSvg";
import { FaChevronRight } from "react-icons/fa6";
import { Dropzone } from "@mantine/dropzone";
import { IconCamera } from "@tabler/icons-react";
import {
  getSinglePatients,
  updateclientprofile,
  uploadClientProfile,
} from "../../services/patient";

const PersonalForm = forwardRef(
  (
    {
      title,
      edit,
      functions,
      // classes,
      view,
      options,
      setState,
      setActive,
      state,
      active,
      handleFormSubmit,
      facility,
      selectedTab,
      saveHandle,
      activeTabValue,
    },
    ref
  ) => {
    // console.log("🚀 ~ state:", state);
    const { classes } = useStyles();
    const form = useForm({
      initialValues: {
        name: "",
        firstName: "",
        lastName: "",
        middleName: "",
        gender: "",
        // dob: new Date(),
        dob: "",
        facility: null,
        address: "",
        socialSecurityNumber: "",
        personalPreference: "",
        lastFourDigits: "",
        // dietaryRestrictions: "",
        // contagious: "no",
        // patientWounds: "no",
        // isKnownIllness: "no",
        zipCode: "",
        state: "",
        admissionDate: new Date(),
      },
      validate: {
        // name: (value) => (value.trim() !== "" ? null : "Name is required"),
        // firstName: (value) => (value.trim() !== "" ? null : "First name is required"),
        // lastName: (value) => (value.trim() !== "" ? null : "Last name is required"),
        // dob: (value) => (value ? null : "Date of birth is required"),
        // facility: (value) => (value ? null : "Facility is required"),
        // address: (value) => (value.trim() !== "" ? null : "Address is required"),
        // socialSecurityNumber: (value) =>
        //   /^\d{9}$/.test(value) ? null : "Invalid Social Security Number",
        // lastFourDigits: (value) =>
        //   /^\d{4}$/.test(value) ? null : "Invalid last four digits",
        // zipCode: (value) =>
        //   /^\d{5}(-\d{4})?$/.test(value) ? null : "Invalid Zip Code",
        // state: (value) => (value.trim() !== "" ? null : "State is required"),
      },
    });
    const icon = <DropDownArrowSvg style={{ width: rem(1), height: rem(1) }} />;
    const [loading, setLoading] = useState(false);
    const [clientDetails, setClientDetails] = useState(null);
    const [dietaries, setDietaries] = useState([""]);
    const [knownAllergies, setKnownAllergies] = useState([""]);

    const [height, setHeight] = useState("");

    const handleInput = (e) => {
      let value = e.target.value;
      value = value.replace(/[^0-9']/g, "");
      if (value.split("'").length > 2) {
        return;
      }
      setHeight(value);
    };

    useEffect(() => {
      if (active === 0 && state?.form1) {
        setPatientWounds(state?.form1?.patientWounds === "yes");
        setIsKnownIllness(state?.form1?.isKnownIllness === "yes");
        if (state?.imageurl) {
          setClientDetails(state?.imageurl); // Set the encrypted image URL
        }
        if (state?.form1?.height) {
          setHeight(state?.form1?.height);
        }
        form.setValues({
          ...state?.form1,
          knownIllness :  state.form1?.knownIllness?.length > 0 && state.form1?.knownIllness?.[0]?.illness ? state.form1?.knownIllness?.map(item => item.illness) : state.form1?.knownIllness, 
          firstName: state.form1.firstName
            ? state.form1.firstName
            : state.form1?.name?.split(" ")[0] || "",
          middleName: state.form1.middleName
            ? state.form1.middleName
            : state.form1?.name?.split(" ")[1] || "",
          lastName: state.form1.lastName
            ? state.form1.lastName
            : state.form1?.name?.split(" ")[2] || "",
          dob: new Date(state?.form1.dob),
          admissionDate: state?.form1.admissionDate
            ? new Date(state?.form1.admissionDate)
            : "",
          socialSecurityNumber: decrypt(
            state?.form1.socialSecurityNumber,
            false
          ),
        });

        let knownAl =
          typeof state?.form1?.knownAllergies === "string"
            ? [""]
            : state?.form1?.knownAllergies || [""];
        let knownIll = state.form1?.knownIllness?.length > 0 && state.form1?.knownIllness?.[0]?.illness ? state.form1?.knownIllness?.map(item => item.illness) : state.form1?.knownIllness
          // typeof state?.form1?.knownIllness === "string"
          //   ? [{ illness: "", contagious: "no" }]
          //   : state?.form1?.knownIllness || [{ illness: "", contagious: "no" }];
        let diet =
          typeof state?.form1?.dietaries === "string"
            ? [""]
            : state?.form1?.dietaries || [""];

        setKnownAllergies(knownAl);
        setKnownIllness(knownIll);
        setDietaries(diet);
      }
    }, [active]);
        // console.log("🚀 ~ useEffect ~ form:", form)

    const genders = ["Male", "Female", "Other"];

    useImperativeHandle(ref, () => ({
      confirm: () => {
        let values = form.values;
        let valid = form?.validate();
        if (Object.keys(valid?.errors).length === 0) {
          let lengthToMask = (values?.socialSecurityNumber?.length || 4) - 4;
          let obj = {
            ...state,
            form1: {
              ...values,
              knownAllergies,
              facility: facility,
              dietaries,
              knownIllness,
              lastFourDigits: `${"*".repeat(
                lengthToMask
              )}${values?.socialSecurityNumber?.slice(-4)}`,
            },
          };

          setState(obj);
          setActive(1);
        }
      },
    }));

    const [knownIllness, setKnownIllness] = useState([]);

    const [isknownIllness, setIsKnownIllness] = useState(false);
    const [patientWounds, setPatientWounds] = useState(false);

    const isMobileScreen = useMediaQuery("(max-width: 600px)");
    const isLabptopScreen = useMediaQuery("(max-width: 1200px)");

    const { close } = functions;

    const onNext = (values, formName) => {
      // console.log("🚀 ~ onNext ~ values:", values)
      let valid = form?.validate();
      handleFormSubmit("personalInfo", "healthInfo");
      if (Object.keys(valid?.errors).length === 0) {
        let lengthToMask = (values?.socialSecurityNumber?.length || 4) - 4;
        setState((prevState) => ({
          ...prevState,
          form1: {
            ...values,
            ...prevState.form1,
            patientWounds: "no",
            // knownIllness: [],
            isKnownIllness: "no",
            height,
            facility,
            socialSecurityNumber: values?.socialSecurityNumber,
            lastFourDigits: `${"*".repeat(
              lengthToMask
            )}${values?.socialSecurityNumber?.slice(-4)}`,
            firstName: values?.firstName || "",
            middleName: values?.middleName || "",
            lastName: values?.lastName || "",
            name: `${values?.firstName || ""} ${values?.middleName || ""} ${
              values?.lastName || ""
            }`.trim(),
            gender: values?.gender,
            dob: values?.dob,
            address: values?.address,
            zipCode: values?.zipCode,
            state: values?.state,
            admissionDate: values?.admissionDate,
            personalPreference: values.personalPreference,
            // height: values?.height,
            weight: values?.weight,
            previousAddress: values?.previousAddress,
            // allergies: knownAllergies,
            // dietaries: dietaries,
            // knownIllness: knownIllness,
          },
        }));
        setActive(1);
      }
    };

    const onSave = () => {
      const values = form.getTransformedValues();
      let valid = form?.validate();
      // handleFormSubmit("personalInfo", "healthInfo");
      if (Object.keys(valid?.errors).length === 0) {
        let lengthToMask = (values?.socialSecurityNumber?.length || 4) - 4;

        let tempForm1 = {
          ...values,
          ...state.form1,
          patientWounds: "no",
          // knownIllness: [],
          isKnownIllness: "no",
          height,
          facility,
          socialSecurityNumber: values?.socialSecurityNumber,
          lastFourDigits: `${"*".repeat(
            lengthToMask
          )}${values?.socialSecurityNumber?.slice(-4)}`,
          firstName: values?.firstName,
          middleName: values?.middleName,
          lastName: values?.lastName,
          name: `${values?.firstName} ${values?.middleName} ${values?.lastName}`.trim(),
          gender: values?.gender,
          dob: values?.dob,
          address: values?.address,
          zipCode: values?.zipCode,
          state: values?.state,
          admissionDate: values?.admissionDate,
          personalPreference: values.personalPreference,
          // height: values?.height,
          weight: values?.weight,
          previousAddress: values?.previousAddress,
          // allergies: knownAllergies,
          // dietaries: dietaries,
          // knownIllness: knownIllness,
        };

        setState((prevState) => ({
          ...prevState,
          form1: tempForm1,
        }));
        // setActive(1);

        saveHandle(tempForm1, null, null);
      }
    };

    const handleChangeKnownAllergies = (index, value) => {
      let temp = [...knownAllergies];
      temp[index] = value;
      setKnownAllergies(temp);
    };

    const addEmptyAllergy = () => {
      let temp = [...knownAllergies];
      temp.push("");
      setKnownAllergies(temp);
    };
    const removeAllergy = (index) => {
      if (knownAllergies?.length > 1) {
        let temp = knownAllergies?.filter((v, i) => i !== index);
        setKnownAllergies(temp);
      }
    };

    const handleChangeDietary = (index, value) => {
      let temp = [...dietaries];
      temp[index] = value;
      setDietaries(temp);
    };

    const addEmptyDietary = () => {
      let temp = [...dietaries];
      temp.push("");
      setDietaries(temp);
    };
    const removeDietary = (index) => {
      if (dietaries?.length > 1) {
        let temp = dietaries?.filter((v, i) => i !== index);
        setDietaries(temp);
      }
    };

    const handleChangeKnownIllness = (index, value, type) => {
      // let temp = [...knownIllness];
      // temp[index][type] = value;
      setKnownIllness((p) =>
        p?.map((v, i) => (index === i ? { ...v, [type]: value } : v))
      );
    };

    const addEmptyKnownIllness = () => {
      let temp = [...knownIllness];
      temp.push({
        illness: "",
        contagious: "no",
      });
      setKnownIllness(temp);
    };
    const removeKnownIllness = (index) => {
      if (knownIllness?.length > 1) {
        let temp = knownIllness?.filter((v, i) => i !== index);
        setKnownIllness(temp);
      }
    };

    const openRef = useRef();

    const onChangePhoto = async (files) => {
      setLoading(true);
      if (files?.length > 0) {
        const formData = new FormData();
        formData.append("file", files[0]);
        const data = await uploadClientProfile(formData);
        // console.log("data", data);
        // const data = await getSinglePatients(id);
        setClientDetails(encrypt(data?.imageUrl));
        setLoading(false);
        // Update the image URL in the state
        const encryptedImageUrl = encrypt(data?.imageUrl);
        setClientDetails(encryptedImageUrl); // Set encrypted image in state
        setLoading(false);
        // Update form state with the new image URL
        setState((prevState) => ({
          ...prevState,
          imageurl: encryptedImageUrl, // Set encrypted image URL
        }));
        setState((prevState) => ({
          ...prevState,
          imageurl: encrypt(data?.imageUrl),
        }));
      }
    };

    return (
      <form
        onSubmit={form?.onSubmit((values) => onNext(values, "personalInfo"))}
      >
        <Text fz={20} weight={500}>
          {title}
        </Text>
        <Grid
          // cols={isMobileScreen ? 1 : 2}
          className={classes.formField}
          // sx={{
          //   marginTop: 50,
          //   paddingBottom: 30,
          // }}
        >
          <Grid.Col span={6}>
            <DateInput
              id="individual-select-admission-date"
              // maxDate={new Date()}
              label="Admission Date"
              valueFormat="MM/DD/YYYY"
              variant="unstyled"
              size="sm"
              placeholder={"mm/dd/yyyy"}
              {...form.getInputProps("admissionDate")}
              disabled={view}
              withAsterisk
              rightSection={
                <img
                  style={{ width: "30px" }}
                  src={"/assets/images/calendar-icon.png"}
                  alt="Icon"
                />
              }
              style={{ borderBottom: "2px solid #aaaaaa" }}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Box className={classes.imgUploadBox}>
              <Center>
                <Dropzone
                  onDrop={(files) => onChangePhoto(files)}
                  className={classes.drop}
                  openRef={openRef}
                  accept={{
                    "image/*": [],
                  }}
                ></Dropzone>

                {loading ? (
                  <Box className={classes.loaderBox}>
                    <Loader
                      color="yellow"
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    />
                  </Box>
                ) : (
                  <>
                    {clientDetails ? (
                      <Image
                        width={111}
                        height={111}
                        src={
                          clientDetails
                            ? decrypt(clientDetails)
                            : "/assets/images/personal2.png"
                        }
                        alt="profile image"
                        onClick={() => openRef.current?.()}
                        className={classes.dropImage}
                        //{...form.getInputProps("imageurl")}
                      />
                    ) : (
                      <PPUploadSvg
                        className={classes.imgUpload}
                        onClick={() => openRef.current?.()}
                      />
                    )}
                  </>
                )}
                {/* <IconCamera
                  color="white"
                  className={classes.camera}
                  onClick={() => openRef.current?.()}
                /> */}
              </Center>
              <Text size="sm" color="dimmed">
                Upload Profile Picture
              </Text>
            </Box>
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              id="individual-first-name"
              className={classes.myfont}
              label="First Name"
              withAsterisk
              size="sm"
              placeholder="Enter First Name"
              {...form.getInputProps("firstName")}
              disabled={view}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              id="individual-middle-name"
              className={classes.myfont}
              label="Middle Name"
              size="sm"
              placeholder="Enter Middle Name"
              {...form.getInputProps("middleName")}
              disabled={view}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              id="individual-last-name"
              className={classes.myfont}
              label="Last Name"
              withAsterisk
              size="sm"
              placeholder="Enter Last Name"
              {...form.getInputProps("lastName")}
              disabled={view}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <DateInput
              id="date-of-birth"
              maxDate={new Date()}
              defaultDate={""}
              className={classes.myfont}
              label="Date of birth"
              valueFormat="MM/DD/YYYY"
              size="sm"
              placeholder="mm/dd/yyyy"
              {...form.getInputProps("dob")}
              disabled={view}
              variant="unstyled"
              style={{ borderBottom: "2px solid #aaaaaa" }}
              withAsterisk
              rightSection={
                <img
                  style={{ width: "30px" }}
                  src={"/assets/images/calendar-icon.png"}
                  alt="Icon"
                />
              }
            />
          </Grid.Col>
          <Grid.Col span={isMobileScreen ? 6 : 4 || isLabptopScreen ? 4 : 1}>
            <PasswordInput
              id="individual-password"
              className={classes.myfont}
              label="SSN"
              size="sm"
              autoComplete="new-password"
              placeholder="xxx-xx-xxxx"
              {...form.getInputProps("socialSecurityNumber")}
            />
          </Grid.Col>

          {/* <Select
            id="individual-facility-name"
            className={classes.myfont}
            label="Facility Name *"
            size="sm"
            data={options}
            autoComplete="off"
            icon={<img src="/assets/images/building.png" alt="home" />}
            placeholder="Select Facility Name"
            {...form.getInputProps("facility")}
            searchable
            disabled={view}
          /> */}
        </Grid>
        <Grid mt={10}>
          <Grid.Col span={isMobileScreen ? 6 : 1 || isLabptopScreen ? 4 : 1}>
            <Select
              id="individual-gender"
              searchable
              label="Gender"
              data={genders}
              size="xs"
              placeholder="Select Gender"
              {...form.getInputProps("gender")}
              disabled={view}
              className={classes.select}
              variant="filled"
              // required
              rightSection={icon}
            />
          </Grid.Col>
          <Grid.Col span={isMobileScreen ? 6 : 1 || isLabptopScreen ? 4 : 1}>
            <Box className={classes.heightWidthInput}>
              {/* <NumberInput
                {...form.getInputProps("height")}
                label="Height"
                placeholder="00'00"
                hideControls
                type="text"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9']/g, "");
                }}
              /> */}
              <TextInput
                id="individual-height"
                label="Height"
                placeholder="00'00"
                value={height}
                onInput={handleInput}
              />
              <NumberInput
                {...form.getInputProps("weight")}
                id="individual-weight"
                label="Weight"
                placeholder="lbs"
                hideControls
                type="number"
              />
            </Box>
          </Grid.Col>
        </Grid>
        <Grid mt={10}>
          <Grid.Col span={6}>
            <TextInput
              id="individual-previous-address"
              className={classes.myfont}
              label="Previous Address"
              size="sm"
              placeholder="Entry Previous Address"
              {...form.getInputProps("previousAddress")}
              disabled={view}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Box className={classes.heightWidthInput}>
              <Select
                id="individual-state"
                searchable
                label="State"
                data={states}
                size="xs"
                placeholder="Select State"
                {...form.getInputProps("state")}
                disabled={view}
                className={classes.select}
                variant="filled"
                // required
                rightSection={icon}
              />
              <TextInput
                id="individual-zip-code"
                label="Zip Code"
                size="xs"
                placeholder="Enter Zip Code"
                {...form.getInputProps("zipCode")}
                disabled={view}
                className={classes.select}
                variant="filled"
              />
            </Box>
          </Grid.Col>
        </Grid>
        <Grid mt={10}>
          <Grid.Col>
            <Box className={classes.textInputBox}>
              <Text className="label">Personal Preference</Text>
              <Textarea
                id="add-care-activity"
                autosize={view}
                minRows={4}
                maxRows={10}
                size="sm"
                disabled={view}
                {...form.getInputProps("personalPreference")}
                // placeholder="Support activity"
                // value={act?.supportActivity}
                // onChange={(e) => {
                //   handleactivityChange(
                //     out?.id,
                //     "supportActivity",
                //     e?.target?.value,
                //     act?.id
                //   );
                // }}
              />
            </Box>
          </Grid.Col>
        </Grid>
        {view ? (
          <div style={{ height: "30px" }} />
        ) : (
          <SimpleGrid cols={3} className={classes.btns}>
            <Button
              variant="white"
              color="yellow"
              size="sm"
              onClick={close}
              styles={(theme) => ({
                root: {
                  color: "#000",
                },
                fontFamily: "inter",
              })}
            >
              Go Back
            </Button>
            <Box className={classes.centerBTN}>
              <Button
                color="yellow"
                size="sm"
                variant="outline"
                radius="sm"
                styles={(theme) => ({
                  root: {
                    color: "#000",
                    borderColor: "#FFBA45",
                  },
                })}
                onClick={() => {
                  handleFormSubmit("", "healthInfo");
                }}
              >
                Add Later
              </Button>
              {edit && (
                <Button
                  id="edit-save"
                  color="yellow"
                  radius="sm"
                  size="sm"
                  // disabled={selectedTab?.disable === true ? false : true}
                  onClick={() => {
                    onSave();
                  }}
                >
                  Save
                </Button>
              )}
            </Box>
            <Button
              id="individual-next"
              radius="sm"
              size="sm"
              color="yellow"
              type="submit"
              styles={(theme) => ({
                root: {
                  color: "#000",
                },
              })}
            >
              Next
              <FaChevronRight />
            </Button>
          </SimpleGrid>
        )}
      </form>
    );
  }
);

PersonalForm.displayName = "PersonalForm";
export default PersonalForm;
