import { ActionIcon, Box, Group, Text, createStyles, rem } from "@mantine/core";
import { IconEye, IconTrash } from "@tabler/icons-react";

import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import swal from "sweetalert";
import { removeReport } from "../../services/report";
import { formatDate, showError } from "../../utils";
import ReportModal from "../ReportModal";
import ReportViewModal from "../ReportViewModal";
import moment from "moment";

const useStyles = createStyles((theme) => ({
  border: {
    borderLeft: "1px solid #F2F2F2",
  },
  action: {
    paddingTop: rem(12),
    paddingBottom: rem(12),
    paddingLeft: rem(30),
    fontFamily: "Inter",
    wordBreak: "break-word",
  },
  parametersContainer: {
    background: "#F0F0F0",
    borderRadius: 5,
    padding: 5,
    width: 50,
    color: "#A8A8A8",
    fontSize: 14,
  },
  cell: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
}));
const ReportTableRow = ({ row }) => {
  const { classes } = useStyles();
  const [view, setView] = useState(false);
  const [viewEmail, setViewEmail] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  // functions
  const remove = async () => {
    const willDelete = await swal({
      title: `Are you sure?`,
      text: "This action cannot be undone.",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    });
    if (willDelete) removeReport(row);
  };

  // components
  const Parameters = ({ parameters }) => {
    if (parameters?.length > 1)
      return (
        <Text className={classes.cell}>
          {parameters?.[0]?.source?.name}
          <Box className={classes.parametersContainer}>
            + {parameters?.length - 1}
          </Box>
        </Text>
      );

    return (
      <Text className={classes.cell}>
        {parameters?.map((val) => val?.source?.name)?.join("")}
      </Text>
    );
  };
  return (
    <tr>
      <td onClick={() => setView(true)}>{row.uId || "-"}</td>
      <td onClick={() => setView(true)}>{row.name || "-"}</td>
      <td onClick={() => setView(true)}>
        <Parameters
          parameters={
            row?.reportType === "task" ? row?.taskParameters : row?.parameters
          }
        />
      </td>
      <td onClick={() => setView(true)}>{formatDate(row.startDate) || "-"}</td>
      <td onClick={() => setView(true)}>{formatDate(row.endDate) || "-"}</td>
      <td onClick={() => setView(true)}>{row.createdBy?.name || "-"}</td>
      <td>
        <Group>
          <ReportModal
            opened={opened}
            onclose={close}
            title={"Edit Report"}
            data={row}
          />
          <ReportViewModal
            data={row}
            opened={view}
            onclose={() => setView(false)}
            viewEmail={viewEmail}
            setViewEmail={setViewEmail}
          />
          {/* <ReportEmailModal viewEmail={viewEmail} setViewEmail={setViewEmail} emailState={emailState} setEmailState={setEmailState} /> */}
          <ActionIcon
            color="yellow"
            variant="light"
            onClick={() => {
              row?.parameters?.every((v) => v?.source !== null)
                ? setView(true)
                : showError("Record Source Not Found");
            }}
          >
            <IconEye id="reports-eye-button" />
          </ActionIcon>
          <ActionIcon color="red" variant="light" onClick={remove}>
            <IconTrash />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  );
};

export default ReportTableRow;
