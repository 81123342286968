import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  createStyles,
  Drawer,
  Flex,
  Input,
  Text,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import moment from "moment";
import { useSelector } from "react-redux";

const useStyles = createStyles((theme) => ({
  drawer: {
    // marginBottom: "20px",
    "& .mantine-Drawer-content": {
      // margin: "20px 20px 40px 0px",
      borderRadius: "20px",
      backgroundColor: "#E5E5E5",
    },
    "& .mantine-Drawer-header": {
      backgroundColor: "#F4F3FF",
    },
    "& .mantine-Drawer-title": {
      fontSize: "24px",
      fontWeight: "700",
      fontFamily: "Inter",
    },
  },
  imgClass: {
    borderRadius: "50px",
    width: "70px",
    height: "70px",
  },
}));

export const CommentDrawer = ({
  opened,
  close,
  title,
  setComments,
  data,
  comments,
  view,
}) => {
  // console.log("🚀 ~ data:", data)
  // console.log("🚀 ~ comments:", comments)
  const { classes } = useStyles();
  const [comment, setComment] = useState("");
  const [inputText, setInputText] = useState("");
  const [editable, setEditable] = useState(null);

  const getComment = () => {
    setComments([{_id: comments?.length + 1, text: comment}, ...comments]);
    setComment("");
  };

  const commentEditHandler = (id) => {
    let updatedComments = comments?.map((_v) => {
      if (_v?._id == id) {
        if(_v?.comment){
          _v = {
            ..._v,
            date: moment(new Date()).format("YYYY-MM-DD hh:mm:ss a"),
            comment: inputText,
          };
        }else if(_v?.text){
          _v = {
            ..._v,
            date: moment(new Date()).format("YYYY-MM-DD hh:mm:ss a"),
            text: inputText,
          };
        }
      }
      return _v;
    });
    setComments(updatedComments);
    setEditable(null);
    setInputText("");
  };

  const deleteHandler = (_v) => {
    if (typeof _v == "string") {
      let filteredComments = comments?.filter((item) => item != _v);
      setComments(filteredComments);
    } else {
      let filteredComments = comments?.filter((item) => item?._id !== _v?._id);
      setComments(filteredComments);
    }
  };

  return (
    <>
      <Drawer
        position="right"
        offset={20}
        zIndex={1000}
        radius="lg"
        width={600}
        size={600}
        opened={opened}
        onClose={close}
        title={title}
        className={classes.drawer}
      >
        {!view && (
          <>
            <Textarea
              size="md"
              autosize
              mt={20}
              mb={20}
              minRows={4}
              maxRows={10}
              radius="md"
              label="Add Comment"
              placeholder="Write your comment here"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <Flex justify="flex-end" gap={10} mt={5}>
              <Button
                onClick={close}
                variant="light"
                color="red"
                size="sm"
                radius={"md"}
              >
                Cancel
              </Button>
              <Button
                onClick={() => getComment()}
                disabled={comment ? false : true}
                variant="filled"
                color="yellow"
                size="sm"
                radius={"md"}
              >
                Save
              </Button>
            </Flex>
          </>
        )}
        {comments?.length > 0 && (
          <Text size="md" fw={700} ff={"Inter"} color="#000" mt={10}>
            Recent Comments
          </Text>
        )}
        {comments?.length > 0 &&
          comments?.map((comment, index) => (
            <Flex key={index} mt={20} gap={10} className={classes.commentBox}>
              {comment?.comment ? (
                <img
                  className={classes.imgClass}
                  src={comment?.staff?.photo ? comment?.staff?.photo : "/assets/images/personal2.png"}
                  alt="Clientphoto"
                />
              ) : data?.user?.photo ? (
                <img
                  className={classes.imgClass}
                  src={data?.user?.photo ? data?.user?.photo : "/assets/images/personal2.png"}
                  alt="Clientphoto"
                />
              ) : (
                <Avatar alt="user" size={"xl"} radius={"xl"}></Avatar>
              )}
              <Flex
                direction="column"
                gap={5}
                className={classes.commentContent}
              >
                <Text size="sm" color="#737373">
                  {`${moment(comment?.date).format("MM/DD/YYYY")} at ${moment(
                    comment?.date
                  ).format("hh:mm a")} `}
                  {/* {comment?.date
                    ? `${moment(comment?.date).format(
                        "MM/DD/YYYY"
                      )} at ${moment(comment?.date).format("hh:mm a")}`
                    : `${moment(data?.user?.updatedAt).format("l")} at ${moment(
                        data?.user?.updatedAt
                      ).format("LT")} `} */}
                </Text>
                <Text fz={14} color="#262626" fw={600}>
                  {comment?.staff?.name}
                  {/* {data?.user?.name} */}
                </Text>
                {editable === comment?._id ? (
                  <Input
                    defaultValue={comment?.comment ? comment?.comment : comment?.text}
                    onChange={(e) => setInputText(e?.target?.value)}
                    w={"450px"}
                  />
                ) : (
                  <Text size="sm" color="#262626">
                    {/* {typeof comment?.comment === "string"
                      ? comment?.comment
                      : typeof comment === "string"
                      ? comment
                      : ""} */}
                      {
                        comment?.comment ? comment?.comment : comment?.text
                      }
                  </Text>
                )}
                {editable == comment?._id ? (
                  <>
                    {comment?._id ? (
                      <Box>
                        <Button
                          variant="unstyle"
                          color="gray"
                          size="sm"
                          radius={"md"}
                          disabled={
                            // typeof comment == "string"
                            (comment?.staff?.id === data?.user?.id ||
                            comment?.text)
                              ? false
                              : true
                          }
                          onClick={() => commentEditHandler(comment?._id)}
                        >
                          Save
                        </Button>
                        <Button
                          variant="unstyle"
                          color="gray"
                          size="sm"
                          radius={"md"}
                          // disabled={
                          //   comment?.staff?.id === data?.user?.id ||
                          //   typeof comment == "string"
                          //     ? false
                          //     : true
                          // }
                          onClick={() => setEditable(null)}
                        >
                          Cancel
                        </Button>
                      </Box>
                    ) : (
                      ""
                    )}
                  </>
                ) : !view ? (
                  <Flex align={"center"} gap={10}>
                    {comment?._id && (
                      <Button
                        variant="unstyle"
                        color="gray"
                        size="sm"
                        radius={"md"}
                        disabled={
                          // typeof comment == "string"
                          (comment?.staff?.id === data?.user?.id || comment?.text)
                            ? false
                            : true
                        }
                        onClick={() => {
                          setEditable(comment?._id)
                          setInputText(comment?.comment ? comment?.comment : comment?.text)
                        }}
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      variant="unstyle"
                      color="gray"
                      size="sm"
                      radius={"md"}
                      disabled={
                        (comment?.staff?.id === data?.user?.id ||
                        comment?.text)
                          ? false
                          : true
                      }
                      onClick={() => deleteHandler(comment)}
                    >
                      Delete
                    </Button>
                  </Flex>
                ) : (
                  <></>
                )}
              </Flex>
            </Flex>
          ))}
      </Drawer>
    </>
  );
};
