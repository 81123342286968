import { Box, Button, Loader, Text } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import useStyles from "./style";
import moment from "moment";
import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import DateCard from "../../../../components/Date/DateCard";
import PRNMedicationModal from "../../../../components/PRNMedicationModal";
import TodayDateCard from "../../../../components/TodayDateCard";
import { getMar } from "../../../../services";
import { setMar } from "../../../../store/reducers/mar";
import ScheduleCard from "../ScheduleCard";
import UserDetail from "./UserDetail";
import MarListTab from "./MarListTab";
import Cuations from "../Cuations";
import HideClient from "../ClientHide";
import ClientColumn from "../ClientColumn";
import MonthWeekCard from "../../../../components/MonthWeekCard";
import MonthCard from "../../../../components/MonthCard";
import { useDisclosure } from "@mantine/hooks";
import AddMarReportModal from "../AddMarReportModal";
import { getAllPatients } from "../../../../services/patient";
import { MonthPickerInput } from "@mantine/dates";
import MarMedicationModal from "../../../../components/MarDetailModal/markMedication";

const MarTab = ({ userId, view, activeTab }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const dateFormat = "MM-DD-YYYY";

  const prnRef = useRef();
  const [date, setDate] = useState(new Date());
  const [month, setMonth] = useState(new Date(moment().startOf("month")));
  const [prnShow, setPRNShow] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [calendar, setCalendar] = useState(
    localStorage.getItem("calendar_view") || "monthly"
  ); // monthly - weekly - daily

  const [show, setShow] = useState(true);
  const {
    mar: appointments,
    loading,
    selectedClient,
  } = useSelector((state) => state.mar);
  const { profile } = useSelector((state) => state.profile);

  const [newCurrentWeek, setNewCurrentWeek] = useState([]);

  useEffect(() => {
    getAllPatients("all");
  }, []);

  useEffect(() => {
    const _date = Array.from(Array(7).keys()).map((idx) => {
      const d = new Date(date);
      d.setDate(d.getDate() - d.getDay() + idx);
      return moment.tz(moment(d), profile?.user?.timeZone)?.toISOString();
    });
    setNewCurrentWeek(_date);
  }, [date]);

  useEffect(() => {
    if (selectedClient?.id || userId || activeTab === "mar") getData();
  }, [date, selectedClient, userId, calendar, activeTab, view, month]);

  const getData = async () => {
    if (view === "list") {
      await getMar(
        `daily_${moment(date).format(dateFormat)}`,
        selectedClient?.id || userId
      );
    } else {
      if (calendar === "monthly") {
        const datesForMonthView = getDatesForMonthView(month);
        await getMar(
          `monthly_${datesForMonthView[0]
            .toISOString()
            .slice(0, 10)}_${datesForMonthView[34].toISOString().slice(0, 10)}`,
          selectedClient?.id || userId
        );
      } else {
        localStorage.setItem(
          "calendar_date",
          `${calendar}_${moment(date).format(dateFormat)}`
        );
        await getMar(
          `${calendar}_${moment(date).format(dateFormat)}`,
          selectedClient?.id || userId
        );
      }
    }
  };

  const handleNextPreDay = (val) => {
    let newDate = new Date(date);
    if (val === "next") {
      newDate.setDate(newDate.getDate() + 1);
    } else {
      newDate.setDate(newDate.getDate() - 1);
    }
    newDate.setHours(0, 0, 0, 0);
    setDate(newDate);
    localStorage.setItem(
      "calendar_date",
      `daily_${newDate.toISOString().slice(0, 10)}`
    );
  };

  const handleNextPreWeek = (val) => {
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() - newDate.getDay());
    if (val === "next") {
      newDate.setDate(newDate.getDate() + 7);
    } else {
      newDate.setDate(newDate.getDate() - 7);
    }
    newDate.setHours(0, 0, 0, 0);
    let newDateString = newDate.toISOString().slice(0, 10);

    setDate(newDate);
    localStorage.setItem("calendar_date", `${calendar}_${newDateString}`);
  };

  const handleMonthChange = (value) => {
    let newDate;

    if (typeof value === "string" && (value === "next" || value === "pre")) {
      newDate = new Date(month);
      newDate.setMonth(newDate.getMonth() + (value === "next" ? 1 : -1));
    } else {
      newDate = value;
    }

    newDate.setHours(0, 0, 0, 0);
    setMonth(newDate);

    const datesForMonthView = getDatesForMonthView(newDate);
    localStorage.setItem(
      "calendar_date",
      `monthly_${datesForMonthView[0]
        .toISOString()
        .slice(0, 10)}_${datesForMonthView[34].toISOString().slice(0, 10)}`
    );
  };

  const getWeekHeading = (days) => {
    const firstDayMonth = moment(days?.[0]).format("MMMM");
    const lastDayMonth = moment(days?.[6]).format("MMMM");
    const year = moment(days?.[0]).format("YYYY");
    if (firstDayMonth === lastDayMonth) {
      return `${firstDayMonth} ${year}`;
    } else {
      return `${firstDayMonth} - ${lastDayMonth} ${year}`;
    }
  };

  const handleCalendar = (val) => {
    // console.log("🚀 ~ handleCalendar ~ val:", val)
    if (val === "monthly") {
      setCalendar(val);
      localStorage.setItem("calendar_view", `${val}`);
      getDatesForMonthView(month);
    } else if (val === "weekly") {
      setCalendar(val);
      localStorage.setItem("calendar_view", `${val}`);
      localStorage.setItem(
        "calendar_date",
        `${val}_${moment(date).format(dateFormat)}`
      );
    } else if (val === "daily") {
      dispatch(setMar({ count: 0, newMar: [] }));
      setCalendar(val);
      localStorage.setItem("calendar_view", `${val}`);
      localStorage.setItem(
        "calendar_date",
        `${val}_${moment(date).format(dateFormat)}`
      );
      const _date = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`;
      setDate(_date);
    } else {
      setCalendar(val);
      localStorage.setItem("calendar_view", `${val}`);
      localStorage.setItem(
        "calendar_date",
        `${val}_${moment(date).format(dateFormat)}`
      );
    }
  };

  const handleDateConverter = (date, timeslot = null) => {
    // console.log("🚀 ~ handleDateConverter ~ timeslot:", timeslot)
    // console.log("🚀 ~ handleDateConverter ~ date:", date)
    const currentDate = new Date(date);
    const timeString = timeslot;

    if (timeslot) {
      const [time, modifier] = timeString.split(" ");
      let [hours, minutes] = time.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      }
      if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      let newdate = currentDate.setHours(hours, minutes, 0, 0);
      // console.log("newdate : " , newdate)
      return moment(newdate).format(dateFormat);
    } else {
      return moment(date).format(dateFormat);
    }
  };

  const openPRN = () => {
    setIsNew(true);
    setPRNShow(true);
  };
  const openPRNView = (data) => {
    setIsNew(false);
    prnRef?.current?.openView(data);
    setPRNShow(true);
  };
  const prnClose = () => setPRNShow(false);

  function roundTimeToNearestHalfHour(timeString) {
    // Parse the time string using Moment.js
    const time = moment(timeString, "hh:mm A");

    // Get the minutes and hours from the time
    const minutes = time.minutes();
    const hours = time.hours();

    // Round minutes to the nearest half-hour
    let roundedMinutes;
    if (minutes < 15) {
      roundedMinutes = 0;
    } else if (minutes < 45) {
      roundedMinutes = 30;
    } else {
      roundedMinutes = 0;
      time.add(1, "hours");
    }

    // Set the minutes and format the time
    time.minutes(roundedMinutes);

    // Format the time to the desired format
    const formattedTime = time.format("hh:mm A")?.toUpperCase();

    return formattedTime;
  }

  const timeSlots = [
    {
      title: "6AM - 9AM",
      startTime: "6",
      endTime: "9",
      slots: [
        "06:00 AM",
        "06:30 AM",
        "07:00 AM",
        "07:30 AM",
        "08:00 AM",
        "08:30 AM",
      ],
    },
    {
      title: "9AM - 12PM",
      startTime: "9",
      endTime: "12",
      slots: [
        "09:00 AM",
        "09:30 AM",
        "10:00 AM",
        "10:30 AM",
        "11:00 AM",
        "11:30 AM",
      ],
    },
    {
      title: "12PM - 3PM",
      startTime: "12",
      endTime: "15",
      slots: [
        "12:00 PM",
        "12:30 PM",
        "01:00 PM",
        "01:30 PM",
        "02:00 PM",
        "02:30 PM",
      ],
    },
    {
      title: "3PM - 6PM",
      startTime: "15",
      endTime: "18",
      slots: [
        "03:00 PM",
        "03:30 PM",
        "04:00 PM",
        "04:30 PM",
        "05:00 PM",
        "05:30 PM",
      ],
    },
    {
      title: "6PM - 9PM",
      startTime: "18",
      endTime: "21",
      slots: [
        "06:00 PM",
        "06:30 PM",
        "07:00 PM",
        "07:30 PM",
        "08:00 PM",
        "08:30 PM",
      ],
    },
    {
      title: "9PM - 12AM",
      startTime: "21",
      endTime: "0",
      slots: [
        "09:00 PM",
        "09:30 PM",
        "10:00 PM",
        "10:30 PM",
        "11:00 PM",
        "11:30 PM",
      ],
    },
    {
      title: "12AM - 3AM",
      startTime: "0",
      endTime: "3",
      slots: [
        "12:00 AM",
        "12:30 AM",
        "01:00 AM",
        "01:30 AM",
        "02:00 AM",
        "02:30 AM",
      ],
    },
    {
      title: "3AM - 6AM",
      startTime: "3",
      endTime: "6",
      slots: [
        "03:00 AM",
        "03:30 AM",
        "04:00 AM",
        "04:30 AM",
        "05:00 AM",
        "05:30 AM",
      ],
    },
  ];

  const weekDay = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const getDatesForMonthView = (_month) => {
    const currentMonth = moment(_month);
    const previousMonthEnd = moment(currentMonth)
      .subtract(1, "month")
      .endOf("month");

    let lastSunday = previousMonthEnd.clone().endOf("week").subtract(6, "days");
    const diffrence = currentMonth.diff(lastSunday, "days") >= 6;

    let _dates = [];
    for (let index = 0; index < 35; index++) {
      _dates.push(diffrence ? currentMonth.clone() : lastSunday.clone());
      diffrence ? currentMonth.add(1, "day") : lastSunday.add(1, "day");
    }

    localStorage.setItem(
      "calendar_date",
      `monthly_${_dates[0].toISOString().slice(0, 10)}_${_dates[34]
        .toISOString()
        .slice(0, 10)}`
    );

    return _dates;
  };

  let datesForMonthView;
  if (calendar === "monthly") {
    datesForMonthView = getDatesForMonthView(month);
  }

  const [opened, { open, close }] = useDisclosure(false);
  const [
    checkboxModalOpened,
    { open: openCheckboxModal, close: closeCheckboxModal },
  ] = useDisclosure(false);

  return (
    <>
      <div className={classes.content}>
        <div className={classes.detailBar}>
          <div className={classes.tabContainer}>
            {view !== "list" && (
              <>
                <div
                  className={`${classes.monthlyBtn} ${
                    calendar === "monthly" ? classes.activeWeekToday : ""
                  }`}
                  onClick={() => handleCalendar("monthly")}
                >
                  {/* <img src={"/assets/images/week-icon.png"} /> */}
                  <p>Month</p>
                </div>
                <div
                  id="medication-week"
                  className={`${classes.weeklyBtn} ${
                    calendar === "weekly" ? classes.activeWeekToday : ""
                  }`}
                  onClick={() => handleCalendar("weekly")}
                >
                  {/* <img src={"/assets/images/week-icon.png"} /> */}
                  <p>Week</p>
                </div>
                <div
                  id="medication-today"
                  className={`${classes.todayBtn} ${
                    calendar === "daily" ? classes.activeWeekToday : ""
                  }`}
                  onClick={() => handleCalendar("daily")}
                >
                  {/* <img src={"/assets/images/today-icon.png"} /> */}
                  <p>Today</p>
                </div>
              </>
            )}
          </div>
          {/* <div className={classes.userDetail}>
          <div className="detail_box">
            <div
              className="image_box"
              style={{
                backgroundImage: `url(${decrypt(selectedClient?.imageurl)})`,
              }}
            />
            <div>
              <h3>{selectedClient?.name}</h3>
              <p>{selectedClient?.id?.slice(0, 6)?.toUpperCase()}</p>
            </div>
          </div>
        </div> */}
        </div>

        <Box className={classes.clientButtonBar}>
          {!userId ? <HideClient show={show} setShow={setShow} /> : <Box></Box>}
          <Box className={classes.rightBtns}>
            {calendar === "monthly" && (
              <Box>
                <MonthPickerInput
                  value={month}
                  color="yellow"
                  label="Select Month"
                  placeholder="Select Month"
                  onChange={handleMonthChange}
                  sx={{
                    width: "150px",
                    marginRight: "10px",
                    marginBottom: "20px",
                  }}
                />
              </Box>
            )}
            <Button color="yellow" c={"#000"} onClick={open}>
              Create Report
            </Button>
          </Box>
        </Box>
        <div className={classes.scheduleDetail}>
          {show ? (
            <>
              {!userId && <UserDetail isClient={false} />}
              {/* {userId && <UserDetail isClient={true} />} */}
            </>
          ) : !loading ? (
            <ClientColumn />
          ) : (
            <></>
          )}
          {view === "calendar" ? (
            <div
              style={{ width: userId ? "100%" : "calc(100% - 270px)" }}
              // className={
              //   (userId && !userId) || !show
              //     ? classes.scheduleDetailRight100
              //     : classes.scheduleDetailRight
              // }
            >
              <div className={classes.wrapper}>
                {loading ? (
                  <Box className={classes.loadingBox}>
                    <Loader color="yellow" size={"md"} />
                  </Box>
                ) : (
                  <>
                    {calendar === "weekly" ? (
                      <div className={classes.mainContainer}>
                        <Box className={classes.cuationsBox}>
                          <Cuations />
                        </Box>

                        {/* DATE */}
                        <div className={classes.date}>
                          {newCurrentWeek && (
                            <p>{getWeekHeading(newCurrentWeek)}</p>
                          )}
                        </div>

                        {/* WEEK */}
                        <div
                          className={`${classes.dateSlider} _monthlyDateCarousel`}
                        >
                          <Carousel
                            showDots={false}
                            autoPlaySpeed={2000}
                            infinite={true}
                            arrows={false}
                            customButtonGroup={
                              <div className={classes.carouselButtonGroup}>
                                <img
                                  src={"/assets/images/arrowLeft.png"}
                                  onClick={() => handleNextPreWeek("pre")}
                                  alt="arrowLeft"
                                />
                                <img
                                  src={"/assets/images/arrowRight.png"}
                                  onClick={() => handleNextPreWeek("next")}
                                  alt="arrowRight"
                                />
                              </div>
                            }
                            responsive={{
                              mobile: {
                                breakpoint: { max: 4000, min: 0 },
                                items: 1,
                              },
                            }}
                            swipeable={false}
                            draggable={false}
                          >
                            <div className={`${classes.slider} _weeklySlider`}>
                              {newCurrentWeek &&
                                newCurrentWeek?.map((v, i, arr) => {
                                  const isLast = i === arr.length - 1;
                                  return (
                                    <>
                                      <DateCard
                                        key={v}
                                        date={v}
                                        data={appointments}
                                        calendar={calendar}
                                        dateFormat={dateFormat}
                                      />
                                      {!isLast && (
                                        <div className={classes.divider} />
                                      )}
                                    </>
                                  );
                                })}
                            </div>
                          </Carousel>
                        </div>

                        <Box style={{ margin: "1rem 0" }}></Box>
                        <Text
                          td="underline"
                          c="yellow"
                          ml={"5.5rem"}
                          mt={"30px"}
                          style={{ cursor: "pointer" }}
                          onClick={openCheckboxModal}
                        >
                          Select Multiple Medication
                        </Text>
                        <MarMedicationModal
                          data={appointments?.filter((x) => {
                            const startOfWeek = moment(date).startOf("week"); // Adjust to startOf('isoWeek') if weeks start on Monday
                            const endOfWeek = moment(date).endOf("week"); // Adjust to endOf('isoWeek') if weeks end on Sunday
                            const appointmentDate = moment(x?.date); // Parse the appointment date

                            return appointmentDate.isBetween(
                              startOfWeek,
                              endOfWeek,
                              null,
                              "[]"
                            ); // Include start and end of week
                          })}
                          calendar={calendar}
                          close={closeCheckboxModal}
                          opened={checkboxModalOpened}
                          date={newCurrentWeek}
                          currentDate={date}
                        />

                        {/* SCHEDULE */}
                        <div className={classes.scheduleBox}>
                          <div className={classes.column1}>
                            <p>Scheduled</p>
                          </div>
                          {newCurrentWeek &&
                            newCurrentWeek?.map((v) => {
                              return (
                                <div key={v} className={classes.column}>
                                  {appointments
                                    ?.filter(
                                      (x) =>
                                        x?.form3?.medicationType?.toUpperCase() !==
                                          "PRN" &&
                                        // moment.tz(moment(v) , profile?.user?.timeZone).format(dateFormat) ===
                                        moment(v).format(dateFormat) ===
                                          moment(x?.date).format(dateFormat)
                                      // handleDateConverter(x.date , x.timeSlot)

                                      // new Date(v).toDateString() ===
                                      // new Date(x?.date).toDateString()

                                      // moment(x?.date).tz("UTC").format(dateFormat)
                                      // moment.tz(moment(x?.date) , profile?.user?.timeZone).format(dateFormat)
                                    )
                                    // ?.sort(function (a, b) {
                                    //   // return moment.tz(moment(a?.date) , profile?.user?.timeZone) - moment.tz(moment(b?.date) , profile?.user?.timeZone);
                                    //   return moment(a?.date) - moment(b?.date);
                                    // })
                                    ?.sort((a, b) => {
                                      const timeA =
                                        a?.medicationType !== "PRN"
                                          ? moment(a?.timeSlot, "hh:mm A").format("HH:mm")
                                          : moment(a?.createdAt).format("HH:mm");
                                      const timeB =
                                        b?.medicationType !== "PRN"
                                          ? moment(b?.timeSlot, "hh:mm A").format("HH:mm")
                                          : moment(b?.createdAt).format("HH:mm");
                          
                                      return timeA.localeCompare(timeB); // Sort times in ascending order
                                    })
                                    ?.map((y, i) => {
                                      return (
                                        <ScheduleCard
                                          date={moment(v).format("MM/DD/YYYY")}
                                          key={i}
                                          data={y}
                                        />
                                      );
                                    })}
                                </div>
                              );
                            })}
                        </div>

                        <div className={classes.administerPrnBtnBox}>
                          <Button
                            id="administer-prn"
                            variant="filled"
                            className={classes.prnButton}
                            onClick={openPRN}
                          >
                            Administer PRN
                            <div className="imgDiv">
                              <img
                                src={"/assets/images/prn-button.png"}
                                style={{ width: "25px", height: "auto" }}
                                alt="prn"
                              />
                            </div>
                          </Button>
                        </div>

                        <div
                          className={classes.scheduleBox}
                          style={{ marginBottom: "30px" }}
                        >
                          <div
                            className={classes.column1}
                            style={{ backgroundColor: "#000" }}
                          >
                            <p style={{ color: "#fff" }}>PRN</p>
                          </div>

                          {newCurrentWeek &&
                            newCurrentWeek?.map((v) => {
                              return (
                                <div key={v} className={classes.column}>
                                  {appointments
                                    ?.filter(
                                      (x) =>
                                        moment(v).format(dateFormat) ===
                                          moment(x?.date).format(dateFormat) &&
                                        // moment(v).format(dateFormat) ===
                                        // handleDateConverter(x.date)
                                        x?.medicationType === "PRN"
                                    )
                                    ?.sort(function (a, b) {
                                      return moment(a?.date) - moment(b?.date);
                                    })
                                    ?.map((y, i) => {
                                      return (
                                        <ScheduleCard
                                          prn
                                          key={i}
                                          data={y}
                                          onOpen={openPRNView}
                                        />
                                      );
                                    })}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : calendar === "daily" ? (
                      <div className={classes.mainContainerDaily}>
                        <Box className={classes.cuationsBox}>
                          <Cuations />
                        </Box>

                        {/* DATE */}
                        <div className={classes.date}>
                          <p>{moment(date).format("dddd MM/DD/YYYY")}</p>
                        </div>

                        {/* WEEK */}
                        <div className={classes.dateSlider}>
                          <Carousel
                            showDots={false}
                            autoPlaySpeed={2000}
                            infinite={true}
                            arrows={false}
                            responsive={{
                              mobile: {
                                breakpoint: { max: 4000, min: 0 },
                                items: 1,
                              },
                            }}
                            swipeable={false}
                            draggable={false}
                            customButtonGroup={
                              <div className={classes.carouselButtonGroup}>
                                <img
                                  src={"/assets/images/arrowLeft.png"}
                                  onClick={() => handleNextPreDay("pre")}
                                  alt="arrowLeft"
                                />
                                <img
                                  src={"/assets/images/arrowRight.png"}
                                  onClick={() => handleNextPreDay("next")}
                                  alt="arrowRight"
                                  style={{ marginRight: "5px" }}
                                />
                              </div>
                            }
                          >
                            <div className={classes.sliderDaily}>
                              {timeSlots?.map((v, i, arr) => {
                                const isLast = i === arr.length - 1;
                                return (
                                  <>
                                    <TodayDateCard
                                      key={i}
                                      slots={v}
                                      data={appointments
                                        ?.filter(
                                          (x) =>
                                            x?.medicationType?.toUpperCase() !==
                                              "PRN" &&
                                            v?.slots?.includes(x?.timeSlot)
                                        )
                                        ?.sort(function (a, b) {
                                          return (
                                            new Date(a?.date) -
                                            new Date(b?.date)
                                          );
                                        })}
                                    />
                                    {!isLast && (
                                      <div className={classes.divider} />
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </Carousel>
                        </div>

                        <Text
                          td="underline"
                          c="yellow"
                          ml={"5.5rem"}
                          mt={"30px"}
                          style={{ cursor: "pointer" }}
                          onClick={openCheckboxModal}
                        >
                          Select Multiple Medication
                        </Text>
                        <MarMedicationModal
                          data={appointments?.filter(
                            (x) =>
                              moment(date).format(dateFormat) ===
                              moment(x?.date).format(dateFormat)
                          )}
                          calendar={calendar}
                          close={closeCheckboxModal}
                          opened={checkboxModalOpened}
                          date={moment(date).format("MMMM DD, YYYY")}
                        />
                        {/* SCHEDULE */}
                        <div className={classes.scheduleBox}>
                          <div className={classes.column1}>
                            <p>Scheduled</p>
                          </div>
                          {timeSlots?.map((v, i) => {
                            return (
                              <div
                                key={i}
                                className={classes.column}
                                style={{ width: "calc((100% - 55px) / 8)" }}
                              >
                                {appointments
                                  ?.filter(
                                    (x) =>
                                      x?.medicationType?.toUpperCase() !==
                                        "PRN" && v?.slots?.includes(x?.timeSlot)
                                  )
                                  ?.sort(function (a, b) {
                                    return (
                                      new Date(a?.date) - new Date(b?.date)
                                    );
                                  })
                                  .map((y, i) => {
                                    return (
                                      <ScheduleCard
                                        calendar={calendar}
                                        key={i}
                                        data={y}
                                      />
                                    );
                                  })}
                              </div>
                            );
                          })}
                        </div>

                        <div className={classes.administerPrnBtnBox}>
                          <Button
                            onClick={openPRN}
                            variant="filled"
                            className={classes.prnButton}
                          >
                            Administer PRN
                            <div className="imgDiv">
                              <img
                                src={"/assets/images/prn-button.png"}
                                style={{ width: "25px", height: "auto" }}
                                alt="prn-button"
                              />
                            </div>
                          </Button>
                        </div>

                        <div
                          className={classes.scheduleBox}
                          style={{ marginBottom: "30px" }}
                        >
                          <div
                            className={classes.column1}
                            style={{ backgroundColor: "#000" }}
                          >
                            <p style={{ color: "#fff" }}>PRN</p>
                          </div>
                          {timeSlots?.map((v, i) => {
                            return (
                              <div key={i} className={classes.column}>
                                {appointments
                                  ?.filter((x) => {
                                    return (
                                      x?.medicationType?.toUpperCase() ===
                                        "PRN" &&
                                      v?.slots?.includes(
                                        roundTimeToNearestHalfHour(
                                          moment(x?.createdAt)
                                            .format("hh:mm A")
                                            ?.toUpperCase()
                                        )
                                      )
                                    );
                                  })
                                  ?.sort(function (a, b) {
                                    return (
                                      new Date(a?.date) - new Date(b?.date)
                                    );
                                  })
                                  ?.map((y, i) => {
                                    return (
                                      <ScheduleCard
                                        calendar={calendar}
                                        prn
                                        key={i}
                                        data={y}
                                        onOpen={openPRNView}
                                      />
                                    );
                                  })}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      // MONTHLY
                      <div className={classes.mainContainer}>
                        <Box className={classes.cuationsBox}>
                          <Cuations />
                        </Box>

                        <Box className={classes.date}>
                          <p>{moment(month).format("MMMM YYYY")}</p>
                        </Box>

                        <div
                          className={`${classes.dateSlider2} _monthlyDateCarousel`}
                        >
                          <Carousel
                            showDots={false}
                            autoPlaySpeed={2000}
                            infinite={true}
                            arrows={false}
                            customButtonGroup={
                              <div className={classes.carouselButtonGroup}>
                                <img
                                  src={"/assets/images/arrowLeft.png"}
                                  onClick={() => handleMonthChange("pre")}
                                  alt="arrowLeft"
                                />
                                <img
                                  src={"/assets/images/arrowRight.png"}
                                  onClick={() => handleMonthChange("next")}
                                  alt="arrowRight"
                                />
                              </div>
                            }
                            responsive={{
                              mobile: {
                                breakpoint: { max: 4000, min: 0 },
                                items: 1,
                              },
                            }}
                            swipeable={false}
                            draggable={false}
                          >
                            <div className={`${classes.slider} _monthlySlider`}>
                              {weekDay?.map((v, i, arr) => {
                                const isLast = i === arr.length - 1;
                                return (
                                  <>
                                    <MonthWeekCard
                                      key={v}
                                      day={v}
                                      data={appointments}
                                    />
                                    {!isLast && (
                                      <div className={classes.divider} />
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </Carousel>
                        </div>

                        {/* SCHEDULE */}
                        <div className={classes.scheduleBoxForMonth}>
                          {weekDay.map((day, index) => {
                            return (
                              <div key={day} className={classes.columnMonth}>
                                {datesForMonthView
                                  .filter(
                                    (date) => moment(date).day() === index
                                  )
                                  ?.map((date, index) => {
                                    return (
                                      <MonthCard
                                        key={index}
                                        date={date}
                                        data={appointments?.filter(
                                          (x) =>
                                            moment(date).format(dateFormat) ===
                                            moment(x?.date).format(dateFormat)
                                          // moment(date).format(dateFormat) ===
                                          // handleDateConverter(x.date , x?.timeSlot)
                                        )}
                                        calendar={calendar}
                                      />
                                    );
                                  })}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div
              className={
                userId || !show
                  ? classes.scheduleDetailRight100
                  : classes.scheduleDetailRight
              }
            >
              <div className={classes.date}>
                {newCurrentWeek && (
                  <p style={{ textTransform: "uppercase" }}>
                    {getWeekHeading(newCurrentWeek)}
                  </p>
                )}
              </div>
              <MarListTab />
            </div>
          )}
        </div>
        <AddMarReportModal
          opened={opened}
          onclose={close}
          userId={userId}
          title={"Create New Report"}
        />
        <PRNMedicationModal
          isNew={isNew}
          ref={prnRef}
          opened={prnShow}
          onclose={prnClose}
          data={selectedClient}
        />
      </div>
    </>
  );
};

export default MarTab;
