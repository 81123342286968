import { Box, Container, createStyles } from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddButton from "../../../components/AddButton";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import PatientModal from "../../../components/PatientModal";
import PatientTable from "../../../components/PatientTable";
import Topbar from "../../../components/Topbar";
import { getAllFacilitiesModal, getAllFacilityTypes } from "../../../services";
import { getAllPatients } from "../../../services/patient";
import FacilityModal from "./FacilityModal";
import ClientForm from "./ClientForm";
import { useLocation, useNavigate } from "react-router-dom";
import { pageInfoAnalytics } from "../../../config/mixpanelConfig";


const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  formContent: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  mymodal: {
    width: 818,
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  table: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
  },
  pagination: {
    margin: "1rem 0",
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
}));
const Patient = (props) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [facility, setFacility] = useState("");
  const { patients } = useSelector((state) => state.patient);
  const { profile } = useSelector((state) => state.profile);
  const [showClientForm, setShowClientForm] = useState(false);
  const [editAll, setEditAll] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.edit) {
      setShowClientForm(true);
      setEditingPatient(location?.state?.clientDetails);

      const ids = location?.state?.clientDetails?.facilityId?.map(fac => {
        return fac?.id
      })
      // setPopulateFacility(ids)
      setUpdateFacility(ids)

    } else {
      setShowClientForm(false);
    }
  }, [location?.state]);

  // let userType = profile?.user?.type;


  useEffect(() => {
    getAllFacilitiesModal("active");
    getAllFacilityTypes();
    pageInfoAnalytics("Client Page Viewed", "Client Page");
  }, []);
  useEffect(() => {
    getAllPatients(currentPage);
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [options, setOptions] = useState([]);
  const { allFacilities } = useSelector((state) => state?.facility);
  const [
    facilityModalOpened,
    { open: openFacilityModal, close: closeFacilityModal },
  ] = useDisclosure(false);
  const [editingPatient, setEditingPatient] = useState(null);
  const [populateName, setPopulateName] = useState(null);
  const [populateFacility, setPopulateFacility] = useState(null);
  const [updateFacility, setUpdateFacility] = useState(null)  

  useEffect(() => {
    const resp = [];
    allFacilities?.facilities?.map((val) => {
      if (val?.active) {
        resp?.push({
          name: val?.name,
          id: val?.id,
        });
      } else {
        resp?.push({
          name: val?.name,
          id: val?.id,
          disabled: true,
        });
      }
    });
    setOptions(resp);
  }, [allFacilities?.facilities]);

  const handleNextClick = (value) => {
    setFacility(value?.map((val) => val?.id));
    closeFacilityModal();
    setPopulateFacility(value);
    setShowClientForm(true);
  };

  const handleBackToClients = () => {
    setShowClientForm(false);
    setEditingPatient(null);
    setFacility("");
    setPopulateName(null);
    setCurrentPage(1);
    setEditAll(false);
    getAllPatients(currentPage);
    navigate("/home/patient", { state: { edit: false, disable: false } });
  };

  const handleEditPatient = (patient) => {
    setEditAll(true);
    setShowClientForm(true);
    setEditingPatient(patient);
    setPopulateFacility(patient?.facilityId);

    if(Array.isArray(patient?.facilityId)){
      const ids = patient?.facilityId?.map(fac => {
        return fac?.id
      })
      
      // setPopulateFacility(ids)
      setUpdateFacility(ids)
    }else{
      // setPopulateFacility([patient?.facilityId?.id])
      setUpdateFacility([patient?.facilityId?.id])
    }

  };

  const handleOpenModalClick = (patient) => {
    // console.log("patient form1 facility", patient?.form1)
    // setPopulateFacility(patient?.form1?.facility);
    openFacilityModal();
    // setShowClientForm(false);
  };
  return (
    <Container fluid className={classes.content} px={20}>
      {showClientForm ? (
        <Box className={classes.formContent}>
          <Topbar
            populateName={populateName}
            initialValues={editingPatient}
            populateFacility={populateFacility}
            backAllClients={handleBackToClients}
            onEditFacilityClick={handleOpenModalClick}
            title={editingPatient ? "Edit Client" : "Add Client"}
          />
          <ClientForm
            opened={opened}
            page={currentPage}
            facility={facility}
            currentPage={currentPage}
            close={handleBackToClients}
            initialValues={editingPatient}
            getAllPatients={getAllPatients}
            setPopulateName={setPopulateName}
            backAllClients={handleBackToClients}
            setShowClientForm={setShowClientForm}
            selectedTab={editAll ? false : location.state}
            buttonLabel={editingPatient ? "Update Client" : "Add Client"}
          />
        </Box>
      ) : (
        <>
          <PatientModal
            close={close}
            opened={opened}
            page={currentPage}
            title={"Add New Individual"}
          />
          {/* <FacilityModal
            options={options}
            onNext={handleNextClick}
            close={closeFacilityModal}
            title={"Select a Facility"}
            opened={facilityModalOpened}
            setPopulateFacility={setPopulateFacility}
            initialSelectedFacilities={populateFacility}
          /> */}
          <Topbar title={"Client"} />
          <Box className={classes.btn}>
            {/* {userType !== "staff" ? ( */}
            {profile?.user?.type !== "staff" ||
            (profile?.user?.type === "staff" &&
              profile?.user?.role === "admin") ? (
              <AddButton
                title={"Add Client"}
                // onClick={open}
                onClick={openFacilityModal}
              />
            ) : null}
          </Box>
          <Box className={classes.table}>
            <PatientTable onEdit={handleEditPatient} />
            <CustomPagination
              itemsPerPage={10}
              data={patients?.count}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          </Box>
        </>
      )}
      <FacilityModal
        options={options}
        onNext={handleNextClick}
        close={closeFacilityModal}
        opened={facilityModalOpened}
        setUpdateFacility={setUpdateFacility}
        setEditingPatient={setEditingPatient}
        setPopulateFacility={setPopulateFacility}
        initialSelectedFacilities={populateFacility}
        editPatient={editingPatient ? editingPatient : false}
        editFacility={editingPatient ? updateFacility : false}
        title={editingPatient ? "Update Facility" : "Select a Facility"}
      />
    </Container>
  );
};

export default Patient;
