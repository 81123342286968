import React from "react";
import { Card, Skeleton, Text, createStyles } from "@mantine/core";
import { useSelector } from "react-redux";

const useStyles = createStyles((theme) => ({
  mycard: {
    textAlign: "center",
   
  },
  stock: {
    fontFamily: "Inter",
    fontSize: 42,
    fontWeight: 600,
    color: "#232323",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 400,
    color: "#A8A8A8",
  },
  desc: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 400,
    color: "#A8A8A8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },
  total: {
    fontFamily: "Inter",
    fontSize: 22,
    fontWeight: 600,
    color: "#232323",
  },
  skeleton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const DashCard = (props) => {
  const { classes } = useStyles();
  const { src="Default src", stock="", title="Default title", desc="Default description", total=0 } = props;
  const { loadingCount } = useSelector((state) => state.dashboard);

  // Convert stock to a string
  const stockString = stock?.toString();

  // Check if the stock is a single digit
  const formattedStock = stockString?.length === 1 ? `0${stockString}` : "";

  // Utility function for formatting stock numbers
  const formatStock = (total) => {
    const totalString = total?.toString();
    return totalString?.length === 1 ? `0${totalString}` : totalString;
  };
  const formattedTotal = formatStock(total);

  // console.log("total =>",total)

  return (
    <Card className={classes.mycard} radius={10} shadow="sm">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <img src={src} alt="" data-testid="default-src"/>
        {loadingCount ? (
          <Skeleton className={classes.skeleton} height={50} circle mb="md" />
        ) : (
          <Text className={classes.stock} data-testid="default-stock">{formattedStock}</Text>
        )}
      </div>
      <Text className={classes.title} mt={8} data-testid="default-title" >
        {title}
      </Text>
      <Text className={classes.desc} mt={48.5} data-testid="default-desc">
        {`${desc}: `}
        {loadingCount ? (
          <Skeleton width={30} height={30} />
        ) : (
          <span className={classes.total} >{formattedTotal}</span>
        )}
      </Text>
    </Card>
  );
};

export default DashCard;
