import { createStyles, rem } from "@mantine/core";
import { IconSelector } from "@tabler/icons-react";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { formatDate, getOptions, noteType_Client } from "../../utils";
import TableLoader from "../TableLoader";
import moment from "moment";

const useStyles = createStyles((theme) => ({
  header: {
    color: "white",
    fontFamily: "Inter",
    fontStyle: "medium",
    fontSize: 20,
    fontWeight: 500,
    backgroundColor: "#232323",
    paddingTop: rem(15),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    marginTop: rem(30),
    width: "100%",
    background: "purple",
  },
  cell: {
    border: "1px solid #EDEDED",
    fontFamily: "Inter",
    fontSize: 14,
    padding: "1rem",
    width: "max-content",
  },
  outcomeCell: {
    border: "1px solid #cbced7",
    fontFamily: "Inter",
    fontSize: 16,
    padding: "1rem",
    width: "max-content",
  },
  control: {
    color: "white",
    background: "green",
  },
  tableContainer: {
    width: "1150px",

    overflowX: "auto",

    "@media (min-width: 1441px)": {
      width: "1320px",
    },
    // "@media (max-width :1024px)": {
    //   width: "900px"
    // },
    // "@media (max-width :800px)": {
    //   width: "520px"
    // }
  },
  thClass: {
    display: "flex",
    alignItems: "center",
    width: "200px",
    gap: "1rem",
    padding: "1rem",
  },
}));

const ReportViewTable = forwardRef(({ data, tables, onFiltered }, ref) => {
  // console.log("🚀 ~ ReportViewTable ~ tables:", tables);
  // console.log("🚀 ~ ReportViewTable ~ data:", data);
  const { classes } = useStyles();
  const [headers, setHeaders] = useState([]);

  const { loadingNote_Types, notesAgainstTypes, allNotesTypes } = useSelector(
    (state) => state?.note
  );

  // console.log("🚀 ~ note ~ notesAgainstTypes:", notesAgainstTypes);

  // console.log("headers?.data", headers?.data);

  useImperativeHandle(ref, () => ({
    applyFilters: applyFilters,
  }));

  useEffect(() => {
    if (data && notesAgainstTypes?.notes) {
      let notes = notesAgainstTypes?.notes;

      let customs = tables?.map((value) => {
        if (value?.source?.id === data?.source?.id) {
          if (data?.fields?.length > 0)
            return value?.source?.custom?.filter((val) =>
              data?.fields?.includes(val?._id)
            );
          else return value?.source?.custom;
        } else return value?.source?.custom;
      });

      let sortedData = notes
        ?.filter((item) => item.fields.Date)
        ?.sort(function (a, b) {
          return new Date(b?.fields?.Date) - new Date(a?.fields?.Date);
        });
      let withoutDates = notes?.filter((item) => !item.fields.Date);

      setHeaders({
        headers: mergeArrays(customs),
        data: [...sortedData, ...withoutDates],
      });
      sendFiltersBack({
        headers: data?.source?.custom,
        data: [...sortedData, ...withoutDates],
      });
    }
  }, [data?.source?.id, notesAgainstTypes]);

  const mergeArrays = (arrays) => {
    const uniqueFields = {};

    arrays.forEach((array) => {
      array.forEach((obj) => {
        const key = obj.field;
        if (!uniqueFields[key]) {
          uniqueFields[key] = obj;
        }
      });
    });
    return Object.values(uniqueFields);
  };

  const applyFilters = (filters) => {
    if (data && notesAgainstTypes?.notes) {
      let notes = notesAgainstTypes?.notes;
      if (Array.isArray(filters) && filters?.length) {
        const toFilter = {};

        let sources = {};
        filters?.forEach((v) => {
          let array = filters?.filter(
            (element) => element?.source == v?.source
          );

          let _filters = {};
          array?.forEach((element) => {
            _filters[element?.field] = element?.filterOn;
          });
          sources[v?.source] = _filters;

          if (
            Object.keys(_filters)?.length === 1 &&
            Object.entries(_filters)?.every(
              (v) => v?.[0] === "" || v?.[0] === undefined
            )
          ) {
            sources[v?.source] = notes?.filter(
              (note) => note?.noteId === v?.source
            );
          } else {
            sources[v?.source] = notes?.filter((note) => {
              let condition = [];
              Object.entries(_filters)?.forEach((value) => {
                let patient =
                  value[0] === "Client Name"
                    ? note?.fields?.["Patient Name"] &&
                      !note?.fields?.["Client Name"]
                      ? "Patient Name"
                      : note?.fields?.["Patient Name"] &&
                        note?.fields?.["Client Name"]
                      ? "Client Name"
                      : "Client Name"
                    : value[0];

                if (
                  `${note?.fields[patient]}`?.toLowerCase() ===
                  `${value[1]}`?.toLowerCase()
                )
                  condition.push(true);
                else condition.push(false);
              });

              return condition?.every((value) => value === true);
            });
          }
        });

        filters?.forEach((element) => {
          toFilter[element?.field] = element?.filterOn;
        });

        notes = [];
        Object.entries(sources)?.forEach((value) => {
          notes = [...notes, ...value[1]];
        });
      }

      let customs = tables?.map((value) => {
        if (value?.source?.id === data?.source?.id) {
          if (data?.fields?.length > 0)
            return value?.source?.custom?.filter((val) =>
              data?.fields?.includes(val?._id)
            );
          else return value?.source?.custom;
        } else return value?.source?.custom;
      });

      let sortedData = notes
        ?.filter((item) => item.fields.Date)
        ?.sort(function (a, b) {
          return new Date(b?.fields?.Date) - new Date(a?.fields?.Date);
        });
      let withoutDates = notes?.filter((item) => !item.fields.Date);

      setHeaders({
        headers: mergeArrays(customs),
        data: [...sortedData, ...withoutDates],
      });
      sendFiltersBack({
        headers: data?.source?.custom,
        data: [...sortedData, ...withoutDates],
      });
    }
  };

  const sendFiltersBack = (headers) => {
    const heads = headers?.headers?.map((value) => value?.field);

    let csvData = headers?.data?.map((row, index) =>
      headers?.headers?.map((value) => {
        if (value?.options) {
          let options = getOptions(
            value?.options,
            allNotesTypes?.ntypes?.find((v) => v?.id === row?.noteId)?.custom
          );
          return options?.find((op) => op?.value === row?.fields[value?.field])
            ?.label;
        }
        return formatDate(row?.fields[value?.field]);
      })
    );
    if (csvData?.length && data?.source) {
      onFiltered(data?.source?.id, data?.source, [heads, ...csvData]);
    }
  };

  const rows = headers?.data?.map((row, index) => {
    if (row?.descriptions?.length > 0 && row?.supportPlan === "yes")
      return row?.descriptions?.map((outcome, outcomeIndex) => {
        return (
          <tr key={`${index}${Math.random()}`}>
            {outcomeIndex === 0 && (
              <td
                rowSpan={
                  row?.descriptions?.length ? row?.descriptions?.length : 1
                }
                className={classes.cell}
              >
                {
                  allNotesTypes?.ntypes?.find((v) => v?.id === row?.noteId)
                    ?.name
                }
              </td>
            )}

            {outcomeIndex === 0 &&
              headers?.headers?.map((value, i) => {
                if (value?.options) {
                  let options = getOptions(
                    value?.options,
                    allNotesTypes?.ntypes?.find((v) => v?.id === row?.noteId)
                      ?.custom,
                    value?.field
                  );
                  let patient =
                    value.field === "Client Name"
                      ? row?.fields?.["Patient Name"] &&
                        !row?.fields?.["Client Name"]
                        ? "Patient Name"
                        : row?.fields?.["Patient Name"] &&
                          row?.fields?.["Client Name"]
                        ? "Client Name"
                        : "Client Name"
                      : value.field;
                  return (
                    <td
                      rowSpan={
                        row?.descriptions?.length > 0
                          ? row?.descriptions?.length
                          : 1
                      }
                      className={classes.cell}
                      key={i}
                    >
                      {
                        options?.find(
                          (op) => op?.value === row?.fields[patient]
                        )?.label
                      }
                    </td>
                  );
                }
                if (value?.field !== "Description")
                  return (
                    <td
                      rowSpan={
                        row?.descriptions?.length > 0
                          ? row?.descriptions?.length
                          : 1
                      }
                      className={classes.cell}
                      key={i}
                    >
                      {value?.field === "Description" ? (
                        <></>
                      ) : value?.field === "Time" ? (
                        moment(row?.fields["Time"], "HH:mm:ss").format(
                          "hh:mm:ss a"
                        )
                      ) : (
                        formatDate(row?.fields[value?.field])
                      )}
                    </td>
                  );
              })}
          </tr>
        );
      });
    else {
      return (
        <>
          <tr key={index}>
            <td
              rowSpan={
                row?.descriptions?.length ? row?.descriptions?.length : 1
              }
              className={classes.cell}
            >
              {allNotesTypes?.ntypes?.find((v) => v?.id === row?.noteId)?.name}
            </td>

            {headers?.headers?.map((value, i) => {
              if (value?.options) {
                let options = getOptions(
                  value?.options,
                  allNotesTypes?.ntypes?.find((v) => v?.id === row?.noteId)
                    ?.custom,
                  value?.field
                );
                let patient =
                  value.field === "Client Name"
                    ? row?.fields?.["Patient Name"] &&
                      !row?.fields?.["Client Name"]
                      ? "Patient Name"
                      : row?.fields?.["Patient Name"] &&
                        row?.fields?.["Client Name"]
                      ? "Client Name"
                      : "Client Name"
                    : value.field;
                return (
                  <td
                    rowSpan={
                      row?.descriptions?.length > 0
                        ? row?.descriptions?.length
                        : 1
                    }
                    className={classes.cell}
                    key={i}
                  >
                    {
                      options?.find((op) => op?.value === row?.fields[patient])
                        ?.label
                    }
                  </td>
                );
              }

              return (
                <td
                  rowSpan={
                    row?.descriptions?.length > 0
                      ? row?.descriptions?.length
                      : 1
                  }
                  className={classes.cell}
                  key={i}
                >
                  {value?.field === "Time"
                    ? moment(row?.fields["Time"], "HH:mm:ss").format(
                        "hh:mm:ss a"
                      )
                    : formatDate(row?.fields[value?.field])}
                </td>
              );
            })}
          </tr>
        </>
      );
    }
  });

  const sizes = {
    Description: "300px",
    "Staff Name": "150px",
    Date: "100px",
    Time: "100px",
    "Facility Name": "150px",
    Shift: "100px",
    [noteType_Client]: "150px",
  };

  return (
    <div className={classes.tableContainer}>
      <table
        id="sampleTable"
        style={{ width: "100%", border: "1px solid #EDEDED" }}
      >
        <tr style={{ background: "#000", color: "#fff" }}>
          <th>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                width: "150px",
                gap: "1rem",
                padding: "1rem",
              }}
            >
              Note Type
            </span>
          </th>
          {headers?.headers?.map((header, index) => {
            return (
              <th key={index}>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: sizes[header?.field] || "150px",
                    gap: "1rem",
                    padding: "1rem",
                  }}
                >
                  {header?.field}
                </span>
              </th>
            );
          })}
        </tr>

        {loadingNote_Types ? <TableLoader /> : rows}
      </table>
      <table
        id="hiddenTable"
        style={{
          width: "100%",
          border: "1px solid #EDEDED",
          position: "absolute",
          top: "-10000000px",
          left: "-10000000px",
        }}
      >
        <tr style={{ background: "#000", color: "#fff" }}>
          <th>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                width: "150px",
                gap: "1rem",
                padding: "1rem",
              }}
            >
              Note Type
            </span>
          </th>
          {headers?.headers?.map((header, index) => {
            return (
              <th key={index}>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: sizes[header?.field] || "150px",
                    gap: "1rem",
                    padding: "1rem",
                  }}
                >
                  {header?.field}
                </span>
              </th>
            );
          })}
        </tr>

        {loadingNote_Types ? <TableLoader /> : rows}
      </table>
    </div>
  );
});

export default ReportViewTable;
