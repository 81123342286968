import React, { useEffect } from "react";
import { Card, Container, Grid, SimpleGrid } from "@mantine/core";

import { useSelector } from "react-redux";
import DashCard from "../../../components/DashCard";
import { DashTable } from "../../../components/DashTable";
import Topbar from "../../../components/Topbar";
import UserCard from "../../../components/UserCard";
import { getAllPatientsModal } from "../../../services/patient";
import useStyles from "./style";
import {
  getDashboardCounts,
  getTodaysNotes,
} from "../../../services/dashboard";
import {
  getAllFacilitiesModal,
  getAllFacilityTypes,
} from "../../../services/facility";
import { getAllNoteTypes } from "../../../services/note";
import { getAllStaff } from "../../../services/staff";
import { TrackPageView } from "../../../config/mixpanelConfig";

const Dashboard = () => {
  const { dashboardCounts } = useSelector((state) => state.dashboard);

  useEffect(() => {
    getTodaysNotes();
    getDashboardCounts();
  }, []);
  useEffect(() => {
    getAllStaff("all");
    getAllNoteTypes();
    getAllFacilityTypes();
    getAllFacilitiesModal('all');
    getAllPatientsModal();

    TrackPageView("Dashboard")
  }, []);

  const { classes } = useStyles();

  return (
    <Container fluid className={classes.content} px={10}>
      <Topbar id="dashboard-title" title={"Dashboard"} />
      <SimpleGrid
        cols={5}
        spacing={20}
        mt={54}
        mx={10}
        breakpoints={[
          { maxWidth: "80rem", cols: 3, spacing: "md" },
          { maxWidth: "70rem", cols: 2, spacing: "sm" },
          { maxWidth: "36rem", cols: 1, spacing: "sm" },
        ]}
      >
        <DashCard
          src={"/assets/images/people2.png"}
          stock={dashboardCounts?.staffActiveToday}
          title={"Active Staff Today"}
          desc={"Total Staff"}
          total={dashboardCounts?.staffCount}
        />
        <DashCard
          src={"/assets/images/patients.png"}
          stock={dashboardCounts?.patientCountToday}
          title={"Active Clients Today"}
          desc={"Total Clients"}
          total={dashboardCounts?.patientCount}
        />
        <DashCard
          src={"/assets/images/records.png"}
          stock={dashboardCounts?.noteCountToday}
          title={"Note Entries Today"}
          desc={"Records"}
          total={dashboardCounts?.noteCount}
        />

        <DashCard
          src={"/assets/images/reports.png"}
          stock={dashboardCounts?.reportsCountToday}
          title={"Reports Created Today"}
          desc={"Total Reports"}
          total={dashboardCounts?.reportCount}
        />
        <UserCard
          stock={"03"}
          title={"Active Today"}
          desc={"Total Staff"}
          total={"15"}
        />
      </SimpleGrid>
      <Grid>
        <Grid.Col xs={12} md={12} lg={12} xl={12}>
          <Card shadow="md" mt={50}>
            <DashTable />
          </Card>
        </Grid.Col>
        {/* <Grid.Col xs={12} md={12} lg={12} xl={6}>
          <DashCharts />
        </Grid.Col> */}
      </Grid>
    </Container>
  );
};
export default Dashboard;
