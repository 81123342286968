import { useDispatch, useSelector } from "react-redux";
import useStyles from "./style";
import { setSelectedClient } from "../../../../store/reducers/mar";
import { Box, Loader } from "@mantine/core";

const UserDetail = ({ isClient }) => {
  const { classes } = useStyles();

  const { allPatients, loading } = useSelector((state) => state?.patient);
  const { selectedClient } = useSelector((state) => state?.mar);

  const dispatch = useDispatch();

  let activePatients = allPatients?.filter(_p => _p?.active === true)

  const handleSelect = (obj) => {
    dispatch(setSelectedClient(obj));
    localStorage.setItem("selectedClient", JSON.stringify(obj));
  };

  // console.log("allPatients =>", allPatients);
  // console.log("isClient =>", isClient);
  // console.log("selectedClient =>", selectedClient);

  return (
    <div className={classes.scheduleDetailLeft}>
      {loading ? (
        <Box
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "100px 0 0 0",
          }}
        >
          <Loader color="yellow" size={"md"} />
        </Box>
      ) : (
        <>
          {/* <div className={classes.users}> */}
          {/* <div className={classes.user}> */}
          {/* <img src={"/assets/images/Group client.png"} /> */}

          {/* <div className={classes.userBox}>
                <img src={"/assets/images/user-avatar.png"} />
              </div> */}
          {/* <div className={classes.userBox}>
                <img src={"/assets/images/user-avatar1.png"} />
              </div> */}
          {/* <div className={classes.userBox}>
                <img src={"/assets/images/user-avatar.png"} />
              </div> */}
          {/* </div> */}
          {/* <div className={classes.user}>
              <div className={classes.userBox}>
                <img src={"/assets/images/user-avatar.png"} />
              </div>
              <div className={classes.userBox}>
                <img src={"/assets/images/user-avatar.png"} />
              </div>
              <div className={classes.userBox}>
                <img src={"/assets/images/user-avatar.png"} />
              </div>
            // </div> */}
          {/* </div> */}

          {/* <div
            className={`${classes.list} listScroll`}
            style={{ paddingRight: allPatients?.length > 10 ? "5px" : "0px" }}
          >
            {allPatients?.length > 0 &&
              allPatients?.map((v, i) => {
                const isBg = i % 2 === 0;

                return (
                  <div
                    key={i}
                    className={`${classes.item} ${isBg ? classes.itemBg : ""} ${
                      selectedClient?.id === v?.id ? classes.itemActive : ""
                    }`}
                    onClick={() => handleSelect(v)}
                  >
                    <p>{selectedClient?.id === v?.id ? selectedClient?.name : v?.name}</p>
                  </div>
                );
              })}
          </div> */}

          {isClient ? (
            <div
              className={`${classes.list} listScroll`}
              // style={{ paddingRight: allPatients?.length > 10 ? "5px" : "0px" }}
              style={{ paddingRight: activePatients?.length > 10 ? "5px" : "0px" }}
            >
              {
                <div
                  className={`${classes.item} ${classes.itemBg} ${
                    selectedClient?.id ? classes.itemActive : ""
                  }`}
                  onClick={() => handleSelect(selectedClient)}
                >
                  <p>{selectedClient?.name}</p>
                </div>
              }
            </div>
          ) : (
            <div
              className={`${classes.list} listScroll`}
              // style={{ paddingRight: allPatients?.length > 10 ? "5px" : "0px" }}
              style={{ paddingRight: activePatients?.length > 10 ? "5px" : "0px" }}
            >
              {/* {allPatients?.length > 0 &&
                allPatients?.map((v, i) => { */}
              {activePatients?.length > 0 &&
                activePatients?.map((v, i) => {
                  const isBg = i % 2 === 0;

                  return (
                    <div
                      key={i}
                      className={`${classes.item} ${
                        isBg ? classes.itemBg : ""
                      } ${
                        selectedClient?.id === v?.id ? classes.itemActive : ""
                      }`}
                      onClick={() => handleSelect(v)}
                    >
                      <p>{v?.name}</p>
                    </div>
                  );
                })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserDetail;
