import {
  Accordion,
  ActionIcon,
  Box,
  Button,
  Group,
  NumberInput,
  rem,
  Select,
  SimpleGrid,
  Switch,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import {
  addPlan,
  getAllPlans,
  getPlans,
  getPlanTypes,
} from "../../services/supportplan";
import useStyles from "./style";
import { DropDownArrowSvg } from "../Svgs/DropDownArrowSvg";
import { useMediaQuery } from "@mantine/hooks";
import { IconChevronDown, IconPlus } from "@tabler/icons-react";
import { DateInput } from "@mantine/dates";
import { IconX } from "@tabler/icons-react";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { now } from "moment";
import { getAllFacilities } from "../../services";

const CarePlaneDetails = ({
  view,
  edit,
  client,
  segment,
  setRowData,
  carePlanType,
  addPlanLoading,
  openAddMedicalModal,
  handleTableBoxClick,
  setShowCarePlanDetails,
  clientFacilities,
}) => {
  const icon = <DropDownArrowSvg style={{ width: rem(1), height: rem(1) }} />;
  const [state, setState] = useState({ startDate: new Date(), endDate: null });
  const [showCustomOutcome, setShowCustomOutcome] = useState({});
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedPlanType, setSelectedPlanType] = useState(null);
  const [openedAccordion, setOpenedAccordion] = useState(null);
  const [checkedSkillBuilding, setCheckedSkillBuilding] = useState([]);
  const [checkedIntensiveInHome, setCheckedIntensiveInHome] = useState([]);
  const [selected, setSelected] = useState(null);
  const [errors, setErrors] = useState({});
  const [types, setTypes] = useState([
    // {
    //   _id: "661510986a6068af0f4e06e6",
    //   name: "Individual Support Plan (ISP)"
    // }
  ]);
  const { classes } = useStyles();

  const { allFacilities } = useSelector((state) => state?.facility);

  const { allPatients } = useSelector((state) => state?.patient);

  const selectedClient = allPatients?.find((val) => val?.id === client);

  // const facility = allFacilities?.facilities?.find(
  //   // (v) => v?._id === client?.facility?._id
  //   (v) => v?.id === selectedClient?.facilityId?.id
  // );

  const getData = async () => {
    let data = await getPlanTypes();
    setTypes(data);
  };

  useEffect(() => {
    getData();
    getAllFacilities("all");
  }, []);

  useEffect(() => {
    if (carePlanType) {
      setSelectedPlanType(carePlanType);
      setSelected(carePlanType);
      setOpenedAccordion("0");
    }
  }, [carePlanType]);

  // console.log(
  //   "facility ID =>",
  //   client?.facilityId?.find((_fId) => _fId?.id == _f?.id)
  // );

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const buttonRef = useRef();

  const initialOutcome = {
    outcomeCategory: "",
    outcomeTitle: "",
    customOutcome: "",
    outcomeDescription: "",
    additionalInformation: "",
    outcomeNumber: "",
    activities: [
      {
        id: Date.now(),
        supportActivity: "",
        supportInstructions: "",
        skillBuilding: "no",
        intensiveInHome: "no",
        activityNumber: "1a",
      },
    ],
    howOften: "",
    iNoLongerNeedThisOutcome: "",
  };

  const alphabet = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];

  const [outcome, setOutcome] = useState([
    { id: Date.now(), ...initialOutcome, outcomeNumber: 1 },
  ]);

  const NoRecord = () => {
    return (
      <div
        style={{
          height: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="/assets/images/building.png"
          style={{
            height: "100px",
            width: "100px",
            objectFit: "contain",
          }}
          alt="no record found"
        />
        <Text style={{ opacity: 0.3 }} mt={10}>
          No Outcomes Found
        </Text>
      </div>
    );
  };

  const onSave = async () => {
    try {
      if (!validateForm()) {
        return;
      }
      // console.log("🚀 ~ outcomes:outcome?.map ~ outcome:", outcome);

      let payload = {
        planType: selected,
        // facility: facility?.id,
        facility: selectedFacility,
        client: client,
        isActive: true,
        startDate: new Date(),
        endDate: state.endDate,
        outcomes: outcome?.map((item) => {
          return {
            ...item,
            activities: item?.activities?.map((activity, index) => {
              return {
                ...activity,
                activityNumber: `${item?.outcomeNumber}${alphabet[index]}`,
              };
            }),
          };
        }),
      };

      setState((p) => ({
        ...p,
        ...payload,
      }));
      const response = await addPlan({
        ...state,
        ...payload,
      });
      if (carePlanType) {
        openAddMedicalModal();
      } else {
        getPlans(1, client, segment?.toLowerCase());
        getAllPlans();

        // Reset the form and outcome state
        setOutcome([{ id: Date.now(), ...initialOutcome, outcomeNumber: 1 }]);
        setState({ startDate: null, endDate: null });
        setSelected(null);

        // Hide CarePlanDetails and show the table with pagination
        setShowCarePlanDetails(false);
        setRowData(null);
      }
    } catch (error) {
      console.error("Error adding plan:", error);
    }
    // onSubmit(outcome);

    setOutcome(initialOutcome);
  };

  const handleOutcomeChange = (outcomeId, field, value) => {
    // console.log(
    //   "🚀 ~ handleOutcomeChange ~ outcomeId, field, value:",
    //   outcomeId,
    //   field,
    //   value
    // );

    let current = outcome.find((v) => v?.id === outcomeId);
    setOutcome((prevOutcome) =>
      prevOutcome.map((outcome) =>
        outcome.id === outcomeId
          ? {
              ...outcome,
              [field]: value,
              isActive: true,
              // errors: {
              //   ...outcome.errors,
              //   [field]:
              //     current?.required?.includes(field) && value == ""
              //       ? "Required"
              //       : undefined
              // }
            }
          : outcome
      )
    );
  };

  const handleactivityChange = (outcomeId, field, value, activityId) => {
    let tempOutcome = outcome;

    const tempResult = tempOutcome.map((outcome) =>
      outcome.id === outcomeId
        ? {
            ...outcome,
            activities: outcome?.activities.map((activity) =>
              activity.id === activityId
                ? {
                    ...activity,
                    [field]: value,
                    isActive: true,
                  }
                : activity
            ),
          }
        : outcome
    );

    setOutcome(tempResult);
  };

  const removeItem = (id) => {
    //setFields((p) => p?.filter((value) => value?.id !== id));
  };

  const addOutcome = () => {
    if (edit) {
      setOutcome([
        ...outcome,
        {
          id: Date.now(),
          ...initialOutcome,
          outcomeNumber: outcome?.slice(-1)[0]?.outcomeNumber + 1,
          activities: [
            {
              id: Date.now(),
              supportActivity: "",
              supportInstructions: "",
              skillBuilding: "no",
              intensiveInHome: "no",
              activityNumber:
                outcome?.map((value) => value.outcomeNumber)?.[0] + 1,
            },
          ],
        },
      ]);
    } else
      setOutcome([
        ...outcome,
        {
          id: Date.now(),
          ...initialOutcome,
          outcomeNumber: outcome?.slice(-1)[0]?.outcomeNumber + 1,
          activities: [
            {
              id: Date.now(),
              supportActivity: "",
              supportInstructions: "",
              skillBuilding: "no",
              intensiveInHome: "no",
              activityNumber:
                outcome?.map((value) => value.outcomeNumber)?.[0] + 1,
            },
          ],
        },
      ]);
    setOpenedAccordion(`${outcome?.length}`);
  };

  // out.activities
  const addactivity = (outcomeIndex, activityNumber, activityIndex) => {
    const newActivityId = Date.now();
    if (edit) {
      setOutcome((prevOutcome) => {
        const newOutcome = JSON.parse(JSON.stringify(prevOutcome)); // Deep clone
        newOutcome[outcomeIndex].activities.push({
          id: newActivityId,
          activityNumber: `${
            outcome?.map((value) => value.outcomeNumber)?.[0]
          }${alphabet[activityIndex + 1]}`,
          supportActivity: "",
          supportInstructions: "",
          skillBuilding: "no",
          intensiveInHome: "no",
        });
        return newOutcome;
      });
    } else {
      setOutcome((prevOutcome) => {
        const newOutcome = [...prevOutcome];
        newOutcome[outcomeIndex].activities.push({
          id: newActivityId,
          activityNumber: `${
            outcome?.map((value) => value.outcomeNumber)?.[0]
          }${alphabet[activityIndex + 1]}`,
          supportActivity: "",
          supportInstructions: "",
          skillBuilding: "no",
          intensiveInHome: "no",
        });
        return newOutcome;
      });
    }
  };

  const removeOutcome = (id) => {
    if (outcome?.length > 1) setOutcome((p) => p?.filter((v) => v?.id != id));
  };

  const removeActivity = (id) => {
    setOutcome((prevOutcome) => {
      return prevOutcome.map((outcomeItem) => {
        return {
          ...outcomeItem,
          activities: outcomeItem.activities.filter(
            (activity) => activity.id !== id
          ),
        };
      });
    });
  };

  // useEffect(() => {
  //   let object = {};
  //   if (state?.outcomes) {
  //     console.log('state', state?.outcomes);
  //     console.log('new state',state?.outcomes[0].activities);

  //   let form = Array.isArray(state?.outcomes) ? state?.outcomes : [];
  //     setOutcome(form);
  //     console.log('outcome', outcome);
  //     form?.forEach((value) => {
  //       object[value?.id] = value?.outcomeCategory === "Other";
  //     });
  //   }
  // }, [state?.outcomes]);

  // useEffect(() => {
  //   if (view || edit) {
  //     // if (state?.outcomes[0]?.activities) {
  //       let form = state?.outcomes?.length > 0 ? state?.outcomes : [];
  //       //[{}]
  //       setOutcome(form);

  //   }
  // }, [state?.outcomes]);

  // useEffect(() => {
  //   if (view || edit) {
  //     let form = state?.outcomes?.length > 0 ? state?.outcomes : [];

  //     form = form.map((outcome) => {
  //       if (!outcome?.activities || outcome?.activities.length === 0) {
  //         return {
  //           ...outcome,
  //           activities: [
  //             {
  //               id: Date.now(),
  //               supportActivity: outcome?.supportActivity || "",
  //               supportInstructions: outcome?.supportInstructions || "",
  //               skillBuilding: outcome?.skillBuilding || "no",
  //               activityNumber: `${outcome?.outcomeNumber}a`,
  //             },
  //           ],
  //         };
  //       }
  //       return outcome;
  //     });

  //     setOutcome(form);
  //   }
  // }, [view, edit, state?.outcomes]);

  const handleFacilityChange = (f) => {
    setSelectedFacility(f);
  };

  const handlePlanTypeChange = (t) => {
    setSelectedPlanType(t);
    setSelected(t);
    setOpenedAccordion("0");
  };

  const validateForm = () => {
    const newErrors = {};

    if (!state.endDate) {
      newErrors.endDate = "End Date is required.";
    }

    outcome?.forEach((out, index) => {
      if (!out?.outcomeNumber)
        newErrors[`outcomeNumber${index}`] = "Outcome Number is required.";
      if (!out?.outcomeTitle)
        newErrors[`outcomeTitle${index}`] = "Outcome Title is required.";
      if (!out?.outcomeCategory)
        newErrors[`outcomeCategory${index}`] = "Outcome Category is required.";
      if (!out?.howOften)
        newErrors[`howOften${index}`] = "Outcome Frequency is required.";
      // if (showCustomOutcome[out?.id] && !out?.customOutcome) newErrors[`customOutcome${index}`] = "Custom Outcome is required.";
      // out?.activities?.forEach((act, actIndex) => {
      //   if (!act?.supportActivity) newErrors[`supportActivity${index}-${actIndex}`] = "Support Activity is required.";
      //   if (!act?.supportInstructions) newErrors[`supportInstructions${index}-${actIndex}`] = "Support Instructions are required.";
      // });
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <Box className={classes.CarePlaneDetails_container}>
      <Box className={classes.header}>
        <Box className={classes.headerTitle}>
          <Text size={"md"} weight={600} ta={"center"}>
            Add Care Plan
          </Text>
        </Box>
        <Box className={classes.headerContent}>
          {/* Facility */}
          <Select
            id="select-support-plan"
            value={selectedFacility}
            onChange={(f) => handleFacilityChange(f)}
            label="Facility"
            placeholder="Select Facility"
            rightSectionPointerEvents="none"
            rightSection={icon}
            data={allFacilities?.facilities?.map((_f) => {
              return {
                value: _f?.id,
                label: _f?.name,
                disabled: selectedClient?.facilityId?.find(
                  (_fId) => _fId?.id == _f?.id
                )
                  ? false
                  : clientFacilities?.find((_fId) => _fId == _f?.id) ? 
                  false
                  : true,
              };
            })}
            className={classes.select}
            variant="filled"
            required
          />
          <Select
            id="select-support-plan"
            value={selected}
            onChange={(t) => handlePlanTypeChange(t)}
            label="Plan Type"
            placeholder="Select Support Plan"
            rightSectionPointerEvents="none"
            rightSection={icon}
            data={types?.map((type) => ({
              value: type?.id,
              label: type?.name,
            }))}
            className={classes.select}
            variant="filled"
            required
          />
          {selectedFacility && selectedPlanType && (
            <>
              {/* Start Date */}
              <DateInput
                id="add-plan-start-date"
                size="sm"
                clearable
                withAsterisk
                variant="unstyled"
                label="Start Date"
                placeholder={"mm/dd/yyyy"}
                rightSection={
                  <img
                    style={{ width: "30px" }}
                    src={"/assets/images/calendar-icon.png"}
                    alt="Icon"
                  />
                }
                style={{ borderBottom: "2px solid #aaaaaa" }}
                value={state.startDate}
                onChange={(date) =>
                  setState((prevState) => ({ ...prevState, startDate: date }))
                }
              />
              {/* End Date */}
              <Box mt={errors?.endDate && 20}>
                <DateInput
                  id="add-plan-end-date"
                  size="sm"
                  clearable
                  label="End Date"
                  variant="unstyled"
                  minDate={new Date()}
                  placeholder={"mm/dd/yyyy"}
                  withAsterisk
                  rightSection={
                    <img
                      style={{ width: "30px" }}
                      src={"/assets/images/calendar-icon.png"}
                      alt="Icon"
                    />
                  }
                  style={{
                    borderBottom: "2px solid #aaaaaa",
                    ...(errors?.endDate && { borderBottom: "2px solid red" }),
                  }}
                  value={state.endDate}
                  onChange={(date) => {
                    setState((prevState) => ({ ...prevState, endDate: date }));
                    if (date) {
                      setErrors((prevErrors) => {
                        const { endDate, ...rest } = prevErrors;
                        return rest;
                      });
                    }
                  }}
                />
                {errors?.endDate && (
                  <Text color="red" size="xs">
                    {errors?.endDate}
                  </Text>
                )}
              </Box>
            </>
          )}
        </Box>
        {/* <Button onClick={() => onSelect(selected)} color="yellow">
          Next
        </Button> */}
      </Box>
      {selectedFacility && selectedPlanType && (
        <Box className={classes.header}>
          {outcome?.length > 0 ? (
            <Accordion
              value={openedAccordion}
              onChange={setOpenedAccordion}
              chevron={<IconChevronDown size={34} color="#FBAA0D" />}
              chevronPosition="right"
              chevronSize={34}
              styles={{
                chevron: {
                  transition: "transform 200ms ease",
                  "&[data-active]": {
                    transform: "rotate(180deg)",
                  },
                },
              }}
            >
              {outcome?.map((out, index) => (
                <Accordion.Item value={`${index}`}>
                  <Accordion.Control>
                    <Text variant="text" fw={600}>
                      Outcome #{out?.outcomeNumber}
                    </Text>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <form>
                      {!view && (
                        <Box className={classes.outcomeDeleteBTN}>
                          <Text onClick={() => removeOutcome(out?.id)}>
                            Delete
                          </Text>
                        </Box>
                      )}

                      <SimpleGrid
                        cols={isMobileScreen ? 1 : 4}
                        className={classes.supportPlaneTopSection}
                      >
                        <NumberInput
                          className={classes.myfont}
                          style={{ marginTop: "15px" }}
                          label="Outcome Number"
                          size="sm"
                          variant="filled"
                          value={out?.outcomeNumber || index + 1}
                          onChange={(value) => {
                            handleOutcomeChange(
                              out?.id,
                              "outcomeNumber",
                              value
                            );
                          }}
                          placeholder="Enter outcome number"
                          required
                          error={errors[`outcomeNumber${index}`]}
                          rightSection={
                            <Box className={classes.outcomeNumberSection}>
                              <ActionIcon
                                size="xs"
                                onClick={() =>
                                  handleOutcomeChange(
                                    out?.id,
                                    "outcomeNumber",
                                    (out?.outcomeNumber || index + 1) - 1
                                  )
                                }
                              >
                                <FaMinus size={10} color="#000" />
                              </ActionIcon>
                              <Text size="sm" c="dimmed">
                                |
                              </Text>
                              <ActionIcon
                                size="xs"
                                onClick={() =>
                                  handleOutcomeChange(
                                    out?.id,
                                    "outcomeNumber",
                                    (out?.outcomeNumber || index + 1) + 1
                                  )
                                }
                              >
                                <FaPlus size={10} color="#000" />
                              </ActionIcon>
                            </Box>
                          }
                        />
                        {/* Outcome Title */}
                        <TextInput
                          id="add-care-title"
                          className={classes.myfont}
                          style={{ marginTop: "15px" }}
                          label="Outcome Title"
                          variant="filled"
                          size="sm"
                          placeholder="Enter outcome title"
                          disabled={view}
                          value={out?.outcomeTitle}
                          onChange={(e) => {
                            handleOutcomeChange(
                              out?.id,
                              "outcomeTitle",
                              e?.target?.value
                            );
                          }}
                          error={errors[`outcomeTitle${index}`]}
                          required
                        />
                        {/* Outcome Category */}
                        <Select
                          id="add-care-category"
                          className={classes.myfont}
                          style={{ marginTop: "15px" }}
                          label="Outcome Category"
                          rightSectionPointerEvents="none"
                          rightSection={icon}
                          variant="filled"
                          required
                          size="sm"
                          data={[
                            "Community Living",
                            "Safety and Security",
                            "Healthy living",
                            "Citizenship & Advocacy",
                            "Daily Life & Employment",
                            "Social & Spirituality",
                            "Integrated Community Involvement",
                            "Other",
                          ]}
                          placeholder="Select outcome category"
                          value={out?.outcomeCategory}
                          onChange={(value) => {
                            handleOutcomeChange(
                              out?.id,
                              "outcomeCategory",
                              value
                            );
                            setShowCustomOutcome((p) => ({
                              ...p,
                              [out?.id]: value === "Other",
                            }));
                          }}
                          disabled={view}
                          error={errors[`outcomeCategory${index}`]}
                        />

                        {showCustomOutcome[out?.id] && (
                          <Textarea
                            autosize={view}
                            className={classes.textArea}
                            style={{ marginTop: "15px" }}
                            label="Add Custom Outcome"
                            size="sm"
                            placeholder="Enter outcome"
                            disabled={view}
                            value={out?.customOutcome}
                            onChange={(e) => {
                              handleOutcomeChange(
                                out?.id,
                                "customOutcome",
                                e?.target?.value
                              );
                            }}
                          />
                        )}
                      </SimpleGrid>

                      {/* Support Details */}
                      <Text variant="text" fw={600} fz={20} mb={15}>
                        Support Details
                      </Text>

                      {out.activities?.length > 0 &&
                        out?.activities.map((act, actIndex) => (
                          <Group key={act.id}>
                            <Box style={{ width: "100%" }}>
                              <Box className={classes.activitiesBox}>
                                <Box mt={40} w={"3%"}>
                                  <p disabled={view}>
                                    {`${out?.outcomeNumber}${alphabet[actIndex]}` ||
                                      index + 1}
                                  </p>
                                </Box>
                                {/* Support Activity */}
                                <Box
                                  className={classes.textInputBox}
                                  style={{ width: "35%" }}
                                >
                                  <Text className="label">
                                    Support Activity
                                  </Text>
                                  <Textarea
                                    id="add-care-activity"
                                    autosize={view}
                                    minRows={4}
                                    maxRows={10}
                                    size="sm"
                                    disabled={view}
                                    placeholder="Support activity"
                                    value={act?.supportActivity}
                                    onChange={(e) => {
                                      handleactivityChange(
                                        out?.id,
                                        "supportActivity",
                                        e?.target?.value,
                                        act?.id
                                      );
                                    }}
                                  />
                                </Box>
                                {/* Support Instruction */}
                                <Box
                                  className={classes.textInputBox}
                                  style={{ width: "35%" }}
                                >
                                  <Text className="label">
                                    Support Instructions
                                  </Text>
                                  <Textarea
                                    id="add-care-instruction"
                                    autosize={view}
                                    minRows={4}
                                    maxRows={10}
                                    size="sm"
                                    placeholder="Support instructions"
                                    disabled={view}
                                    value={act?.supportInstructions}
                                    onChange={(e) => {
                                      handleactivityChange(
                                        out?.id,
                                        "supportInstructions",
                                        e?.target?.value,
                                        act?.id
                                      );
                                    }}
                                  />
                                </Box>
                                <Box>
                                  <Box
                                    className={
                                      classes.supportDetailRightButtons
                                    }
                                  >
                                    <Text variant="text" fw={500}>
                                      Skill-Building
                                    </Text>
                                    <Switch
                                      key={act.id}
                                      id={actIndex + 1}
                                      checked={act?.skillBuilding === "yes"}
                                      color="yellow"
                                      onChange={(event) => {
                                        const newChecked =
                                          event.currentTarget.checked;
                                        const updatedChecked = [
                                          ...checkedSkillBuilding,
                                        ];
                                        updatedChecked[actIndex] = newChecked;
                                        setCheckedSkillBuilding(updatedChecked);
                                        handleactivityChange(
                                          out?.id,
                                          "skillBuilding",
                                          newChecked ? "yes" : "no",
                                          act?.id
                                        );
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    className={
                                      classes.supportDetailRightButtons
                                    }
                                  >
                                    <Text variant="text" fw={500}>
                                      Intensive in-home
                                    </Text>
                                    <Switch
                                      key={act.id}
                                      id={actIndex + 1}
                                      checked={act?.intensiveInHome === "yes"}
                                      color="yellow"
                                      onChange={(event) => {
                                        const newChecked =
                                          event.currentTarget.checked;
                                        const updatedChecked = [
                                          ...checkedIntensiveInHome,
                                        ];
                                        updatedChecked[actIndex] = newChecked;
                                        setCheckedIntensiveInHome(
                                          updatedChecked
                                        );
                                        handleactivityChange(
                                          out?.id,
                                          "intensiveInHome",
                                          newChecked ? "yes" : "no",
                                          act?.id
                                        );
                                      }}
                                    />
                                  </Box>
                                </Box>

                                <Box
                                  className={classes.supportDetailRightButtons}
                                >
                                  {out.activities?.length > 1 && (
                                    <ActionIcon
                                      onClick={() => removeActivity(act?.id)}
                                      size={"xl"}
                                      color="red"
                                      variant="light"
                                    >
                                      <IoClose />
                                    </ActionIcon>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              className={classes.supportDetailAdditionButton}
                            >
                              {actIndex === out.activities.length - 1 && (
                                <ActionIcon
                                  onClick={() =>
                                    addactivity(
                                      index,
                                      act.activityNumber
                                        ? act.activityNumber
                                        : "",
                                      actIndex
                                    )
                                  }
                                  size={"lg"}
                                  ref={buttonRef}
                                  color="yellow"
                                  variant="filled"
                                >
                                  <IconPlus />
                                </ActionIcon>
                              )}
                            </Box>
                          </Group>
                        ))}

                      <SimpleGrid
                        cols={isMobileScreen ? 1 : 3}
                        className={classes.mwd}
                        sx={{
                          paddingBottom: 30,
                        }}
                      >
                        {/* Outcome Frequency */}
                        <Select
                          id="add-care-frequency"
                          className={classes.myfont}
                          style={{ marginTop: "15px" }}
                          label="Outcome Frequency"
                          required
                          size="sm"
                          data={[
                            "Daily",
                            "Weekly",
                            "Twice a Week",
                            "Monthly",
                            "Twice a Month",
                          ]}
                          placeholder="Select how often you do this activity."
                          value={out?.howOften}
                          onChange={(value) => {
                            handleOutcomeChange(out?.id, "howOften", value);
                          }}
                          disabled={view}
                          rightSectionPointerEvents="none"
                          rightSection={icon}
                          variant="filled"
                          error={errors[`howOften${index}`]}
                        />
                      </SimpleGrid>
                      <SimpleGrid
                        cols={isMobileScreen ? 1 : 2}
                        className={classes.mwd}
                        sx={{
                          paddingBottom: 30,
                        }}
                      >
                        {/* I no longer need this outcome when .. */}
                        <Box style={{ width: "80%", margin: "0 30px" }}>
                          <Box className={classes.textInputBox}>
                            <Text className="label">
                              No longer need this outcome when ..
                            </Text>
                            <Textarea
                              id="add-care-reason"
                              autosize={view}
                              minRows={4}
                              maxRows={10}
                              className={classes.textArea}
                              size="sm"
                              placeholder="Enter response"
                              disabled={view}
                              value={out?.iNoLongerNeedThisOutcome}
                              onChange={(e) => {
                                handleOutcomeChange(
                                  out?.id,
                                  "iNoLongerNeedThisOutcome",
                                  e?.target?.value
                                );
                              }}
                            />
                          </Box>
                        </Box>
                        {/* Additional Note */}
                        <Box style={{ width: "80%", margin: "0 -30px" }}>
                          <Box className={classes.textInputBox}>
                            <Text className="label">Additional Note</Text>
                            <Textarea
                              id="add-care-note"
                              autosize={view}
                              minRows={4}
                              maxRows={10}
                              className={classes.textArea}
                              size="sm"
                              placeholder="Enter additional note"
                              disabled={view}
                              value={out?.additionalInformation}
                              onChange={(e) => {
                                handleOutcomeChange(
                                  out?.id,
                                  "additionalInformation",
                                  e?.target?.value
                                );
                              }}
                            />
                          </Box>
                        </Box>
                      </SimpleGrid>
                    </form>
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          ) : (
            <NoRecord />
          )}

          {!view && (
            <Button
              size="sm"
              color="yellow"
              onClick={addOutcome}
              style={{ float: "right", margin: "1rem" }}
            >
              Add New Outcome
            </Button>
          )}

          {!view && (
            <Box className={classes.buttonBox}>
              <Button
                size="sm"
                color="red"
                variant="light"
                onClick={handleTableBoxClick}
                disabled={view}
                leftIcon={<IconX />}
              >
                Cancel
              </Button>
              <Button
                id="add-care-save"
                loading={addPlanLoading}
                size="sm"
                color="yellow"
                onClick={onSave}
                disabled={view}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CarePlaneDetails;
