import {
  ActionIcon,
  Box,
  Group,
  Text,
  Tooltip,
  createStyles,
} from "@mantine/core";
import { IconEdit, IconEye, IconTrash } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { removeNote } from "../../services/note";
import { formatDate, getOptions, noteType_Client } from "../../utils";
import moment from "moment";
import { getAllFacilitiesModal } from "../../services";

const useStyles = createStyles((theme) => ({
  border: {
    borderLeft: "1px solid #F2F2F2",
  },
  cell: {
    background: "#F0F0F0",
    borderRadius: 5,
    padding: 5,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  parametersContainer: {
    background: "#F0F0F0",
    borderRadius: 5,
    padding: 5,
    width: 50,
    color: "#A8A8A8",
    fontSize: 14,
  },
}));

const NoteTableRow = ({
  row,
  index,
  open,
  setView,
  userId,
  sortField,
  sortOrder,
}) => {
  console.log(
    "🚀 ~ NoteTableRow ~ sortField, sortOrder:",
    sortField,
    sortOrder
  );
  const { profile } = useSelector((state) => state.profile);
  const { allStaff } = useSelector((state) => state.staff);
  const [rowValues, setRowValues] = useState({});
  // let userType = profile?.user?.type;
  const { classes } = useStyles();

  const getFields = () => {
    const fields = {};
    row?.noteId?.custom?.forEach((customElement) => {
      if ("options" in customElement) {
        let optName =
          customElement?.options?.toLowerCase() == "staff"
            ? "Staff All"
            : customElement?.options;
        let options = getOptions(optName, row?.noteId?.custom, undefined, true);
        // let options = getOptions(customElement?.options, row?.noteId?.custom);
        let tempFields = Object.entries(row?.fields)?.find(
          (value) =>
            customElement?.field === "Client Name"
              ? value?.[0]?.["Patient Name"] && !value?.[0]?.["Client Name"]
                ? value?.[0] === "Patient Name"
                : value?.[0]?.["Patient Name"] && value?.[0]?.["Client Name"]
                ? value?.[0] === "Client Name"
                : value?.[0] === "Patient Name" || value?.[0] === "Client Name"
              : value?.[0] === customElement?.field
          // ||
          // value?.[0] === "Client Name"
        );
        // console.log("🚀 ~ tempFields:", tempFields);

        let value = options?.find(
          (val) => val?.value === tempFields?.[1]
        )?.label;
        fields[tempFields?.[0]] = value;
      } else {
        if (
          row?.noteId?.name === "Progress Note" &&
          row?.supportPlan === "yes"
        ) {
          fields["_outcomes"] = row.descriptions;
        }
        let tempFields = Object.entries(row?.fields)?.find(
          (value) => value?.[0] === customElement?.field
        );
        if (tempFields?.[0] !== undefined)
          fields[tempFields?.[0]] = tempFields?.[1];
      }
    });
    // console.log("🚀 ~ Object.entries ~ fields:", Object.entries(fields));

    const obj = {};
    Object.entries(fields)?.map((v) => {
      if (v?.[0] !== undefined || null) {
        obj[v[0]] = v?.[1] || "-";
      }
    });
    // console.log("🚀 ~ getFields ~ obj:", obj);
    setRowValues(obj);
    return Object.entries(fields);
  };
  useEffect(() => {
    getFields();
  }, [row, allStaff]);

  // functions
  const remove = async (canDelete) => {
    const willDelete = await swal({
      title: `Are you sure?`,
      text: "This action cannot be undone.",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Delete"],
    });

    if (willDelete) removeNote(row, userId, () => {}, sortField, sortOrder);
  };

  function handleRowClick(event) {
    setView(true);
    open(row);
  }
  const Parameters = ({ parameters }) => {
    if (parameters?.length > 1)
      return (
        <Text className={classes.cell}>
          {`${
            parameters?.[0]?.description?.length > 50
              ? `${parameters?.[0]?.description?.slice(0, 50)}...`
              : parameters?.[0]?.description
          }`}
          <Box className={classes.parametersContainer}>
            + {parameters?.length - 1}
          </Box>
        </Text>
      );

    return (
      <Text className={classes.cell}>
        {`${
          parameters?.[0]?.description?.length > 50
            ? `${parameters?.[0]?.description?.slice(0, 50)}...`
            : parameters?.[0]?.description
        }`}
      </Text>
    );
  };

  return (
    <>
      <tr style={{ cursor: "pointer" }} key={index}>
        <td
          onClick={handleRowClick}
          className={classes.border}
          style={{ textTransform: "uppercase" }}
        >
          {row?.id?.slice(-6)}
        </td>
        <td onClick={handleRowClick} className={classes.border}>
          {row?.noteId?.name}
        </td>
        <td onClick={handleRowClick}>{rowValues?.["Staff Name"] || "-"}</td>

        {/* <td onClick={handleRowClick} className={classes.border}>
          {moment(row?.noteDate)?.format("DD/MM/YYYY")}
        </td>

        <td onClick={handleRowClick} className={classes.border}>
          {moment(row?.createdAt).format("DD/MMM/YYYY")}
        </td>

        <td onClick={handleRowClick} className={classes.border}>
          {moment(row?.updatedAt).format("DD/MMM/YYYY")}
        </td> */}

        <td onClick={handleRowClick} className={classes.border}>
          {rowValues?.["Date"] ? formatDate(rowValues?.["Date"]) : "-"}
        </td>
        <td onClick={handleRowClick} className={classes.border}>
          {/* {rowValues?.["Time"] || "-"} */}
          {/* {moment(rowValues?.["Time"]).format("hh:mm:ss a")} */}
          {/* {moment(rowValues?.["Time"], "HH:mm:ss").format("hh:mm:ss a")  } */}

          {rowValues?.["Time"]
            ? moment(rowValues["Time"], "HH:mm:ss").format("hh:mm:ss a")
            : "-"}

          {/* {rowValues?.["Time"] ? format(rowValues?.["Time"]) : "-"} */}

          {/* {moment(rowValues?.["Time"]).format("hh:mm:ss a") || "-"}  */}
        </td>
        <td onClick={handleRowClick} className={classes.border}>
          {rowValues?.["Facility Name"] || "-"}
        </td>
        <td onClick={handleRowClick} className={classes.border}>
          {rowValues?.[noteType_Client] || rowValues?.["Patient Name"] || "-"}
        </td>
        <td onClick={handleRowClick} className={classes.border}>
          {rowValues?._outcomes ? (
            <Parameters parameters={rowValues["_outcomes"]} />
          ) : rowValues?.["Description"] ? (
            <Tooltip
              w={300}
              multiline
              withArrow
              label={`${rowValues?.["Description"]}`}
            >
              <span>
                {rowValues?.["Description"]?.length > 50
                  ? `${rowValues?.["Description"]?.slice(0, 140)}...`
                  : rowValues?.["Description"]}
              </span>
            </Tooltip>
          ) : (
            "-"
          )}
        </td>
        <td onClick={handleRowClick} className={classes.border}>
          {rowValues?.["Medication"] || "-"}
        </td>
        <td onClick={handleRowClick} className={classes.border}>
          {rowValues?.["Reason"] ? (
            <Tooltip
              w={300}
              multiline
              withArrow
              label={`${rowValues?.["Reason"]}`}
            >
              <span>
                {rowValues?.["Reason"]?.length > 50
                  ? `${rowValues?.["Reason"]?.slice(0, 50)}...`
                  : rowValues?.["Reason"]}
              </span>
            </Tooltip>
          ) : (
            "-"
          )}
        </td>

        <td className={classes.border}>
          <Group>
            <ActionIcon
              color="yellow"
              variant="light"
              onClick={() => {
                setView(true);
                open(row);
                getAllFacilitiesModal("withDeleted");
              }}
            >
              <IconEye id="icon-eye" />
            </ActionIcon>
            <ActionIcon
              disabled={
                profile?.user?.type !== "staff" ||
                (profile?.user?.type === "staff" &&
                  profile?.user?.role === "admin") ||
                row?.fields?.["Staff Name"] === profile?.user?.id
                  ? false
                  : true
              }
              color="green"
              variant="light"
              onClick={() => {
                open(row);
                getAllFacilitiesModal("withDeleted");
              }}
            >
              <IconEdit id="icon-edit" />
            </ActionIcon>

            <ActionIcon
              color="red"
              variant="light"
              onClick={() => remove(row?.canDelete)}
              disabled={
                profile?.user?.type !== "staff" ||
                (profile?.user?.type === "staff" &&
                  profile?.user?.role === "admin") ||
                row?.fields?.["Staff Name"] === profile?.user?.id
                  ? false
                  : true
              }
            >
              <IconTrash id="icon-trash" />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    </>
  );
};

const NoteTableRowDashboard = ({ row, index, open, setView }) => {
  const { classes } = useStyles();
  const { allPatients } = useSelector((state) => state?.patient);
  const { allFacilities } = useSelector((state) => state?.facility);
  const { staff } = useSelector((state) => state?.staff);

  const [rowValues, setRowValues] = useState({});

  const getFields = () => {
    const fields = {};
    row?.noteId?.custom?.forEach((customElement) => {
      if ("options" in customElement) {
        let options = getOptions(customElement?.options, row?.noteId?.custom);
        let tempFields = Object.entries(row?.fields)?.find(
          (value) => value?.[0] === customElement?.field
        );

        let value = options?.find(
          (val) => val?.value === tempFields?.[1]
        )?.label;
        fields[tempFields?.[0]] = value;
      } else {
        if (
          row?.noteId?.name === "Progress Note" &&
          row?.supportPlan === "yes"
        ) {
          fields["_outcomes"] = row.descriptions;
        }
        let tempFields = Object.entries(row?.fields)?.find(
          (value) => value?.[0] === customElement?.field
        );
        if (tempFields?.[0] !== undefined)
          fields[tempFields?.[0]] = tempFields?.[1];
      }
    });

    const obj = {};
    Object.entries(fields)?.map((v) => {
      if (v?.[0] !== undefined || null) {
        obj[v[0]] = v?.[1] || "-";
      }
    });
    setRowValues(obj);
    return Object.entries(fields);
  };
  function handleRowClick(event) {
    setView(true);
    open(row);
  }
  useEffect(() => {
    getFields();
  }, [row, allPatients, allFacilities?.facilities, staff?.staffs]);

  const Parameters = ({ parameters }) => {
    if (parameters?.length > 1)
      return (
        <Text
          className={classes.cell}
          style={{ display: "flex", alignItems: "center", gap: 20 }}
        >
          {`${
            parameters?.[0]?.description?.length > 15
              ? `${parameters?.[0]?.description?.slice(0, 15)}...`
              : parameters?.[0]?.description
          }`}
          <div
            style={{
              background: "#F0F0F0",
              borderRadius: 5,
              padding: 5,
              width: 50,
              color: "#A8A8A8",
              fontSize: 14,
            }}
          >
            + {parameters?.length - 1}
          </div>
        </Text>
      );

    return (
      <Text className={classes.cell}>
        {`${
          parameters?.[0]?.description?.length > 15
            ? `${parameters?.[0]?.description?.slice(0, 15)}...`
            : parameters?.[0]?.description
        }`}
      </Text>
    );
  };
  return (
    <>
      <tr key={index} onClick={handleRowClick}>
        <td className={classes.border}>{row?.noteId?.name}</td>
        <td className={classes.border}>
          {rowValues?.[noteType_Client] || "-"}
        </td>
        <td className={classes.border}>
          {" "}
          {rowValues?.["Facility Name"] || "-"}
        </td>
        <td className={classes.border}>{rowValues?.["Staff Name"] || "-"}</td>
        <td className={classes.border}>
          {rowValues?.["Date"] ? formatDate(rowValues?.["Date"]) : "-"}
        </td>

        <td className={classes.border}>
          {rowValues?._outcomes ? (
            <Parameters parameters={rowValues["_outcomes"]} />
          ) : rowValues?.["Description"] ? (
            <Tooltip
              w={300}
              multiline
              withArrow
              label={`${rowValues?.["Description"]}`}
            >
              <span>
                {rowValues?.["Description"]?.length > 50
                  ? `${rowValues?.["Description"]?.slice(0, 140)}...`
                  : rowValues?.["Description"]}
              </span>
            </Tooltip>
          ) : (
            "-"
          )}
        </td>
      </tr>
    </>
  );
};

export default NoteTableRow;
export { NoteTableRowDashboard };
