import {
  Box,
  Button,
  Container,
  Flex,
  Menu,
  Select,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddButton from "../../../components/AddButton";
import NoteModal from "../../../components/NoteModal";
import NoteTypeSelectModal from "../../../components/NoteSelectTypeModal";
import NoteTable from "../../../components/NoteTable";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import { getAllFacilitiesModal, getAllFacilityTypes } from "../../../services";
import { getAllNoteTypes, getNotes } from "../../../services/note";
import { getAllPatientsModal } from "../../../services/patient";
import { getAllStaff } from "../../../services/staff";
import moment from "moment";
import { DatePickerInput } from "@mantine/dates";
import { FilterIcon } from "../../../components/Svgs/FilterIcon";
import useStyles from "./style";
import { FaSort } from "react-icons/fa6";

const ClientNoteview = ({ userId, activeTab }) => {
  const { classes } = useStyles();

  const [data, setData] = useState(null);
  const [view, setView] = useState(false);
  const [isopen, setOpen] = useState(false);
  const [noteType, setNoteType] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { notes, loading } = useSelector((state) => state?.note);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [dateRange, setDateRange] = useState(null);
  const [dateRangeVal, setDateRangeVal] = useState([]);
  const [oneDayValue, setOneDayValue] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);

  const [sortOpen, setSortOpen] = useState(false);
  const [sortField, setSortField] = useState("noteDate");
  const [sortOrder, setSortOrder] = useState("descending");

  useEffect(() => {
    getAllNoteTypes();
    getAllFacilityTypes();
    getAllPatientsModal();
    getAllFacilitiesModal("all");
  }, []);

  useEffect(() => {
    if (activeTab === "notes") {
      getAllStaff("all");
    }
  }, [activeTab]);

  useEffect(() => {
    getNotes(currentPage, userId, true, dateRange, sortField, sortOrder);
  }, [currentPage, dateRange]);

  const handleDateRange = (dates) => {
    if (dates[0] == null && dates[1] == null) {
      setDateRangeVal([]);
    } else {
      setDateRangeVal(dates);
    }
  };

  const handleDateChange = (date) => {
    setOneDayValue(date);
  };

  // const handleCreatedAt = (date) => {
  //   let startDate = moment(dateRangeVal[0]);
  //   setCreatedAt()
  // };

  const handleSaveDateRange = () => {
    if (dateRangeVal?.length) {
      let startDate = moment(dateRangeVal[0]).startOf("day");
      let endDate = moment(dateRangeVal[1]).endOf("day");
      setDateRange([startDate, endDate]);
    } else if (oneDayValue) {
      let startDate = moment(oneDayValue).startOf("day");
      let endDate = moment(oneDayValue).endOf("day");
      setDateRange([startDate, endDate]);
    }
  };

  const handleCancelDateRange = () => {
    setDateRangeVal([]);
    setDateRange(null);
    setDropdownOpen(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSortChange = (field, order) => {
    setSortField(field);
    setSortOrder(order);
  };

  return (
    <Container fluid className={classes.content} px={20}>
      {/* Note Modal */}
      <NoteModal
        close={() => {
          setOpen(false);
          setData(null);
        }}
        view={view}
        data={data}
        userId={userId}
        opened={isopen}
        setView={setView}
        noteType={noteType}
        title={"Add New Note"}
      />
      {/* Note Type Select Modal */}
      <NoteTypeSelectModal
        opened={opened}
        onclose={(nType) => {
          close();
          setNoteType(nType);
        }}
        title={"Select Note Type"}
        save={setOpen}
      />
      {/* Filter and Add Button */}
      <Box className={classes.btn}>
        <Flex align={"center"} gap={10}>
          <Box className={classes.FilterIcon}>
            <Menu
              width={350}
              shadow="md"
              position="bottom-start"
              opened={dropdownOpen}
              onClose={() => setDropdownOpen(false)}
            >
              <Menu.Target title="Filter">
                <Box
                  className={classes.FilterIcon}
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <FilterIcon />
                </Box>
              </Menu.Target>

              <Menu.Dropdown>
                <Text size="md" weight={500} ml={5} mt={10} mb={5}>
                  Filter
                </Text>
                <DatePickerInput
                  size="sm"
                  clearable
                  m={".5rem"}
                  type="range"
                  value={dateRangeVal}
                  disabled={oneDayValue}
                  label="Select Date Range"
                  onChange={handleDateRange}
                  placeholder="Select Date Range"
                />
                <DatePickerInput
                  size="sm"
                  clearable
                  m={".5rem"}
                  label="Select Date"
                  value={oneDayValue}
                  placeholder="Select Date"
                  onChange={handleDateChange}
                  disabled={dateRangeVal?.length}
                />
                <Box className={classes.filterBtn}>
                  <Button
                    size="sm"
                    radius={8}
                    color="gray"
                    variant="outline"
                    onClick={handleCancelDateRange}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    radius={8}
                    color="yellow"
                    disabled={loading}
                    onClick={handleSaveDateRange}
                  >
                    Save
                  </Button>
                </Box>
              </Menu.Dropdown>
            </Menu>
          </Box>
          <Box className={classes.FilterIcon}>
            <Menu
              width={350}
              shadow="md"
              opened={sortOpen}
              position="bottom-start"
              onClose={() => setSortOpen(false)}
            >
              <Menu.Target title="Sort">
                <Box
                  className={classes.FilterIcon}
                  onClick={() => setSortOpen(!sortOpen)}
                >
                  <FaSort size={30} />
                </Box>
              </Menu.Target>

              <Menu.Dropdown>
                <Text size="md" weight={500} ml={5} mt={10} mb={5}>
                  Sorting
                </Text>
                <Select
                  label="Sort By"
                  placeholder="Sort by"
                  data={[
                    { label: "Created Date", value: "createdAt" },
                    { label: "Note Date", value: "noteDate" },
                    { label: "Edited Date", value: "updatedAt" },
                  ]}
                  value={sortField}
                  onChange={(value) => handleSortChange(value, sortOrder)}
                />
                <Select
                  label="Sort Order"
                  placeholder="Sort Order"
                  data={[
                    { label: "Ascending", value: "ascending" },
                    { label: "Descending", value: "descending" },
                  ]}
                  value={sortOrder}
                  onChange={(value) => handleSortChange(sortField, value)}
                />
                <Box className={classes.filterBtn}>
                  <Button
                    size="sm"
                    radius={8}
                    color="yellow"
                    disabled={loading}
                    onClick={() =>
                      getNotes(
                        currentPage,
                        null,
                        true,
                        dateRange,
                        sortField,
                        sortOrder
                      )
                    }
                  >
                    Save
                  </Button>
                </Box>
              </Menu.Dropdown>
            </Menu>
          </Box>
        </Flex>
        <AddButton title={"Add New Note"} onClick={open} />
      </Box>
      {/* Note Table with Pagination */}
      <Box className={classes.table}>
        <NoteTable
          openModal={(data) => {
            setData(data);
            setOpen(true);
          }}
          setView={(data) => {
            setView(true);
          }}
          userId={userId}
          loading={loading}
        />
        <CustomPagination
          itemsPerPage={10}
          data={notes?.count}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </Box>
    </Container>
  );
};

export default ClientNoteview;
