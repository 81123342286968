import {
  Box,
  Button,
  Container,
  Grid,
  Flex,
  Indicator,
  Loader,
  Select,
  SimpleGrid,
  Textarea,
  createStyles,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AccordionCheck from "../../../components/AccordionCheck";
import TopBar from "../../../components/Topbar";
import { getAllFacilities } from "../../../services";
import { getAllPatientsModal } from "../../../services/patient";
import { getAllStaff } from "../../../services/staff";
import {
  addTask,
  changeTaskStatus,
  getClientTask,
} from "../../../services/task";
import { decrypt } from "../../../utils";
import PreviousNotesComp from "../../../components/TaskTable/PreviousNotesComp";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { FaPlus } from "react-icons/fa6";
import { CommentDrawer } from "./CommentDrawer";
import AppNavbar from "../../../components/AppNavbar/AppNavbar";
import NewTaskModal from "../../../components/NewTaskModal";
import { IconChevronDown } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
    backgroundColor: "#F5F5F5",
    height: "100vh",
  },
  container: {
    // border: "1px solid #dee2e6",
    margin: "10px 20px 0  8px",

  },
  mainContainer: {
    borderRadius: "15px",
    backgroundColor: "#ffffff",
    padding: "20px 10px 10px 20px",
    marginBottom: "20px",
  },
  topContainer:{
    borderRadius: "15px",
    backgroundColor: "#ffffff",
    padding: "10px 10px 30px 10px",
    marginBottom: "20px",
  },
  checkBoxParent: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    // margin: "20px",
    "@media (min-width:992px)": {
      padding: "20px 140px",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
  },
  mainHeading: {
    margin: "0",
  },
  draft: {
    p: {
      margin: "0",
      border: "1px solid #fab005",
      padding: "2px 8px",
      borderRadius: "4px",
      color: "#fab005",
    },
  },
  completed: {
    p: {
      margin: "0",
      border: "1px solid green",
      padding: "2px 8px",
      borderRadius: "4px",
      color: "green",
    },
  },
  expired: {
    p: {
      margin: "0",
      border: "1px solid red",
      padding: "2px 8px",
      borderRadius: "4px",
      color: "red",
    },
  },
  detail: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  detail: {
    span: {
      color: "#A8A8A8",
    },
  },
  detail: {
    p: {
      margin: "0",
    },
  },
  info: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  info: {
    p: {
      margin: "0",
      textAlign: "center",
    },
  },
  btnFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "60px 0 0 0",
    gap: "20px",
  },
  divGap: {
    // margin: "20px 0",
    alignItems: "center",
    backgroundColor: "#ffffff",
    borderRadius: "15px",
  },
  newTaskBtn: {
    textAlign: "end",
    "@media (max-width:600px)": {
      textAlign: "center",
      margin: "10px 0 10px 0",
    },
  },
  topBarContent: {
    margin: "5px 0 10px 0",
    "@media (max-width:600px)": {
      textAlign: "center",
      margin: "5px 0 0 0",
    },
  },
  topBarDate: {
    color: "#A3A3A3",
    fontSize: "small",
  },
  iconStyle: {
    width: "20px",
    height: "20px",
    color: "#94A3B8",
  },
  commentBtnStyle: {
    boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor:"#F4F3FF",
    color:"#6C63FF"
  },
  commentFlex: {
    "@media (max-width:600px)": {
      marginTop: "20px",
    },
  },
}));

const AddTask = () => {
  const { classes } = useStyles();
  const isMoobileScreen = useMediaQuery("(max-width: 600px)");
  const isTabletScreen = useMediaQuery("(max-width: 1030px)");
  const [opened, { open, close }] = useDisclosure(false);
  const location = useLocation();
  // console.log("🚀 ~ AddTask ~ location:", location);
  const editstate = location.state?.fromModal ? location.state : null;
  // console.log("🚀 ~ AddTask ~ editstate:", editstate);
  const navigate = useNavigate();
  const [state, setState] = useState(null);
  // console.log("🚀 ~ AddTask ~ state:", state);
  const [taskTypeDetails, setTypeDetails] = useState(null);
  const [notes, setNotes] = useState("");
  const [comments, setComments] = useState([]);
  // console.log("🚀 ~ AddTask ~ comments:", comments)
  const [checks, setChecks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingClientTasks, setLoadingClientTasks] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);
  const [clientValue, setClientValue] = useState({});
  const [clientData, setClientData] = useState("");
  const { tasks, loadingTasks } = useSelector((state) => state?.task);

  const { allPatients } = useSelector((state) => state?.patient);
  const { allFacilities } = useSelector((state) => state?.facility);
  const { profile } = useSelector((state) => state.profile);
  // console.log("🚀 ~ AddTask ~ profile:", profile);
  let userType = profile?.user?.type;
  let taskType =
    editstate?.fromModal === true
      ? editstate?.taskType?.id
      : localStorage.getItem("taskType");
  let taskTypeName = localStorage.getItem("taskTypeName");

  // console.log("clientData =>", clientData);
  // console.log("clientValue =>", clientValue);
  // console.log(
  //   "comparison =>",
  //   clientValue?.task?.client?._id,
  //   clientData?.client?._id
  // );

  useEffect(() => {
    if (editstate?.fromModal === true) {
      setLoading(true);
      setState(editstate);
      setTypeDetails(editstate?.taskType);
      // setNotes(editstate?.notes);
      // setChecks(editstate?.fieldsDone);
      setLoading(false);
      // setHasEnded(editstate?.hasEnded);
      setLoadingClientTasks(true);
      setLoadingClientTasks(false);
      getClientTask({
        client: editstate?.client?.id,
        type: editstate?.taskType?.id,
      });
      form.setFieldValue("client", editstate?.client?.id);
      form.setFieldValue("facility", editstate?.facility?.id);
      form.setFieldValue(
        "shift",
        editstate?.shift?.[editstate?.shift?.length - 1]?.name
      );
    }
  }, [editstate]);

  // console.log("taskTypeDetails =>", taskTypeDetails);

  useEffect(() => {
    getAllPatientsModal();
    getAllStaff("all");
    getAllFacilities("all");
  }, []);

  const form = useForm({
    initialValues: {
      client: state?.client ? state?.client?.id : "",
      facility: state?.facility ? state?.facility?.id : "",
      shift: "",
    },
    validate: {
      client: (value) => (value ? null : "Client is Required"),
      facility: (value) => (value ? null : "Facility is Required"),
      shift: (value) =>
        value
          ? // value || state?.shift?.find((item) => item.staff === profile?.user?.id)
            null
          : "Shift is Required",
    },
  });

  const resetForm = () => {
    form?.reset();
  };

  const completePreviousTask = async () => {
    await changeTaskStatus(clientValue?.task?._id, false);
  };

  const onSave = async (values) => {
    setLoading(true);
    let payload;
    // if (state?.startedBy) {
    //   let emptyShifts = checks?.filter((obj) => !obj.shift);
    //   let withShifts = checks?.filter((obj) => obj.shift);

    //   let filledShifts = emptyShifts?.map((item) => {
    //     return { ...item, shift: values.shift };
    //   });
    //   //update
    //   payload = {
    //     ...values,
    //     isStarted: false,
    //     notes,
    //     fieldsDone: [...withShifts, ...filledShifts],
    //     taskId: state?._id,
    //   }; //update wala payload
    // } else {
    //   //add
    //   payload = {
    //     ...values,
    //     isStarted: true,
    //     notes,
    //     fieldsDone: checks,
    //     taskType,
    //   };
    //   console.log("🚀 ~ onSave ~ payload:", payload);
    // }

    let formattedComments = comments?.map(_c => {
      if(_c?.text){
        return _c?.text
      }
    })

    payload = {
      ...values,
      isStarted: true,
      notes,
      comments: formattedComments,
      fieldsDone: checks,
      taskType,
    };

    let clientTask = await getClientTask({
      client: values?.client,
      type: taskType,
    });

    if (clientTask?.task) {
      // await changeTaskStatus(clientTask?.task?._id, false);
      await addTask(payload, resetForm, navigate, setLoading);
    } else {
      await addTask(payload, resetForm, navigate, setLoading);
    }
  };

  const AuctionTimer = () => {
    const [remainingTime, setRemainingTime] = useState("");

    useEffect(() => {
      let interval;
      interval = setInterval(() => {
        const now = moment();
        const end = moment(state?.taskEndAt);
        const duration = moment.duration(end.diff(now));
        if (duration.asSeconds() <= 0) {
          clearInterval(interval);
          setRemainingTime("Task Ended!");
          setHasEnded(true);
        } else {
          const days = Math.floor(duration.asDays());
          const hours = String(duration.hours()).padStart(2, "0");
          const minutes = String(duration.minutes()).padStart(2, "0");
          const seconds = String(duration.seconds()).padStart(2, "0");
          setRemainingTime(
            days === 0
              ? `${hours}h ${minutes}m ${seconds}s`
              : `${days}d ${hours}h ${minutes}m ${seconds}s`
          );
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }, [state?.taskEndAt]);

    return (
      <>
        <div style={{ minWidth: "130px" }}>
          <p
            style={{
              textTransform:
                remainingTime === "Task Ended!" ? "initial" : "lowercase",
              fontSize: remainingTime === "Task Ended!" ? "16px" : "18px",
            }}
          >
            {remainingTime || "-"}
          </p>
        </div>
      </>
    );
  };

  return (
    <Container fluid className={classes.content} p={0}>
      <AppNavbar title={"Task"} profile={profile} />
      <Title order={3} mt={10} ml={10}>
        Add Task
      </Title>
      {/* <TopBar title={"Add Task"} /> */}
      <Box className={classes.container}>
        <form onSubmit={form?.onSubmit((values) => onSave(values))}>
          <Box className={classes.topContainer}>
            <div className={classes.flex}>
              {/* <h2 className={classes.mainHeading}>{taskTypeName || ""}</h2> */}
              {/* {state?.status === "draft" && !hasEnded && (
                <div className={classes.draft}>
                  <p>Draft</p>
                </div>
              )}
              {hasEnded && (
                <div className={classes.completed}>
                  <p>Completed</p>
                </div>
              )} */}
              {/* {state?.status === "expired" && (
              <div className={classes.expired}>
                <p>Expired</p>
              </div>
            )} */}
            </div>
            <div className={classes.divGap}>
              <Grid justify="space-between" align="center">
                <Grid.Col xs={3} sm={3} md={3} lg={2}>
                  <h2 className={classes.topBarContent}>
                    {taskTypeName || ""}
                  </h2>
                </Grid.Col>
                <Grid.Col p={0} xs={6} sm={6} md={7} lg={8}>
                  <p
                    className={`${classes.topBarContent} ${classes.topBarDate}`}
                  >
                    {moment(state?.createdAt).format("dddd, MMMM Do, YYYY")}
                  </p>
                </Grid.Col>
                <Grid.Col xs={3} sm={3} md={2} className={classes.newTaskBtn}>
                  {/* <Button
                    className={classes.taskBtn}
                    variant="outline"
                    color="green"
                    radius="md"
                    size="xs"
                  >
                    New Task
                  </Button> */}
                  {/* <NewTaskModal className={classes.taskBtn} /> */}
                </Grid.Col>
              </Grid>
              <SimpleGrid
                cols={isMoobileScreen ? 1 : isTabletScreen ? 2 : 4}
                // className={classes.divGap}
              >
                <div className={classes.detail}>
                  {/* <p>
                  <strong>Started By Staff: </strong> {state?.startedBy?.name}
                </p> */}
                  <Select
                    label="Staff Name"
                    placeholder={
                      state?.startedBy
                        ? state?.startedBy?.name
                        : profile?.user?.name
                    }
                    disabled={true}
                    data={[]}
                    radius={"md"}
                  />
                </div>
                <div className={classes.info}>
                  <Select
                    id="add-task-client-name"
                    label="Client Name"
                    radius={"md"}
                    value={state?.client?.id}
                    rightSectionPointerEvents="none"
                    rightSection={
                      <IconChevronDown className={classes.iconStyle} />
                    }
                    placeholder={
                      state?.client
                        ? decrypt(state?.client?.name, false)
                        : "Select an option"
                    }
                    onChange={async (value) => {
                      setLoadingClientTasks(true);
                      form.setFieldValue("client", value);
                      let facility = allPatients?.find(
                        (item) => item.id === value
                      )?.facilityId?.id;
                      form.setFieldValue("facility", facility);
                      let data = await getClientTask({
                        client: value,
                        type: taskType,
                      });
                      setClientValue(data);
                      if (data?.task) {
                        // console.log("task field", data?.task);
                        setClientData(data?.task);

                        // const taskStatusRes = await changeTaskStatus(
                        //   data?.task?._id,
                        //   false
                        // );

                        setState(null);
                        setTypeDetails(data?.taskType);
                        setLoadingClientTasks(false);
                        setChecks([]);
                        setLoadingClientTasks(false);

                        // if (taskStatusRes) {
                        //   setState(null);
                        //   setTypeDetails(data?.taskType);
                        //   setLoadingClientTasks(false);
                        //   setChecks([]);
                        //   setLoadingClientTasks(false);
                        // }

                        // setState({ ...data?.task });
                        // setTypeDetails(data?.taskType);
                        // let fieldIds = data?.task?.fieldsDone?.map((item) => {
                        //   return {
                        //     field: item?.field?._id,
                        //     staff: item?.staff?._id,
                        //     shift: item?.shift,
                        //   };
                        // });
                        // console.log("fieldIds", fieldIds);
                        // setChecks(fieldIds);
                        // setNotes(data?.task?.notes);
                      } else {
                        setState(null);
                        setTypeDetails(data?.taskType);
                        setLoadingClientTasks(false);
                        setChecks([]);
                        // setNotes("");
                      }
                      // console.log("data", data);
                    }}
                    data={
                      allPatients?.map((data) => {
                        return {
                          value: data?.id,
                          label: data?.name,
                        };
                      }) || []
                    }
                    // {...form.getInputProps("client")}
                  />
                  {/* <Select
                    label="Started By Staff"
                    placeholder={
                      state?.startedBy
                        ? state?.startedBy?.name
                        : profile?.user?.name
                    }
                    disabled={true}
                    data={[]}
                  /> */}
                </div>
                {/* <div className={classes.info}>
                  <Select
                    id="add-task-client-name"
                    label="Client Name"
                    value={state?.client?.id}
                    placeholder={
                      state?.client
                        ? decrypt(state?.client?.name, false)
                        : "Client"
                    }
                    // disabled={state?.client}
                    onChange={async (value) => {
                      setLoadingClientTasks(true);
                      form.setFieldValue("client", value);
                      let facility = allPatients?.find(
                        (item) => item.id === value
                      )?.facilityId?.id;
                      form.setFieldValue("facility", facility);
                      let data = await getClientTask({
                        client: value,
                        type: taskType,
                      });
                      if (data?.task) {
                        setState({ ...data?.task });
                        setTypeDetails(data?.taskType);
                        let fieldIds = data?.task?.fieldsDone?.map((item) => {
                          return {
                            field: item?.field?._id,
                            staff: item?.staff?._id,
                            textField: item?.textField,
                            shift: item?.shift,
                          };
                        });
                        // console.log("fieldIds", fieldIds);
                        setChecks(fieldIds);
                        // setNotes(data?.task?.notes);
                        setLoadingClientTasks(false);
                      } else {
                        setState(null);
                        setTypeDetails(data?.taskType);
                        setLoadingClientTasks(false);
                        setChecks([]);
                        // setNotes("");
                      }
                      // console.log("data", data);
                    }}
                    data={
                      allPatients?.map((data) => {
                        return {
                          value: data?.id,
                          label: data?.name,
                        };
                      }) || []
                    }
                    {...form.getInputProps("client")}
                  />

                  <p>
                {decrypt(state?.client?.name, false) || state?.client?.name}
              </p>
                </div> */}
                <div className={classes.info}>
                  <Select
                    label="Facility"
                    radius={"md"}
                    rightSectionPointerEvents="none"
                    rightSection={
                      <IconChevronDown className={classes.iconStyle} />
                    }
                    placeholder={
                      state?.client ? state?.facility?.name : "Select an option"
                    }
                    // disabled={true}
                    // disabled={state?.facility}
                    value={state?.facility?.id}
                    // data={
                    //   allFacilities?.facilities?.map((data) => {
                    //     return {
                    //       value: data?.id,
                    //       label: data?.name,
                    //     };
                    //   }) || []
                    // }
                    data={
                      allFacilities?.facilities?.map((data) => {
                        // console.log("form client transformed value", form.getTransformedValues()?.client)
                        let filteredPatient = allPatients?.find(
                          (item) => item.id == form.getTransformedValues()?.client  
                        );
                        let facilityDisabled =
                          filteredPatient?.facilityId?.find(
                            (_f) => _f?.id == data?.id
                          )
                            ? false
                            : true;
                        // console.log(
                        //   "filteredPatient inside facilities data",
                        //   filteredPatient
                        // );
                        return {
                          value: data?.id,
                          label: data?.name,
                          disabled: facilityDisabled,
                        };
                      }) || []
                    }
                    {...form.getInputProps("facility")}
                  />

                  {/* <p>Facility Name</p> */}
                  {/* <p>{state?.facility?.name}</p> */}
                </div>
                <div className={classes.info}>
                  {state?.shift?.find(
                    (item) => item?.staff?.id === profile?.user?.id
                  ) ? (
                    <Select
                    radius={"md"}
                      label="Shift"
                      rightSectionPointerEvents="none"
                      rightSection={
                        <IconChevronDown className={classes.iconStyle} />
                      }
                      placeholder={
                        state?.shift?.find(
                          (item) => item?.staff?.id === profile?.user?.id
                        )?.name
                      }
                      // disabled={true}
                      value={
                        state?.shift?.find(
                          (item) => item?.staff?.id === profile?.user?.id
                        )?.name
                      }
                      data={["Morning", "Evening", "Night"]}
                      {...form.getInputProps("shift")}
                    />
                  ) : (
                    <Select
                      id="add-task-staff"
                      label="Shift"
                      radius={"md"}
                      placeholder={"Select an option"}
                      rightSectionPointerEvents="none"
                      rightSection={
                        <IconChevronDown className={classes.iconStyle} />
                      }
                      data={["Morning", "Evening", "Night"]}
                      {...form.getInputProps("shift")}
                    />
                  )}
                  {/* <p>Shift</p>
              <p>{state?.shift || "-"}</p> */}
                </div>
                {/* {state?.dateTime ||
                  (state?.createdAt && (
                    <div className={classes.detail}>
                      <p>
                        <strong>Date Created: </strong>
                        {state?.dateTime &&
                          moment(
                            state?.dateTime ? state?.dateTime : state?.createdAt
                          ).format("MM-DD-YYYY")}
                        at{" "}
                        {state?.dateTime
                          ? moment(state?.dateTime).format("hh:mm a")
                          : "-"}
                      </p>
                    </div>
                  ))}
                {state?.dateTime && state?.taskEndAt && (
                  <div className={classes.detail}>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <strong>Time Remaining: </strong>{" "}
                      {state?.dateTime && state?.taskEndAt ? (
                        <AuctionTimer />
                      ) : (
                        "-"
                      )}
                    </p>
                  </div>
                )} */}

                {/* <div className={classes.info}>
                <p>
                  <strong>Time: </strong>
                  
                </p>
              </div> */}
              </SimpleGrid>
            </div>
            {/* <SimpleGrid cols={5}>
            <div className={classes.info}>
              <p>Client Name</p>
              <p>
                {decrypt(state?.client?.name, false) || state?.client?.name}
              </p>
            </div>
            <div className={classes.info}>
              <p>Facility Name</p>
              <p>{state?.facility?.name}</p>
            </div>
            <div className={classes.info}>
              <p>Time</p>
              <p> {moment(state?.dateTime).format("hh:mm A")}</p>
            </div>
            <div className={classes.info}>
              <p>Shift</p>
              <p>{state?.shift || "-"}</p>
            </div>
          </SimpleGrid> */}
          </Box>
          {/* <Title order={4} ml={20}>
            Task List
          </Title> */}
          <div>
          <h3>Task List</h3>
        </div>
          <Box className={classes.mainContainer}>
            <Box
              className={`${classes.mainContainer} ${classes.checkBoxParent}`}
            >
              {loadingClientTasks ? (
                <div
                  style={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loader size={40} color="yellow" />
                </div>
              ) : (
                <SimpleGrid cols={isMoobileScreen ? 1 : isTabletScreen ? 2 : 3}>
                  {taskTypeDetails?.subTasks?.map((tasks) => (
                    <div>
                      <AccordionCheck
                        type={"add"}
                        data-testid="task-grid"
                        tasks={tasks}
                        Data={tasks?.fields}
                        // checksDone={tasks?.fieldsDone || []}
                        checksDone={[]}
                        activityFields={
                          clientValue?.task?.client?._id ===
                            clientData?.client?._id &&
                          clientValue?.task?.facility?._id ===
                            clientData?.facility?._id
                            ? clientData?.fieldsDone
                            : []
                        }
                        Heading={tasks?.task?.name}
                        setChecks={setChecks}
                        checks={checks}
                        userType={userType}
                        userId={profile?.user?.id}
                        shift={form.getInputProps("shift")?.value}
                        profile={profile}
                      />
                    </div>
                  ))}
                </SimpleGrid>
              )}
            </Box>

            <div>
              {/* <h3>Additional Notes</h3> */}
              {/* <div className={classes.flex}>
              <Radio label="Yes" />
              <Radio label="No" />
            </div> */}
              {/* <div className={classes.divGap}>
                <Textarea
                  id="additional-note"
                  defaultValue={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                  disabled={hasEnded}
                  minRows={4}
                  style={{ height: "auto" }}
                />
              </div> */}
              {!hasEnded && (
                <div className={classes.btnFlex}>
                  <Button
                    w={200}
                    size="lg"
                    color="gray"
                    radius={"md"}
                    variant="outline"
                    onClick={() => {
                      navigate("/home/tasks");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="ad-task-start"
                    w={200}
                    size="lg"
                    c={"#000"}
                    type="submit"
                    radius={"md"}
                    color="yellow"
                    loading={loading}
                  >
                    Save
                    {/* {state?.startedBy ? "Save" : "Start"} */}
                  </Button>
                </div>
              )}
            </div>
            <Flex className={classes.commentFlex} justify="flex-end">
              <Indicator label={comments?.length} color="red" size={20}>
                <Button
                  w={200}
                  size="lg"
                  radius={"md"}
                  onClick={open}
                  variant="light"
                  className={classes.commentBtnStyle}
                >
                  + Comments
                </Button>
              </Indicator>
            </Flex>
          </Box>

          <CommentDrawer
            title={"Comments"}
            opened={opened}
            close={close}
            comments={comments}
            setComments={setComments}
            data={profile}
          />

          {/* <Box
            className={classes.mainContainer}
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "10px",
              margin: "20px",
            }}
          >
            <h3>Previous Notes</h3>
            <div className={classes.divGap}>
              <PreviousNotesComp notes={state?.notes} />
            </div>
          </Box> */}
        </form>
      </Box>
    </Container>
  );
};

export default AddTask;
