import mixpanel from "mixpanel-browser";
import { https } from "../http";
import store from "../store";
import {
  _login,
  setEmailVerifyLoading,
  setLoading,
  setForgotLoading,
  setResetLoading,
  setContactUs,
} from "../store/reducers/auth";
import { removeCookie, setCookie, showError, showInfo } from "../utils/index";
import { getProfile } from "./profile";
import swal from "sweetalert";
import { userInfoAnalytics } from "../config/mixpanelConfig";

const login = async (values, navigate) => {
  try {
    store.dispatch(setLoading(true));
    let response = await https().post("/auth/login", values);
    if (response?.status === 200) {
      // console.log("response login", response);
      let profile = await getProfile(true, response?.data?.token);
      if (profile) {
        // console.log("profile on login", profile)
        // mixpanel.identify(profile?.user?.user?.id);
        // mixpanel.people.set({
        //   $name: profile?.user?.user?.name,
        //   $email: profile?.user?.user?.email,
        //   $avatar: profile?.user?.user?.photo,
        // });
        userInfoAnalytics(profile);
        let { token, user, message } = response?.data;
        if (message === "Email Verified") {
          setCookie(token, "token");
          if (user?.user?.type !== "staff") {
            // console.log("user", user);
            if (user?.business) {
              navigate("/selectprice");
            } else if (!user?.business) {
              // navigate("/profile");
              navigate("/signup", { state: { token: token } });
            } else if (user?.business && user?.subscription) {
              navigate("/home");
            }
          } else navigate("/home");
          store.dispatch(_login({ token }));
        } else if (message === "Verification Email Sent") {
          showInfo("Verification Email Sent");
        }
      }
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status, false);
  }
};

// google login
const googleLogin = async (values, navigate) => {
  try {
    store.dispatch(setLoading(true));
    let response = await https().post("/auth/google-auth", values);
    if (response?.status === 200) {
      let profile = await getProfile(true, response?.data?.token);
      if (profile) {
        let { token, user } = response?.data;
        // console.log("user :", user);
        setCookie(token, "token");
        if (user?.user?.type !== "staff") {
          if (user?.business) {
            navigate("/selectprice");
          } else if (!user?.business) {
            navigate("/profile");
          } else if (user?.business && user?.subscription) {
            navigate("/home");
          }
        } else navigate("/home");
        store.dispatch(_login({ token }));
      }
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status, false);
  }
};

// google sign up
const googleSignup = async (values, navigate) => {
  try {
    store.dispatch(setLoading(true));
    let response = await https().post("/auth/decode-token", values);
    if (response?.status === 200) {
      setCookie(response?.data?.user?.token, "token");
      store.dispatch(setLoading(false));
      navigate("/profile");
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status, false);
  }
};

const signUp = async (values) => {
  try {
    store.dispatch(setLoading(true));
    let response = await https().post("/auth/sign-up", values);
    if (response?.status === 200) {
      setCookie(response?.data?.user?.token, "token");
      store.dispatch(setLoading(false));
      // navigate("/profile");
      await swal({
        title: `Email Verification`,
        text: "Email verification link sent successfully. Please verify your email address",
        icon: "success",
        dangerMode: false,
        buttons: ["Cancel", "Ok"],
      });
      return response;
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
    return error?.response;
  }
};

const registerBusiness = async (values, navigate) => {
  try {
    store.dispatch(setLoading(true));
    let response = await https().post("/auth/business", values);

    if (response?.status === 200) {
      await getProfile(true, response?.data?.token);
      store.dispatch(setLoading(false));
      navigate("/selectprice");
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    const duplicate = error?.response?.data?.message
      ?.toLowerCase()
      ?.includes("duplicate");
    const companyName = error?.response?.data?.message
      ?.toLowerCase()
      ?.includes("companyname");
    // console.log("erroe in sercice", error?.response?.data?.message);
    const businessEmail = error?.response?.data?.message
      ?.toLowerCase()
      ?.includes("businessemail");
    const contactPhone = error?.response?.data?.message
      ?.toLowerCase()
      ?.includes("contactphone");

    if (duplicate && companyName)
      return showError("Company name alreay in use.");
    if (duplicate && businessEmail)
      return showError("Company Email alreay in use.");
    if (duplicate && contactPhone)
      return showError("Company Number alreay in use.");
    else showError(error?.response?.data?.message, error?.response?.status);
  }
};

const forgetPassword = async (values, navigate) => {
  try {
    store.dispatch(setForgotLoading(true));
    let response = await https().post("/auth/forgotPassword", values);

    if (response?.status === 200) {
      store.dispatch(setForgotLoading(false));
      showInfo(response?.data?.message);
    }
  } catch (error) {
    store.dispatch(setForgotLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};
const resetPassword = async (values, navigate) => {
  try {
    store.dispatch(setResetLoading(true));
    let response = await https().post("/auth/resetPassword", values);
    if (response?.status === 200) {
      showInfo(response?.data?.message);
      removeCookie("token");
      navigate("/login");
    }
  } catch (error) {
    store.dispatch(setResetLoading(false));

    showError(error?.response?.data?.message, error?.response?.status);
  }
};
const verifyEmail = async (values, navigate) => {
  try {
    store.dispatch(setEmailVerifyLoading(true));
    let response = await https().post("/auth/verifyEmail", values);

    // console.log("response inside verify email");

    if (response?.status === 200) {
      store.dispatch(setEmailVerifyLoading(false));
      return response?.data;
    }
  } catch (error) {
    store.dispatch(setEmailVerifyLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};
const contactUs = async (values) => {
  try {
    store.dispatch(setContactUs(true));
    let response = await https().post("/auth/contactUs", values);

    if (response?.status === 200) {
      store.dispatch(setContactUs(false));
      return response?.data;
    }
  } catch (error) {
    store.dispatch(setContactUs(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};
export {
  login,
  googleLogin,
  googleSignup,
  signUp,
  forgetPassword,
  verifyEmail,
  resetPassword,
  registerBusiness,
  contactUs,
  setResetLoading,
};
