import React from "react";

import { Table, ScrollArea, createStyles, Box, Text } from "@mantine/core";

import { useSelector } from "react-redux";
import ReportTableRow from "../ReportTableRow";
import TableLoader from "../TableLoader";
import RecordNotFound from "../RecordNotFound";
import { useState } from "react";

const useStyles = createStyles((theme) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: "16px",
    transition: "box-shadow 150ms ease",
    "& th": {
      backgroundColor: "#000",
      color: "#fff !important",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      left: 0,
      right: 0,
      bottom: 0,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },
  notFoundTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "100vh",
    gap: "6rem",
  },
  notFoundText: {
    color: "#A8A8A8",
    fontFamily: "Inter",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
  },
}));

const ReportTable = ({ data }) => {
  const { reports, loading } = useSelector((state) => state.report);
  // console.log("🚀 ~ ReportTable ~ reports:", reports)
  const { classes, cx } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const rows = reports?.reports?.map((row, i) => (
    <ReportTableRow row={row} key={i} />
  ));
  return (
    <ScrollArea
      onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
      mt={"30px"}
    >
      <Table
        withBorder
        fontSize={"sm"}
        highlightOnHover
        withColumnBorders
        enableStickyHeader
        verticalSpacing={"md"}
        sx={{ fontFamily: "Inter", cursor: "pointer" }}
      >
        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
          <tr>
            <th style={{ minWidth: "150px" }}>Report ID</th>
            <th style={{ minWidth: "140px" }}>Report Title</th>
            <th style={{ minWidth: "200px" }}>Record Source</th>
            <th style={{ minWidth: "140px" }}>Start Date</th>
            <th style={{ minWidth: "140px" }}>End Date</th>
            <th style={{ minWidth: "140px" }}>Created by</th>
            <th style={{ minWidth: "170px" }}></th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={1000}>
                <TableLoader />
              </td>
            </tr>
          ) : rows?.length ? (
            rows
          ) : (
            <tr>
              <td colSpan={1000}>
                <Box className={classes.notFoundTextContainer} >
                  <RecordNotFound />
                  <Text className={classes.notFoundText}>
                    You don&apos;t have any type of Reports, click on “Add New
                    Report”
                  </Text>
                </Box>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
};

export default ReportTable;
