import {
  Box,
  Button,
  Container,
  Flex,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  Title,
  createStyles,
  Indicator,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AccordionCheck from "../../../components/AccordionCheck";
import TopBar from "../../../components/Topbar";
import {
  changeTaskStatus,
  editTask,
  getClientTask,
} from "../../../services/task";
import { decrypt } from "../../../utils";
import PreviousNotesComp from "../../../components/TaskTable/PreviousNotesComp";
import { useMediaQuery } from "@mantine/hooks";
import AppNavbar from "../../../components/AppNavbar/AppNavbar";
import NewTaskModal from "../../../components/NewTaskModal";
import { CommentDrawer } from "./CommentDrawer";
import { useDisclosure } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflowX: "auto",
    backgroundColor: "#F5F5F5",
  },
  container: {
    // borderRadius: "15px",
    margin: "10px 20px 0  8px",
  },
  mainContainer: {
    borderRadius: "15px",
    backgroundColor: "#ffffff",
    padding: "20px 10px 10px 20px",
    marginBottom: "20px",
  },
  topContainer:{
    borderRadius: "15px",
    backgroundColor: "#ffffff",
    padding: "10px 10px 15px 10px",
    marginBottom: "20px",
  },
  checkBoxParent: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    "@media (min-width:992px)": {
      padding: "0 140px",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "20px",
  },
  mainHeading: {
    margin: "0",
  },
  draft: {
    p: {
      margin: "0",
      border: "1px solid #fab005",
      padding: "2px 8px",
      borderRadius: "4px",
      color: "#fab005",
    },
  },
  completed: {
    p: {
      margin: "0",
      border: "1px solid green",
      padding: "2px 8px",
      borderRadius: "4px",
      color: "green",
    },
  },
  expired: {
    p: {
      margin: "0",
      border: "1px solid red",
      padding: "2px 8px",
      borderRadius: "4px",
      color: "red",
    },
  },
  detail: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  detail: {
    span: {
      color: "#A8A8A8",
    },
  },
  detail: {
    p: {
      margin: "0",
    },
  },
  info: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  info: {
    p: {
      margin: "0",
      textAlign: "center",
    },
  },
  btnFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0",
    gap: "20px",
  },
  divGap: {
    margin: "20px 0",
    alignItems: "center",
  },
  commentBtnStyle: {
    boxShadow: "4px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundColor: "#F4F3FF",
    color: "#6C63FF",
  },
  commentFlex: {
    "@media (max-width:600px)": {
      marginTop: "20px",
    },
  },
}));
const EditTask = () => {
  const { classes } = useStyles();
  const isMoobileScreen = useMediaQuery("(max-width: 600px)");
  const isTabletScreen = useMediaQuery("(max-width: 1030px)");
  const { tasks, loadingTasks } = useSelector((state) => state?.task);
  const [opened, { open, close }] = useDisclosure(false);

  const { state } = useLocation();
  // console.log("🚀 ~ EditTask ~ state:", state);
  const navigate = useNavigate();
  const [notes, setNotes] = useState("");
  const [comments, setComments] = useState([]);
  const [checks, setChecks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasEnded, setHasEnded] = useState(false);

  const { profile } = useSelector((state) => state.profile);
  let userType = profile?.user?.type;
  // console.log("state =>", state);

  useEffect(() => {
    // console.log("state?.fieldsDone", state?.fieldsDone);
    let fieldIds = state?.fieldsDone?.map((item) => {
      return {
        field: item?.field?._id,
        textField: item?.textField,
        staff: item?.staff?.id,
        shift: item.shift,
        checkedAt: item?.checkedAt,
        textField: item?.textField,
      };
    });
    setChecks(fieldIds);
    setNotes(
      state?.notes?.find(
        (obj) =>
          obj?.staff?.id === state?.selectedShift?.staff?.id &&
          obj.shift === state?.selectedShift?.name
      )?.note || ""
    );

    setComments(state?.comments);
  }, [state]);

  const form = useForm();

  const onSave = async (values) => {
    setLoading(true);

    let formattedComments = comments?.map(_c => {
      if(_c?.text){
        return _c?.text
      } else {
        return _c
      }
    })

    let payload = {
      notes,
      comments: formattedComments,
      fieldsDone: checks,
      taskId: state?.id,
      shift: state?.selectedShift?.name,
    };
    
    await editTask(payload, navigate, setLoading);
  };

  const AuctionTimer = () => {
    const [remainingTime, setRemainingTime] = useState("");
    // console.log("🚀 ~ AuctionTimer ~ remainingTime:", remainingTime);

    useEffect(() => {
      let interval;
      interval = setInterval(() => {
        // const now = moment();
        // const end = moment(state?.taskEndAt);
        const now = moment.tz(moment(), profile?.user?.timeZone);
        const end = moment.tz(
          moment(state?.taskEndAt),
          profile?.user?.timeZone
        );

        const duration = moment.duration(end.diff(now));
        if (duration.asSeconds() <= 0) {
          clearInterval(interval);
          setRemainingTime("Task Ended!");
          setHasEnded(true);
        } else {
          const days = Math.floor(duration.asDays());
          const hours = String(duration.hours()).padStart(2, "0");
          const minutes = String(duration.minutes()).padStart(2, "0");
          const seconds = String(duration.seconds()).padStart(2, "0");
          setRemainingTime(
            days === 0
              ? `${hours}h ${minutes}m ${seconds}s`
              : `${days}d ${hours}h ${minutes}m ${seconds}s`
          );
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }, [state?.taskEndAt]);

    return (
      <>
        <div style={{ minWidth: "130px" }}>
          <p
            style={{
              textTransform:
                remainingTime === "Task Ended!" ? "initial" : "lowercase",
              fontSize: remainingTime === "Task Ended!" ? "16px" : "18px",
            }}
          >
            {remainingTime || "-"}
          </p>
        </div>
      </>
    );
  };
  // const [clientValue, setClientValue] = useState({});
  // const completePreviousTask = async () => {
  //   await changeTaskStatus(clientValue?.task?._id, false);
  // };
  // let data =  getClientTask({
  //   client: state?.client?.id,
  //   type: state?.taskType,
  // });
  // setClientValue(data)

  return (
    <Container fluid className={classes.content} p={0}>
      <AppNavbar title={"Task"} profile={profile} />
      {/* <TopBar title={"Edit Task"} /> */}
      <Title order={3} mt={10} ml={10}>
        Edit Task
      </Title>
      <Box className={classes.container}>
        <form onSubmit={form?.onSubmit((values) => onSave(values))}>
          <Box className={classes.topContainer}>
            <div className={classes.flex}>
              <Flex align="center" gap={50}>
                <h2 className={classes.mainHeading}>
                  {state?.taskType?.name || ""}
                </h2>
                <Text c={"dimmed"}>
                  {state?.dateTime &&
                    moment(
                      state?.dateTime ? state?.dateTime : state?.createdAt
                    ).format("dddd, MMMM Do, YYYY")}
                </Text>
                {/* {state?.status === "draft" && !hasEnded && (
                  <div className={classes.draft}>
                    <p>Draft</p>
                  </div>
                )}
                {hasEnded && (
                  <div className={classes.completed}>
                    <p>Completed</p>
                  </div>
                )} */}
              </Flex>
              <Box>
                <NewTaskModal
                  state={state}
                  tasks={tasks}
                  className={classes.taskBtn}
                />
              </Box>
              {/* {state?.status === "expired" && (
                <div className={classes.expired}>
                  <p>Expired</p>
                </div>
              )} */}
            </div>
            <SimpleGrid
              cols={isMoobileScreen ? 1 : isTabletScreen ? 3 : 4}
              className={classes.divGap}
            >
              <div className={classes.detail}>
                {/* <p>
                    <strong>Started By Staff: </strong> {state?.startedBy?.name}
                  </p> */}
                <Select
                  label="Staff Name"
                  placeholder={state?.selectedShift?.staff?.name}
                  disabled={true}
                  data={[]}
                  radius={"md"}
                />
              </div>
              <div className={classes.info}>
                <Select
                  label="Client Name"
                  value={state?.client?.id}
                  placeholder={
                    state?.client
                      ? decrypt(state?.client?.name, false)
                      : "Client"
                  }
                  radius={"md"}
                  disabled={true}
                  //   onChange={async (value) => {
                  //     setLoadingClientTasks(true);
                  //     form.setFieldValue("client", value);
                  //     let facility = allPatients?.find(
                  //       (item) => item.id === value
                  //     )?.facilityId?.id;
                  //     form.setFieldValue("facility", facility);
                  //     let data = await getClientTask({
                  //       client: value,
                  //       type: taskType,
                  //     });
                  //     if (data?.task) {
                  //       setState({ ...data?.task });
                  //       setTypeDetails(data?.taskType);
                  //       let fieldIds = data?.task?.fieldsDone?.map((item) => {
                  //         return {
                  //           field: item?.field?._id,
                  //           staff: item?.staff?._id,
                  //         };
                  //       });
                  //       // console.log("fieldIds", fieldIds);
                  //       setChecks(fieldIds);
                  //       setNotes(data?.task?.notes);
                  //       setLoadingClientTasks(false);
                  //     } else {
                  //       setState(null);
                  //       setTypeDetails(data?.taskType);
                  //       setLoadingClientTasks(false);
                  //       setChecks([]);
                  //       setNotes("");
                  //     }
                  //     // console.log("data", data);
                  //   }}
                  data={[]}
                  // {...form.getInputProps("client")}
                />

                {/* <p>
                  {decrypt(state?.client?.name, false) || state?.client?.name}
                </p> */}
              </div>
              <div className={classes.info}>
                <Select
                  label="Facitlity"
                  placeholder={
                    state?.client ? state?.facility?.name : "Facility"
                  }
                  disabled={true}
                  // disabled={state?.facility}
                  // value={state?.facility?.id}
                  data={[]}
                  radius={"md"}
                  {...form.getInputProps("facility")}
                />
              </div>
              <div className={classes.info}>
                <Select
                  label="Shift"
                  radius={"md"}
                  disabled={true}
                  placeholder={state?.selectedShift?.name}
                  data={["Morning", "Evening", "Night"]}
                />
              </div>
              {/* <div className={classes.detail}>
                <p>
                  <strong>Date Created: </strong>{" "}
                  {state?.dateTime &&
                    moment(
                      state?.dateTime ? state?.dateTime : state?.createdAt
                    ).format("MM-DD-YYYY")}{" "}
                  at{" "}
                  {state?.dateTime
                    ? moment(state?.dateTime).format("hh:mm a")
                    : "-"}
                </p>
              </div> */}
              {/* <div className={classes.detail}>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <strong>Time Remaining: </strong>{" "}
                  {state?.dateTime &&
                  state?.taskEndAt &&
                  state?.status !== "completed" ? (
                    <AuctionTimer />
                  ) : (
                    "-"
                  )}
                </p>
              </div> */}
            </SimpleGrid>
          </Box>

          <div>
            <h3>Task List</h3>
          </div>

          <Box className={classes.mainContainer}>
            <Box
              className={`${classes.mainContainer} ${classes.checkBoxParent}`}
            >
              <SimpleGrid cols={isMoobileScreen ? 1 : isTabletScreen ? 2 : 3}>
                {state?.taskType?.subTasks?.map((tasks) => (
                  <div>
                    <AccordionCheck
                      type={"edit"}
                      Data={tasks?.fields}
                      checksDone={state?.fieldsDone || []}
                      Heading={tasks?.task?.name}
                      setChecks={setChecks}
                      checks={checks}
                      userType={userType}
                      userId={profile?.user?.id}
                      shift={state?.selectedShift?.name}
                    />
                  </div>
                ))}
              </SimpleGrid>
            </Box>
            <div>
              {/* <h3>Additional Notes</h3>
              <div className={classes.divGap}>
                <Textarea
                  id="edit-additional-note"
                  defaultValue={notes}
                  onChange={(e) => {
                    setNotes(e.target.value);
                  }}
                  disabled={hasEnded}
                  minRows={4}
                  style={{ height: "auto" }}
                />
              </div> */}
              {!hasEnded && (
                <div className={classes.btnFlex}>
                  <Button
                    w={200}
                    size="lg"
                    color="gray"
                    radius={"md"}
                    variant="outline"
                    onClick={() => {
                      navigate("/home/tasks");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="update-task"
                    w={200}
                    size="lg"
                    c={"#000"}
                    type="submit"
                    radius={"md"}
                    color="yellow"
                    loading={loading}
                  >
                    Update
                  </Button>
                </div>
              )}
            </div>
            <Flex className={classes.commentFlex} justify="flex-end">
              <Indicator label={comments?.length} color="red" size={20}>
                <Button
                  w={200}
                  size="lg"
                  radius={"md"}
                  onClick={open}
                  variant="light"
                  className={classes.commentBtnStyle}
                >
                  + Comments
                </Button>
              </Indicator>
            </Flex>
          </Box>
          <CommentDrawer
            title={"Comments"}
            opened={opened}
            close={close}
            comments={comments}
            setComments={setComments}
            data={profile}
          />
          {/* <Box className={classes.mainContainer}>
            <h3>Previous Notes</h3>
            <div className={classes.divGap}>
              <PreviousNotesComp notes={state?.notes} />
            </div>
          </Box> */}
        </form>
      </Box>
    </Container>
  );
};

export default EditTask;
