import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Router from "./router";
import { useDispatch, useSelector } from "react-redux";
import { getAllFacilitiesModal, getAllFacilityTypes } from "./services";
import { getAllNoteTypes } from "./services/note";
import { getAllPatientsModal } from "./services/patient";
import { getProfile } from "./services/profile";
import { getAllStaff } from "./services/staff";
import { getCookie } from "./utils";
import { io } from "socket.io-client";
import { baseURL } from "./http";
import { getNotificationsCount } from "./services/notification";
import { setTimeZone } from "./store/reducers/profile";
import mixpanel from "mixpanel-browser";

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const socket = io("https://staging-websocket.ogiso.designsdigitalstudio.xyz");
  // const { profile } = useSelector((state) => state?.profile);

  useEffect(() => {
    // f9d8617c0160483569de2dcc7d51ba78 -> production
    // 78363c38d10ec3ea34a41db7084bfe88 -> staging
    // f5efb3cb7f19adf372353c90d8bac388 -> umer
    
      mixpanel.init("78363c38d10ec3ea34a41db7084bfe88", {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });

    getDetails();

    socket.on("get-notification-count", (data) => {
      // console.log("notification socket run");
      getCount();
    });
  }, []);

  const getCount = async () => {
    await getNotificationsCount();
  };

  const getDetails = async () => {
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch(setTimeZone(currentTimezone));

    const token = getCookie("token");
    if (token) {
      const response = await getProfile(true, token, currentTimezone);
      if (response) {
        // getAllStaff("all");
        getAllStaff("staff");
        getAllNoteTypes();
        getAllFacilityTypes();
        getAllFacilitiesModal("all");
        getAllPatientsModal();
        setLoading(false);
      } else setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      {loading ? (
        <div
          style={{
            height: "100vh",
            padding: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/images/logo2.png"
            alt="logo"
            style={{ width: "20%", objectFit: "contain" }}
          />
          {/* <TableLoader /> */}
        </div>
      ) : (
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
