import React from "react";
import {
  Accordion,
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  Group,
  Modal,
  Popover,
  Radio,
  ScrollArea,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  Textarea,
  rem,
} from "@mantine/core";
import { DateInput, TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import { IconClock, IconInfoCircle } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createNote, editNote } from "../../services/note";
import { getAllPatientsModal } from "../../services/patient";
import {
  decrypt,
  formatDate,
  getOptions,
  noteType_Client,
  noteType_Client_Options,
} from "../../utils";
import useStyles from "./styles";
import ViewInfo from "./ViewInfo";
import CloseCircle from "../CloseCircle";
import { DropDownArrowSvg } from "../Svgs/DropDownArrowSvg";
import { IconX } from "@tabler/icons-react";
import { IconChevronDown } from "@tabler/icons-react";
import { setPatients } from "../../store/reducers/patient";
import { getAllPlans } from "../../services/supportplan";
import { getAllFacilitiesModal } from "../../services";

const NoteModal = ({
  opened,
  close,
  title,
  view,
  setView,
  userId, //clientId
  noteType: NOTETYPE,
  ...data
}) => {
  // console.log("🚀 ~ data ============>:", data);
  // Get the current time in HH:mm:ss format
  const {sortField, sortOrder} = data;
  console.log("🚀 ~ sortField, sortOrder:", sortField, sortOrder)
  // console.log("🚀 ~ noteData:", noteData);
  const { classes } = useStyles();
  const ref = useRef();
  const edit = data.data ? true : false;
  const noteT = data?.data?.noteId;
  const { allNotesTypes, addNoteLoading } = useSelector((state) => state?.note);
  const { allPlans } = useSelector((state) => state?.supportPlan);
  // console.log("🚀 ~ allPlans:", allPlans);
  const { profile } = useSelector((state) => state.profile);
  const { allPatients } = useSelector((state) => state?.patient);
  const [errors, setErrors] = useState({});
  const [outcomeNotes, setOutcomeNotes] = useState([]);

  const patientInfo = allPatients?.map((patient) => {
    return {
      selectedPatientId: patient?.id,
      label: patient?.name,
    };
  });
  useEffect(() => {
    // console.log("🚀 ~ patientInfo ~ patientInfo:", patientInfo);
  }, [allPatients]);

  const [medicationOptions, setMedicationOptions] = useState([]);
  const [supportOptions, setSupportOptions] = useState([]);
  const [outComeTitle, setOutComeTitle] = useState([]);
  // console.log("🚀 ~ outComeTitle:", outComeTitle)
  const [outcomeNumbers, setOutComeNumbers] = useState([]);
  // console.log("🚀 ~ outcomeNumbers:", outcomeNumbers)
  const [displayAccordionTitle, setDisplayAccordionTitle] = useState(false);
  const [noteType, setNoteType] = useState(
    noteT
      ? allNotesTypes?.ntypes?.find((value) => value?.id === noteT?.id)
      : allNotesTypes?.ntypes?.find((value) => value?.id === NOTETYPE)
  );
  const [selectedPatient, setSelectedPatient] = useState(null);
  // console.log("🚀 ~ selectedPatient:", selectedPatient);
  const id = selectedPatient?.id;
  const clientId = id?.slice(-6);
  const [patientsId, setPatientsId] = useState(null);
  const isFacilityDisabled = noteType?.custom?.find((value) => {
    return (
      value?.fieldType?.name?.toLowerCase() ===
      noteType_Client_Options?.toLowerCase()
    );
  });

  // console.log("selectedPatient in note modal", selectedPatient);

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const [supportPlan, setSupportPlan] = useState("no");
  // console.log("🚀 ~ supportPlan:", supportPlan);
  const icon = <DropDownArrowSvg style={{ width: rem(1), height: rem(1) }} />;

  const initialDescription = {
    outcomeTitle: "",
    outcomeNumber: "",
    outComeCategory: "",
    description: "",
    details: null,
    error: "",
  };
  const [selectedFacility, setSelectedFacility] = useState();
  // console.log("🚀 ~ selectedFacility:", selectedFacility)

  const [descriptions, setDescriptions] = useState([
    { id: 1, ...initialDescription },
  ]);
  // const facilityNames = allPlans?.map((plan) => plan?.facility?.name)

  // useEffect(() => {
  //   if (facilityNames?.includes(selectedFacility)) {
  //     setDescriptions(allPlans);
  //   }
  // }, [selectedFacility, facilityNames, allPlans]);

  React.useEffect(() => {
    // console.log("descriptions ======>>>>>>", descriptions);
  }, [descriptions]);

  React.useEffect(() => {
    setDescriptions([{ id: 1, ...initialDescription }]);
  }, [selectedFacility]);

  useEffect(() => {
    // setDescriptions(metGoal || []);
    let outcomeTitle = [];
    let outcomeNumberArray = [];
    const filtered = selectedPatient
      ? allPlans
          ?.filter(
            (v) =>
              v?.client?._id === selectedPatient?.id &&
              v?.facility?._id === selectedFacility
          )
          ?.map((v) => {
            v?.outcomes?.map((vv) => {
              outcomeTitle.push({
                value: vv?.id,
                label: vv?.outcomeTitle,
                disabled: vv.hasOwnProperty("isActive") ? !vv?.isActive : false,
              });
              outcomeNumberArray.push({
                value: vv?.id,
                label: vv?.outcomeNumber,
              });
            });
          })
      : [];
    setOutComeTitle(outcomeTitle || []);
    setOutComeNumbers(outcomeNumberArray || []);
  }, [selectedPatient, allPlans, selectedFacility]);

  // Add New Outcome Handler
  const addDescription = () => {
    if (descriptions?.length < outComeTitle?.length) {
      setDescriptions([
        ...descriptions,
        { id: Date.now(), ...initialDescription },
      ]);
      // setDisplayAccordionTitle(false);
      setOpenedAccordion(`${descriptions?.length}`);
    }
  };

  // Remove Outcome Handler
  const removeMedication = (medicationId) => {
    setDescriptions(descriptions.filter((med) => med.id !== medicationId));
  };

  // Edit Outcome Inputs Handler
  const handleMedicationChange = (medicationId, field, value) => {
    let filteredObj = null;

    if (field === "outcomeTitle") {
      filteredObj = outComeTitle?.filter((item) => item?.value === value)?.[0];
    } else if (field === "outcomeNumber") {
      filteredObj = outcomeNumbers?.filter(
        (item) => item?.value === value
      )?.[0];
    }
    // console.log("🚀 ~ handleMedicationChange ~ filteredObj:", filteredObj);

    setDisplayAccordionTitle(field);
    let previous = descriptions?.find(
      (v) => v?.outcomeTitle === value || v?.outcomeNumber === value
    );
    // console.log("🚀 ~ handleMedicationChange ~ previous:", previous);
    let rest = {};
    if (field === "outcomeTitle" || field === "outcomeNumber") {
      let { id, ...others } = allPlans
        // ?.find((v) => v?.client?._id === selectedPatient?.id)
        ?.find(
          (v) =>
            v?.client?._id === selectedPatient?.id &&
            v?.facility?._id === selectedFacility
        )
        ?.outcomes?.find((v) => v?.id === value);
      rest = others;
    }

    setDescriptions((prevMedications) =>
      prevMedications.map((med, index) => {
        if (field !== "metGoal" && field !== "description") {
          // console.log("🚀 ~ ifffffffffffffff");
          return med.id === medicationId
            ? {
                ...med,
                ...(outcomeNotes[index]?.length > 0 && {
                  description: outcomeNotes[index],
                }),
                // description: outcomeNotes[index],
                [field]: value,
                [field == "outcomeTitle" ? "outcomeNumber" : "outcomeTitle"]:
                  filteredObj?.value,
                outComeCategory:
                  field === "outcomeTitle" || field === "outcomeNumber"
                    ? allPlans
                        ?.find(
                          (v) =>
                            v?.client?._id === selectedPatient?.id &&
                            v?.facility?._id === selectedFacility
                        )
                        ?.outcomes?.find((v) => v?.id === value)
                        ?.outcomeCategory
                    : med?.outComeCategory,
                details:
                  field === "outcomeTitle" || field === "outcomeNumber"
                    ? filterNonNullFields(rest)
                    : med?.details,
                error: previous ? "Already Selected" : null,
              }
            : med;
        } else {
          // console.log("🚀 ~ elseeeeeeeeeeeeeeee");
          return med.id === medicationId
            ? {
                ...med,
                [field]: value,
                outComeCategory:
                  field === "outcomeTitle" || field === "outcomeNumber"
                    ? allPlans
                        ?.find(
                          (v) =>
                            v?.client?._id === selectedPatient?.id &&
                            v?.facility?._id === selectedFacility
                        )
                        ?.outcomes?.find((v) => v?.id === value)
                        ?.outcomeCategory
                    : med?.outComeCategory,
                details:
                  field === "outcomeTitle" || field === "outcomeNumber"
                    ? filterNonNullFields(rest)
                    : med?.details,
                error: previous ? "Already Selected" : null,
              }
            : med;
        }
      })
    );
    setErrors("");
  };

  // Reset Form
  const resetForm = () => {
    setErrors("");
    form.reset();
    setOutcomeNotes([]);
    close();
    // setNoteType(null);
    setPatientsId(null);
    setSelectedPatient(null);
  };

  // Save Note
  const onSave = (values) => {
    let obj = {
      noteId: NOTETYPE,
      fields: { ...values, noteId: NOTETYPE },
      supportPlan,
      userId,
    };
    const isProgressNote = noteType?.name === "Progress Note";

    if (isProgressNote) obj["descriptions"] = descriptions;

    if (isProgressNote && supportPlan === "yes")
      obj.fields["Description"] = descriptions
        ?.map((rest) => rest?.details?.outcomeTitle)
        ?.join(", ");

    if (edit) {
      let fields = { ...values };
      delete fields["noteId"];
      delete fields["noteType"];
      const obj = {
        ...values,
        id: data?.data?.id,
        fields,
        supportPlan,
        userId,
      };
      if (isProgressNote) obj["descriptions"] = descriptions;

      if (isProgressNote && supportPlan === "yes")
        obj.fields["Description"] = descriptions
          ?.map((rest) => rest?.details?.outcomeTitle)
          ?.join(", ");
      const validations = validateFields();
      if (validations !== true && supportPlan === "yes") {
        validateFields();
      } else {
        editNote(obj, false, resetForm, sortField, sortOrder);
        setErrors("");
      }
    } else {
      // console.log("before check validation");
      const validations = validateFields();
      // console.log("after check validation");
      if (validations !== true && supportPlan === "yes") {
        validateFields();
      } else {
        createNote(obj, resetForm);
        setErrors("");
      }
    }
  };

  function objectToString(obj) {
    let result = Object.entries(obj)
      .map(
        ([key, value]) =>
          `${key.charAt(0).toUpperCase() + key.slice(1)}: ${`"${
            value || "-"
          }"`}`
      )
      .join("\n");

    return result;
  }

  const arrayToString = (array) => {
    if (array?.length === 0 || array === undefined) return "";
    return array
      ?.filter((v) => v)
      ?.map((v, i) => `${i + 1}) ${v}`)
      .join("\n \n");
  };

  function filterNonNullFields(obj) {
    const result = {};
    for (const [key, value] of Object.entries(obj)) {
      if (value !== null && value !== undefined && value !== "") {
        result[key] = value;
      }
    }
    return result;
  }

  // useEffect(() => {
  //   console.log("selectedPatient", selectedPatient);
  // }, [selectedPatient]);

  // Handle Client Change
  const handlePatientChange = (patientId, name) => {
    setPatientsId(patientId);
    // setSelectedPatient(allPatients?.find((value) => value?.id === patientId));

    // auto populating facility related to this user...

    !edit && !view && setDescriptions([{ id: 1, ...initialDescription }]);

    form.setFieldValue(name, patientId);
    let facilityExists = noteType?.custom?.find(
      (value) => value?.fieldType?.name?.toLowerCase() === "facilities"
    );
    let patient = allPatients?.find((value) => value?.id === patientId);
    // console.log("🚀 ~ handlePatientChange ~ patient:", patient);

    let pcpInfo = {
      "PCP Name": patient?.form2?.["pcpName"],
      "PCP Email": patient?.form2?.["pcpEmail"],
      "PCP Phone Number": patient?.form2?.["pcpPhoneNumber"],
      "PCP Fax Number": patient?.form2?.["pcpFaxNumber"],
    };

    let insuranceDetails = {
      "Insurance Company Name": patient?.form2?.["insuranceCompanyName"],
      "Member Name": patient?.form2?.["memberName"],
      "Member Number": patient?.form2?.["memberNumber"],
      "Prescription Group": patient?.form2?.["prescriptionGroup"],
      "Group Number": patient?.form2?.["groupNumber"],
      "Effective Date": formatDate(patient?.form2?.["effectiveDate"]),
    };
    // console.log("facilityExists?.field", facilityExists?.field, patient?.facilityId)
    // form.setFieldValue(facilityExists?.field, patient?.facilityId?.id);
    // console.log("data?.data?.field", data?.data?.fields["Facility Name"])
    (edit || view) &&
      form.setFieldValue(
        facilityExists?.field,
        data?.data?.fields?.["Facility Name"]
      );

    form.setFieldValue("All PCP Info", objectToString(pcpInfo));
    form.setFieldValue(
      "Known Allergies",
      arrayToString(patient?.form1?.knownAllergies)
    );
    form.setFieldValue(
      "Dietary Restrictions",
      arrayToString(patient?.form1?.dietaries)
    );

    form.setFieldValue(
      "Known Illnes",
      arrayToString(
        patient?.form1?.knownIllness
          ? patient?.form1?.knownIllness?.map(
              (v) =>
                `${v?.illness || ""} - Contagious: "${v?.contagious || ""}"`
            )
          : []
      ) || "-"
    );
    form.setFieldValue("Insurance Details", objectToString(insuranceDetails));
    form.setFieldValue("Known Wounds", patient?.form1?.woundsInformation);

    form.setFieldValue("Medication Records", "");
    form.setFieldValue("Support Plan", "");
    setMedicationOptions(
      Array.isArray(patient?.form3)
        ? patient?.form3?.map((value) => {
            let { isPrescribed, id, ...rest } = value;
            return {
              label: value?.medicationName,
              value: objectToString({
                ...filterNonNullFields(rest),
                Prescribed: isPrescribed ? "Yes" : "No",
              }),
            };
          })
        : []
    );

    setSupportOptions(
      allPlans
        ?.find((v) => v?.client?.id === patient?.id)
        ?.outcomes?.map(({ id, ...rest }) => ({
          value: objectToString({
            ...filterNonNullFields(rest),
          }),
          label: rest.outcomeTitle,
        })) || []
    );
    if (patient) setSelectedPatient(patient);
  };

  // Handle Facility Change
  const handleFacilityChange = (value, name) => {
    form.setFieldValue(name, value);
    setSelectedFacility(value);
  };

  useEffect(() => {
    if (!opened) {
      // console.log("🚀 ~ useEffect ~ opened:", opened)
      setMedicationOptions([]);
      setSupportOptions([]);
      setDescriptions([{ id: 1, ...initialDescription }]);
      setSupportPlan("no");
      setDisplayAccordionTitle("");
      getAllFacilitiesModal("all");

      form.reset();
    }
    if (noteT && edit) {
      getAllFacilitiesModal("all");
      form?.setFieldValue(
        "noteType",
        allNotesTypes?.ntypes?.find((value) => value?.id === noteT?.id)?.name
      );

      let _noteType = {
        ...allNotesTypes?.ntypes?.find((value) => value?.id === noteT?.id),
      };
      let customs = _noteType ? [..._noteType?.custom] : [];
      let indexOfPatient = customs.findIndex(
        (v) => v?.field === noteType_Client
      );
      let patientClone = customs[indexOfPatient];
      if (indexOfPatient !== -1) {
        customs.splice(indexOfPatient, 1);
        customs.unshift(patientClone);
        _noteType.custom = customs;
      }
      setNoteType(_noteType);
      setSelectedFacility(data?.data?.fields?.["Facility Name"]);
    }
    if (opened) {
      getAllPatientsModal();
      getAllFacilitiesModal("active");

      // getAllPlans();
    }
  }, [noteT, opened]);

  useEffect(() => {
    if (edit) {
      if (data?.data?.fields)
        Object.entries(data?.data?.fields)?.map((value) => {
          if (
            `${value[1]}`.split(":")?.length === 3 &&
            `${value[1]}`.split("-")?.length === 3
          ) {
            let date = new Date(value[1]);
            form?.setFieldValue(value[0], date);
          } else {
            // console.log("data values before set form", value[0], value[1]);
            form?.setFieldValue(value[0], value[1]);
          }
        });
    }

    // console.log("form values after set", form?.values);

    if (NOTETYPE && opened) {
      form?.setFieldValue(
        "noteType",
        allNotesTypes?.ntypes?.find((value) => value?.id === NOTETYPE)?.name
      );

      let _noteType = {
        ...allNotesTypes?.ntypes?.find((value) => value?.id === NOTETYPE),
      };
      let customs = _noteType ? [..._noteType?.custom] : [];
      let indexOfPatient = customs.findIndex(
        (v) => v?.field === noteType_Client
      );
      let patientClone = customs[indexOfPatient];
      if (indexOfPatient !== -1) {
        customs.splice(indexOfPatient, 1);
        customs.unshift(patientClone);
        _noteType.custom = customs;
      }
      setNoteType(_noteType);
    }
  }, [NOTETYPE, edit, opened]);

  useEffect(() => {
    if (opened) {
      // console.log("🚀 ~ useEffect ~ opened:", opened);
      if (profile?.user?.type === "staff" && !view && !edit) {
        form.setFieldValue("Staff Name", profile?.user?.id);
      } else if (profile?.user?.type !== "staff" && !view && !edit) {
        form.setFieldValue("Staff Name", profile?.user?.id);
      } else {
        form.setFieldValue("Staff Name", data?.data?.fields["Staff Name"]);
      }
      let patientType = noteType?.custom?.find(
        (v) => v?.fieldType?.name === noteType_Client_Options
      );
      let patient = allPatients?.find((value) =>
        data?.data?.fields?.["Patient Name"] &&
        !data?.data?.fields?.["Client Name"]
          ? value?.id === data?.data?.fields["Patient Name"]
          : data?.data?.fields?.["Patient Name"] &&
            data?.data?.fields?.["Client Name"]
          ? value?.id === data?.data?.fields["Client Name"]
          : value?.id === data?.data?.fields[patientType?.field]
      );
      // console.log("🚀 ~ useEffect ~ patient:", patient);
      if (patient) {
        // console.log("🚀 ~ useEffect ~ patient:", patient);
        form.setFieldValue(noteType_Client, patient.id);
        setMedicationOptions(
          Array.isArray(patient?.form3) &&
            patient?.form3?.map((value) => {
              let { isPrescribed, id, ...rest } = value;
              return {
                label: value?.medicationName,
                value: objectToString({
                  ...filterNonNullFields(rest),
                  Prescribed: isPrescribed ? "Yes" : "No",
                }),
              };
            })
        );

        setSupportOptions(
          allPlans
            ?.find((v) => v?.client?.id === patient?.id)
            ?.outcomes?.map(({ id, ...rest }) => ({
              value: objectToString({
                ...filterNonNullFields(rest),
              }),
              label: rest.outcomeTitle,
            })) || []
        );
        setSelectedPatient(patient);
        setDescriptions(data?.data?.descriptions);
        setOutcomeNotes(data?.data?.descriptions?.map((_d) => _d?.description));
        setSupportPlan(data?.data?.supportPlan || "no");
      }
    }
    if (!view)
      noteType?.custom?.forEach((field) => {
        if (field?.fieldType?.name === "date" && !edit) {
          form?.setFieldValue(field.field, new Date());
        }
        if (field?.fieldType?.name === "time" && !edit) {
          const currentTime = new Date().toLocaleTimeString("en-US", {
            hour12: false,
          });
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );
          if (isSafari) {
            let hours = new Date().getHours(),
              minutes = new Date().getMinutes();
            form?.setFieldValue(field.field, `${hours}:${minutes}`);
          } else {
            form?.setFieldValue(field.field, currentTime);
          }
        }
      });
  }, [noteType?.custom, opened]);

  useEffect(() => {
    if (userId) {
      // console.log("handle patient change useEffect", userId);
      handlePatientChange(userId, "Client Name");

      // setPatientsId(userId)
      // let patient = allPatients?.find((value) => value?.id === userId);
      // form.setFieldValue(noteType_Client, userId);
      // form.setFieldValue("Patient Name", userId);

      // let facilityExists = noteType?.custom?.find(
      //   (value) => value?.fieldType?.name?.toLowerCase() === "facilities"
      // );

      // form.setFieldValue(facilityExists?.field, patient?.facilityId?.id);
    }
  }, [userId, allPatients]);

  const initialValues = {};

  allNotesTypes?.ntypes?.custom?.map((value) => {
    initialValues[value?.field] = "";
  });

  const form = useForm({
    initialValues: (() => {
      const initialValues = { name: edit ? data?.data?.name : "" };
      // const initialValues = { name: edit ? data?.data?.name : profile?.user?.type === "staff" ? "" : profile?.user?.id };

      noteType?.custom?.forEach((field) => {
        initialValues[field.field] = "";
      });

      return initialValues;
    })(),
    validate: (values) => {
      // console.log("validate values", values);
      const errors = {};
      noteType?.custom?.forEach((field) => {
        const fieldName = field.field;
        // console.log("fieldName", fieldName, field);

        if (field.fieldType?.name === "number" && isNaN(values[fieldName])) {
          errors[fieldName] = "Must be a number";
        } else if (field.fieldType?.name === "number") {
          errors[fieldName] = null;
        }

        if (!values[fieldName]) {
          if (
            fieldName === "Description" &&
            noteType?.name === "Progress Note"
          ) {
            // console.log("Do nothing");
          } else if (fieldName == "Shift") {
            // console.log("field shift");
          } else {
            errors[fieldName] = "Required";
          }
        }
      });
      return errors;
    },
  });

  const [openedAccordion, setOpenedAccordion] = useState("0");

  const validateFields = () => {
    const newErrors = {};
    descriptions?.forEach((medication, index) => {
      if (!medication?.outcomeNumber) {
        newErrors[index] = "Field is required";
      }
      if (!medication?.outcomeTitle) {
        newErrors[index] = "Field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // useEffect(() => {
  //   console.log("form values of formItem", form?.values);
  // }, [form]);

  const ReturnFormItem = (data, index, textArea = "") => {
    let { field: name, fieldType, placeholder, options, viewOnly } = data;

    if (
      textArea &&
      supportPlan === "no" &&
      data?.field === "Description" &&
      noteType?.name === "Progress Note"
    ) {
      return (
        <Box
          style={{ background: "#fff", padding: "1rem", borderRadius: "10px" }}
        >
          {/* <Radio.Group
            name="useSupportPlan"
            label="Use Support Plan"
            value={supportPlan}
            onChange={setSupportPlan}
            style={{ marginBottom: "20px" }}
          >
            <Group mt="xs">
              <Radio color="yellow" disabled={view} value="yes" label="Yes" />
              <Radio color="yellow" disabled={view} value="no" label="No" />
            </Group>
          </Radio.Group> */}
          <Box className={classes.textInputBox}>
            <Text className="label">Add Note</Text>
            <Textarea
              key={index}
              radius="md"
              maxRows={10}
              minRows={10}
              placeholder={placeholder || "Enter text"}
              autosize={view || (viewOnly ? true : false) ? true : false}
              className={`${classes.myfont} ${classes.descriptionText}`}
              {...form.getInputProps(name)}
              readOnly={view || (viewOnly ? true : false)}
            />
          </Box>
        </Box>
      );
    }

    if (
      supportPlan === "yes" &&
      noteType?.name === "Progress Note" &&
      data?.field === "Description" &&
      textArea
    ) {
      return (
        <Box style={{ overflowY: "auto", maxHeight: "100%" }}>
          {/* <Box style={{ overflowY: "auto", height: "200px" }}> */}
          {/* <Radio.Group
            name="useSupportPlan"
            label="Use Support Plan"
            value={supportPlan}
            onChange={setSupportPlan}
            style={{ marginBottom: "10px" }}
          >
            <Group mt="xs">
              <Radio color="yellow" disabled={view} value="yes" label="Yes" />
              <Radio color="yellow" disabled={view} value="no" label="No" />
            </Group>
          </Radio.Group> */}
          {supportPlan === "yes" && (
            <>
              <Accordion
                value={openedAccordion}
                onChange={setOpenedAccordion}
                chevron={<IconChevronDown size={34} color="#FBAA0D" />}
                chevronPosition="right"
                chevronSize={34}
                styles={{
                  chevron: {
                    transition: "transform 200ms ease",
                    "&[data-active]": {
                      transform: "rotate(180deg)",
                    },
                  },
                }}
              >
                {descriptions?.map((medication, index) => (
                  <Accordion.Item
                    className={classes.accordionItem}
                    value={`${index}`}
                    style={{
                      marginBottom:
                        index === descriptions?.length - 1 ? "0" : "1rem",
                    }}
                  >
                    <Accordion.Control>
                      {(medication?.outcomeNumber === "" ||
                        medication?.outcomeTitle === "") && (
                        <Box className={classes.accordionControl}>
                          <Text variant="text" fw={600}>
                            Outcome
                          </Text>
                          <Text
                            variant="text"
                            color="red"
                            onClick={() => removeMedication(medication.id)}
                          >
                            Delete
                          </Text>
                        </Box>
                      )}
                      {(typeof medication?.outcomeNumber === "number" ||
                        typeof medication?.outcomeTitle === "number") && (
                        <SimpleGrid
                          className={classes.staffContainer}
                          style={{
                            justifyContent:
                              openedAccordion === `${index}` ? "end" : "center",
                          }}
                        >
                          {openedAccordion !== `${index}` && (
                            <>
                              <Box
                                className={classes.staffContainer}
                                style={{ width: "250px" }}
                              >
                                <Badge color="yellow" size="xl" circle>
                                  {medication?.details?.outcomeNumber}
                                </Badge>
                                <Text variant="text" fw={600}>
                                  Outcome
                                </Text>
                              </Box>
                              <Box
                                className={classes.staffContainer}
                                style={{ width: "250px" }}
                              >
                                <Text variant="text" fw={600}>
                                  Title:
                                </Text>
                                <Text
                                  variant="text"
                                  className={classes.accordionTitle}
                                  sx={{ minWidth: "150px" }}
                                >
                                  {medication?.details?.outcomeTitle}
                                </Text>
                              </Box>
                              <Box
                                className={classes.staffContainer}
                                style={{
                                  width: "500px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Box className={classes.staffContainer}>
                                  <Text variant="text" fw={600}>
                                    Category:
                                  </Text>
                                  <Text
                                    variant="text"
                                    className={classes.accordionTitle}
                                    sx={{ minWidth: "200px" }}
                                  >
                                    {medication?.details?.outcomeCategory}
                                  </Text>
                                </Box>
                              </Box>
                            </>
                          )}

                          <Box className={classes.staffContainer}>
                            {!view && (
                              <Text
                                variant="text"
                                color="red"
                                onClick={() => removeMedication(medication.id)}
                              >
                                Delete
                              </Text>
                            )}
                          </Box>
                        </SimpleGrid>
                      )}
                    </Accordion.Control>
                    <Accordion.Panel>
                      <Group align="center">
                        <Text variant="text" fw={500} mb={15}>
                          Select an Outcome
                        </Text>
                      </Group>
                      <div key={medication.id}>
                        {/* {view == true ||
                        (edit && !noteData?.data?.planActive === false) ? ( */}
                        {view == true ? (
                          <Grid
                            gutter={10}
                            cols={isMobileScreen ? 1 : 3}
                            className={classes.mwd}
                            sx={{
                              paddingBottom: 30,
                            }}
                          >
                            <Grid.Col span={isMobileScreen ? 12 : 3}>
                              <Box className={classes.outcomeContainer}>
                                <Text className={classes.outcomeText}>
                                  Outcome Number<Text c={"red"}>*</Text>
                                </Text>
                                <Text c={"#909296"} ml={10} ff={"inter"}>
                                  {" "}
                                  {medication?.details?.outcomeNumber}
                                </Text>
                              </Box>
                            </Grid.Col>
                            <Grid.Col span={isMobileScreen ? 12 : 5}>
                              <Box className={classes.outcomeContainer}>
                                <Text className={classes.outcomeText}>
                                  Outcome Title<Text c={"red"}>*</Text>
                                </Text>
                                <Text c={"#909296"} ml={10} ff={"inter"}>
                                  {" "}
                                  {medication?.details?.outcomeTitle}
                                </Text>
                              </Box>
                            </Grid.Col>
                            <Grid.Col span={isMobileScreen ? 12 : 4}>
                              <TextInput
                                className={classes.myfont}
                                label={
                                  <div className={classes.infoLabel}>
                                    Outcome Category
                                    {/* <Popover
                                  width={300}
                                  position="bottom-end"
                                  shadow="md"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Popover.Target>
                                    <IconInfoCircle
                                      color="gray"
                                      size={"15px"}
                                    />
                                  </Popover.Target>
                                  <Popover.Dropdown
                                    sx={{ pointerEvents: "none" }}
                                  >
                                    <Textarea
                                      key={`${index}-test`}
                                      radius="md"
                                      autosize={true}
                                      icon={
                                        <img
                                          className={classes.descriptionIcon}
                                          src="/assets/images/home2.png"
                                          alt="home"
                                        />
                                      }
                                      // placeholder={
                                      //   placeholder || `Select ${name} to see details here.`
                                      // }
                                      className={`${classes.myfont} ${classes.descriptionText}`}
                                      style={{
                                        height: "200px !important",
                                        marginTop: "10px",
                                      }}
                                      value={objectToString(
                                        medication?.details || {}
                                      )}
                                      disabled={true}
                                    />
                                  </Popover.Dropdown>
                                </Popover> */}
                                  </div>
                                }
                                size="sm"
                                placeholder="Enter outcome category"
                                value={medication?.outComeCategory}
                                disabled
                              />
                            </Grid.Col>
                          </Grid>
                        ) : (
                          <Grid
                            gutter={10}
                            cols={isMobileScreen ? 1 : 3}
                            className={classes.mwd}
                            sx={{
                              paddingBottom: 30,
                            }}
                          >
                            <Grid.Col xs={12} sm={12} md={4}>
                              <Select
                                id="outcome-number"
                                size="sm"
                                required
                                data={outcomeNumbers}
                                label="Outcome Number"
                                className={classes.selectBG}
                                placeholder="Select Outcome Number"
                                value={medication?.outcomeNumber}
                                onChange={(value) => {
                                  handleMedicationChange(
                                    medication.id,
                                    "outcomeNumber",
                                    value
                                  );
                                }}
                                disabled={view}
                                variant="filled"
                                rightSectionPointerEvents="none"
                                rightSection={icon}
                              />
                              {errors[index] && (
                                <Text color="red" fz={14}>
                                  {errors[index]}
                                </Text>
                              )}
                            </Grid.Col>
                            <Grid.Col xs={12} sm={12} md={4}>
                              <Select
                                required
                                size="sm"
                                data={outComeTitle}
                                label="Outcome Title"
                                className={classes.selectBG}
                                placeholder="Select Outcome Title"
                                value={medication?.outcomeTitle}
                                onChange={(value) => {
                                  handleMedicationChange(
                                    medication.id,
                                    "outcomeTitle",
                                    value
                                  );
                                }}
                                disabled={view}
                                variant="filled"
                                rightSectionPointerEvents="none"
                                rightSection={icon}
                              />
                              {errors[index] && (
                                <Text color="red" fz={14}>
                                  {errors[index]}
                                </Text>
                              )}
                            </Grid.Col>
                            <Grid.Col xs={12} sm={12} md={4}>
                              <TextInput
                                className={classes.myfont}
                                label={
                                  <div className={classes.infoLabel}>
                                    Outcome Category
                                    {/* <Popover
                                  width={300}
                                  position="bottom-end"
                                  shadow="md"
                                  style={{ marginBottom: "10px" }}
                                >
                                  <Popover.Target>
                                    <IconInfoCircle
                                      color="gray"
                                      size={"15px"}
                                    />
                                  </Popover.Target>
                                  <Popover.Dropdown
                                    sx={{ pointerEvents: "none" }}
                                  >
                                    <Textarea
                                      key={`${index}-test`}
                                      radius="md"
                                      autosize={true}
                                      icon={
                                        <img
                                          className={classes.descriptionIcon}
                                          src="/assets/images/home2.png"
                                          alt="home"
                                        />
                                      }
                                      // placeholder={
                                      //   placeholder || `Select ${name} to see details here.`
                                      // }
                                      className={`${classes.myfont} ${classes.descriptionText}`}
                                      style={{
                                        height: "200px !important",
                                        marginTop: "10px",
                                      }}
                                      value={objectToString(
                                        medication?.details || {}
                                      )}
                                      disabled={true}
                                    />
                                  </Popover.Dropdown>
                                </Popover> */}
                                  </div>
                                }
                                size="sm"
                                placeholder="Enter outcome category"
                                value={medication?.outComeCategory}
                                disabled
                              />
                            </Grid.Col>
                          </Grid>
                        )}
                        <ViewInfo data={medication} />
                        <Box className={classes.textInputBox}>
                          <Text className="label">Add Note</Text>
                          <Textarea
                            id="add-note"
                            className={`${classes.myfont} ${classes.descriptionText}`}
                            size="sm"
                            maxRows={5}
                            minRows={10}
                            placeholder="Enter Note"
                            value={
                              medication?.description?.length > 0
                                ? medication?.description
                                : outcomeNotes[index]
                            }
                            onChange={(e) => {
                              handleMedicationChange(
                                medication.id,
                                "description",
                                e?.target?.value
                              );

                              let tempNotesArr = outcomeNotes;
                              tempNotesArr.splice(index, 1, e?.target?.value);
                              setOutcomeNotes([...tempNotesArr]);
                            }}
                            readOnly={view}
                            autosize={view}
                          />
                        </Box>
                        {!view && (
                          <p style={{ color: "red", margin: 0, padding: 0 }}>
                            {medication?.error}
                          </p>
                        )}
                      </div>
                      <Select
                        id="about-goal"
                        size="sm"
                        data={[
                          "Yes",
                          "No",
                          "Goal in progress",
                          "Not Applicable",
                        ]}
                        label="Was this goal met?"
                        placeholder="Select a response"
                        value={medication?.metGoal}
                        onChange={(value) => {
                          handleMedicationChange(
                            medication.id,
                            "metGoal",
                            value
                          );
                        }}
                        disabled={view}
                        variant="filled"
                        rightSectionPointerEvents="none"
                        rightSection={icon}
                        className={classes.select}
                      />
                    </Accordion.Panel>
                  </Accordion.Item>
                ))}
              </Accordion>
              {/* {!view && (
                <Button
                  size="sm"
                  m={10}
                  color="yellow"
                  onClick={addDescription}
                  style={{ marginLeft: "auto", display: "block" }}
                >
                  Add New Outcome
                </Button>
              )} */}
            </>
          )}
        </Box>
      );
    }
    let obj = {
      string: (
        <TextInput
          size="xs"
          id="reason"
          key={index}
          type="text"
          label={name}
          variant="filled"
          className={classes.myfont}
          {...form.getInputProps(name)}
          disabled={view || (viewOnly ? true : false)}
          placeholder={placeholder || `Enter response`}
        />
      ),
      "text area": (
        <Box
          className={classes.textInputBox}
          style={{ height: "100px !important" }}
        >
          <Text htmlFor={name} className="label">
            {name}
          </Text>

          <Textarea
            id={
              data?.field === "Description"
                ? "enter-description"
                : data?.field === "Medication"
                ? "enter-medication"
                : "enter-note"
            }
            key={index}
            radius="xs"
            minRows={view || (viewOnly ? true : false) ? 3 : 6}
            maxRows={view || (viewOnly ? true : false) ? 3 : 6}
            autosize={view || (viewOnly ? true : false) ? true : false}
            placeholder={placeholder || `Enter response`}
            className={`${classes.myfont} ${classes.descriptionText} ${
              view || (viewOnly ? true : false) ? classes.disabledTextarea : ""
            }`}
            style={{ height: "500px !important" }}
            {...form.getInputProps(name)}
            readOnly={view || viewOnly ? true : false}
          />
        </Box>
      ),

      number: (
        <TextInput
          size="xs"
          key={index}
          label={name}
          type="number"
          className={classes.myfont}
          {...form.getInputProps(name)}
          disabled={view || (viewOnly ? true : false)}
          placeholder={placeholder || `Enter response`}
          // icon={<img src="/assets/images/home2.png" alt="home" />}
        />
      ),

      time: (
        <TimeInput
          label={name}
          variant="unstyled"
          key={index}
          ref={ref}
          size="xs"
          placeholder={`Select ${name}`}
          className={classes.myfont}
          rightSection={
            <ActionIcon onClick={() => ref?.current?.showPicker()}>
              <IconClock size="1rem" stroke={1.5} />
            </ActionIcon>
          }
          {...form.getInputProps(name)}
          onChange={(e) => {
            const isSafari = /^((?!chrome|android).)*safari/i.test(
              navigator.userAgent
            );
            const datetime = new Date(e?.target?.value).toLocaleTimeString(
              "en-US",
              { hour12: false }
            );
            let time = e.target.value?.split(":")?.slice(0, 2)?.join(":");
            isSafari
              ? form.setFieldValue(name, time)
              : form.setFieldValue(name, e?.target?.value);
          }}
          disabled={view || (viewOnly ? true : false)}
          style={{ borderBottom: "2px solid #aaaaaa" }}
        />
      ),
      date: (
        <DateInput
          size="xs"
          key={index}
          label={name}
          id="date-click"
          variant="unstyled"
          className={classes.myfont}
          {...form.getInputProps(name)}
          placeholder={`Select ${name}`}
          disabled={view || (viewOnly ? true : false)}
          style={{ borderBottom: "2px solid #aaaaaa" }}
          rightSection={
            <img
              style={{ width: "30px" }}
              src={"/assets/images/calendar-icon.png"}
              alt="Icon"
            />
          }
        />
      ),
      Clients: (
        <Select
          size="xs"
          searchable
          label={name}
          variant="filled"
          rightSection={icon}
          defaultValue={userId}
          id={"client-name-note"}
          className={classes.selectBG}
          {...form.getInputProps(name)}
          placeholder={`Select ${name}`}
          rightSectionPointerEvents="none"
          required={viewOnly || userId ? false : true}
          onChange={(v) => handlePatientChange(v, name)}
          data={getOptions(noteType_Client_Options, noteType?.custom)}
          disabled={view || edit || (viewOnly || userId ? true : false)}
        />
      ),
      Facilities: (
        <Select
          size="xs"
          searchable
          label={name}
          variant="filled"
          rightSection={icon}
          className={classes.selectBG}
          {...form.getInputProps(name)}
          placeholder={`Select ${name}`}
          onChange={(e) => handleFacilityChange(e, name)}
          rightSectionPointerEvents="none"
          disabled={view || (viewOnly ? true : false)}
          required={viewOnly || userId ? false : true}
          data={getOptions(
            "Facilities",
            noteType?.custom,
            undefined,
            view ? true : false
          )?.map((opt) => {
            if (Array.isArray(selectedPatient?.facilityId)) {
              if (
                selectedPatient?.facilityId?.find(
                  (fac) => fac?.id == opt?.value
                )
              ) {
                return opt;
              } else {
                return { ...opt, disabled: true };
              }
            } else {
              if (selectedPatient?.facilityId?.id == opt?.value) {
                return opt;
              } else {
                return { ...opt, disabled: true };
              }
            }
          })}
        />
      ),
      Staff: (
        <Box className={classes.staffContainer}>
          <Text fw={500}>{name}:</Text>
          {view ? (
            <Select
              size="xs"
              variant="filled"
              rightSectionPointerEvents="none"
              data={getOptions("Staff All", noteType?.custom)}
              placeholder={`Select ${name}`}
              searchable
              defaultValue={
                form?.getInputProps(name)
                // !edit && profile?.user?.type !== "staff"
                //   ? profile?.user?.id
                //   : form?.getInputProps(name)
              }
              className={classes.myfont}
              {...form.getInputProps(name)}
              disabled={true}
            />
          ) : edit ? (
            <Box className={classes.staffOnEdit}>
              <Select
                size="xs"
                variant="filled"
                rightSectionPointerEvents="none"
                data={getOptions("Staff All", noteType?.custom)}
                placeholder={`Select ${name}`}
                searchable
                defaultValue={
                  form?.getInputProps(name)
                  // !edit && profile?.user?.type !== "staff"
                  //   ? profile?.user?.id
                  //   : form?.getInputProps(name)
                }
                className={classes.myfont}
                {...form.getInputProps(name)}
                disabled={true}
              />

              <Select
                size="xs"
                variant="filled"
                rightSectionPointerEvents="none"
                data={getOptions("Staff", noteType?.custom)}
                placeholder={`Select New ${name}`}
                searchable
                defaultValue={
                  form?.getInputProps(name)
                  // !edit && profile?.user?.type !== "staff"
                  //   ? profile?.user?.id
                  //   : form?.getInputProps(name)
                }
                className={classes.myfont}
                {...form.getInputProps(name)}
                disabled={
                  view ||
                  (viewOnly
                    ? true
                    : !edit && profile?.user?.type === "staff"
                    ? true
                    : false)
                }
              />
            </Box>
          ) : (
            <Select
              size="xs"
              variant="filled"
              rightSectionPointerEvents="none"
              data={getOptions("Staff", noteType?.custom)}
              placeholder={`Select ${name}`}
              searchable
              defaultValue={
                form?.getInputProps(name)
                // !edit && profile?.user?.type !== "staff"
                //   ? profile?.user?.id
                //   : form?.getInputProps(name)
              }
              className={classes.myfont}
              {...form.getInputProps(name)}
              disabled={
                view ||
                (viewOnly
                  ? true
                  : !edit && profile?.user?.type === "staff"
                  ? true
                  : false)
              }
            />
          )}
        </Box>
      ),
      "Facility Types": (
        <Select
          size="xs"
          searchable
          label={name}
          variant="filled"
          rightSection={icon}
          className={classes.myfont}
          {...form.getInputProps(name)}
          placeholder={`Select ${name}`}
          rightSectionPointerEvents="none"
          disabled={view || (viewOnly ? true : false)}
          data={getOptions("Facility Types", noteType?.custom)}
        />
      ),
      Other: (
        <Select
          size="xs"
          required={data?.field?.toLowerCase() == "shift" ? false : true}
          searchable
          label={name}
          id="enter-shift"
          variant="filled"
          rightSection={icon}
          className={classes.selectBG}
          {...form.getInputProps(name)}
          placeholder={`Select ${name}`}
          rightSectionPointerEvents="none"
          disabled={view || (viewOnly ? true : false)}
          data={getOptions("Other", noteType?.custom, name)}
        />
      ),
      "Medication Records": (
        <Group align="flex-end">
          <Select
            size="xs"
            searchable
            label={name}
            data={medicationOptions}
            className={classes.myfont}
            {...form.getInputProps(name)}
            placeholder={`Select ${name}`}
            disabled={view || (viewOnly ? true : false)}
            // icon={<img src="/assets/images/home2.png" alt="home" />}
          />
          <Popover
            shadow="md"
            width={300}
            position="bottom-end"
            style={{ marginBottom: "10px" }}
          >
            <Popover.Target>
              <IconInfoCircle
                color="gray"
                styles={{ marginTop: "30px !important" }}
              />
            </Popover.Target>
            <Popover.Dropdown sx={{ pointerEvents: "none" }}>
              <Textarea
                key={`${index}-test`}
                radius="md"
                autosize={true}
                icon={
                  <img
                    className={classes.descriptionIcon}
                    src="/assets/images/home2.png"
                    alt="home"
                  />
                }
                placeholder={
                  placeholder || `Select ${name} to see details here.`
                }
                className={`${classes.myfont} ${classes.descriptionText}`}
                style={{ height: "200px !important", marginTop: "10px" }}
                value={form.values[name]}
                disabled={true}
              />
            </Popover.Dropdown>
          </Popover>
        </Group>
      ),
      "Support Plan": (
        <Group align="flex-end">
          <Select
            size="xs"
            searchable
            label={name}
            data={supportOptions}
            className={classes.myfont}
            {...form.getInputProps(name)}
            placeholder={`Select ${name}`}
            disabled={view || (viewOnly ? true : false)}
            // icon={<img src="/assets/images/home2.png" alt="home" />}
          />
          <Popover
            width={300}
            shadow="md"
            position="bottom-end"
            style={{ marginBottom: "10px" }}
          >
            <Popover.Target>
              <IconInfoCircle
                color="gray"
                styles={{ marginTop: "30px !important" }}
              />
            </Popover.Target>
            <Popover.Dropdown sx={{ pointerEvents: "none" }}>
              <Textarea
                key={`${index}-test`}
                radius="md"
                autosize={true}
                icon={
                  <img
                    className={classes.descriptionIcon}
                    src="/assets/images/home2.png"
                    alt="home"
                  />
                }
                placeholder={
                  placeholder || `Select ${name} to see details here.`
                }
                className={`${classes.myfont} ${classes.descriptionText}`}
                // style={{ height: "200px !important", marginTop: "10px" }}
                style={{ height: "200px !important", marginTop: "10px" }}
                value={form.values[name]}
                disabled={true}
              />
            </Popover.Dropdown>
          </Popover>
        </Group>
      ),
    };

    if (!textArea && fieldType?.name != "text area")
      return obj[fieldType?.name];
    else if (textArea && fieldType?.name === "text area")
      return obj[fieldType?.name];
    else return null;
  };
  const showRadioButtons =
    (noteType?.name?.includes("Incident Note") &&
      noteType?.name?.includes("Medication Note")) ||
    noteType?.name.includes("Progress Note");

  return (
    <>
      <div style={{ overflowX: "auto" }}>
        <div>
          <Modal
            centered
            radius={15}
            shadow="md"
            size={1000}
            zIndex={1000}
            opened={opened}
            overlayProps={{ blur: 3 }}
            className={classes.mymodal}
            closeOnClickOutside={false}
            onClose={() => {
              resetForm();
              setOpenedAccordion("0");
              setView(false);
            }}
          >
            <CloseCircle
              onclose={() => {
                resetForm();
                setView(false);
                setOpenedAccordion("0");
              }}
            />
            <Box className={classes.header}>
              <Text variant="text" fw={600} size={24}>
                <Text
                  sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 800 }}
                >
                  <span>{view ? "View" : edit ? "Edit" : "Add New"}</span>{" "}
                  <span>{noteType?.name}</span>
                  {/* {form.getInputProps("noteType").value} */}
                </Text>
              </Text>
            </Box>
            <form
              onSubmit={form?.onSubmit((values) => onSave(values))}
              style={{
                background: "#F2F2F2",
                padding: ".5rem 0",
                // height: "75vh",
                // overflow: "scroll",
              }}
            >
              <Box
                className={classes.modalContentBG}
                sx={{ padding: "1rem", background: "#fff" }}
              >
                <Box className={classes.modalTopHeader}>
                  <SimpleGrid cols={1} pt={"1rem"}>
                    {noteType?.custom?.map((value, index) =>
                      value.field === "Staff Name"
                        ? ReturnFormItem(value, index)
                        : null
                    )}
                  </SimpleGrid>
                  {/* client details */}
                  {(view || edit || patientsId || selectedPatient) && (
                    <Box className={classes.userDetails}>
                      <Avatar
                        radius="lg"
                        size={"lg"}
                        shape="square"
                        src={
                          selectedPatient?.imageurl
                            ? decrypt(selectedPatient?.imageurl)
                            : "/assets/images/personal2.png"
                        }
                      />
                      <Box className={classes.userInfo}>
                        <Text variant="text" fw={600} size={20}>
                          {selectedPatient?.name}
                        </Text>
                        <Text variant="text" fw={300}>
                          {clientId}
                        </Text>
                      </Box>
                    </Box>
                  )}
                </Box>
                {/* <SimpleGrid
                  pt={"1rem"}
                  className={classes.mwd}
                  cols={isMobileScreen ? 1 : 4}
                >
                  {noteType?.custom?.map((value, index) =>
                    value.field === "Facility Name"
                      ? ReturnFormItem(value, index)
                      : null
                  )}
                </SimpleGrid> */}

                <SimpleGrid
                  pt={"1rem"}
                  className={classes.mwd}
                  cols={isMobileScreen ? 1 : 4}
                >
                  {noteType?.custom?.map((value, index) =>
                    value.field !== "Staff Name"
                      ? ReturnFormItem(value, index)
                      : null
                  )}
                </SimpleGrid>

                {showRadioButtons && (
                  <Radio.Group
                    size="xs"
                    value={supportPlan}
                    name="useSupportPlan"
                    label="Use Support Plan"
                    onChange={setSupportPlan}
                    style={{ marginBottom: "10px" }}
                  >
                    <Group mt="xs">
                      <Radio
                        value="yes"
                        label="Yes"
                        id="check-yes"
                        color="yellow"
                        disabled={view}
                      />
                      <Radio
                        value="no"
                        label="No"
                        color="yellow"
                        disabled={view}
                      />
                    </Group>
                  </Radio.Group>
                )}
              </Box>
              <Box
                className={classes.modalContentBG}
                style={{
                  overflowY:
                    noteType?.name !== "Progress Note" ? "auto" : "visible",
                  maxHeight:
                    noteType?.name !== "Progress Note" ? "100%" : "auto",
                  height: noteType?.name !== "Progress Note" ? "100%" : "auto",
                }}
              >
                <SimpleGrid cols={1} className={classes.mwd}>
                  {noteType?.custom?.map((value, index) =>
                    ReturnFormItem(value, index, "text area")
                  )}
                </SimpleGrid>
              </Box>

              {!view && supportPlan === "yes" && (
                <Button
                  m={10}
                  size="sm"
                  radius={8}
                  color="yellow"
                  onClick={addDescription}
                  className={`${classes.responsiveBtn}`}
                  style={{
                    marginLeft: "auto",
                    display: "block",
                  }}
                >
                  <Text c="#000">Add New Outcome</Text>
                </Button>
              )}

              {view ? (
                <div />
              ) : (
                <>
                  <div className={`${classes.saveAndCancelParent}`}>
                    <Button
                      radius={8}
                      color="red"
                      variant="light"
                      onClick={resetForm}
                      leftIcon={<IconX size={13} />}
                      className={`${classes.saveAndCancelBtn}`}
                    >
                      Cancel
                    </Button>
                    <Button
                      id="save-note-button"
                      color="yellow"
                      className={`${classes.saveAndCancelBtn}`}
                      styles={(theme) => ({
                        root: {
                          color: "#000",
                        },
                      })}
                      radius={8}
                      type="submit"
                      loading={addNoteLoading}
                    >
                      Save
                    </Button>
                  </div>
                </>
              )}
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
  // console.log("🚀 ~ noteType:", noteType);
  // console.log("🚀 ~ noteType:", noteType);
  // console.log("🚀 ~ noteType:", noteType);
};

export default NoteModal;
