import React, { useState } from "react";
import {
  Group,
  Text,
  Accordion,
  Checkbox,
  TextInput,
  HoverCard,
  Box,
  Flex,
  createStyles,
} from "@mantine/core";

import moment from "moment-timezone";
import { useSelector } from "react-redux";

const useStyles = createStyles((theme) => ({
  accordion: {
    "& .mantine-Accordion-control": {
      backgroundColor: "#F4F3FF !important",
      borderTopLeftRadius: "15px !important",
      borderTopRightRadius: "15px !important",
      borderBottomColor: "#D9D9D9 !important",
      borderBottomWidth: "1px !important",
      borderBottomStyle: "solid !important",

      borderLeftColor: "#D9D9D9 !important",
      borderLeftWidth: "1px !important",
      borderLeftStyle: "solid !important",
      borderRightColor: "#D9D9D9 !important",
      borderRightWidth: "1px !important",
      borderRightStyle: "solid !important",
    },
    "& .mantine-Accordion-content": {
      borderBottomLeftRadius: "15px !important",
      backgroundColor: "#fff !important",
      borderBottomRightRadius: "15px !important",
      border: "none",
    },
    "& .mantine-Accordion-item":{
      border: "1px solid #D9D9D9",
      borderRadius: "16px !important",
    }
  },
  hoverTextField: {
    border: "1px solid #F2F4F7",
    borderRadius: "5px",
    padding: "5px 10px",
  },
}));

function AccordionLabel({ label, description }) {
  return (
    <Group>
      <div>
        <Text>{label}</Text>
        <Text size="sm" c="dimmed" fw={400}>
          {description}
        </Text>
      </div>
    </Group>
  );
}

function AccordionCheck({
  type,
  Data,
  Heading,
  checksDone,
  setChecks,
  checks,
  userType,
  userId,
  viewOnly,
  shift,
  profile,
  tasks,
  activityFields,
}) {
  // console.log("🚀 ~ ===> Data:", Data)
  // console.log("🚀 ~ ===>checks:", checks)
  // console.log("🚀 ~ checksDone:", checksDone)
  const { timeZone } = useSelector((state) => state.profile);
  const [checkboxStates, setCheckboxStates] = useState({});
  // console.log("🚀 ~ checkboxStates:", checkboxStates)
  const [inputValues, setInputValues] = useState({});
  const [fieldText, setFieldText] = useState();
  // console.log("🚀 ~ inputValues:", inputValues)
  const { classes } = useStyles();

  // console.log("activitiesDone =>", activityFields);
  // console.log("Data =>", Data);

  let mappedDataId = Data?.map((data, index) => data?._id);
  let mappedActivityId = activityFields?.map((item) => item?.field);

  // console.log("mappedDataId =>", mappedDataId);
  // console.log("mappedActivityId =>", mappedActivityId);

  let filterActivity = activityFields?.map((item) => item?.field?.name);
  // console.log("filterActivity =>", filterActivity);

  let filterActivityVal = activityFields?.map((item) => item?.textField);
  // console.log("filterActivityVal =>", filterActivityVal);

  let hasId = mappedDataId.filter((id) => mappedActivityId?.includes(id));

  // console.log("contains ID =>", hasId);

  // console.log("activityFields =>", activityFields);

  // console.log("checkDone =>", checksDone);

  // console.log("checks =>", checks);

  const handleCheckboxChange = (id, isChecked) => {
    setCheckboxStates((prev) => ({
      ...prev,
      [id]: isChecked,
    }));
    setFieldText(id)

    if (isChecked) {
      setChecks((prev) => [
        ...prev,
        {
          field: id,
          staff: userId,
          shift: shift || "",
          textField: inputValues[id] || "",
        },
      ]);
    } else {
      setChecks((prev) => prev.filter((item) => item.field !== id));
    }
  };

  const handleInputChange = (id, value) => {
    // console.log("🚀 ~ handleInputChange ~ id, value:", id, value)
    setInputValues((prev) => ({
      ...prev,
      [id]: value,
    }));
    setChecks((prev) =>
      prev.map((item) =>
        item.field === id ? { ...item, textField: value } : item
      )
    );
  };

  const charactersList = [
    {
      id: "bender",
      label: Heading,
      content: (
        <div>
          {Data?.map((data) => {
            console.log("data inside data map", data)
            let alreadyChecked = {}
            if(type == "add"){
              alreadyChecked = activityFields?.filter(_v => _v?.field?._id === data?._id)?.[0]
            }else {
              alreadyChecked = checksDone?.filter(_v => _v?.field?._id === data?._id)?.[0]
            }
            let index = checks?.findIndex(
              // (item) => item === data?._id
              (item) => item.field === data?._id
            );
            {/* console.log("index =>", index); */}
            {/* console.log("data =>", data); */}
            let indexForDisabled = checks?.find(
              // (item) => item === data?._id
              (item) =>
                item.field === data?._id &&
                item?.staff === userId &&
                item.shift === shift
            );
            return (
              <HoverCard
                width={300}
                radius={"lg"}
                shadow="md"
                disabled={
                  filterActivity?.includes(data?.name) ||
                  checksDone?.[index]?.checkedAt
                    ? false
                    : true
                }
              >
                <HoverCard.Target>
                  <div key={data?._id} style={{ margin: "10px" }}>
                    <Checkbox
                      // id="checkbox"
                      color="gray"
                      variant="outline"
                      // className={classes.checkbox}
                      style={{
                        marginBottom: "10px",
                      }}
                      styles={{
                        label: {
                          backgroundColor:
                            filterActivity?.includes(data?.name) ||
                            checksDone?.[index]
                              ? "#FFF6ED !important"
                              : "#F2F4F7 !important",
                          color:
                            filterActivity?.includes(data?.name) ||
                            checksDone?.[index]
                              ? "#C4320A !important"
                              : "#525252 !important",
                          padding: "5px 10px",
                          borderRadius: "30px",
                          marginLeft: "10px",
                        },
                      }}
                      checked={index !== -1 ? true : false}
                      // checked={!!checkboxStates[data?._id]}
                      disabled={
                        viewOnly
                          ? true
                          : index !== -1 &&
                            !indexForDisabled &&
                            userType === "staff"
                          ? true
                          : false
                      }
                      key={data?._id}
                      onChange={(e) =>
                        handleCheckboxChange(data?._id, e.target.checked)
                      }
                      // onChange={(e) => {
                      //   const isChecked = e.target.checked;
                      //   toggleCheckbox(data?._id, isChecked);
                      //   if (isChecked) {
                      //     setChecks([
                      //       ...checks,
                      //       {
                      //         field: data?._id,
                      //         staff: userId,
                      //         shift: shift || "",
                      //       },
                      //     ]);
                      //   } else {
                      //     let index = checks?.findIndex(
                      //       (item) => item.field === data?._id
                      //     );
                      //     checks.splice(index, 1);
                      //     setChecks([...checks]);
                      //   }
                      // }}
                      label={`${data?.name}`}
                      // label={`${data?.name} ${
                      //   index !== -1 && checksDone?.[index]?.staff?.name
                      //     ? ` (${checksDone?.[index]?.staff?.name}) (${
                      //         checksDone?.[index]?.shift?.toLowerCase() ==
                      //         "night"
                      //           ? "N"
                      //           : checksDone?.[index]?.shift?.toLowerCase() ==
                      //             "morning"
                      //           ? "M"
                      //           : "E"
                      //       }) (${moment
                      //         .tz(checksDone?.[index]?.checkedAt, timeZone)
                      //         .format("MM-DD-YYYY hh:mm a")})`
                      //     : ""
                      // }`}
                    />

                    {checkboxStates[data?._id] && (
                      <TextInput
                        ml={30}
                        my={10}
                        radius={"md"}
                        placeholder="Add Text"
                        value={inputValues[data?._id] || ""}
                        onChange={(e) =>
                          handleInputChange(data?._id, e.target.value)
                        }
                      />
                    )}
                  </div>
                </HoverCard.Target>

                <HoverCard.Dropdown radius="lg" position="bottom">
                  <Flex direction={"column"} gap={5}>
                    <Text ff={"Inter"} fw={700} fz={20}>
                      {profile?.user?.name || checksDone?.[index]?.staff?.name}
                    </Text>
                    <Text ff={"Inter"} fw={500} fz={12} c={"#A3A3A3"}>
                      {/* {moment
                        .tz(checksDone?.[index]?.checkedAt, timeZone)
                        .format("MMM DD, hh:mm a")} */}
                      {moment
                        .tz(alreadyChecked?.checkedAt, timeZone)
                        .format("MMM DD, hh:mm a")}
                    </Text>
                    <Box className={classes.hoverTextField}>
                      <Text
                        ff={"Inter"}
                        fw={500}
                        fz={12}
                        c={
                          checksDone?.[index]?.textField === ""
                            ? "#A3A3A3"
                            : "#000"
                        }
                      >
                        {/* {checksDone?.[index]?.textField || "No Text"} */}
                        {alreadyChecked?.textField || "No Text"}
                      </Text>
                    </Box>
                  </Flex>
                </HoverCard.Dropdown>
              </HoverCard>
            );
          })}
        </div>
      ),
    },
  ];

  const items = charactersList?.map((item) => (
    <Accordion.Item value={item.id} key={item.label}>
      <Accordion.Control>
        <AccordionLabel {...item} />
      </Accordion.Control>
      <Accordion.Panel>
        <Text size="sm">{item.content}</Text>
      </Accordion.Panel>
    </Accordion.Item>
  ));
  // console.log("checks", checks);
  return (
    <Accordion
      multiple
      radius="0px"
      variant="contained"
      chevronPosition="right"
      defaultValue={["bender"]}
      className={classes.accordion}
    >
      {items}
    </Accordion>
  );
}

export default AccordionCheck;
