import { Box, Text, Grid, Image } from "@mantine/core";
import { Link } from "react-router-dom";
import useStyles from "./../Signup/style";

const AdminProfile = ({
  renderStep1,
  renderStep2,
  renderStep3,
  step,
}) => {
  const { classes } = useStyles();

  return (
    <Grid className={classes.container}>
      <Grid.Col xs={12} sm={12} md={5} className={`${classes.left_pane} ${classes.bgImg}`}>
        <Box className={classes.welcome}>
          <Link to={"/"}>
            <Image
              fit="fill"
              mr={"auto"}
              className={classes.logo}
              src="/assets/images/logo2.png"
            />
          </Link>
          <Box className={classes.leftSideTitle}>
            <p className={classes.titleText}>
              Simplifying <br /> Healthcare <br /> Documentation
            </p>
          </Box>
          <Text c="#000" ff={"Inter"} fz={20} fw={400}>
            Already have an account? Sign in to continue managing your notes
            effortlessly.
          </Text>
        </Box>

        {/* <img
          className={classes.authImg}
          src="assets/images/loginImg.png"
          alt=""
        /> */}
      </Grid.Col>
      <Grid.Col xs={12} sm={12} md={7} className={classes.right_pane}>
        {step === 1 && renderStep1()}
        {step === 2 && renderStep2()}
        {step === 3 && renderStep3()}
      </Grid.Col>
    </Grid>
  );
};

export default AdminProfile;
