import { Accordion, Box,Text } from "@mantine/core";
import useStyles from "./styles";
import { IconInfoCircle } from "@tabler/icons-react";
import { IconChevronDown } from "@tabler/icons-react";

const ViewInfo = ({ data }) => {
  const { classes } = useStyles();

  return data?.details ? (
    <Accordion
      variant="separated"
      radius="lg"
      defaultValue="Apples"
      style={{ marginBottom: "20px" }}
      chevron={<IconChevronDown size={34} color="#FBAA0D" />}
      chevronPosition="right"
      chevronSize={34}
      styles={{
        chevron: {
          transition: "transform 200ms ease",
          "&[data-active]": {
            transform: "rotate(180deg)",
          },
        },
      }}
    >
      <Accordion.Item key={1} value={"Medication Detail"}>
        <Accordion.Control>
          <IconInfoCircle
            color="gray"
            style={{ marginBottom: "-1px" }}
            size={"15px"}
          />
          {` Info: ${data?.details?.outcomeTitle}`}
        </Accordion.Control>
        <Accordion.Panel>
          <div className={classes.infoAccordion}>
            <div className={classes.accordionRowWrapper}>
              {data?.details?.activities?.map((a, index) => (
                <div key={index} className={classes.accordionRowSingleLine}>
                  <Text
                    className={classes.accordionRowSingleLineText}
                    variant="text"
                    w={30}
                    fw={400}
                  >
                    {a?.activityNumber}
                  </Text>
                  <div style={{width:"calc(100% - 30px)",display:"flex", gap:"10px"}}>
                    <div className={classes.accordianSingleLineBox}>
                      <Text variant="text" fw={600}>
                        Support Activities
                      </Text>
                      <Box className={classes.activityContainer}>
                        <Box  className={classes.accordionInput}>
                          <p>{a?.supportActivity || "-"}</p>
                        </Box>
                      </Box>
                    </div>

                    <div className={classes.accordianSingleLineBox}>
                      <Text variant="text" fw={600}>
                        Support Instructions
                      </Text>
                      <Box className={classes.activityContainer}>
                        <Box key={index} className={classes.accordionInput}>
                          <p>{a?.supportInstructions || "-"}</p>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* <div className={classes.accordionRow}>
              <Text variant="text" fw={600}>
                Support Activities
              </Text>
              {data?.details?.activities?.map((a, index) => (
                <Box className={classes.activityContainer}>
                  <Text variant="text" w={20} fw={400}>
                    {a?.activityNumber}
                  </Text>
                  <Box key={index} className={classes.accordionInput}>
                    <p>{a?.supportActivity || "-"}</p>
                  </Box>
                </Box>
              ))}
            </div> */}

            {/* <div className={classes.accordionRow}>
              <Text variant="text" fw={600}>
                Support Instructions
              </Text>
              {data?.details?.activities?.map((a, index) => (
                <Box className={classes.activityContainer}>
                  <Text variant="text" w={20} fw={400}>
                    {a?.activityNumber}
                  </Text>
                  <Box key={index} className={classes.accordionInput}>
                    <p>{a?.supportInstructions || "-"}</p>
                  </Box>
                </Box>
              ))}
            </div> */}
            {/* <div className={classes.accordionRow}>
              <Text variant="text" fw={600}>
                Skill-Building
              </Text>
              {data?.details?.activities?.map((a, index) => (
                <Radio.Group
                  key={index}
                  value={a?.skillBuilding}
                  // mt={10}
                  mb={10}
                >
                  <Box className={classes.activityContainer}>
                    <Text variant="text" w={20} fw={400}>
                      {a?.activityNumber}
                    </Text>
                    <Group mt="xs" w={"100%"} className={classes.radioGroup}>
                      <Radio
                        color={"yellow"}
                        label="Yes"
                        value={"yes"}
                        m={10}
                        // disabled={true}
                      />
                      <Radio
                        color={"yellow"}
                        label="No"
                        value={"no"}
                        m={10}
                        // disabled={true}
                      />
                    </Group>
                  </Box>
                </Radio.Group>
              ))}
            </div> */}

            {/* <div className={classes.accordionRow}>
              <Text variant="text" fw={600}>
              Intensive in-home
              </Text>
              {data?.details?.activities?.map((a, index) => (
                <Radio.Group
                  key={index}
                  value={a?.intensiveInHome}
                  // mt={10}
                  mb={10}
                >
                  <Box className={classes.activityContainer}>
                    <Text variant="text" w={20} fw={400}>
                      {a?.activityNumber}
                    </Text>
                    <Group mt="xs" w={"100%"} className={classes.radioGroup}>
                      <Radio
                        color={"yellow"}
                        label="Yes"
                        value={"yes"}
                        m={10}
                        // disabled={true}
                      />
                      <Radio
                        color={"yellow"}
                        label="No"
                        value={"no"}
                        m={10}
                        // disabled={true}
                      />
                    </Group>
                  </Box>
                </Radio.Group>
              ))}
            </div> */}

            <div className={classes.accordionRow}>
              <Text variant="text" fw={600} mt={15}>
                How Often
              </Text>
              <div className={classes.howOftenClass}>
                <p>{data?.details?.howOften || "-"}</p>
              </div>
            </div>

            {/* <div className={classes.accordionRow}>
              <Text variant="text" fw={600}>
                I no longer need this outcome when ...
              </Text>
              <div className={classes.accordionInput}>
                <p>{data?.details?.iNoLongerNeedThisOutcome || "-"}</p>
              </div>
            </div> */}

            {data?.details?.additionalInformation && (
              <div className={classes.accordionRow}>
                <Text variant="text" fw={600}>
                  Additional Note
                </Text>
                <div className={classes.accordionInput}>
                  <p>{data?.details?.additionalInformation || "-"}</p>
                </div>
              </div>
            )}
          </div>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  ) : (
    <></>
  );
};
export default ViewInfo;
