import {
  Avatar,
  Badge,
  Box,
  Button,
  createStyles,
  Drawer,
  Indicator,
  Popover,
  Tabs,
  Text,
  Title,
} from "@mantine/core";
import React, { useState } from "react";
import { FiBell } from "react-icons/fi";
import { NavLink } from "react-router-dom";

const useStyles = createStyles(() => ({
  container: {
    display: "flex",
    backgroundColor: "#fff",
    width: "100%",
    height: "75px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2rem",
    borderBottom: "1px solid #E5E5E5",
    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
  },
  right: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "1rem",
    " .mantine-Popover-dropdown": {
      padding: "0 !important",
    },
  },
  notificationBorder: {
    backgroundColor: "#E5E5E5",
    borderRadius: "50%",
    padding: "0.7rem",
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    justifyContent: "space-between",
    width: "100%",
    padding: "1rem",
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  body: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
    backgroundColor: "#F5F5F5",
    gap: "1rem",
    width: "100%",
    padding: "1rem",
    maxHeight: "500px",
    overflowY: "auto",
  },
  card: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
    padding: "1rem",
    backgroundColor: "#fff",
    borderRadius: "10px",
    border: "1px solid #E5E5E5",
  },
  drawerCard: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
    padding: "1rem",
    backgroundColor: "#fff",
    borderRadius: "10px",
    border: "1px solid #E5E5E5",
    gap: "1rem",
    margin: "1rem 0",
  },
  titleIndicator: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  drawer: {
    ".mantine-Drawer-content": {
      backgroundColor: "#f5f5f5",
    },
    ".mantine-Drawer-title": {
      fontSize: 20,
      fontWeight: 700,
      fontFamily: "Inter",
      color: "#000",
    },
    ".mantine-Drawer-header": {
      backgroundColor: "#fff",
    },
  },
  tabs: {
    marginTop: "1rem",
  },
  tabsList: {
    backgroundColor: "#fff",
    width: "fit-content",
  },
  empty: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "80vh",
  },
}));

const AppNavbar = ({ title, profile }) => {
  const { classes } = useStyles();
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      type: "Facility",
      message: "A new facility has been created",
      time: "Today",
    },
    {
      id: 2,
      type: "Event",
      message: "An upcoming event has been scheduled",
      time: "2 hours ago",
    },
    {
      id: 3,
      type: "Alert",
      message: "System maintenance is planned for midnight",
      time: "1 day ago",
    },
    {
      id: 4,
      type: "Message",
      message: "You have received a new message",
      time: "3 days ago",
    },
    {
      id: 5,
      type: "Update",
      message: "Your profile has been updated successfully",
      time: "5 days ago",
    },
    {
      id: 6,
      type: "Facility",
      message: "A new facility has been created",
      time: "Today",
    },
    {
      id: 7,
      type: "Event",
      message: "An upcoming event has been scheduled",
      time: "2 hours ago",
    },
    {
      id: 8,
      type: "Alert",
      message: "System maintenance is planned for midnight",
      time: "1 day ago",
    },
    {
      id: 9,
      type: "Message",
      message: "You have received a new message",
      time: "3 days ago",
    },
    {
      id: 10,
      type: "Update",
      message: "Your profile has been updated successfully",
      time: "5 days ago",
    },
  ]);

  const [isReadAll, setIsReadAll] = useState(false);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [popoverOpened, setPopoverOpened] = useState(false);

  const unreadNotifications = notifications?.filter((notif) => !notif?.read);
  const readNotifications = notifications?.filter((notif) => notif?.read);

  const handleReadAll = () => {
    setIsReadAll(true);
    setNotifications((prev) =>
      prev?.map((notif) => ({ ...notif, read: true }))
    );
  };
  const handleViewAll = () => {
    setPopoverOpened(false);
    setDrawerOpened(true);
  };
  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.left}>
          <Title order={1}>{title}</Title>
        </Box>
        <Box className={classes.right}>
          {/* <Popover
            trapFocus
            shadow="md"
            radius={10}
            offset={10}
            width={600}
            position="bottom-end"
            opened={popoverOpened}
            onChange={setPopoverOpened}
          >
            <Popover.Target>
              <Box
                className={classes.notificationBorder}
                onClick={() => setPopoverOpened((prev) => !prev)}
              >
                <Indicator color="red" size={10} inline disabled={isReadAll}>
                  <FiBell size={24} style={{ margin: "0.3rem" }} />
                </Indicator>
              </Box>
            </Popover.Target>
            <Popover.Dropdown>
              <Box className={classes.header}>
                <Box className={classes.headerTitle}>
                  <Title order={3}>Notifications</Title>
                  {!isReadAll && (
                    <Badge variant="light" color="red" radius="xl" size="md">
                      {notifications?.length} New
                    </Badge>
                  )}
                </Box>
                <Text
                  size="sm"
                  ff={"Inter"}
                  onClick={handleReadAll}
                  style={{ cursor: "pointer" }}
                  c={isReadAll ? "#737373" : "#000"}
                >
                  Read All
                </Text>
              </Box>
              <Box className={classes.body}>
                <Text ff={"Inter"} size="md" fw={400}>
                  Today
                </Text>
                {notifications?.map((notification) => (
                  <Box key={notification?.id} className={classes.card}>
                    <Box className={classes.titleIndicator}>
                      <Indicator color={isReadAll ? "#737373" : "#F78C09"} />
                      <Title order={3}>{notification.type}</Title>
                    </Box>
                    <Text size="sm" color={"#737373"} fw={400} ff={"Inter"}>
                      {notification?.message}
                    </Text>
                    <Text size="sm" color={"#737373"} fw={400} ff={"Inter"}>
                      {notification?.time}
                    </Text>
                  </Box>
                ))}
              </Box>
              <Box className={classes.footer}>
                <Button
                  size="md"
                  fullWidth
                  c={"#000"}
                  variant="white"
                  onClick={handleViewAll}
                >
                  View All
                </Button>
              </Box>
            </Popover.Dropdown>
          </Popover> */}
          <NavLink to={"/home/settings"}>
            <Avatar
              size="lg"
              radius="xl"
              src={profile?.user?.photo}
              style={{ cursor: "pointer" }}
            />
          </NavLink>
        </Box>
      </Box>

      <Drawer
        size="35%"
        offset={8}
        radius="md"
        zIndex={1000}
        position="right"
        title="Notifications"
        opened={drawerOpened}
        className={classes.drawer}
        onClose={() => setDrawerOpened(false)}
      >
        <Box className={classes.drawer}>
          <Tabs
            radius="md"
            color="gray"
            variant="default"
            defaultValue="all"
            className={classes.tabs}
          >
            <Tabs.List className={classes.tabsList}>
              <Tabs.Tab value="all">All</Tabs.Tab>
              <Tabs.Tab value="unread">Unread</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="all">
              <Box>
                <Text ff={"Inter"} size="md" fw={400} mt={"1rem"}>
                  Today
                </Text>
                {unreadNotifications?.length > 0 ? (
                  unreadNotifications?.map((notification) => (
                    <Box key={notification?.id} className={classes.drawerCard}>
                      <Box className={classes.titleIndicator}>
                        <Indicator color={isReadAll ? "#737373" : "#F78C09"} />
                        <Title order={3}>{notification?.type}</Title>
                      </Box>
                      <Text size="sm" color="#737373">
                        {notification?.message}
                      </Text>
                      <Text size="sm" color="#737373">
                        {notification?.time}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Box className={classes.empty}>
                    <Text size="md" color="#737373" fw={400} ff={"Inter"}>
                      No notifications
                    </Text>
                  </Box>
                )}
              </Box>
            </Tabs.Panel>
            <Tabs.Panel value="unread">
              <Box>
                {readNotifications?.length > 0 ? (
                  readNotifications?.map((notification) => (
                    <Box key={notification?.id} className={classes.drawerCard}>
                      <Box className={classes.titleIndicator}>
                        <Indicator color={isReadAll ? "#737373" : "#F78C09"} />
                        <Title order={3}>{notification?.type}</Title>
                      </Box>
                      <Text size="sm" color="#737373">
                        {notification?.message}
                      </Text>
                      <Text size="sm" color="#737373">
                        {notification?.time}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Box className={classes.empty}>
                    <Text size="md" color="#737373" fw={400} ff={"Inter"}>
                      No unread notifications
                    </Text>
                  </Box>
                )}
              </Box>
            </Tabs.Panel>
          </Tabs>
        </Box>
      </Drawer>
    </>
  );
};

export default AppNavbar;
