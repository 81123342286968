import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Group,
  MultiSelect,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { DateInput, DatePickerInput } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showError } from "../../utils";
import {
  AddMedication,
  EditMedication,
  getSinglePatients,
} from "../../services/patient";
import moment from "moment";

function Form3({
  classes,
  view,
  setState,
  form3,
  close,
  setActive,
  setClientDetails,
  state,
  active,
  onSubmit,
  addPatientLoading,
  data,
  edit,
}) {
  // console.log("data", data);
  // console.log("form3", form3);
  // console.log("hamza edit", edit);

  const initialMedication = {
    medicationName: "",
    isPrescribed: false,
    medicationFrequency: "",
    dosage: "",
    unit: "",
    ingestionMethod: "",
    medicationUse: "",
    medicationInstruction: "",
    //medicationOutcome: "",
    otherIngestionMethod: "",
    responsibleParty: "",
    otherResponsibleParty: "",
    administering: "",
    otherAdministering: "",
    medicationDate: new Date(),
    timeSlots: "",
    medicineProvided: 0,
    medicationadministered: 0,
    expirationDate: null,
  };

  const location = useLocation();
  const id = location.state;

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const [showOtherIngestionMethod, setShowOtherIngestionMethod] = useState({});
  const [medications, setMedications] = useState(
    data ? [data] : [{ id: Date.now(), ...initialMedication }]
  );
  const [showOtherResponsible, setShowOtherResponsible] = useState({});
  //const [clientDetails, setClientDetails] = useState(null);

  const [showOtherAdminstering, setShowOtherAdminstering] = useState({});
  const [error, setError] = useState(undefined);
  const [medicationSelected, setMedicationSelected] = useState(false);

  // Components
  const NoRecord = () => {
    return (
      <div
        style={{
          height: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="/assets/images/building.png"
          style={{
            height: "100px",
            width: "100px",
            objectFit: "contain",
          }}
        />
        <Text style={{ opacity: 0.3 }} mt={10}>
          No Medications Found
        </Text>
      </div>
    );
  };

  // functions

  const addMedication = () => {
    setMedications([...medications, { id: Date.now(), ...initialMedication }]);
  };

  const removeMedication = (medicationId) => {
    setMedications(medications.filter((med) => med.id !== medicationId));
  };

  const handleMedicationChange = (medicationId, field, value) => {
    setMedications((prevMedications) =>
      prevMedications.map((med) =>
        med?.id === medicationId
          ? field === "medicationDate"
            ? {
                ...med,
                medicationDate: value,
              }
            : { ...med, [field]: value }
          : med
      )
    );
  };

  const onSave = async (e) => {
    let new_medicines = [];
    medications?.length > 0 &&
      medications?.map((medicine) => {
        if (
          medicine.medicationName &&
          medicine.timeSlots?.length > 0 &&
          medicine.medicineProvided &&
          medicine.medicationadministered
        ) {
          let number_of_days =
            Number(medicine.medicineProvided) /
            (Number(medicine.medicationadministered) *
              medicine.timeSlots?.length);
          number_of_days = Math.floor(number_of_days);
          let medicationEndDate = moment(medicine?.medicationDate)
            .add(number_of_days, "days")
            .toISOString();
          return new_medicines.push({
            ...medicine,
            medicationDate: moment(medicine?.medicationDate).toISOString(),
            medicationEndDate,
            number_of_days,
          });
        }
      });
    if (edit) {
      if (medications.length !== new_medicines.length) {
        showError("Missing Required Fields");
      } else {
        setState((p) => ({ ...p, form3: medications }));
        await EditMedication([...new_medicines], id);
        const data = await getSinglePatients(id);
        setClientDetails(data);
        close();
      }
    } else {
      if (new_medicines.length === 0) {
        showError("Medications list is empty");
      } else {
        if (medications.length !== new_medicines.length) {
          showError("Missing Required Fields");
        } else {
          // console.log("🚀 ~ onSave ~ new_medicines:", new_medicines);

          setState((p) => ({ ...p, form3: medications }));
          //onSubmit(medications);
          await AddMedication([...new_medicines], id);
          const data = await getSinglePatients(id);
          setClientDetails(data);
          close();
        }
      }
    }
  };

  // const onSave = async (e) => {
  //   console.log('medications',medications);
  //   if (medications.length === 0) {
  //     showError("Medications list is empty");
  //   } else {
  //     const lastIndex = medications.length - 1;

  //     if (
  //       !medications[lastIndex].medicationName ||
  //       !medications[lastIndex].timeSlots ||
  //       !medications[lastIndex].medicineProvided ||
  //       medications[lastIndex].dosage === ""
  //     ) {
  //       showError("Missing Required Fields");
  //     } else {
  //       if (edit) {
  //         console.log('updated medications',medications);
  //         //setState((p) => ({ ...p, form3: form3.filter(item => item.id !== medications[lastIndex].id) }));
  //       }
  //       //setState((p) => ({ ...p, form3: [...p.form3, ...medications] }));

  //       //console.log('myform', form3);
  //       //await AddMedication([...medications, ...form3], id);
  //       //const data = await getSinglePatients(id);
  //       //setClientDetails(data);
  //       //close();
  //     }
  //   }
  // };

  //     const onSave = async (e) => {
  //   console.log('update medication', medications);
  //   if (medications.length === 0) {
  //     showError("Medications list is empty");
  //   } else {
  //     const lastIndex = medications.length - 1;

  //     if (
  //       !medications[lastIndex].medicationName ||
  //       !medications[lastIndex].timeSlots ||
  //       !medications[lastIndex].medicineProvided ||
  //       medications[lastIndex].dosage === ""
  //     ) {
  //       showError("Missing Required Fields");
  //     } else {
  //       if (edit) {
  //
  //         const updatedForm3 = (form3 || []).map(med => {
  //           if (med.id === data.id) {
  //             return data; // Replace with updated medication
  //           }
  //           return med;
  //         });

  //         setState((p) => ({ ...p, form3: updatedForm3 }));
  //         console.log('myform', form3);
  //         //await AddMedication([...medications,...form3], id)
  //         //const data = await getSinglePatients(id)
  //         //setClientDetails(data)
  //         //close()
  //       } else {
  //         setState((p) => ({ ...p, form3: [...(p.form3 || []), ...medications] }));
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    if (active === 2) {
      setError(undefined);
      if (state?.form3) {
        setMedications(state?.form3);
        let ingestionObject = {},
          responsibleObject = {},
          adminesteringObject = {};
        state?.form3?.forEach((value) => {
          ingestionObject[value?.id] = value?.ingestionMethod === "Other";
          responsibleObject[value?.id] = value?.responsibleParty === "Other";
          adminesteringObject[value?.id] = value?.administering === "Other";
        });
        setShowOtherIngestionMethod(ingestionObject);
        setShowOtherResponsible(responsibleObject);
        setShowOtherAdminstering(adminesteringObject);
      }
    }
  }, [active]);

  return (
    <div>
      {medications?.length > 0 ? (
        medications.map((medication) => (
          <div key={medication.id}>
            <Group align="center" grow>
              <Group align="center">
                <Text variant="text" style={{ fontWeight: "600" }}>
                  Medication Record
                </Text>
                <Checkbox
                  label="Prescribed"
                  color="yellow"
                  checked={medication?.isPrescribed}
                  onChange={(event) => {
                    handleMedicationChange(
                      medication.id,
                      "isPrescribed",
                      event.target.checked
                    );
                  }}
                  disabled={view}
                />
              </Group>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {!view && !data && (
                  <span
                    onClick={() => removeMedication(medication.id)}
                    style={{
                      color: "#fab005",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Delete
                  </span>
                )}
              </div>
            </Group>
            <SimpleGrid
              cols={isMobileScreen ? 1 : 2}
              className={classes.mwd}
              sx={{
                paddingBottom: 30,
              }}
            >
              <TextInput
                className={classes.myfont}
                label="Name of Medication"
                size="sm"
                placeholder="Enter medication name"
                value={medication?.medicationName}
                onChange={(event) =>
                  handleMedicationChange(
                    medication.id,
                    "medicationName",
                    event.target.value
                  )
                }
                required
                disabled={view}
              />

              <Select
                className={classes.myfont}
                label="Medication Frequency"
                size="sm"
                data={[
                  { value: "PRN", label: "PRN" },
                  { value: "QAM", label: "QAM" },
                  { value: "QPM", label: "QPM" },
                  { value: "1 Hour", label: "1 Hour" },
                  { value: "2 Hour", label: "2 Hour" },
                  { value: "6 Hour", label: "6 Hour" },
                  { value: "Daily", label: "Daily" },
                  { value: "Weekly", label: "Weekly" },
                ]}
                placeholder="Select frequency"
                value={medication?.frequency}
                onChange={(value) => {
                  handleMedicationChange(medication.id, "frequency", value);
                  setMedicationSelected(true);
                }}
                disabled={view}
              />

              <div>
                <Text variant="text" style={{ fontWeight: "600" }}>
                  Medication Dosage
                </Text>

                <div style={{ display: "flex", gap: "5px" }}>
                  <TextInput
                    className={classes.myfont}
                    label="Quantity"
                    size="sm"
                    placeholder="Enter Quantity"
                    value={medication?.dosage}
                    onChange={(event) =>
                      handleMedicationChange(
                        medication.id,
                        "dosage",
                        event.target.value
                      )
                    }
                    disabled={view}
                  />
                  <Select
                    className={classes.myfont}
                    label="Unit"
                    size="sm"
                    data={[
                      { value: "mg", label: "mg" },
                      { value: "mg/ml", label: "mg/ml" },
                      { value: "cc", label: "cc" },
                      { value: "iu", label: "iu" },
                      { value: "ml", label: "ml" },
                    ]}
                    placeholder="Select Unit"
                    value={medication?.unit}
                    onChange={(value) => {
                      handleMedicationChange(medication.id, "unit", value);
                    }}
                    disabled={view}
                  />
                </div>
              </div>

              <Select
                className={classes.myfont}
                label="Route"
                size="sm"
                data={[
                  { value: "Orally", label: "Orally" },
                  { value: "Sublingual", label: "Sublingual" },
                  { value: "Suppository", label: "Suppository" },
                  { value: "Injection", label: "Injection" },
                  { value: "Inhalation", label: "Inhalation" },
                  { value: "Topical", label: "Topical" },
                  { value: "Other", label: "Other" },
                ]}
                placeholder="Select ingestion method"
                value={medication?.ingestionMethod}
                onChange={(value) => {
                  handleMedicationChange(
                    medication?.id,
                    "ingestionMethod",
                    value
                  );
                  setShowOtherIngestionMethod((p) => ({
                    ...p,
                    [medication?.id]: value === "Other",
                  }));
                }}
                disabled={view}
              />
              {showOtherIngestionMethod[medication?.id] && (
                <Textarea
                  className={classes.textArea}
                  style={{ paddingBottom: "5" }}
                  label="Other Ingestion Method"
                  size="sm"
                  placeholder="Explain other ingestion method"
                  value={medication?.otherIngestionMethod}
                  onChange={(event) => {
                    handleMedicationChange(
                      medication.id,
                      "otherIngestionMethod",
                      event?.target?.value
                    );
                  }}
                  disabled={view}
                />
              )}

              <TextInput
                className={classes.myfont}
                label="Use of Medication"
                size="sm"
                placeholder="Enter medication use"
                value={medication?.medicationUse}
                onChange={(event) =>
                  handleMedicationChange(
                    medication.id,
                    "medicationUse",
                    event.target.value
                  )
                }
                //required
                disabled={view}
              />

              <TextInput
                className={classes.myfont}
                label="Medication Instruction"
                size="sm"
                placeholder="Enter medication Instruction"
                value={medication?.medicationInstruction}
                onChange={(event) =>
                  handleMedicationChange(
                    medication.id,
                    "medicationInstruction",
                    event.target.value
                  )
                }
                //required
                disabled={view}
              />

              <Select
                className={classes.myfont}
                label="Responsible for purchasing"
                size="sm"
                data={[
                  { value: "Family", label: "Family" },
                  { value: "Care Facility", label: "Care Facility" },
                  { value: "Doctor/Nurse", label: "Doctor/Nurse" },
                  { value: "Other", label: "Other" },
                ]}
                placeholder="Select responsible party"
                value={medication?.responsibleParty}
                onChange={(value) => {
                  handleMedicationChange(
                    medication.id,
                    "responsibleParty",
                    value
                  );
                  setShowOtherResponsible((p) => ({
                    ...p,
                    [medication?.id]: value === "Other",
                  }));
                }}
                disabled={view}
              />
              {showOtherResponsible[medication?.id] && (
                <Textarea
                  className={classes.textArea}
                  label="Other Responsible Party"
                  size="sm"
                  placeholder="Explain other responsible party"
                  value={medication?.otherResponsibleParty}
                  onChange={(event) => {
                    handleMedicationChange(
                      medication.id,
                      "otherResponsibleParty",
                      event?.target?.value
                    );
                  }}
                  disabled={view}
                />
              )}
              <Select
                className={classes.myfont}
                label="Responsible for administering"
                size="sm"
                data={[
                  "Caregiver",
                  "Direct",
                  "Support Staff",
                  "Nurse/Doctor",
                  "Self",
                  "Other",
                ]}
                placeholder="Select responsible"
                value={medication?.administering}
                onChange={(value) => {
                  handleMedicationChange(medication.id, "administering", value);
                  setShowOtherAdminstering((p) => ({
                    ...p,
                    [medication?.id]: value === "Other",
                  }));
                }}
                disabled={view}
              />
              {showOtherAdminstering[medication?.id] && (
                <Textarea
                  className={classes.textArea}
                  label="Other Adminestering"
                  size="sm"
                  placeholder="Explain other"
                  value={medication?.otherAdministering}
                  onChange={(event) => {
                    handleMedicationChange(
                      medication.id,
                      "otherAdministering",
                      event?.target?.value
                    );
                  }}
                  disabled={view}
                />
              )}

              <DateInput
                maxDate={new Date()}
                // type="range"
                size="sm"
                //placeholder={new Date().toLocaleDateString("en-US", { day: "2-digit", month: "2-digit", year: "numeric" })}
                label="Medication Start Date"
                disabled={view}
                value={
                  medication?.medicationDate
                    ? new Date(medication.medicationDate)
                    : null
                }
                onChange={(value) => {
                  // if (value?.length > 1) {
                  // console.log("value", value);
                  handleMedicationChange(
                    medication.id,
                    "medicationDate",
                    value
                  );
                  // calculateMedicine(
                  //   medication.interval,
                  //   value?.[0],
                  //   value?.[1],
                  //   medication.id
                  // );
                  // }
                }}
                required
              />

              <DateInput
                maxDate={new Date()}
                // type="range"
                size="sm"
                //placeholder={new Date().toLocaleDateString("en-US", { day: "2-digit", month: "2-digit", year: "numeric" })}
                label="Expiration Date"
                disabled={view}
                value={
                  medication?.expirationDate
                    ? new Date(medication.expirationDate)
                    : null
                }
                onChange={(value) => {
                  // if (value?.length > 1) {
                  // console.log("value", value);
                  handleMedicationChange(
                    medication.id,
                    "expirationDate",
                    value
                  );
                  // calculateMedicine(
                  //   medication.interval,
                  //   value?.[0],
                  //   value?.[1],
                  //   medication.id
                  // );
                  // }
                }}
                //required
              />

              <MultiSelect
                className={classes.myfont}
                label="Administering Time(s)"
                size="sm"
                data={[
                  "12:00 AM",
                  "12:30 AM",
                  "01:00 AM",
                  "01:30 AM",
                  "02:00 AM",
                  "02:30 AM",
                  "03:00 AM",
                  "03:30 AM",
                  "04:00 AM",
                  "04:30 AM",
                  "05:00 AM",
                  "05:30 AM",
                  "06:00 AM",
                  "06:30 AM",
                  "07:00 AM",
                  "07:30 AM",
                  "08:00 AM",
                  "08:30 AM",
                  "09:00 AM",
                  "09:30 AM",
                  "10:00 AM",
                  "10:30 AM",
                  "11:00 AM",
                  "11:30 AM",
                  "12:00 PM",
                  "12:30 PM",
                  "01:00 PM",
                  "01:30 PM",
                  "02:00 PM",
                  "02:30 PM",
                  "03:00 PM",
                  "03:30 PM",
                  "04:00 PM",
                  "04:30 PM",
                  "05:00 PM",
                  "05:30 PM",
                  "06:00 PM",
                  "06:30 PM",
                  "07:00 PM",
                  "07:30 PM",
                  "08:00 PM",
                  "08:30 PM",
                  "09:00 PM",
                  "09:30 PM",
                  "10:00 PM",
                  "10:30 PM",
                  "11:00 PM",
                  "11:30 PM",
                ]}
                placeholder="Select Slots"
                searchable
                value={medication?.timeSlots}
                onChange={(value) => {
                  handleMedicationChange(medication.id, "timeSlots", value);
                  // calculateMedicine(
                  //   value,
                  //   medication.medicationStartOn,
                  //   medication.medicationEndOn,
                  //   medication.id
                  // );
                }}
                disabled={view}
                required
              />
              <TextInput
                className={classes.myfont}
                label="Medicines Count"
                size="sm"
                type="number"
                value={medication?.medicineProvided}
                disabled={view}
                onChange={(event) =>
                  handleMedicationChange(
                    medication.id,
                    "medicineProvided",
                    event.target.value
                  )
                }
                required
              />

              <TextInput
                className={classes.myfont}
                label="Medicines Administered"
                size="sm"
                type="number"
                value={medication?.medicationadministered}
                disabled={view}
                onChange={(event) =>
                  handleMedicationChange(
                    medication.id,
                    "medicationadministered",
                    event.target.value
                  )
                }
                required
              />
            </SimpleGrid>

            {/* Divider for Visual Separation */}
            <Divider my="sm" variant="dashed" />
          </div>
        ))
      ) : (
        <NoRecord />
      )}

      <p style={{ color: "red", fontSize: "13px" }}> {error ? error : ""}</p>

      {/* Button to Add Medication */}
      {!view && !data && (
        <Button
          size="sm"
          color="yellow"
          onClick={addMedication}
          style={{ marginLeft: "auto", display: "block" }}
        >
          Add Medication
        </Button>
      )}

      {/* Form Controls Section */}
      {!view && (
        <Group
          sx={{
            margin: "auto",
            marginTop: 25,
            marginBottom: 10,
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            loading={addPatientLoading}
            color="yellow"
            onClick={onSave}
            disabled={view}
            sx={{ width: 122 }}
          >
            Submit
          </Button>
        </Group>
      )}
    </div>
  );
}

export default Form3;
