import axios from "axios";
import { getCookie } from "./utils";

const configURLS = {
  production: {
    baseURL: "https://api.ogiso.io",
  },
  staging: {
    baseURL: "https://staging-api.ogiso.io",
  },
  test: {
    baseURL: "https://staging-api.ogiso.designsdigitalstudio.xyz",
  },
  local: {
    // baseURL: "http://192.168.100.13:5000",
    baseURL: "http://localhost:5000",
    // baseURL: "http://192.168.100.29:3000", 
    // baseURL: "http://192.168.100.38:5000",
    // baseURL: "http://192.168.100.6:5000",
  },
};

// Points to check before deployment:
// Change Firebase Project
// Change Base URL

// const environment = "local"; // staging, production, local, test
const environment = "test"; // staging, production, local, test

export const https = (TOKEN) => {
  const token = getCookie("token");
  return axios.create({
    baseURL: configURLS[environment].baseURL,
    headers: {
      Authorization: `Bearer ${token || TOKEN}`,
    },
  });
};

const baseURL = configURLS[environment].baseURL;
export { baseURL };
