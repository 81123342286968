import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Text,
  Box,
  Avatar,
  Table,
  createStyles,
  ActionIcon,
} from "@mantine/core";
import TableLoader from "../../../components/TableLoader";
import Cuations from "./Cuations";
import { useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getMar } from "../../../services";
import moment from "moment";
import { decrypt } from "../../../utils";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import defaultImage from "./../../../images/personal2.png";

const MarViewReportModal = ({
  opened,
  onClose,
  selectedClient,
  monthValue,
}) => {
  const { classes } = useStyles();
  const { mar: appointments, loading } = useSelector((state) => state.mar);
  const [marTableData, setMarTableData] = useState([]);
  const image = decrypt(selectedClient?.imageurl);
  const [pdfLoading, setPDFLoading] = useState(false);

  const today = new Date();
    const formattedDate = `${
      today.getMonth() + 1
    }-${today.getDate()}-${today.getFullYear()}`;
    const reportName = `${formattedDate}_eMAR_Report`;

  // const start = moment(monthValue?.[0]).startOf("month");
  const start = moment(monthValue?.[0])
    // .subtract(1, "day")
    .clone()
    .startOf("day");
  // const end = moment(monthValue?.[1]).endOf("month");
  let end;
  if (
    moment(monthValue?.[0])?.format("MMM") ==
    moment(monthValue?.[monthValue?.length - 1])?.format("MMM")
  ) {
    end = moment(monthValue?.[monthValue?.length - 1])
      .add(1, "M")
      // .add(1, "day")
      .clone()
      .startOf("day");
  } else {
    end = moment(monthValue?.[monthValue?.length - 1])
      .endOf("month")
      // .add(1, "day")
      .clone()
      .startOf("day");
  }

  const datesArray = [];
  let currentDate = start;

  if (
    moment(monthValue?.[0])?.format("MMM") ==
    moment(monthValue?.[monthValue?.length - 1])?.format("MMM")
  ) {
    while (currentDate < end) {
      datesArray.push(currentDate.clone());
      currentDate.add(1, "day");
    }
  } else {
    while (currentDate <= end) {
      datesArray.push(currentDate.clone());
      currentDate.add(1, "day");
    }
  }

  const daysArray = datesArray.map((date) => date.format("ddd"));

  // useEffect(() => {
  //   if (opened) {
  //     getMar(
  //       `monthly_${monthValue[0].toISOString()}_${monthValue[1].toISOString()}`,
  //       selectedClient
  //     );
  //   }
  // }, [opened]);

  useEffect(() => {
    if (opened) {
      if (
        moment(monthValue?.[0])?.format("MMM") ==
        moment(monthValue?.[monthValue?.length - 1])?.format("MMM")
      ) {
        let endVal = moment(monthValue?.[monthValue?.length - 1]).add(1, "M");
        getMar(`monthly_${monthValue[0]}_${endVal}`, selectedClient?.id);
      } else {
        let endVal = moment(monthValue?.[monthValue?.length - 1]).endOf(
          "month"
        );
        getMar(`monthly_${monthValue[0]}_${endVal}`, selectedClient?.id);
      }
    }
  }, [opened]);

  const isTimeInSlot = (timeToCheck, slot) => {
    const format = "hA"; // Time format, e.g. "6AM"

    // for (let slot of slots) {
    const [start, end] = slot.split(" - ").map((time) => moment(time, format));
    const formattedTime = moment(timeToCheck, "h:mm A");

    // Adjust for the cross-midnight slots like "10PM - 2AM"
    if (end.isBefore(start)) {
      // For slots that span midnight, check in two parts
      if (
        (formattedTime.isSameOrAfter(start) &&
          formattedTime.isBefore(moment("11:59:59 PM", "h:mm A"))) ||
        (formattedTime.isSameOrAfter(moment("12:00 AM", "h:mm A")) &&
          formattedTime.isBefore(end))
      ) {
        // return true;
        return { time: timeToCheck, slot };
      }
    } else {
      // For normal slots
      if (formattedTime.isSameOrAfter(start) && formattedTime.isBefore(end)) {
        // return true;
        return { time: timeToCheck, slot };
      }
    }
    // }

    return false;
  };

  useEffect(() => {
    if (opened && appointments?.length) {
      let medicines = [];
      appointments?.map((appointment) => {
        if (
          medicines?.filter((item) => item?.id == appointment?.form3?.id)
            ?.length === 0
        ) {
          medicines?.push({
            id: appointment?.form3?.id,
            name: appointment?.form3?.medicationName,
            dosage: appointment?.form3?.dosage,
            unit: appointment?.form3?.unit,
            ingestionMode: appointment?.form3?.ingestionMode,
            instruction: appointment?.form3?.medicationInstruction,
          });
        }
      });

      // console.log("medicines list", medicines);

      const slots = [
        "6AM - 10AM",
        "10AM - 2PM",
        "2PM - 6PM",
        "6PM - 10PM",
        "10PM - 2AM",
        "2AM - 6AM",
      ];

      // console.log("datesArray in use-effect", datesArray);

      let tempTableData = [];
      medicines?.map((medicine) => {
        let medicineAppointment = appointments?.filter(
          (item) => item?.form3?.id == medicine?.id
        );
        datesArray?.map((date) => {
          // console.log(
          //   "inside date array map",
          //   medicineAppointment,
          //   moment(date)
          // );
          let singleDayAppointments = medicineAppointment?.filter(
            (item) =>
              moment(item?.date).format("YYYY-MM-DD") ==
              moment(date).format("YYYY-MM-DD")
          );
          if (singleDayAppointments?.length) {
            singleDayAppointments?.map((dayAppointment) => {
              dayAppointment?.form3?.timeSlots?.map((appointmentSlots) => {
                slots?.forEach((slot) => {
                  let timeInSlot = isTimeInSlot(appointmentSlots, slot);
                  // console.log("timeInSlot", timeInSlot);
                  if (!timeInSlot) {
                    // tempTableData.push({medicine: medicine, slot: slot, appointment: null, date: moment(dayAppointment?.date).format("MM-DD-YYYY")})
                    // tempTableData.push({medicine: medicine?.name, slot: slot, appointment: null, date: moment(dayAppointment?.date).format("MM-DD-YYYY")})
                    // tempTableData.push({
                    //   medicine: medicine?.name,
                    //   slot: slot,
                    //   appointment: [null],
                    //   date: moment(date).format("MM-DD-YYYY"),
                    // });
                    let sameMedicineInSlotIndex = tempTableData?.findIndex(
                      (tempItem) =>
                        tempItem?.medicine == medicine?.name &&
                        tempItem?.slot == slot &&
                        tempItem?.date == moment(date).format("MM-DD-YYYY")
                    );

                    if (sameMedicineInSlotIndex == -1) {
                      // console.log("sameMedicineInSlotIndex", sameMedicineInSlotIndex ,tempTableData[sameMedicineInSlotIndex] ,tempTableData[sameMedicineInSlotIndex]?.appointment)
                      tempTableData.push({
                        medicine: medicine?.name,
                        slot: slot,
                        appointment: [null],
                        date: moment(date).format("MM-DD-YYYY"),
                      });
                    }
                  } else {
                    // tempTableData.push({medicine: medicine, slot: slot, appointment: dayAppointment, date: moment(dayAppointment?.date).format("MM-DD-YYYY")})
                    // tempTableData.push({medicine: medicine?.name, slot: slot, appointment: dayAppointment, date: moment(dayAppointment?.date).format("MM-DD-YYYY")})
                    // tempTableData.push({
                    //   medicine: medicine?.name,
                    //   slot: slot,
                    //   appointment: dayAppointment,
                    //   date: moment(date).format("MM-DD-YYYY"),
                    // });

                    let sameMedicineInSlotIndex = tempTableData?.findIndex(
                      (tempItem) =>
                        tempItem?.medicine == medicine?.name &&
                        tempItem?.slot == slot &&
                        tempItem?.date == moment(date).format("MM-DD-YYYY")
                    );

                    if (sameMedicineInSlotIndex !== -1) {
                      // console.log(
                      //   "sameMedicineInSlotIndex",
                      //   sameMedicineInSlotIndex,
                      //   tempTableData[sameMedicineInSlotIndex],
                      //   tempTableData[sameMedicineInSlotIndex]?.appointment
                      // );
                      tempTableData[sameMedicineInSlotIndex] = {
                        medicine: medicine?.name,
                        slot: slot,
                        // appointment: dayAppointment,
                        appointment: [
                          ...tempTableData[sameMedicineInSlotIndex]
                            ?.appointment,
                          dayAppointment,
                        ],
                        date: moment(date).format("MM-DD-YYYY"),
                      };
                    } else {
                      tempTableData.push({
                        medicine: medicine?.name,
                        slot: slot,
                        appointment: [dayAppointment],
                        date: moment(date).format("MM-DD-YYYY"),
                      });
                    }
                  }
                });
              });
            });
          } else {
            slots?.forEach((slot) => {
              tempTableData.push({
                medicine: medicine?.name,
                slot: slot,
                appointment: [null],
                date: moment(date).format("MM-DD-YYYY"),
              });
            });
          }
        });
      });

      // console.log("tempTableData", tempTableData);

      // tempTableData?.map((alteredData) => {
      //   medicines?.map((med) => med)
      // })
      let newData = [];
      medicines?.map((med) => {
        let filteredDataOnMed = tempTableData?.filter(
          (item) => item?.medicine == med?.name
        );

        slots?.forEach((slot) => {
          let filteredOnSlot = filteredDataOnMed?.filter(
            (item) => item?.slot == slot
          );
          let appointments = [];
          filteredOnSlot?.map((filteredData) => {
            appointments?.push({
              appointment: filteredData?.appointment,
              date: filteredData?.date,
            });
          });
          newData.push({ medicine: med, slot, data: appointments });
        });
      });

      // console.log("newData", newData);
      // setMarTableData(tempTableData)
      setMarTableData(newData);
    }
  }, [appointments]);

  const medicationStatus = (medications) => {
    // console.log("handler", medications)
    if (medications?.every((element) => element == null)) {
      return false;
    }

    let notNullMed = medications?.filter((item) => item !== null);
    // console.log("notNullMed", notNullMed);

    if (
      notNullMed?.every(
        (element) => element?.medicationType?.toLowerCase() == "missed"
      )
    ) {
      return "missed";
    }

    if (
      notNullMed?.every(
        (element) => element?.medicationType?.toLowerCase() == "taken"
      )
    ) {
      return "taken";
    }

    if (
      notNullMed?.every(
        (element) => element?.medicationType?.toLowerCase() == "refused"
      )
    ) {
      return "refused";
    }

    if (
      notNullMed?.every(
        (element) =>
          element?.medicationType?.toLowerCase() == "out of medication"
      )
    ) {
      return "out";
    }

    if (
      notNullMed?.every(
        (element) => element?.medicationType?.toLowerCase() == "unable to take"
      )
    ) {
      return "unable";
    }

    if (
      notNullMed?.filter(
        (element) => element?.medicationType?.toLowerCase() == "missed"
      )?.length
    ) {
      return "missed";
    }
  };

  const returnStaffLetters = (medications) => {
    let notNullMed = medications?.filter((item) => item !== null);
    let staffLetters = notNullMed?.map((item) => {
      let splittedName = item?.staff?.name?.split(" ");
      return splittedName[0]?.[0] + splittedName[1]?.[0];
    });

    return staffLetters?.join(", ");
  };

  const downloadPDF = () => {
    setPDFLoading(true);
    const input = document.getElementById("marPDFReport");

    

    const decryptedImageUrl = `${decrypt(
      selectedClient?.imageurl
    )}?r=${Math.floor(Math.random() * 100000)}`;

    const imageUrlCheck = decrypt(selectedClient?.imageurl);
    const defaultUrl =
      "https://cdn.vectorstock.com/i/1000v/92/16/default-profile-picture-avatar-user-icon-vector-46389216.jpg";
    const img = new Image();
    if (imageUrlCheck == undefined) {
      img.src = defaultUrl;
      // img.src =  defaultImage;
    } else {
      img.src = decryptedImageUrl;
    }
    img.crossOrigin = "anonymous";

    img.onload = function () {
      if (imageUrlCheck == undefined) {
        createPDF(input, defaultUrl);
      } else {
        createPDF(input, decryptedImageUrl);
      }
    };
    img.onerror = function () {
      setPDFLoading(false);

    };
  };

  const createPDF = (input, image) => {
    // console.log("🚀 ~ createPDF ~ image:", image);
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 290; // Width of the image in the PDF
      const pageHeight = 210; // Height of an A4 page in landscape mode in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      const pdf = new jsPDF("landscape", "mm", "a4");

      // Add the first part of the image
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);

      // Add logo or additional image at the top corner
      const margin = 1;
      const imgSize = 30;
      pdf.addImage(image, "PNG", margin, margin, imgSize, imgSize);

      heightLeft -= pageHeight;

      // Add additional pages if the content exceeds one page
      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`${reportName}.pdf`);
      setPDFLoading(false);
    });
  };

  return (
    <>
      <Modal
        centered
        size={1720}
        shadow="md"
        radius={"lg"}
        zIndex={1000}
        opened={opened}
        onClose={onClose}
        styles={{
          header: { width: '1720px' },
        }}
        overlayProps={{ blur: 3 }}
      >
        <>
          <Box className={classes.exportBtn}>
            <ActionIcon ml={15} disabled={loading} loading={loading}>
              <ReactHTMLTableToExcel
                table="marTable"
                loading={loading}
                disabled={loading}
                sheet={reportName}
                id="test-table-xls-button"
                filename={`${reportName}`}
                className="download-table-xls-button"
                buttonText={
                  <ActionIcon>
                    <img src="/assets/images/xls.png" alt="" />
                  </ActionIcon>
                }
              />
            </ActionIcon>

            <ActionIcon
              ml={15}
              loading={pdfLoading || loading}
              disabled={loading}
              onClick={downloadPDF}
            >
              <img src="/assets/images/pdf.png" alt="" />
            </ActionIcon>
          </Box>
          <MarTableComponent
            image={image}
            loading={loading}
            daysArray={daysArray}
            monthValue={monthValue}
            datesArray={datesArray}
            marTableData={marTableData}
            selectedClient={selectedClient}
            medicationStatus={medicationStatus}
            returnStaffLetters={returnStaffLetters}
          />
        </>
      </Modal>

      <PDFMarTableComponent
        image={image}
        loading={loading}
        datesArray={datesArray}
        monthValue={monthValue}
        marTableData={marTableData}
        selectedClient={selectedClient}
        medicationStatus={medicationStatus}
        returnStaffLetters={returnStaffLetters}
      />
    </>
  );
};

const MarTableComponent = ({
  image,
  loading,
  daysArray,
  datesArray,
  monthValue,
  marTableData,
  selectedClient,
  medicationStatus,
  returnStaffLetters,
}) => {
  // console.log("selectedClient mar view modal", selectedClient);
  const { classes } = useStyles();
  return (
    <Box className={classes.tableContainer}>
      {loading ? (
        <Box className={classes.loaderContainer}>
          <TableLoader />
        </Box>
      ) : (
        <>
          <Box className={classes.tableHeader}>
            <Box className={classes.clientDetails}>
              <Avatar
                w={250}
                h={250}
                alt="logo"
                src={image}
                size={"250"}
                crossOrigin="anonymous"
                onError={() => {
                  "https://via.placeholder.com/150x150.png?text=No+Image+Found";
                }}
              ></Avatar>

              <Box className={classes.clientInfo}>
                <Table withBorder withColumnBorders>
                  <tbody>
                    <tr>
                      <td className={classes.bgCellColor}>
                        <Text fw={700} w={"100px"}>
                          Client Name:
                        </Text>
                      </td>
                      <td>
                        <Text w={"100px"}>{selectedClient?.name}</Text>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.bgCellColor}>
                        <Text fw={700}>Client DOB</Text>
                      </td>
                      <td>
                        {moment(selectedClient?.dob).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.bgCellColor}>
                        <Text fw={700}>Report Month</Text>
                      </td>
                      <td>
                        {monthValue
                          ?.map((item) =>
                            moment(item).isValid()
                              ? moment(item).format("MMM YYYY")
                              : ""
                          )
                          .join(" - ")}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Box>
              <Box className={classes.cuationsBox}>
                <Cuations />
                <Text fz={32} fw={700}>
                  {/* Special Healthcare LLC */}
                  {selectedClient?.facilityId
                    ? selectedClient?.facilityId?.map((item) => item?.name).join(", ")
                    : ""}
                </Text>
                <Text fz={26} fw={600}>
                  Medication Administration Record (MAR)
                </Text>
                <Text fz={20} fw={500}>
                  {monthValue
                    ?.map((item) =>
                      moment(item).isValid()
                        ? moment(item).format("MMM YYYY")
                        : ""
                    )
                    .join(" - ")}
                </Text>
              </Box>
              <img
                src="/assets/images/logo2.png"
                alt="Logo"
                style={{ width: "10%", margin: "auto" }}
              />
            </Box>
          </Box>
          <Box className={classes.tableBody}>
            <Table id="marTable" withColumnBorders>
              {/* this thead content only show on excel sheet not on modal */}
              <thead style={{ display: "none" }}>
                <tr>
                  <td className={classes.dateCellsContent}>
                    {" "}
                    <Avatar
                    style={{ width: "50px", height: "50px" }}
                      w={50}
                      h={50}
                      size={50}
                      alt="logo"
                      src={image}
                      crossOrigin="anonymous"
                    ></Avatar>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ background: "#4fa80d" }}>
                    <Text fw={700} fz={14} w={200}>
                      Taken
                    </Text>
                  </td>
                  <td style={{ background: "#eb7a74" }}>
                    <Text fw={700} fz={14} w={200}>
                      Missed
                    </Text>
                  </td>
                  <td style={{ background: "#c7daf5" }}>
                    <Text fw={700} fz={14} w={200}>
                      Refused
                    </Text>
                  </td>
                  <td style={{ background: "#979797" }}>
                    <Text fw={700} fz={14} w={200}>
                      Upcoming
                    </Text>
                  </td>
                  <td style={{ background: "#feff80" }}>
                    <Text fw={700} fz={14} w={200}>
                      Unable to Take
                    </Text>
                  </td>
                  <td style={{ background: "#000000", color: "#ffffff" }}>
                    <Text fw={700} fz={14} w={200}>
                      Out of Medication
                    </Text>
                  </td>
                  <td style={{ background: "#a03399" }}>
                    <Text fw={700} fz={14} w={200}>
                      PRN
                    </Text>
                  </td>
                  <td></td>
                  <td>
                    {" "}
                    <Avatar
                      w={50}
                      h={50}
                      alt="logo"
                      src={
                        "https://ogiso-bucket.s3.us-east-2.amazonaws.com/logo.png"
                      }
                      size={"50"}
                    ></Avatar>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ background: "#b7b7b7" }}>
                    <Text fw={700} w={"100px"}>
                      Client Name:
                    </Text>
                  </td>
                  <td>
                    <Text w={"100px"}>{selectedClient?.name}</Text>{" "}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ background: "#b7b7b7" }}>
                    <Text fw={700}>Client DOB</Text>
                  </td>
                  <td>{moment(selectedClient?.dob).format("DD/MM/YYYY")}</td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ background: "#b7b7b7" }}>
                    <Text fw={700}>Report Months</Text>
                  </td>
                  <td colSpan={3}>
                    {monthValue
                      ?.map((item) =>
                        moment(item).isValid()
                          ? moment(item).format("MMM YYYY")
                          : ""
                      )
                      .join(" - ")}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td colSpan={2}>
                    <Text fz={32} fw={700} w={400}>
                      {/* Special Healthcare LLC */}
                      {selectedClient?.facilityId
                        ? selectedClient?.facilityId?.name
                        : ""}
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td colSpan={3}>
                    <Text fz={26} fw={600} w={500}>
                      Medication Administration Record (MAR)
                    </Text>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td colSpan={3}>
                    <Text fz={20} fw={500} w={300}>
                      {monthValue
                        ?.map((item) =>
                          moment(item).isValid()
                            ? moment(item).format("MMM YYYY")
                            : ""
                        )
                        .join(" - ")}
                    </Text>
                  </td>
                </tr>
                <tr></tr>
              </thead>

              <tbody>
                <tr
                  className={classes.dateCells}
                  style={{ background: "#d0e0e3" }}
                >
                  <td className={classes.dateCellsContent}></td>
                  <td className={classes.dateCellsContent}></td>
                  {daysArray.map((day, index) => (
                    <td key={index} className={classes.dateCellsContent}>
                      <Text>{day}</Text>
                    </td>
                  ))}
                </tr>
                <tr
                  className={classes.dateCells}
                  style={{ background: "#d0e0e3" }}
                >
                  <td className={classes.dateCellsContent}></td>
                  <td className={classes.dateCellsContent}></td>
                  {datesArray.map((date, index) => (
                    <td key={index} className={classes.dateCellsContent}>
                      <Text>{date.format("MM/DD/YYYY")}</Text>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Medication</td>
                  <td>Time</td>
                  {datesArray.map((date, index) => (
                    <td key={index}>
                      <Text>{date.format("DD")}</Text>
                    </td>
                  ))}
                  {/* {marTableData[0]?.data?.map((v, zerothIndexs) => (
                    <td>{zerothIndexs + 1}</td>
                  ))} */}
                </tr>
                {marTableData?.length &&
                  marTableData?.map((marItem, marIndex) => (
                    <>
                      {marIndex % 6 === 0 && (
                        <tr>
                          {/* <td rowSpan={marIndex == 0 ? 7 : 6} className={classes.bgTitleColor}> */}
                          <td
                            rowSpan={7}
                            className={classes.bgTitleColor}
                            style={{
                              background: "#ffff00",
                              border: "1px solid #000000",
                            }}
                          >
                            <Box className={classes.medTitleCell}>
                              <Text fz={22} fw={600}>
                                {marItem?.medicine?.name}
                              </Text>
                              <Text>{marItem?.medicine?.instruction}</Text>
                              {/* <Text>Scheduled: 8AM, 6PM</Text> */}
                            </Box>
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td>
                          <Text w={"100px"}>{marItem?.slot}</Text>
                        </td>
                        {
                          // marItem?.data?.map((item) => <td>{item?.appointment?.includes(null) ? "-" : "found"}</td>)
                          marItem?.data?.map((item) => (
                            <td
                              style={{
                                background:
                                  medicationStatus(item?.appointment) ==
                                  "missed"
                                    ? "#eb7a74"
                                    : medicationStatus(item?.appointment) ==
                                      "taken"
                                    ? "#4fa80d"
                                    : medicationStatus(item?.appointment) ==
                                      "out"
                                    ? "#000"
                                    : medicationStatus(item?.appointment) ==
                                      "refused"
                                    ? "#c7daf5"
                                    : medicationStatus(item?.appointment) ==
                                      "unable"
                                    ? "#feff80"
                                    : "",
                                color:
                                  medicationStatus(item?.appointment) == "out"
                                    ? "#fff"
                                    : "",
                              }}
                            >
                              {medicationStatus(item?.appointment) == false
                                ? ""
                                : medicationStatus(item?.appointment) ==
                                  "missed"
                                ? "-"
                                : medicationStatus(item?.appointment) ==
                                  "refused"
                                ? returnStaffLetters(item?.appointment)
                                : medicationStatus(item?.appointment) == "out"
                                ? returnStaffLetters(item?.appointment)
                                : medicationStatus(item?.appointment) ==
                                  "unable"
                                ? returnStaffLetters(item?.appointment)
                                : medicationStatus(item?.appointment) == "taken"
                                ? returnStaffLetters(item?.appointment)
                                : ""}
                            </td>
                          ))
                        }
                      </tr>
                    </>
                  ))}
              </tbody>
            </Table>
          </Box>
        </>
      )}
    </Box>
  );
};

const PDFMarTableComponent = ({
  image,
  loading,
  datesArray,
  monthValue,
  marTableData,
  selectedClient,
  medicationStatus,
  returnStaffLetters,
}) => {
  const { classes } = useStyles();

  const ref_months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let startMonth = ref_months?.findIndex(
    (item) =>
      item?.toLowerCase() == moment(monthValue[0])?.format("MMM")?.toLowerCase()
  );
  let endMonth = ref_months?.findIndex(
    (item) =>
      item?.toLowerCase() ==
      moment(monthValue[monthValue?.length - 1])
        ?.format("MMM")
        ?.toLowerCase()
  );

  // console.log("startMonth endMonth", startMonth, endMonth);

  let filteredMonths = ref_months?.slice(
    startMonth,
    // startMonth == endMonth ? startMonth + 1 : endMonth
    endMonth + 1
  );

  // console.log("filteredMonths", filteredMonths);

  return (
    <Box className={classes.forPdfBox} id={"marPDFReport"}>
      {loading ? (
        <Box className={classes.loaderContainer}>
          <TableLoader />
        </Box>
      ) : (
        <>
          {/* Table Header */}
          <Box
            className={classes.tableHeader}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box className={classes.clientDetails}>
              {/* <Avatar
                w={250}
                h={250}
                alt="logo"
                // src={image}
                src={`${image}?r=${Math.floor(Math.random() * 100000)}`}
                size={"250"}
                crossOrigin="anonymous"
              ></Avatar> */}
              <img
                alt="logo"
                src={image}
                width={"250px"}
                height={"250px"}
                crossOrigin="anonymous"
                onError={() => {
                  "https://via.placeholder.com/250x250.png?text=No+Image+Found";
                }}
              />

              <Box className={classes.clientInfo}>
                <Table withBorder withColumnBorders borderColor="red">
                  <tbody>
                    <tr>
                      <td className={classes.bgCellColor}>
                        <Text fw={700} w={"100px"}>
                          Client Name:
                        </Text>
                      </td>
                      <td>
                        <Text w={"100px"}>{selectedClient?.name}</Text>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.bgCellColor}>
                        <Text fw={700}>Client DOB</Text>
                      </td>
                      <td>
                        {moment(selectedClient?.dob).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className={classes.bgCellColor}>
                        <Text fw={700}>Report Month</Text>
                      </td>
                      <td>{month}</td>
                    </tr> */}
                  </tbody>
                </Table>
              </Box>
            </Box>

            <Box className={classes.cuationsBox}>
              <Cuations />
              <Text fz={32} fw={700}>
                {/* Special Healthcare LLC */}
                {selectedClient?.facilityId
                  ? selectedClient?.facilityId?.name
                  : ""}
              </Text>
              <Text fz={26} fw={600}>
                Medication Administration Record (MAR)
              </Text>
            </Box>
            <img
              src="/assets/images/logo2.png"
              alt="Logo"
              style={{ width: "5%", margin: "auto" }}
            />
          </Box>
          {filteredMonths?.map((month, monthIndex) => (
            <Box key={monthIndex} mb={30}>
              <Box className={classes.reportMonth}>
                <Text fw={700}>Report Month: </Text>
                <Text fz={20} fw={500} align="center">
                  {month}
                </Text>
              </Box>

              {/* Table Body */}
              <Box className={classes.tableBody}>
                <Table id={`marTable-${monthIndex}`} withColumnBorders>
                  <thead>
                    <tr
                      className={classes.dateCells}
                      style={{ background: "#d0e0e3" }}
                    >
                      <td className={classes.dateCellsContent}></td>
                      <td className={classes.dateCellsContent}></td>
                      {/* {daysArray
                        ?.map((day, index) => (
                          <td key={index} className={classes.dateCellsContent}>
                            <Text>{day}</Text>
                          </td>
                        ))} */}
                      {datesArray
                        ?.filter(
                          (item) =>
                            moment(item)?.format("MMM")?.toLowerCase() ==
                            month?.toLowerCase()
                        )
                        ?.map((date, index) => (
                          <td key={index} className={classes.dateCellsContent}>
                            <Text>{date.format("ddd")}</Text>{" "}
                            {/* Change here to 'ddd' for day of the week */}
                          </td>
                        ))}
                    </tr>
                    <tr
                      className={classes.dateCells}
                      style={{ background: "#d0e0e3" }}
                    >
                      <td className={classes.dateCellsContent}></td>
                      <td className={classes.dateCellsContent}></td>
                      {datesArray
                        ?.filter(
                          (item) =>
                            moment(item)?.format("MMM")?.toLowerCase() ==
                            month?.toLowerCase()
                        )
                        ?.map((date, index) => (
                          <td key={index} className={classes.dateCellsContent}>
                            <Text>{date.format("MM/DD/YYYY")}</Text>
                          </td>
                        ))}
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Medication</td>
                      <td>Time</td>
                      {datesArray
                        ?.filter(
                          (item) =>
                            moment(item)?.format("MMM")?.toLowerCase() ==
                            month?.toLowerCase()
                        )
                        ?.map((date, index) => (
                          <td key={index} className={classes.dateCellsContent}>
                            <Text>{index + 1}</Text>
                          </td>
                        ))}
                    </tr>

                    {marTableData
                      // ?.filter((marItem) => {
                      //   // Assuming you have a date field in each marItem, you can filter it here based on the month
                      //   const marDate = moment(marItem?.date);
                      //   return marDate.isSame(month, "month");
                      // })
                      ?.map((marItem, marIndex) => (
                        <>
                          {marIndex % 6 === 0 && (
                            <tr>
                              <td
                                rowSpan={7}
                                className={classes.bgTitleColor}
                                style={{
                                  background: "#ffff00",
                                  border: "1px solid #000000",
                                }}
                              >
                                <Box className={classes.medTitleCell}>
                                  <Text fz={22} fw={600}>
                                    {marItem?.medicine?.name}
                                  </Text>
                                  <Text>{marItem?.medicine?.instruction}</Text>
                                </Box>
                              </td>
                            </tr>
                          )}

                          <tr>
                            <td>
                              <Text w={"100px"}>{marItem?.slot}</Text>
                            </td>
                            {marItem?.data
                              ?.filter(
                                (item) =>
                                  moment(item?.date)
                                    ?.format("MMM")
                                    ?.toLowerCase() == month?.toLowerCase()
                              )
                              ?.map((item) => (
                                <td
                                  style={{
                                    background:
                                      medicationStatus(item?.appointment) ===
                                      "missed"
                                        ? "#eb7a74"
                                        : medicationStatus(
                                            item?.appointment
                                          ) === "taken"
                                        ? "#4fa80d"
                                        : medicationStatus(
                                            item?.appointment
                                          ) === "out"
                                        ? "#000"
                                        : medicationStatus(
                                            item?.appointment
                                          ) === "refused"
                                        ? "#c7daf5"
                                        : medicationStatus(
                                            item?.appointment
                                          ) === "unable"
                                        ? "#feff80"
                                        : "",
                                    color:
                                      medicationStatus(item?.appointment) ===
                                      "out"
                                        ? "#fff"
                                        : "",
                                  }}
                                >
                                  {medicationStatus(item?.appointment) === false
                                    ? ""
                                    : medicationStatus(item?.appointment) ===
                                      "missed"
                                    ? "-"
                                    : medicationStatus(item?.appointment) ===
                                      "refused"
                                    ? returnStaffLetters(item?.appointment)
                                    : medicationStatus(item?.appointment) ===
                                      "out"
                                    ? returnStaffLetters(item?.appointment)
                                    : medicationStatus(item?.appointment) ===
                                      "unable"
                                    ? returnStaffLetters(item?.appointment)
                                    : medicationStatus(item?.appointment) ===
                                      "taken"
                                    ? returnStaffLetters(item?.appointment)
                                    : ""}
                                </td>
                              ))}
                          </tr>
                        </>
                      ))}
                  </tbody>
                </Table>
              </Box>
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "70vh",
  },
  reportMonth: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  greenBox: {
    width: "15px",
    height: "15px",
    background: "#4fa80d",
  },
  exportBtn: {
    display: "flex",
    justifyContent: "end",
    padding: "1rem",
    alignItems: "center",
    gap: "1rem",
    width: "1700px",
  },
  tableContainer: {
    border: "1px solid #EDEDED",
    width: "1700px",
  },
  forPdfBox: {
    width: "1920px",
    position: "absolute",
    left: "-9999px",
  },
  clientDetails: {
    display: "flex",
    alignItems: "start",
    gap: ".5rem",
    justifyContent: "start",
    padding: "1rem",
  },
  cuationsBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: ".5rem",
    flexDirection: "column",
  },
  tableBodyBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: ".5rem",
    width: "100%",
  },
  bgCellColor: {
    backgroundColor: "#b7b7b7",
  },
  dateCells: {
    backgroundColor: "#d0e0e3",
    border: "unset",
  },
  bgTitleColor: {
    background: "#ffff00",
    width: "200px",
  },
  medTitleCell: {
    width: "200px",
  },
  tableHeader: {
    width: "100%",
  },
  tableBody: {
    overflowX: "scroll",
    "& table": {
      width: "500px",
      "& tr:nth-child(1) td": {
        fontSize: "12px !important",
        fontWeight: "400 !important",
        textAlign: "center",
      },
      "& tr:nth-child(2) td": {
        fontSize: "10px !important",
        fontWeight: "300 !important",
        textAlign: "center",
      },
    },
  },
  dateCellsContent: {
    borderTop: "none !important",
    borderRight: "none !important",
  },
}));

export default MarViewReportModal;
