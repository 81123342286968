import React from "react";

import {
  Button,
  Group,
  Modal,
  Select,
  SimpleGrid,
  Text,
  createStyles,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { useSelector } from "react-redux";
import { getTaskTypes } from "../../services/task";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    overflow: "visible",
    // height: 500,
    // "& .mantine-Modal-content": {
    //   height: "300px !important",
    // },
    // "& .mantine-Modal-inner": {
    //   padding: "0px !important",
    // },
    "& .mantine-Paper-root": {
      overflow: "initial !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },

    "@media (max-width:400px)": {
      width: "auto",
    },
  },
  mwd: {
    width: "100%",
  },
}));

const AddTaskModal = ({ opened, onclose, title }) => {
  const { classes } = useStyles();
  const { taskTypes } = useSelector((state) => state?.task);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (opened) {
      getTaskTypes("all");
    }
  }, [opened]);

  const onSave = async (values) => {
    localStorage.setItem("taskType", values?.taskType);
    let typeName = taskTypes?.find((item) => item.id === values?.taskType);
    localStorage.setItem("taskTypeName", typeName?.name);
    navigate("/home/add-task");
  };
  const form = useForm({
    initialValues: {
      taskType: "",
    },
    validate: {
      taskType: (value) => (value ? null : "Please select type"),
    },
  });

  return (
    <Modal
      className={classes.mymodal}
      opened={opened}
      size={"lg"}
      onClose={() => {
        form?.reset();
        onclose();
      }}
      shadow="md"
      overlayProps={{ blur: 3 }}
      radius={"lg"}
      zIndex={1000}
      title={
        <Group>
          <img src="/assets/images/addfac.png" alt="" />
          <Text sx={{ fontFamily: "Inter", fontSize: 22, fontWeight: 600 }}>
            {title}
          </Text>
        </Group>
      }
      centered
    >
      <form onSubmit={form?.onSubmit((values) => onSave(values))}>
        {/* <TextInput
          size="md"
          label="Task Name"
          placeholder="Enter Task Name"
          {...form.getInputProps("name")}
        /> */}

        <Select
          id="task-name"
          label="Task Type*"
          sx={{
            paddingTop: 20,
            borderTopWidth: 1,
            borderTopColor: "#CBCBCB66",
            borderTopStyle: "solid",
            // paddingBottom: 100
          }}
          searchable
          size="md"
          icon={<img src="/assets/images/home2.png" alt="home" />}
          placeholder="Task Type"
          data={
            taskTypes?.length > 0
              ? taskTypes?.map((value) => ({
                  label: value?.name,
                  value: value?.id,
                }))
              : []
          }
          {...form.getInputProps("taskType")}
        />
        <SimpleGrid
          cols={2}
          sx={{ margin: "auto", marginTop: 25, marginBottom: 10 }}
        >
          <Button
            color="yellow"
            sx={{ marginLeft: "auto", width: 122, fontFamily: "Inter" }}
            onClick={onclose}
          >
            Cancel
          </Button>
          <Button
            id="task-next-button"
            loading={loading}
            color="yellow"
            type="submit"
            sx={{ marginRight: "auto", width: 122, fontFamily: "Inter" }}
          >
            Next 
          </Button>
        </SimpleGrid>
      </form>
    </Modal>
  );
};

export default AddTaskModal;
