import {
  Accordion,
  Box,
  Button,
  Divider,
  Group,
  Modal,
  Radio,
  Select,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useSelector } from "react-redux";
import { decrypt, showInfo } from "../../utils";
import useStyles from "./style";
import moment from "moment";
import "moment-timezone";
import { createMar, getMar, recordResponse } from "../../services";
import { getColor } from "../../pages/Home/Medication";
import { getAllPatientsModal } from "../../services/patient";
import DeleteComponent from "../MedicationModal/DeleteComponent";
import CloseCircle from "../CloseCircle";
import NarcoticBox from "../NarcoticBox";

const PRNMedicationModal = forwardRef(
  ({ opened, onclose, data, isNew: isNewPRN }, ref) => {
    const { classes } = useStyles();

    const medicines =
      data?.form3?.length > 0
        ? data?.form3
            ?.filter(
              (v) =>
                v?.medicationType?.toUpperCase() === "PRN" && !v?.discontinued
            )
            ?.map((value) => ({
              value: value?.id,
              label: value?.medicationName,
            })) || []
        : [];
    const [isNew, setIsNew] = useState(isNewPRN);
    const [loading, setLoading] = useState(false);
    const [loadingResponse, setLoadingResponse] = useState(false);
    const { selectedClient } = useSelector((state) => state.mar);
    const [selectedMedicine, setSelectedMedicine] = useState(null);
    // console.log("🚀 ~ selectedMedicine:", selectedMedicine)
    const { profile } = useSelector((state) => state.profile);
    let userid = profile?.user?.id;
    const [state, setState] = useState(null);
    // console.log("🚀 ~ state:", state)

    useEffect(() => {
      if (isNewPRN) {
        setIsNew(true);
        setSelectedMedicine(null);
      }
    }, [opened, isNewPRN]);

    const form = useForm({
      initialValues: {
        medicine: "",
        reason: "",
        comments: "",
        wasEffective: "",
      },
      validate: {
        reason: (value) => (value ? null : "Required"),
        medicine: (value) => (value ? null : "Required"),
        comments: (value) => (value ? null : "Required"),
      },
    });
    const form2 = useForm({
      initialValues: {
        wasEffective: "",
      },
      validate: {
        wasEffective: (value) => (value ? null : "Required"),
      },
    });

    useImperativeHandle(ref, () => ({
      openView: (data) => {
        setIsNew(false);
        setState(data);
        setSelectedMedicine(data?.form3);
        form.setFieldValue("quantity", data?.dosage);
        form.setFieldValue("reason", data?.reason);
        form.setFieldValue("otherReason", data?.otherReason);
        form.setFieldValue("comments", data?.comments);
        form2.setFieldValue("wasEffective", data?.wasMedicineEffective);
      },
    }));

    const onMedicineChange = (med) => {
      let medicine = data?.form3?.find((v) => v?.id === med);
      form.setFieldValue("medicine", med);
      setSelectedMedicine(medicine);
      form.setFieldValue(
        "quantity",
        String(medicine?.medicationadministered || "0")
      );
    };
    const onSave = async (values) => {
      if (Number(values?.quantity) > 0) {
        let obj = {
          client: selectedClient?.id,
          date: moment().toISOString(),
          medicationId: selectedMedicine?.id,
          medicationType: "PRN",
          staff: userid,
          comments: values?.comments,
          reason: values?.reason,
          medicationName: selectedMedicine?.medicationName,
        };

        if(values?.reason === "Other"){
          obj = {...obj, otherReason: values?.otherReason}
        }

        const dosage = Number(values?.quantity);

        const payload = {
          ...obj,
          dosage,
        };
        setLoading(true);
        try {
          await createMar(payload);
          form.reset();
          setSelectedMedicine(null);
          onclose();
        } catch (error) {
          console.error("Error creating MAR:", error.message);
        } finally {
          let calendar_view = localStorage.getItem("calendar_date");
          getMar(calendar_view, selectedClient?.id);
          getAllPatientsModal(true, selectedClient?.id);
          setLoading(false);
        }
      } else {
        alert("Quantity is Required!");
      }
    };

    const onSavePress = async (values) => {
      if (values.wasEffective && state?._id) {
        let payload = {
          id: state?._id,
          wasMedicineEffective: values?.wasEffective,
        };
        setLoadingResponse(true);
        try {
          await recordResponse(payload, () => {
            setLoadingResponse(false);
            showInfo("Response has been recorded");
            form2.reset();
            onclose();
          });
        } catch (error) {
          console.error("Error creating MAR:", error.message);
        } finally {
          setLoadingResponse(false);
        }
      }
    };

    return (
      <>
        <div style={{ overflowX: "auto" }}>
          <div style={{ minWidth: "600px" }}>
            <Modal
              className={classes.medicationModal}
              opened={opened}
              size={1200}
              onClose={() => {
                onclose();
              }}
              shadow="md"
              overlayProps={{ blur: 3 }}
              radius={"lg"}
              zIndex={1000}
              centered
            >
              <CloseCircle onclose={onclose} />

              {isNew ? (
                <div className={classes.upcomingHeader}>
                  <img
                    src="/assets/images/prn-icon.png"
                    width={50}
                    height={50}
                  />
                  <h2>Administer PRN Medication</h2>
                </div>
              ) : (
                <div
                  style={{
                    background: getColor("PRN")?.dark,
                  }}
                  className={`${classes.title}`}
                >
                  <div className={classes.buttonPRN}>PRN</div>
                  <h2>{selectedMedicine?.medicationName}</h2>
                  {selectedMedicine?.isNarcotic === "Yes" && <NarcoticBox />}
                  <span
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: 25,
                    }}
                  >
                    <DeleteComponent
                      data={state}
                      onClose={onclose}
                      type={"prn"}
                    />
                  </span>
                </div>
              )}

              <div className={classes.mainContentWarpper}>
                <div className={classes.mainContent}>
                  <div className={classes.details}>
                    <p
                      style={{
                        textAlign: "left",
                        marginTop: 0,
                        fontWeight: 600,
                        fontSize: "20px",
                        color: "#5C5C5C",
                      }}
                    >
                      {moment().format("MM/DD/YYYY")}
                    </p>
                    <div className={classes.head}>
                      {selectedMedicine && (
                        <div className={classes.box}>
                          <h3>
                            {state?.medicineRemaining > 0
                              ? state?.medicineRemaining
                              : "0" || selectedMedicine?.medicineRemaining > 0
                              ? selectedMedicine?.medicineRemaining
                              : "0"}
                          </h3>
                          <p>
                            of{" "}
                            {state?.totalMedicine ||
                              selectedMedicine?.totalMedicine}{" "}
                            <br />
                            {/* of {selectedMedicine?.totalMedicine} <br /> */}
                            Remaining
                          </p>
                        </div>
                      )}
                      <div>
                        <div className={`${classes.detailTitle}`}>
                          {isNew ? (
                            <h2>{selectedMedicine?.medicationName}</h2>
                          ) : null}
                          {selectedMedicine?.isNarcotic === "Yes" && isNew && (
                            <Button>
                              <img src="/assets/images/narcotic.png" alt="" />
                              Narcotic
                            </Button>
                          )}
                        </div>

                        <p>
                          <span className={classes.span1}>
                            {selectedMedicine?.frequency}{" "}
                          </span>{" "}
                          <span className={classes.span2}>
                            {selectedMedicine?.dosage}
                            {selectedMedicine?.unit}{" "}
                            {selectedMedicine?.ingestionMode}{" "}
                            {selectedMedicine?.dosageForm}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className={classes.medicationBox}>
                      {isNew && (
                        <Select
                          id="select-prn-medicine"
                          // classNames="select-prn-medicine"
                          label="PRN Medicine Name"
                          placeholder="Select Medicine"
                          data={medicines}
                          {...form.getInputProps("medicine")}
                          onChange={onMedicineChange}
                          className={classes.prnSelectBox}
                          style={{
                            marginBottom: "10px",
                          }}
                        />
                      )}

                      <Accordion variant="contained">
                        <Accordion.Item
                          value="use"
                          style={{
                            borderRadius: "15px",
                            background: "#F2F2F2",
                            marginBottom: "10px",
                          }}
                        >
                          <Accordion.Control>
                            Use of Medication
                          </Accordion.Control>
                          <Accordion.Panel>
                            {selectedMedicine?.medicationUse}
                          </Accordion.Panel>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion variant="contained">
                        <Accordion.Item
                          value="instruction"
                          style={{
                            borderRadius: "15px",
                            background: "#F2F2F2",
                            marginBottom: "10px",
                          }}
                        >
                          <Accordion.Control>
                            Medication Instruction
                          </Accordion.Control>
                          <Accordion.Panel>
                            {selectedMedicine?.medicationInstruction}
                          </Accordion.Panel>
                        </Accordion.Item>
                      </Accordion>
                    </div>

                    <form
                      className={classes.modalBody}
                      onSubmit={form?.onSubmit((values) => onSave(values))}
                    >
                      <div className={classes.outcome}>
                        <div className={classes.quantity}>
                          <TextInput
                            id="prn-quantity-dispensed"
                            size="xl"
                            radius="xl"
                            type="number"
                            disabled={!isNew}
                            value={form.values.quantity}
                            className={classes.quantityInput}
                            {...form.getInputProps("quantity")}
                            onWheel={(e) => e.target.blur()}
                          />
                          <p>Quantity Dispensed</p>
                        </div>
                      </div>

                      <Select
                        id="select-reason"
                        label="Reason for Administration"
                        data={[
                          { value: "Allergies", label: "Allergies" },
                          { value: "Headache", label: "Headache" },
                          { value: "Congestion", label: "Congestion" },
                          { value: "Cold/Flu", label: "Cold/Flu" },
                          { value: "Constipation", label: "Constipation" },
                          { value: "Cough", label: "Cough" },
                          { value: "Diarrhea", label: "Diarrhea" },
                          {
                            value: "Itchiness/Rash",
                            label: "Itchiness/Rash",
                          },
                          { value: "Fever", label: "Fever" },
                          {
                            value: "Cuts/Abrasions",
                            label: "Cuts/Abrasions",
                          },
                          { value: "Pain", label: "Pain" },
                          { value: "Chafing", label: "Chafing" },
                          { value: "Hemorrhoids", label: "Hemorrhoids" },
                          { value: "Agitation", label: "Agitation" },
                          { value: "Blood Sugar", label: "Blood Sugar" },
                          {
                            value: "Blood Pressure",
                            label: "Blood Pressure",
                          },
                          {
                            value: "Anxiety and panic attacks",
                            label: "Anxiety and panic attacks",
                          },
                          {
                            value: "Nausea and vomiting",
                            label: "Nausea and vomiting",
                          },
                          {
                            value: "Sleep disturbances",
                            label: "Sleep disturbances",
                          },
                          {
                            value: "Asthma attacks",
                            label: "Asthma attacks",
                          },
                          {
                            value: "Allergic reactions",
                            label: "Allergic reactions",
                          },
                          {
                            value: "Migraine relief",
                            label: "Migraine relief",
                          },
                          { value: "Muscle spasms", label: "Muscle spasms" },
                          {
                            value: "Heartburn or acid reflux",
                            label: "Heartburn or acid reflux",
                          },
                          {
                            value: "Flare-ups of chronic conditions",
                            label: "Flare-ups of chronic conditions",
                          },
                          {
                            value: "Menstrual cramps",
                            label: "Menstrual cramps",
                          },
                          { value: "Infections", label: "Infections" },
                          {
                            value: "Dermatological conditions",
                            label: "Dermatological conditions",
                          },
                          {
                            value: "Other",
                            label: "Other",
                          },
                        ]}
                        size="sm"
                        disabled={!isNew}
                        rightSection={
                          <IoIosArrowDown color={"#FBAA0D"} size={25} />
                        }
                        style={{ marginBottom: "15px" }}
                        placeholder="Select Reason"
                        {...form.getInputProps("reason")}
                      />
                      {form.getInputProps("reason")?.value === "Other" ? (
                        <Textarea
                          id="other-reason"
                          label="Other Reason"
                          disabled={!isNew}
                          placeholder="Enter other reason here"
                          {...form.getInputProps("otherReason")}
                        />
                      ) : null}

                      <div
                        className={classes.comments}
                        style={{ marginBottom: "20px" }}
                      >
                        <Textarea
                          id="comments"
                          minRows={6}
                          label="Comments"
                          disabled={!isNew}
                          placeholder="Enter comments here"
                          {...form.getInputProps("comments")}
                        />
                      </div>

                      {isNew ? (
                        <div className={classes.saveButton}>
                          <Button loading={loading} type="submit" id="save-prn">
                            <img src="/assets/images/save-icon.png" alt="" />
                            Save
                          </Button>
                        </div>
                      ) : (
                        <div className={classes.info}>
                          <div className="column1">
                            <p>Administered by</p>
                            <h3>{state?.staff?.name}</h3>
                          </div>
                          <div className="column2">
                            <p>Date</p>
                            <h3>{moment(state?.date).format("MM/DD/YYYY")}</h3>
                          </div>
                          <div className="column2">
                            <p>Time</p>
                            <h3>
                              {/* {moment(state?.createdAt).format("hh:mm a")} */}
                              {moment
                                .tz(
                                  moment(state?.date),
                                  profile?.user?.timeZone
                                )
                                .format("hh:mm a")}
                            </h3>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>

                  <div className={classes.personInfo}>
                    <img src={decrypt(selectedClient?.imageurl)} />
                    <h3>{selectedClient?.name}</h3>
                    <p>{selectedClient?.id?.slice(0, 5)?.toUpperCase()}</p>
                  </div>
                </div>
                {!isNew && (
                  <>
                    <form
                      onSubmit={form2?.onSubmit((values) =>
                        onSavePress(values)
                      )}
                    >
                      <Divider size="lg" my="sm" variant="dashed" />
                      <div className={classes.effectiveContainer}>
                        <div className={classes.wasEffectiveRadioContainer}>
                          <h1>Was the medication effective?</h1>
                          <Radio.Group
                            label=""
                            defaultValue={form2?.values.wasEffective}
                            {...form2.getInputProps("wasEffective")}
                            required
                            withAsterisk
                          >
                            <Group mt="lg">
                              <Radio
                                disabled={
                                  state?.wasMedicineEffective &&
                                  state?.wasMedicineEffective != ""
                                    ? true
                                    : false
                                }
                                value="Yes"
                                label="Yes"
                              />
                              <Radio
                                disabled={
                                  state?.wasMedicineEffective &&
                                  state?.wasMedicineEffective != ""
                                    ? true
                                    : false
                                }
                                value="No"
                                label="No"
                              />
                            </Group>
                          </Radio.Group>
                        </div>
                        <Button
                          loading={loadingResponse}
                          leftIcon={<img src="/assets/images/save.png" />}
                          type="submit"
                          disabled={
                            state?.wasMedicineEffective &&
                            state?.wasMedicineEffective != ""
                              ? true
                              : false
                          }
                        >
                          Save
                        </Button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </Modal>
          </div>
        </div>
      </>
    );
  }
);

export default PRNMedicationModal;
