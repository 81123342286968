import React, { useEffect, useRef, useState } from "react";
import {
  ActionIcon,
  Box,
  Button,
  Group,
  Modal,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  createStyles,
  MultiSelect,
} from "@mantine/core";

import { IconPlus, IconTrash } from "@tabler/icons-react";
import { DatePickerInput, MonthPickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { getAllNoteTypes } from "../../services/note";
import { getTaskTypes } from "../../services/task";
import { useSelector } from "react-redux";
import { createReport, editReport } from "../../services/report";
import { getMar } from "../../services";
import { getAllPatients } from "../../services/patient";
import moment from "moment";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  btn: {
    marginLeft: "auto",
    marginTop: 50,
  },
  mymodal: {
    width: 818,
    overflow: "visible",
    "& .mantine-Modal-content": {
      height: "600px !important",
    },
    "& .mantine-Modal-inner": {
      padding: "0px !important",
    },
  },
  mwd: {
    width: "100%",
  },
  reportInputFields: {
    margin: "auto",
    marginBottom: 10,
    borderBottomColor: "#FFBA4599",
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    paddingBottom: 20,
    display: "flex",
    flexDirection: "row",
  },
}));

///
const ReportModal = ({ opened, onclose, title, save, ...data }) => {
  const buttonRef = useRef();
  const { classes } = useStyles();
  const edit = data.data ? true : false;

  const { patients } = useSelector((state) => state.patient);
  const { allNotesTypes } = useSelector((state) => state.note);
  const { taskTypes } = useSelector((state) => state.task);

  // console.log("patients", patients);

  const [noteTypeOptions, setNoteTypeOptions] = useState([]);
  const [taskTypeOptions, setTaskTypeOptions] = useState([]);

  const [generalSelect, setGeneralSelect] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [error, setError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [fields, setFields] = useState([]);
  const [options, setOptions] = useState([]);

  const [monthValue, setMonthValue] = useState([]);
  // console.log("🚀 ~ ReportModal ~ monthValue:", monthValue);

  useEffect(() => {
    getAllPatients("withDeleted");
  }, []);

  // Populate options based on note/task types
  useEffect(() => {
    if (allNotesTypes?.ntypes) {
      setNoteTypeOptions(
        allNotesTypes?.ntypes?.map((val) => ({
          label: val?.name,
          value: val?.id,
        }))
      );
    }
  }, [allNotesTypes?.ntypes]);

  useEffect(() => {
    if (taskTypes) {
      setTaskTypeOptions(
        taskTypes?.map((val) => ({
          label: val?.name,
          value: val?.id,
        }))
      );
    }
  }, [taskTypes]);

  // Filter options based on selection
  useEffect(() => {
    if (generalSelect === "note") {
      setFilteredOptions(noteTypeOptions);
    } else if (generalSelect === "task") {
      setFilteredOptions(taskTypeOptions);
    } else {
      setFilteredOptions([]);
    }
  }, [generalSelect, noteTypeOptions, taskTypeOptions]);

  const form = useForm({
    initialValues: {
      name: "",
      startDate: null,
      endDate: null,
    },
    validate: {
      name: (v) => (v ? null : "Required"),
      startDate: (v) => (v ? null : "Required"),
      endDate: (v) => (v ? null : "Required"),
    },
  });
  // console.log("🚀 ~ ReportModal ~ form:", form);
  const fieldForm = useForm({
    initialValues: {
      source: "",
      fields: "",
    },
    validate: {
      source: (value) => (value ? null : "Required"),
      fields: (value) => (value ? null : "Required"),
    },
  });

  useEffect(() => {
    if (opened) getAllNoteTypes() && getTaskTypes() && getMar();
    if (!opened) {
      form?.reset();
      fieldForm?.clearErrors();
      form?.clearErrors();
      setFields([]);
    }
    if (opened && edit) {
      form?.setFieldValue("name", data?.data?.name);
      // if (!isNaN(new Date(data?.data?.startDate)?.getTime()))
      //   form?.setFieldValue("startDate", new Date(data?.data?.startDate));
      // if (!isNaN(new Date(data?.data?.endDate)?.getTime()))
      //   form?.setFieldValue("endDate", new Date(data?.data?.endDate));

      const { parameters } = data?.data;
      let values = parameters
        ?.filter((v) => v?.source)
        ?.map((value) => {
          return {
            id: value?._id,
            source: value?.source?.id,
            fields: value?.fields?.length ? value?.fields : ["all"],
          };
        });

      if (parameters?.length) setFields(values);
    }
  }, [opened]);

  // functions
  const onPartialSave = (values) => {
    let filtered = fields?.find((value) => value?.source === values?.source);
    const source = allNotesTypes?.ntypes?.find(
      (val) => val?.id === filtered?.source
    );
    if (!filtered) {
      if (values?.fields?.includes("all")) {
        let tempArr =
          generalSelect === "task"
            ? {
                ...values,
                fields: [
                  "Facility Name",
                  "Task Name",
                  "Client Name",
                  "Date",
                  "Staff Name",
                  "Time",
                  "Shift",
                  "Task Completed",
                  "Activities Performed",
                  "Notes",
                ],
                id: Date.now(),
              }
            : { ...values, fields: ["all"], id: Date.now() };
        fields?.push(tempArr);
        setOptions([]);
      } else {
        fields?.push({ ...values, id: Date.now() });
        setOptions([]);
      }
      setFields(fields);
      fieldForm.setValues({
        source: true,
      });
      fieldForm.setFieldValue("fields", true);
      setOptions([]);
      setError("");
    } else {
      if (filtered?.fields === "all")
        setError(`You have already selected all fields from '${source?.name}'`);
      else setError(`You have already selected a field from '${source?.name}'`);
    }
  };

  const onSave = async () => {
    buttonRef?.current?.click();
    // form?.validate();
    if (!generalSelect) {
      setTypeError("Required");
      return;
    }

    // if (
    //   !form?.getInputProps("name")?.value ||
    //   !form?.getInputProps("startDate")?.value ||
    //   !form?.getInputProps("endDate")?.value
    // ) {
    //   if (fields?.length === 0) {
    //     setError("Report must contain one parameter");
    //     return;
    //   }
    //   return;
    // }
    // if (fields?.length === 0) {
    //   setError("Report must contain one parameter");
    //   return;
    // }

    let obj;

    if (generalSelect.toLowerCase() !== "mar") {
      obj = {
        ...form.values,
        // startDate: monthValue[0],
        // endDate: monthValue[1],
        reportType: generalSelect.toLowerCase(),
        parameters: fields?.map(({ fields, id, _id, ...value }) => {
          if (fields?.includes("all")) return value;
          else
            return {
              ...value,
              fields,
            };
        }),
      };
      // console.log("🚀 ~ onSave ~ form.values:", form.values);
      // console.log("🚀 ~ ifffffffffffff");
    } else {
      // console.log("🚀 ~ elseeeeeeeee");

      obj = {
        ...form.values,
        startDate: monthValue[0],
        endDate: moment(monthValue[1]).endOf("M").toDate(),
        client: selectedClient,
        reportType: generalSelect.toLowerCase(),
        // parameters: fields?.map(({ fields, id, _id, ...value }) => {
        //   if (fields?.includes("all")) return value;
        //   else
        //     return {
        //       ...value,
        //       fields,
        //     };
        // }),
      };
    }

    // if(generalSelect === "mar"){
    //   obj = {...obj, client: selectedClient}
    // }

    // console.log("🚀 ~ onSave ~ obj:", obj);

    if (edit) {
      editReport({
        ...obj,
        id: data?.data?.id,
      });
    } else {
      await createReport(obj);
      handleClose();
    }
  };
  const handleSourceChange = (id) => {
    if (generalSelect === "note") {
      if (allNotesTypes?.ntypes) {
        let one = allNotesTypes?.ntypes?.find((value) => value?.id === id);
        let _options = one?.custom?.map((value) => ({
          label: value?.field,
          value: value?._id,
        }));
        _options?.unshift({
          label: "All",
          value: "all",
        });
        setOptions(_options);
        fieldForm.setFieldValue("fields", null);
      }
    } else {
      const taskFields = [
        "Facility Name",
        "Task Name",
        "Client Name",
        "Date",
        "Staff Name",
        "Time",
        "Shift",
        "Task Completed",
        "Activities Performed",
        "Notes",
      ];
      let _options = [
        { label: "All", value: "all" },
        ...taskFields?.map((item) => ({ label: item, value: item })),
      ];
      setOptions(_options);
      fieldForm.setFieldValue("fields", null);
    }
  };

  const getFieldOptions = (id) => {
    if (generalSelect === "note") {
      if (allNotesTypes?.ntypes) {
        let one = allNotesTypes?.ntypes?.find((value) => value?.id === id);
        let _options = one?.custom?.map((value) => ({
          label: value?.field,
          value: value?._id,
        }));
        _options?.unshift({
          label: "All",
          value: "all",
        });
        return _options;
      }
    } else {
      const taskFields = [
        "Facility Name",
        "Task Name",
        "Client Name",
        "Date",
        "Staff Name",
        "Time",
        "Shift",
        "Task Completed",
        "Activities Performed",
        "Notes",
      ];
      let _options = [
        { label: "All", value: "all" },
        ...taskFields?.map((item) => ({ label: item, value: item })),
      ];
      return _options;
    }
  };
  const removeItem = (id) => {
    setFields((p) => p?.filter((value) => value?.id !== id));
  };

  const handleClose = () => {
    form.reset();
    fieldForm.reset();

    form.clearErrors();
    fieldForm.clearErrors();

    setMonthValue([]);

    setError("");
    setTypeError("");
    setFields([]);
    setOptions([]);
    setNoteTypeOptions([]);
    setTaskTypeOptions([]);
    setFilteredOptions([]);
    setGeneralSelect("");

    onclose();
  };

  return (
    <Modal
      className={classes.mymodal}
      opened={opened}
      size={900}
      onClose={handleClose}
      shadow="md"
      overlayProps={{ blur: 3 }}
      radius={"lg"}
      zIndex={1000}
      title={
        <Group style={{ zIndex: -1 }}>
          <img src="/assets/images/addfac.png" alt="" />
          <Text ff={"Inter"} fw={600} fz={22}>
            {title}
          </Text>
        </Group>
      }
      centered
    >
      <form>
        <Select
          id="select-type"
          label="Select Type"
          placeholder="Select Task or Note"
          data={[
            { value: "task", label: "Task" },
            { value: "note", label: "Note" },
            { value: "mar", label: "MAR" },
          ]}
          size="md"
          w={{ base: "50%" }}
          value={generalSelect}
          onChange={(value) => {
            setGeneralSelect(value);
            setTypeError("");
          }}
          error={typeError}
        />
        <TextInput
          id="report-name"
          label="Report Name*"
          pt={20}
          size="md"
          icon={<img src="/assets/images/home2.png" alt="home" />}
          placeholder="Ex: Newman Audit"
          {...form.getInputProps("name")}
          disabled={edit ? true : false}
        />
        {/* client list for mar report */}
        {generalSelect === "mar" && patients?.patients?.length && (
          <Select
            label="Client"
            placeholder="Select Client"
            data={patients?.patients?.map((patient) => {
              return { value: patient?.id, label: patient?.name };
            })}
            size="md"
            w={{ base: "100%" }}
            value={selectedClient}
            onChange={(value) => {
              setSelectedClient(value);
              setTypeError("");
            }}
            error={typeError}
          />
        )}

        <SimpleGrid
          cols={generalSelect === "mar" ? 1 : 2}
          m={"auto"}
          mt={10}
          mb={10}
        >
          {generalSelect === "mar" ? (
            <MonthPickerInput
              type="multiple"
              size="md"
              label="Select Months"
              placeholder="Select Months"
              value={monthValue}
              onChange={setMonthValue}
            />
          ) : (
            <DatePickerInput
              type="range"
              size="md"
              label="Start/End Date"
              placeholder="Pick dates range"
              onChange={(dates) => {
                if (dates?.length > 1) {
                  form.setFieldValue("startDate", dates?.[0]);
                  form.setFieldValue("endDate", dates?.[1]);
                }
              }}
            />
          )}

          {/* <DateInput
            label="Start Date*"
            size="md"
            icon={<img src="/assets/images/location.png" alt="address" />}
            placeholder="Select Date"
            {...form.getInputProps("startDate")}
          />
          <DateInput
            label="End Date*"
            size="md"
            icon={<img src="/assets/images/location.png" alt="address" />}
            placeholder="Select Date"
            minDate={form.getInputProps("startDate")}
            {...form.getInputProps("endDate")}
          /> */}
        </SimpleGrid>
      </form>

      {generalSelect !== "mar" && (
        <>
          {fields?.map((val, index) => (
            <Box cols={2} className={classes.reportInputFields}>
              <Group grow align="flex-start">
                <TextInput
                  disabled
                  size="md"
                  label="Number"
                  value={index + 1}
                  sx={{ marginTop: 20 }}
                />
                <Select
                  disabled
                  size="md"
                  searchable
                  value={val?.source}
                  data={filteredOptions}
                  sx={{ marginTop: 20 }}
                  label="Record Source*"
                  placeholder="Select Source"
                  icon={<img src="/assets/images/location.png" alt="address" />}
                />
                <MultiSelect
                  disabled
                  size="md"
                  searchable
                  label="Fields*"
                  value={val?.fields}
                  sx={{ marginTop: 20 }}
                  placeholder="Select Fields"
                  data={getFieldOptions(val?.source)}
                  icon={<img src="/assets/images/location.png" alt="address" />}
                />
              </Group>
              <Group sx={{ marginTop: 40, marginLeft: 24 }}>
                <ActionIcon
                  size={"xl"}
                  color="red"
                  variant="light"
                  onClick={() => removeItem(val?.id)}
                >
                  <IconTrash />
                </ActionIcon>
              </Group>
            </Box>
          ))}
          <form
            onSubmit={fieldForm?.onSubmit((values) => onPartialSave(values))}
            className={classes.form}
          >
            <Box cols={2} className={classes.reportInputFields}>
              <Group>
                <Select
                  searchable
                  label="Record Source*"
                  data={filteredOptions}
                  size="md"
                  icon={<img src="/assets/images/location.png" alt="address" />}
                  placeholder="Select Data"
                  {...fieldForm?.getInputProps("source")}
                  onChange={(value) => {
                    handleSourceChange(value);
                    fieldForm?.setFieldValue("source", value);
                  }}
                  mt={20}
                />
                <MultiSelect
                  searchable
                  label="Fields*"
                  size="md"
                  data={options}
                  icon={<img src="/assets/images/location.png" alt="address" />}
                  sx={{ marginTop: 20, minWidth: "300px" }}
                  placeholder="Select Data"
                  {...fieldForm?.getInputProps("fields")}
                />
              </Group>

              <Group sx={{ marginTop: 42, marginLeft: 24 }}>
                <ActionIcon
                  type="submit"
                  size={"xl"}
                  ref={buttonRef}
                  color="yellow"
                  variant="light"
                >
                  <IconPlus />
                </ActionIcon>
              </Group>
            </Box>
            {error != "" ? (
              <p style={{ color: "red", margin: 0, padding: 0 }}>{error}</p>
            ) : (
              ""
            )}
          </form>
        </>
      )}

      <SimpleGrid cols={2} mt={generalSelect === "mar" ? 50 : 0}>
        <Button
          color="yellow"
          sx={{ marginLeft: "auto", width: 122, fontFamily: "Inter" }}
          onClick={onclose}
        >
          Cancel
        </Button>
        <Button
          color="yellow"
          sx={{ marginRight: "auto", width: 122, fontFamily: "Inter" }}
          onClick={onSave}
          id="save-report"
        >
          Save
        </Button>
      </SimpleGrid>
    </Modal>
  );
};

export default ReportModal;
