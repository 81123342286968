import { Box, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import moment from "moment";
import MarDetailModal from "../MarDetailModal";
import { getColor } from "../../pages/Home/Medication";
import MedicationModal from "../MedicationModal";
import { useRef, useState } from "react";
import PRNMedicationModal from "../PRNMedicationModal";
import { useSelector } from "react-redux";
import MarMedicationModal from "../MarDetailModal/markMedication";
import { MdOutlineNoteAlt } from "react-icons/md";

const useStyles = createStyles((theme) => ({
  monthCardBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    padding: "10px",
    height: "190px",
    borderBottom: "1px solid rgba(90,90,90,0.1)",
    "& p": {
      margin: 0,
    },
  },
  dateRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& > div": {
      flex: 1,
      display: "flex",
      justifyContent: "center",
    },
    "& p": {
      color: "#5C5C5C",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "uppercase",
    },
  },
  generalRow: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    "& .colorBox": {
      width: "13px",
      height: "13px",
    },
    "& .medName": {
      flex: 1,
      fontSize: "14px",
      color: "#A8A8A8",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",

      display: "inline-block",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .medDose": {
      fontSize: "12px",
      color: "#A8A8A8",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "normal",
      width: "43px",

      display: "inline-block",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .medTime": {
      fontSize: "12px",
      color: "#5C5C5C",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "normal",
      width: "32px",
    },
    "& .iconBox": {
      fontSize: "12px",
      width: "18px",
      "& img": {
        marginBottom: "-2px",
      },
    },
  },
  missedRow: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    borderRadius: "5px",
    background: "rgba(255, 0, 0, 0.15)",
    padding: "0px 0px 0px 5px",
    "& .medName": {
      flex: 1,
      fontSize: "14px",
      color: "#F00",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",

      display: "inline-block",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .medDose": {
      fontSize: "12px",
      color: "#F00",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "normal",
      width: "43px",

      display: "inline-block",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .medTime": {
      fontSize: "12px",
      color: "#F00",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "normal",
      width: "32px",
    },
    "& .iconBox": {
      fontSize: "12px",
      width: "18px",
      // height: "13px",
      "& img": {
        marginBottom: "-3px",
      },
    },
  },
  prnRow: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    borderRadius: "5px",
    background: "rgba(136, 0, 128, 0.15)",
    padding: "0px 0px 0px 5px",
    "& .medName": {
      flex: 1,
      fontSize: "14px",
      color: "#880080",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",

      display: "inline-block",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .medDose": {
      fontSize: "12px",
      color: "#880080",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "normal",
      width: "43px",

      display: "inline-block",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& .medTime": {
      fontSize: "12px",
      color: "#880080",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "normal",
      width: "32px",
    },
    "& .iconBox": {
      fontSize: "12px",
      width: "18px",
      "& img": {
        marginBottom: "-3px",
      },
    },
  },
  moreRow: {
    display: "flex",
    justifyContent: "center",
    "& p": {
      color: "#5C5C5C",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      cursor: "pointer",
    },
  },
}));

const MonthCard = ({ data, date, calendar }) => {
  // console.log("🚀 ~ MonthCard ~ data:", data)
  const { classes } = useStyles();
  const { selectedClient } = useSelector((state) => state.mar);
  const [
    checkboxModalOpened,
    { open: openCheckboxModal, close: closeCheckboxModal },
  ] = useDisclosure(false);

  const [opened, { open, close }] = useDisclosure(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [prnShow, setPRNShow] = useState(false);
  const [isNew, setIsNew] = useState(true);

  const prnRef = useRef();

  const compareDate = (date, medicationType, upcoming) => {
    var time1 = moment(date).startOf("day");
    var today = moment().startOf("day");
    if (
      calendar !== "weekly" &&
      calendar !== "monthly" &&
      medicationType === upcoming
    ) {
      return -1;
    } else if (time1.isSame(today)) {
      return 0;
    } else if (time1.isAfter(today)) {
      return 1;
    } else {
      return -1;
    }
  };

  const openPRNView = (data) => {
    setIsNew(false);
    prnRef?.current?.openView(data);
    setPRNShow(true);
  };
  const prnClose = () => {
    setSelectedRow(null);
    setPRNShow(false);
  };

  return (
    <>
      <div className={classes.monthCardBox}>
        <div className={classes.dateRow}>
          <Box>
            <p>{moment(date).format("D")}</p>
          </Box>
          <Box>
            {moment(date).format("D") == 1 && (
              <div>
                <p>{moment(date).format("MMM")}</p>
              </div>
            )}
          </Box>
          {data && data?.length > 0 && (
            <Box style={{ cursor: "pointer", justifyContent: "end" }}>
              <MdOutlineNoteAlt
                color={"#FFBA45"}
                size={20}
                onClick={openCheckboxModal}
              />
              <MarMedicationModal
                data={data}
                calendar={calendar}
                close={closeCheckboxModal}
                opened={checkboxModalOpened}
                futureDate={compareDate(date)}
                date={moment(date).format("MMMM DD, YYYY")}
              />
            </Box>
          )}
        </div>
        {data
          ?.sort((a, b) => {
            const timeA =
              a?.medicationType !== "PRN"
                ? moment(a?.timeSlot, "hh:mm A").format("HH:mm")
                : moment(a?.createdAt).format("HH:mm");
            const timeB =
              b?.medicationType !== "PRN"
                ? moment(b?.timeSlot, "hh:mm A").format("HH:mm")
                : moment(b?.createdAt).format("HH:mm");

            return timeA.localeCompare(timeB); // Sort times in ascending order
          })
          ?.slice(0, 5)
          ?.map((med, ind) => {
            const _medicationType = med?.medicationType;
            const _outOfMedication = "Out of Medication";
            const _upcoming = "upcoming";

            return (
              <>
                <div
                  style={{
                    cursor: "pointer",
                    // compareDate(med?.date) === 1 ? "not-allowed" : "pointer",
                  }}
                  key={ind}
                  className={`${
                    _medicationType === "Missed"
                      ? classes.missedRow
                      : _medicationType === "PRN"
                      ? classes.prnRow
                      : classes.generalRow
                  }`}
                  onClick={() => {
                    setSelectedRow(med);
                    if (med?.form3?.medicationType === "PRN") {
                      return openPRNView(med);
                    }

                    // if (compareDate(med?.date) === 1) {
                    //   setIsViewOpen(false);
                    // } else {
                    //   _medicationType === _upcoming ||
                    //   _medicationType === "Missed"
                    //     ? setIsViewOpen(false)
                    //     : setIsViewOpen(true);
                    //   open();
                    // }
                    _medicationType === _upcoming ||
                    _medicationType === "Missed"
                      ? setIsViewOpen(false)
                      : setIsViewOpen(true);
                    open();
                  }}
                >
                  {_medicationType !== "Missed" &&
                    _medicationType !== "PRN" && (
                      <div
                        className="colorBox"
                        style={{
                          backgroundColor: getColor(_medicationType)?.dark,
                        }}
                      />
                    )}
                  <p className="medName">{med?.form3?.medicationName}</p>
                  <p className="medDose">
                    {med?.form3?.dosage} {med?.form3?.unit}
                  </p>
                  <p className="medTime">
                    {_medicationType !== "PRN"
                      ? moment(med?.timeSlot, "hh:mm A")?.format("HH:mm")
                      : moment(med?.createdAt).format("HH:mm")}
                  </p>
                  <div className="iconBox">
                    {_medicationType === "Taken" && (
                      <img
                        src={"/assets/images/done-icon.png"}
                        style={{ width: "15px", height: "15px" }}
                        alt="done-icon"
                      />
                    )}
                    {_medicationType === "Missed" && (
                      <img
                        src={"/assets/images/warning.png"}
                        style={{ width: "15px", height: "15px" }}
                        alt="warning-icon"
                      />
                    )}
                    {_medicationType === "Unable to Take" && (
                      <img
                        src={"/assets/images/do-not-disturb.png"}
                        style={{ width: "15px", height: "15px" }}
                        alt="unavailable-icon"
                      />
                    )}
                    {_medicationType === "Refused" && (
                      <img
                        src={"/assets/images/unavailable.png"}
                        style={{ width: "15px", height: "15px" }}
                        alt="unavailable-icon"
                      />
                    )}
                    {_medicationType === "Out of Medication" && (
                      <img
                        src={"/assets/images/close-white.png"}
                        style={{ width: "15px", height: "15px" }}
                        alt="close-icon"
                      />
                    )}
                  </div>
                </div>
              </>
            );
          })}
        {data?.length > 5 && (
          <MarDetailModal
            data={data}
            calendar={calendar}
            date={moment(date).format("MMMM DD, YYYY")}
          />
        )}
      </div>
      <PRNMedicationModal
        ref={prnRef}
        isNew={isNew}
        opened={prnShow}
        onclose={prnClose}
        data={selectedClient}
      />
      <MedicationModal
        onclose={() => {
          close();
          setIsViewOpen(false);
          setSelectedRow(null);
        }}
        date={date}
        opened={opened}
        data={selectedRow}
        isViewOpen={isViewOpen}
        setIsViewOpen={setIsViewOpen}
        futureDate={compareDate(selectedRow?.date)}
      />
    </>
  );
};

export default MonthCard;
