import { createStyles, em, rem } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    // minHeight: "100vh",
    // background: "red",
    margin: '0',
    "@media (max-width :40em)": {
      maxWidth: '100%',
      display: 'block',
    },
  },
  left_pane: {
    minHeight: "100vh",
    width: "50%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    padding: "0px",
    justifyContent: 'space-between',
    "@media (max-width :992px)": {
      display: 'none'
    },
  },
  right_pane: {
    //  background: 'red',
     borderTopLeftRadius: "40px",
     borderBottomLeftRadius: "40px",
     // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 1.25)",
     boxShadow:
       "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
     display: "flex",
     alignItems: "center",
     justifyContent: "center",
     "@media (max-width :992px)": {
       height: "100vh",
       maxWidth: "100%",
       borderTopLeftRadius: "unset",
       borderBottomLeftRadius: "unset",
       padding: "3rem 1rem",
     },
    //  padding: 0,
  },
  leftSideTitle: {
    " p": {
      fontFamily: "Inter",
      fontSize: "40px",
      fontWeight: "500",
      lineHeight: "46px",
      textAlign: "left",
      color: "#000",
      "@media (max-width : 800px)": {
        fontSize: "30px",
      },
    },
  },
  titleText:{
    fontFamily: "Inter",
    fontSize: "30px !important",
    fontWeight: "500",
    lineHeight: "45px !important",
    textAlign: "left",
    color: "#000",
    margin:"10px 0"
  },
  bgImg: {
    backgroundImage:`url('assets/images/loginImg.png')`,
    backgroundRepeat:"no-repeat",
    backgroundPosition:"left bottom",
    backgroundSize:"contain",
    backgroundSize: "95% 55%"
  },
  welcome: {
    maxWidth: "80%",
    marginLeft: "2rem",
    marginTop: "2rem",
  },
  logo: {
    width: "131px !important",
    height: "48px !important",
    "@media (max-width: 1440px)": {
      width: "110px !important",
      height: "auto !important"
    },
    "@media (max-width:768px)": {
      marginLeft: 30
    },
    "@media (max-width:330px)": {
      marginLeft: 10
    }
  },
  logo_img2: {
    width: "40%",
    aspectRatio: 3.7,
    "@media (max-width :80em)": {
      width: "30%",
      height: '30%',
    },
    "@media (max-width :40em)": {
      display: 'inline',
    },
    // display: "none",
  },
  authImg: {
    maxWidth: "100%",
    height: "auto",
  },
  login_btn: {
    "@media (max-width :48em)": {
      width: "100%",
    },
    "@media (max-width :800px)": {
      width: "100%",
    },
    background: "#000 ",
    marginTop: "2rem",
    width: "100%",
    height: rem(50),
    fontFamily: "Inter",
    fontSize: "22px",
    borderRadius: "4px",
    color: "#FFF",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    ":hover": {
      background: "#000",
      opacity: 0.8,
    },
  },
  glass_man: {
    marginTop: "9.4%",
    marginLeft: "8.95%",
    width: "59.2%",
    aspectRatio: 0.88,
  },
  credential: {
    "@media (max-width :80em)": {
      position: "absolute",
      top: "50%",
    },
    position: "absolute",
    top: "30%",
    left: "26.09%",
    width: "19%",
    aspectRatio: "0.8693",
  },
  myform: {
    width: "80%",
    margin: "auto",
    // padding: '3rem 0'
    // position: "relative",
    // top: "10%",
  },
  rememberbox: {
    marginTop: rem(15),
    marginBottom: rem(15),
  },
  forgot: {
    marginLeft: "auto",
    color: "#FFBA45",
    textDecoration: "underline",
  },
  signup: {
    color: "#FFBA45",
    textDecoration: "underline",
  },
  toptitle: {
    position: "relative",
    top: "5.5%",
    right: "5.5%",
    textAlign: "right",
    color: '#A8A8A8',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal'
  },
  title: {
    marginTop: rem(12),
    marginBottom: rem(12),
    fontSize: rem(24),
    fontWeight: 700,
    fontFamily: "Inter",
  },
  login_btn_google: {
    "@media (max-width :48em)": {
      width: "100%",
    },
    "@media (max-width :80em)": {
      width: "100%",
      fontSize: '1rem'
    },
    width: rem(296),
    height: rem(50),
    fontSize: rem(22),
    marginTop: rem(20),
    color: "grey",
  },
  codeVerification: {
    "@media (max-width :48em)": {
      width: "100%",
    },
    marginTop: "2rem",
    width: rem(296),
    height: rem(50),
    textAlign: 'center',
    fontSize: rem(22),
    textDecoration: 'none',
    color: '#FFFFFF',
  },
}));

export default useStyles;
