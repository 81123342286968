import React from "react";

import {
  ActionIcon,
  Box,
  Button,
  Group,
  Loader,
  Popover,
  ScrollArea,
  Switch,
  Table,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import useStyles from "./style";

import { useSelector } from "react-redux";
import UserDetail from "../MarTab/UserDetail";
import CustomPagination from "../../../../components/Pagination/CustomPagination";
import TableLoader from "../../../../components/TableLoader";
import NotFound from "../NotFound";
import {
  IconDownload,
  IconEdit,
  IconFile,
  IconTrash,
} from "@tabler/icons-react";
import { decrypt, formatDate } from "../../../../utils";
import HideClient from "../ClientHide";
import { IconEye } from "@tabler/icons-react";
import ClientColumn from "../ClientColumn";
import DiscontinueModal from "../../../../components/DiscontinueModal";
import { useDisclosure } from "@mantine/hooks";
import { deleteMedication } from "../../../../services/patient";
import { MdFormatColorFill } from "react-icons/md";
import FillMedicationModal from "../../../../components/FillMedicationModal";

const MedicationListTab = ({
  userId,
  clientId,
  clientDetails,
  onEditClick,
}) => {
  // console.log("client details in medication list", clientDetails);
  const { classes } = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsCopy, setRowsCopy] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(true);
  const [selectDiscontinue, setSelectDiscontinue] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);

  const { selectedClient } = useSelector((state) => state.mar);
  const { loading } = useSelector((state) => state.patient);

  useEffect(() => {
    if (clientDetails) {
      if (clientDetails?.form3?.length > 0) {
        setRows(clientDetails?.form3);
        setRowsCopy(clientDetails?.form3);
        return () => {
          setRows([]);
          setRowsCopy([]);
        };
      }
    } else {
      if (selectedClient && selectedClient?.form3?.length > 0) {
        setRows(selectedClient?.form3);
        setRowsCopy(selectedClient?.form3);
        return () => {
          setRows([]);
          setRowsCopy([]);
        };
      }
    }
  }, [selectedClient, clientDetails]);

  useEffect(() => {
    const start = (currentPage - 1) * 10;
    const end = start + 10;
    setRows(rowsCopy?.slice(start, end));
  }, [currentPage, rowsCopy]);

  // console.log("medication tab =>", rows);

  return (
    <div className={classes.content}>
      {/* <div className={classes.detailBar}>
        <div className={classes.tabContainer}></div>
        <div className={classes.userDetail}>
          <div className="detail_box">
            <div
              className="image_box"
              style={{
                backgroundImage: `url(${decrypt(selectedClient?.imageurl)})`,
              }}
              // style={{
              //   backgroundImage: `url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREoRGyXmHy_6aIgXYqWHdOT3KjfmnuSyxypw&s)`,
              // }}
            />
            <h3>{selectedClient?.name}</h3>
            <p>{selectedClient?.id?.slice(0, 6)?.toUpperCase()}</p>
          </div>
        </div>
      </div> */}
      {!userId ? (
        <HideClient show={show} setShow={setShow} />
      ) : (
        <Box m={20}></Box>
      )}

      {/* <HideClient show={show} setShow={setShow} /> */}

      <div className={classes.scheduleDetail}>
        {show ? (
          <>
            {!userId && <UserDetail isClient={false} />}
            {/* {userId && <UserDetail isClient={true} />} */}
          </>
        ) : !loading ? (
          <ClientColumn />
        ) : (
          <></>
        )}

        <div
          style={{ width: userId ? "100%" : "calc(100% - 270px)" }}
          // className={
          //   (userId && !userId) || !show
          //     ? classes.scheduleDetailRight100
          //     : classes.scheduleDetailRight
          // }
        >
          <div className={classes.wrapper}>
            {loading ? (
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "100px 0 0 0",
                }}
              >
                <Loader color="yellow" size={"md"} />
              </Box>
            ) : (
              <div className={classes.mainContainer}>
                <ScrollArea
                  onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
                  sx={{ minHeight: "350px" }}
                >
                  <Table
                    withColumnBorders
                    withBorder
                    highlightOnHover
                    enablestickyheader
                    sx={{ fontFamily: "Inter", minWidth: "2000px" }}
                    fontSize={"sm"}
                    verticalSpacing={"md"}
                  >
                    <TableHeader scrolled={scrolled} />
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={1000}>
                            <TableLoader />
                          </td>
                        </tr>
                      ) : rows?.length > 0 ? (
                        <TableRow
                          data={rows}
                          open={open}
                          clientId={clientId}
                          onEditClick={onEditClick}
                          setSelectDiscontinue={setSelectDiscontinue}
                        />
                      ) : (
                        <NotFound />
                      )}
                    </tbody>
                  </Table>
                  <CustomPagination
                    data={rowsCopy?.length}
                    itemsPerPage={10}
                    currentPage={currentPage}
                    handlePageChange={(page) => {
                      setCurrentPage(page);
                    }}
                  />
                </ScrollArea>
                <DiscontinueModal
                  opened={opened}
                  onclose={() => close()}
                  data={selectDiscontinue}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const TableHeader = ({ scrolled }) => {
  const { classes, cx } = useStyles();
  return (
    <thead
      className={cx(classes.header, {
        [classes.scrolled]: scrolled,
      })}
    >
      <tr>
        <th className={classes.stickyColumn} style={{ minWidth: "80px" }}>
          Medication Name
        </th>
        <th style={{ minWidth: "80px" }}>Prescription</th>
        <th style={{ minWidth: "80px" }}>Dosage Amount</th>
        <th style={{ minWidth: "80px" }}>Dosage Unit</th>
        <th style={{ minWidth: "80px" }}>Frequency</th>
        <th style={{ minWidth: "80px" }}>Medication Type</th>
        <th style={{ minWidth: "80px" }}>Start Date</th>
        <th style={{ minWidth: "80px" }}>Expiration Date</th>
        <th style={{ minWidth: "80px" }}>Narcotic</th>
        <th style={{ minWidth: "80px" }}>Medication Route </th>
        <th style={{ minWidth: "80px" }}>Dosage Form</th>
        <th style={{ minWidth: "80px" }}>Current Count</th>
        <th style={{ minWidth: "80px" }}>Total Quantity</th>
        <th style={{ minWidth: "80px" }}>Discontinued Date</th>
        <th style={{ minWidth: "80px" }}>Active</th>
        <th style={{ minWidth: "80px" }}>Actions</th>
      </tr>
    </thead>
  );
};

const TableRow = ({
  data,
  onEditClick,
  setSelectDiscontinue,
  open,
  clientId,
}) => {
  const { classes } = useStyles();
  const [statusId, setStatusId] = useState("");

  const _handleMedicationStatus = async (id) => {
    setStatusId(id);
    // await updateMarStatus({ id }); // api not created yet
    setStatusId("");
  };

  const downloadFile = async (url) => {
    try {
      if (url) {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = url?.split("/")?.pop();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      } else {
        console.error("No url found");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const DeleteComponent = ({ id, name, onPressOk }) => {
    const { addPatientLoading } = useSelector((state) => state?.patient);
    const [deleteModal, setDeleteModal] = useState(false);

    return (
      <Popover
        opened={deleteModal}
        width={300}
        position="top"
        withArrow
        shadow="md"
      >
        <Popover.Target>
          <ActionIcon
            color="red"
            variant="light"
            // disabled={userType === "staff"}
            onClick={() => setDeleteModal(true)}
            title="Delete"
          >
            <IconTrash id="icon-trash" />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Text size="sm">
            Deleting Medication will result in also deleting the MAR
            past,present and future entries. Please confirm
          </Text>

          <Group grow mt={10}>
            <Button
              onClick={() => setDeleteModal(false)}
              size={"xs"}
              color="yellow"
            >
              Cancel
            </Button>
            <Button
              id="confirm-delete"
              loading={addPatientLoading}
              size={"xs"}
              color={"red"}
              onClick={() => onPressOk(id, name)}
            >
              Delete
            </Button>
          </Group>
        </Popover.Dropdown>
      </Popover>
    );
  };

  const remove = async (medicationId, name) => {
    await deleteMedication({
      clientId,
      medicationId,
      medicationName: name,
    });
    // const data = await getSinglePatients(id);
    // setClientDetails(data);
  };

  return (
    <>
      {data?.map((row, i) => {
        {/* console.log("🚀 ~ {data?.map ~ data:", data); */}
        const bg = i % 2 === 0;

        const isDiscontinued = "discontinued" in row;

        return (
          <tr
            key={i}
            className={
              isDiscontinued ? classes.bgDis : bg ? classes.bg2 : classes.bg1
            }
          >
            <td className={classes.stickyColumn}>
              {row?.medicationName || "-"}
            </td>
            <td style={{ color: row?.isPrescribed ? "#F00" : "black" }}>
              {row?.isPrescribed ? "Yes" : "No"}
            </td>
            <td>{row?.dosage || "-"}</td>
            <td>{row?.unit || "-"}</td>
            <td>{row?.frequency || "-"}</td>
            <td>{row?.medicationType || "-"}</td>
            <td>
              {row?.medicationDate ? formatDate(row?.medicationDate) : "-"}
            </td>
            <td>
              {row?.hasExpiryDate === false
                ? "-"
                : row?.medicationEndDate
                ? formatDate(row?.medicationEndDate)
                : ""}
            </td>
            <td style={{ color: row?.isNarcotic ? "#F00" : "black" }}>
              {row?.isNarcotic || "No"}
            </td>
            <td>{row?.ingestionMode || "-"}</td>
            <td>{row?.dosageForm || "-"}</td>
            <td>{row?.medicineRemaining || "-"}</td>
            <td>{row?.totalMedicine || "-"}</td>
            <td>
              {/* {"-"} */}
              {row?.discontinuedOn ? formatDate(row?.discontinuedOn) : "-"}
            </td>
            <td>
              {row?.id === statusId ? (
                <Loader color="black" size="xs" />
              ) : (
                <Switch
                  size="md"
                  color="yellow"
                  disabled={isDiscontinued ? row?.discontinued : false}
                  checked={isDiscontinued ? !row?.discontinued : true}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setSelectDiscontinue(row);
                      open();
                    }
                  }}
                />
              )}
            </td>
            <td>
              <div style={{ display: "flex", gap: "10px" }}>
                {/* preview  */}
                <ActionIcon
                  id="preview"
                  color="yellow"
                  variant="light"
                  onClick={() => {
                    onEditClick(row, true);
                  }}
                  title="Preview"
                >
                  <IconEye />
                </ActionIcon>
                {!isDiscontinued && (
                  <ActionIcon
                    id="edit"
                    color="yellow"
                    variant="light"
                    onClick={(e) => {
                      onEditClick(row, false);
                    }}
                    title="Edit"
                  >
                    <IconEdit />
                  </ActionIcon>
                )}

                {!isDiscontinued && !row?.discontinueNote && (
                  <FillMedicationModal data={row} clientId={clientId} />
                )}
                {isDiscontinued && row?.discontinueNote && (
                  <ActionIcon
                    color="yellow"
                    variant="light"
                    onClick={(e) => {
                      downloadFile(row?.discontinueNote);
                      // window.open(row?.discontinueNote, "_blank");
                    }}
                    title="Download File"
                  >
                    <IconFile />
                  </ActionIcon>
                )}
                {row?.doctorNote && (
                  <ActionIcon
                    color="yellow"
                    variant="light"
                    onClick={(e) => {
                      downloadFile(row?.doctorNote);
                    }}
                    title="Doctor's Note"
                  >
                    <IconDownload />
                  </ActionIcon>
                )}
                <DeleteComponent
                  id={row?.id}
                  name={row?.medicationName}
                  onPressOk={remove}
                />
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default MedicationListTab;
