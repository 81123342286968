import { https } from "../http";
import store from "../store";
import { setAddMarLoading, setLoading, setMar, setMedicationLogs } from "../store/reducers/mar";

import { showError } from "../utils/index";
import { getAllPatientsModal } from "./patient";

//post

const createMar = async (data, callback) => {
  try {
    store.dispatch(setAddMarLoading(true));
    let response = await https().post("/mar/create", data);
    if (response) {
      //getNotes(false);
      callback?.();
      return true;
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    if (_error?.includes("duplicate") && _error?.includes("name"))
      showError("This name is already taken");
    else showError(_error, error?.response?.status);
    store.dispatch(setAddMarLoading(false));
    // showError(error?.response?.data?.message, error?.response?.status)
    return false;
  }
};
const recordResponse = async (data, callback) => {
  try {
    store.dispatch(setAddMarLoading(true));
    let response = await https().post("/mar/updatePrnFeedback", data);

    if (response?.status === 200) {
      callback?.();
      getMar();
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    showError(_error, error?.response?.status);
    // showError(error?.response?.data?.message, error?.response?.status)
    return false;
  }
};

const updateMar = async (data, callback) => {
  try {
    store.dispatch(setAddMarLoading(true));
    let response = await https().post("/mar/update", data);

    if (response?.status === 200) {
      store.dispatch(setAddMarLoading(false));
      callback?.();
    }
  } catch (error) {
    let _error = error?.response?.data?.message;
    if (_error?.includes("duplicate") && _error?.includes("name"))
      showError("This name is already taken");
    else showError(_error, error?.response?.status);
    store.dispatch(setAddMarLoading(false));
    // showError(error?.response?.data?.message, error?.response?.status)
    return false;
  }
};

//get

const getMar = async (
  page = localStorage.getItem("calendar_date"),
  Userid,
  showLoading = true,
  dates,
  logType,
  logSearch
) => {
  const selectedClient = store?.getState()?.mar?.selectedClient?.id;
  try {
    store.dispatch(
      setMar({
        newMar: [],
        count: 0,
      })
    );
    if (showLoading) store.dispatch(setLoading(true));

    //let response = await https().get(`/mar/get?page=${page}&?client=${Userid}`);
    //   let response = await https().get(`/mar/get?client=${Userid}&&?page=${page}`);
    let response = await https().get(
      `/mar/get?client=${Userid ? Userid : selectedClient
      }&page=${page}&filterDateStart=${dates?.[0]}&filterDateEnd=${dates?.[1]
      }&filterType=${logType}&logSearch=${logSearch}`
    );

    if (response?.status === 200) {
      let save = response?.data?.data;
      store.dispatch(setMar(save));
    }
  } catch (error) {
    // console.log("🚀 ~ error:", error);
    showError(error?.response?.data?.message, error?.response?.status);
  } finally {
    store.dispatch(setLoading(false));
  }
};

const getMedicationLogs = async (
  page,
  Userid,
  showLoading = true,
  logSearch
) => {
  const selectedClient = store?.getState()?.mar?.selectedClient?.id;
  try {
    store.dispatch(
      setMedicationLogs({
        newMar: [],
        count: 0,
      })
    );
    if (showLoading) store.dispatch(setLoading(true));
    let response = await https().get(
      `/mar/getMedicationLogs?client=${Userid ? Userid : selectedClient
      }&page=${page}&logSearch=${logSearch !== "" ? logSearch : "undefined"}`
    );

    if (response?.status === 200) {
      let save = response?.data?.data;
      store.dispatch(setMedicationLogs(save));
    }
  } catch (error) {
    // console.log("🚀 ~ error:", error);
    showError(error?.response?.data?.message, error?.response?.status);
  } finally {
    store.dispatch(setLoading(false));
  }
};

const getAddedTimeSlots = async (payload) => {
  try {
    let response = await https().get(
      `/mar/getClientTimeSlots?client=${payload.client}&medicationId=${payload.medicationId}`
    );

    if (response?.status === 200) {
      // console.log("response", response?.data);
      return response?.data;
    }
  } catch (error) {
    store.dispatch(setLoading(false));
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export const deleteMar = async (payload) => {
  try {
    let response = await https().post(`/mar/delete`, payload);

    if (response?.status === 200) {
      getMar();
      return response?.data;
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export const updateMedicationAdministration = async (payload) => {
  try {
    let response = await https().post(`/mar/update-administered-mar`, payload);
    if (response) {
      await getMar();
      return response;
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export const updateMarStatus = async (payload) => {
  try {
    let response = await https().post(`/mar/update-status`, payload);
    if (response) {
      await getMar(localStorage.getItem("calendar_date"), null, false);
      return response;
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export const updateDiscontinue = async (payload, clientId) => {
  try {
    let response = await https().post(`/client/discontinuemedication`, payload);
    if (response) {
      await getAllPatientsModal(true, clientId);
      return response;
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export const updateFillMedication = async (payload) => {
  try {
    let response = await https().post(`/client/updateMedicineCount`, payload);
    if (response) {
      getAllPatientsModal(true, payload?.clientId);
      // await getMar();
      return response;
    }
  } catch (error) {
    showError(error?.response?.data?.message, error?.response?.status);
  }
};

export { createMar, getMar, getAddedTimeSlots, updateMar, recordResponse,getMedicationLogs };
