import {
  ActionIcon,
  Button,
  Card,
  Center,
  Container,
  Flex,
  Group,
  Loader,
  SimpleGrid,
  Skeleton,
  Text,
  TextInput,
} from "@mantine/core";

import { usePlaidLink } from "react-plaid-link";

import { IconChevronLeft } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useStyles } from "./style";

import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { io } from "socket.io-client";
import swal from "sweetalert";
import { baseURL } from "../../http";
import {
  connectCard,
  deleteCustomer,
  exchangeToken,
  getConnectedAccounts,
  getPlaidLink,
} from "../../services/payment";
import { getProfile } from "../../services/profile";
import { createSubscription } from "../../services/subscription";
import { getCookie, removeCookie } from "../../utils";
import { pageInfoAnalytics } from "../../config/mixpanelConfig";
const PaymentInfo = () => {
  const [value, setValue] = useState("manual");

  // const socket = io(baseURL);
  const socket = io("https://staging-websocket.ogiso.designsdigitalstudio.xyz");
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const newConnect = queryParams.get("connect");
  const { profile } = useSelector((state) => state.profile);

  const { classes } = useStyles();
  const location = useLocation();
  const [sessionLink, setSessionLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentDeductionLoading, setPaymentDeductionLoading] = useState(false);
  const [paymentDeduction, setPaymentDeduction] = useState(false);
  let item = state?.plan;
  let string =
    newConnect == "true"
      ? "This account is currently being used."
      : `${item?.staff} Staff Members, ${item?.facility} Facility, ${item?.patient} Patients & ${item?.dailyUpdates} Daily Updates`;
  const [paymentDeductionFeedback, setPaymentDeductionFeedback] = useState("");
  const [instance, setInstance] = useState(null);
  const [connected, setConnected] = useState([]);
  const [connectingCard, setConnectingCard] = useState(false);

  // loading when fetching connected accounts
  const [connectedLoading, setConnectedLoading] = useState(true);

  // un comment to use delete account functionality.
  // const [deleteAccountLoading, setDeleteAccountLoading] = useState(false);

  const card = connected?.find((v) => v?.creditCard);

  const form = useForm({
    initialValues: {
      nameCard: "",
      cardNumber: "",
      cardExp: "",
      CVV: "",
    },
    validate: {
      nameCard: (value) => (value ? null : "Please add card name"),
      cardNumber: (value) =>
        value && value?.length <= 16 ? null : "Please add a valid card number",
      cardExp: (value) => (value ? null : "Select expiry date"),
      CVV: (value) => {
        const isValidLength =
          value && (value?.length === 3 || value?.length === 4);
        return isValidLength ? null : "Please add a valid number";
      },
    },
  });

  const config = {
    onSuccess: async (public_token, metadata) => {
      let bankData = await exchangeToken({ publicToken: public_token });
      if (bankData) {
        if (newConnect === "true") return navigate("/home/settings");
        getAllConnected();
      }
    },
    onExit: (err, metadata) => {
      console.log("err, metadata", err, metadata);
    },
    onEvent: (eventName, metadata) => {
      if (eventName.includes("EXIT")) {
        setLoading(false);
      }
    },
    token: sessionLink,
  };
  const { open, exit, ready } = usePlaidLink(config);

  useEffect(() => {
    if (getCookie("token") && profile) {
      socket.emit("join", profile?.user.id);
      setInstance(socket);
    }
  }, [getCookie("token")]);

  useEffect(() => {
    socket.on("reconnect", () => {
      if (profile?.user) {
        socket.emit("join", profile?.user?.id);
      }
    });

    socket.on("payment-update", (status) => {
      if (status === "payment succeeded") {
        setPaymentDeductionLoading(false);
        setPaymentDeduction(false);
        getProfile();
        if (!location.pathname.includes("home/settings"))
          swal("Subscription Completed!", {
            icon: "success",
            buttons: {
              // ok: "Proceed to Dashboard"
              ok: {
                text: "Proceed to Dashboard",
                value: "dashboard", // Assign a unique value to identify the button
              },
            },
          }).then((value) => {
            switch (value) {
              default:
                navigate("/home");
            }
          });
        else
          swal("Account changed Successfully", {
            icon: "success",
            buttons: {
              ok: "Ok",
            },
          });
      } else if (status?.code === "payment failed") {
        swal("Payment Failed", {
          icon: "error",
          text: `${status?.message}\nPlease ensure your card has enough balance.`,
          buttons: {
            ok: {
              text: "Go Back",
              value: "dashboard",
            },
          },
        }).then((value) => {
          switch (value) {
            case "dashboard":
              removeCookie("token");
              navigate("/");
            default:
              navigate("/home");
          }
        });
      } else {
        setPaymentDeductionLoading(false);
        setPaymentDeductionFeedback(status);
      }
    });
  }, []);

  useEffect(() => {
    getLink();
    getAllConnected();
    pageInfoAnalytics("Payment Page Viewed", "Payment Page");
  }, []);

  useEffect(() => {
    if (ready) {
      setLoading(false);
    }
  }, [ready, sessionLink]);

  useEffect(() => {
    if (form.getInputProps("cardNumber")?.value?.length > 16) {
      form.setFieldValue(
        "cardNumber",
        form.getInputProps("cardNumber")?.value?.slice(0, 16)
      );
    }
  }, [form.getInputProps("cardNumber")?.value]);

  useEffect(() => {
    if (form.getInputProps("CVV")?.value?.length > 4) {
      form.setFieldValue("CVV", form.getInputProps("CVV")?.value?.slice(0, 4));
    }
  }, [form.getInputProps("CVV")?.value]);

  const getAllConnected = async () => {
    let accounts = await getConnectedAccounts();

    setConnected(accounts);

    setConnectedLoading(false);
  };

  const getLink = async () => {
    let data = await getPlaidLink();
    setSessionLink(data?.link_token);
  };
  const onFinish = async (values) => {
    let obj = {
      ...values,
      packageId: state?.planId,
      stripePlanId: state?.stripePlan,
      email: profile?.user?.email,
      userId: profile?.user.id,
      businessId: profile?.business?.id,
    };
    setConnectingCard(true);
    connectCard(obj, (error, data) => {
      if (!error) {
        setConnectingCard(false);
        if (newConnect === "true") return navigate("/home/settings");
        getAllConnected();
      } else {
        setConnectingCard(false);
      }
    });
  };
  const onBack = () => {
    navigate("/selectprice");
  };

  const start = () => {
    open();
    setLoading(true);
  };
  const removeAccount = async (paymentMethod) => {
    if (!paymentMethod?.isActive) {
      // setDeleteAccountLoading(true);
      let response = await deleteCustomer(paymentMethod?.stripeCustomerId);
      if (response) {
        getAllConnected();
        // setDeleteAccountLoading(false);
        setPaymentLoading(false);
      }
    } else {
      swal({
        title: "Remove Account",
        text: "Cannot remove account, this account is being used.",
        icon: "warning",
      });
    }
  };

  const payNow = async (paymentMethod) => {
    setPaymentLoading(true);
    let obj = {
      packageId: state?.planId,
      stripePlanId: state?.stripePlan,
      email: profile?.user?.email,
      userId: profile?.user.id,
      businessId: profile?.business?.id,
      stripeCustomerId: paymentMethod?.stripeCustomerId,
    };
    const resp = await createSubscription(obj, null, "automatic");
    if (resp?.status === 200) {
      setPaymentLoading(false);
      setPaymentDeduction(true);
      setPaymentDeductionLoading(true);
    }
  };
  const names = {
    Personal: "Silver",
    Agency: "Gold",
    Trial: "Trial",
  };

  return (
    <div className="payment-page">
      <Group className={classes.back}>
        <ActionIcon
          variant="outline"
          size={"xl"}
          radius={"xl"}
          onClick={onBack}
        >
          <IconChevronLeft />
        </ActionIcon>
        <Text>Back</Text>
      </Group>

      <Container className={classes.centered} size={"sm"}>
        <div className={classes.logo}>
          <img width={"30%"} src="assets/images/logo2.png" alt="" />
        </div>
        <Text className={classes.title}>Payment Information</Text>
      </Container>

      <Container size={"sm"}>
        {/* <Radio.Group value={value} onChange={setValue} size="md" name="paytype">
          <SimpleGrid cols={2} spacing={10}>
            <Radio
              className={
                value === "automatic" ? classes.selected : classes.type
              }
              value="automatic"
              size="md"
              color="yellow"
              label={
                <>
                  <Text className={classes.font1}> Automatic</Text>{" "}
                  <Text className={classes.font2}>
                    Connect to bank with plaid
                  </Text>
                </>
              }
            />
            <Radio
              className={value === "manual" ? classes.selected : classes.type}
              size="md"
              color="yellow"
              value="manual"
              label={
                <>
                  <Text className={classes.font1}>Manual</Text>{" "}
                  <Text className={classes.font2}>
                    Enter card Details Manually
                  </Text>
                </>
              }
            />
          </SimpleGrid>
        </Radio.Group> */}
      </Container>

      {/* plaid link component */}
      {value === "automatic" && (
        <center style={{ marginTop: "50px" }}>
          <Flex
            mih={50}
            gap="md"
            justify="flex-start"
            // align="center"
            direction="row"
            wrap="wrap"
            className={classes.plaidComponent}
          >
            <img
              className={classes.connectBankImage}
              src="assets/images/bank.png"
              alt=""
            />
            <Flex
              direction="column"
              align={"flex-start"}
              className={classes.insideFlex}
            >
              {connectedLoading ? (
                <>
                  <Skeleton
                    height={50}
                    width={100}
                    radius="sm"
                    style={{ marginTop: "10px" }}
                  />
                  <Skeleton
                    height={50}
                    radius="sm"
                    style={{ marginTop: "10px" }}
                  />
                </>
              ) : connected?.filter((v) => !v?.creditCard)?.length ? (
                paymentDeduction ? (
                  <div>
                    <Flex direction="row" align={"center"} gap={10}>
                      {paymentDeductionLoading && (
                        <Loader color="yellow" size={"sm"} />
                      )}
                      <Text
                        my={10}
                        style={{
                          fontWeight: "600",
                          textAlign: "start",
                        }}
                        color={"yellow"}
                      >
                        {paymentDeductionFeedback
                          ? ""
                          : "Processing Payment ..."}
                      </Text>
                    </Flex>
                    <Text
                      my={10}
                      style={{
                        fontWeight: "600",
                        textAlign: "start",
                        opacity: 0.3,
                      }}
                    >
                      {paymentDeductionFeedback
                        ? paymentDeductionFeedback
                        : "Please wait we are tranfering funds..."}
                    </Text>
                  </div>
                ) : (
                  <div>
                    <Text
                      my={10}
                      style={{
                        fontWeight: "600",
                        textAlign: "start",
                      }}
                      color="green"
                    >
                      Connected Bank Account
                    </Text>

                    <p
                      style={{
                        textAlign: "start",
                        fontWeight: "bold",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {/* {names[state?.plan?.name]}{" "} */}
                      {state?.plan?.name}{" "}
                      {newConnect != "true" ? `($${state?.plan?.cost})` : ""}
                    </p>
                    <p
                      style={{
                        color: "#A8A8A8",
                        textAlign: "start",
                      }}
                    >
                      {string}
                    </p>

                    {newConnect != "true" &&
                      !connected?.find((v) => !v?.creditCard)?.isActive && (
                        <Flex direction="row" gap={20}>
                          <Button
                            color="yellow"
                            onClick={() =>
                              payNow(connected?.find((v) => !v?.creditCard))
                            }
                            loading={paymentLoading}
                          >
                            Subscribe Now
                          </Button>

                          {/* <Button
                            variant="outline"
                            color="yellow"
                            loading={deleteAccountLoading}
                            onClick={() =>
                              removeAccount(
                                connected?.find((v) => !v?.creditCard)
                              )
                            }
                          >
                            Remove
                          </Button> */}
                        </Flex>
                      )}
                  </div>
                )
              ) : (
                <div>
                  <Text
                    my={10}
                    style={{ fontWeight: "600", textAlign: "start" }}
                  >
                    Connect Bank Account
                  </Text>
                  <Text variant="text" style={{ textAlign: "start" }}>
                    Link your bank account for smooth transaction.
                  </Text>

                  <p
                    style={{
                      textAlign: "start",
                      fontWeight: "bold",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {state?.plan?.name}{" "}
                    {newConnect != "true" ? `($${state?.plan?.cost})` : ""}
                  </p>
                  <p
                    style={{ color: "#A8A8A8", textAlign: "start", margin: 0 }}
                  >
                    <br />
                    {string}
                  </p>
                  <Flex justify={"flex-start"} my={10}>
                    <Button color="yellow" onClick={start} loading={loading}>
                      Connect
                    </Button>
                  </Flex>
                </div>
              )}
            </Flex>
          </Flex>
        </center>
      )}
      {value === "manual" && (
        <Container size={"md"}>
          {connectedLoading ? (
            <>
              <Skeleton
                height={50}
                width={100}
                radius="sm"
                style={{ marginTop: "10px" }}
              />
              <Skeleton height={50} radius="sm" style={{ marginTop: "10px" }} />
            </>
          ) : connected?.filter((v) => v?.creditCard)?.length ? (
            <Card shadow="sm" className={classes.card}>
              <Flex
                // mih={50}s
                gap="md"
                justify="flex-start"
                direction="row"
                wrap="wrap"
                // className={classes.plaidComponent}
              >
                <img
                  className={classes.connectBankImage}
                  src="assets/images/bank.png"
                  alt=""
                />
                {paymentDeduction ? (
                  <div>
                    <Flex direction="row" align={"center"} gap={10}>
                      {paymentDeductionLoading && (
                        <Loader color="yellow" size={"sm"} />
                      )}
                      <Text
                        my={10}
                        style={{
                          fontWeight: "600",
                          textAlign: "start",
                        }}
                        color={"yellow"}
                      >
                        {paymentDeductionFeedback
                          ? ""
                          : "Processing Payment ..."}
                      </Text>
                    </Flex>
                    <Text
                      my={10}
                      style={{
                        fontWeight: "600",
                        textAlign: "start",
                        opacity: 0.3,
                      }}
                    >
                      {paymentDeductionFeedback
                        ? paymentDeductionFeedback
                        : "Please wait we are tranfering funds..."}
                    </Text>
                  </div>
                ) : (
                  <div>
                    <Text
                      my={10}
                      style={{
                        fontWeight: "600",
                        textAlign: "start",
                      }}
                      color="green"
                    >
                      Connected Card
                    </Text>
                    <p style={{ color: "#A8A8A8", textAlign: "start" }}>
                      Details
                    </p>
                    <table>
                      <tr>
                        <td>Account Name: </td>
                        <td>{card?.cardName}</td>
                      </tr>
                      <tr>
                        <td> Card Number : </td>
                        <td>{card?.cardNumber}</td>
                      </tr>
                    </table>

                    {newConnect !== "true" &&
                      !connected?.find((v) => v?.creditCard)?.isActive && (
                        <Flex direction="row" gap={20}>
                          <Button
                            color="yellow"
                            onClick={() =>
                              payNow(connected?.find((v) => v?.creditCard))
                            }
                            loading={paymentLoading}
                          >
                            Subscribe Now
                          </Button>

                          {/* <Button
                            variant="outline"
                            color="yellow"
                            loading={deleteAccountLoading}
                            onClick={() =>
                              removeAccount(
                                connected?.find((v) => v?.creditCard)
                              )
                            }
                          >
                            Remove
                          </Button> */}
                        </Flex>
                      )}
                  </div>
                )}
              </Flex>
            </Card>
          ) : (
            <Card shadow="sm" className={classes.card} padding={40}>
              <form onSubmit={form.onSubmit((values) => onFinish(values))}>
                <div className={classes.inputs}>
                  <TextInput
                    variant="filled"
                    id="name-on-card"
                    label="Name on Card"
                    size="lg"
                    type=""
                    placeholder="Eg.abc"
                    {...form.getInputProps("nameCard")}
                    className={[classes.myfont, classes.m10]}
                  />
                </div>

                <div className={classes.inputs}>
                  <TextInput
                    variant="filled"
                    id="card-number"
                    label="Card Number"
                    size="lg"
                    type="number"
                    placeholder="Eg.5952 **** **** ****"
                    {...form.getInputProps("cardNumber")}
                    className={classes.myfont}
                  />
                  {form.errors.cardNumber && (
                    <div style={{ color: "red" }}>
                      {form.errors.cardNumber.message}
                    </div>
                  )}
                </div>

                <SimpleGrid cols={2} className={classes.m10}>
                  <DateInput
                    variant="filled"
                    id="expiry-date"
                    label="Expiry Date"
                    valueFormat="MM/YY"
                    size="lg"
                    minDate={new Date()}
                    placeholder="Eg.08/23"
                    {...form.getInputProps("cardExp")}
                    className={classes.myfont}
                  />
                  <TextInput
                    variant="filled"
                    id="cvv"
                    label="CVV"
                    type="number"
                    size="lg"
                    placeholder="Eg.***"
                    {...form.getInputProps("CVV")}
                    className={classes.myfont}
                  />
                </SimpleGrid>

                <Center>
                  <Button
                    id="submit-card"
                    type="submit"
                    loading={connectingCard}
                    color="yellow"
                    size="lg"
                    className={classes.payBtn}
                  >
                    Connect
                  </Button>
                </Center>
              </form>
            </Card>
          )}
        </Container>
      )}
    </div>
  );
};

export default PaymentInfo;
