import { https } from "../http";

import { showError, showInfo } from "../utils/index";

const getActivites = async (client) => {
    try {
    //   store.dispatch(setFolderLoading(true));
      let response = await https().get(`/activity/get/activites?client=${client}`);
      if (response) {
        // console.log("activites response", response)
        return response?.data?.data
        // store.dispatch(setFolders(response.data));
      }
    } catch (error) {
      let _error = error?.response?.data?.message;
      showError(_error, error?.response?.status);
    //   store.dispatch(setFolderLoading(false));
      return false;
    }
  };

  export {
    getActivites
  };