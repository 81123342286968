import React, { useEffect, useState } from "react";
import AdminProfile from "./AdminProfile";
import {
  Box,
  Button,
  Image,
  NumberInput,
  PasswordInput,
  Select,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import useStyles from "./../Signup/style";

import { useForm } from "@mantine/form";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { googleLogin, registerBusiness, signUp } from "../../services";
import { useSelector } from "react-redux";
import { getCookie } from "../../utils";
import { IoIosArrowDown } from "react-icons/io";
import { useMediaQuery } from "@mantine/hooks";
import { pageInfoAnalytics, TrackPageExit } from "../../config/mixpanelConfig";
import { GoogleLogin } from "@react-oauth/google";

const AdminProfilePage = () => {
  const { classes } = useStyles();
  const [nextBtnDisable, setNextBtnDisable] = useState(false);
  const isMobile = useMediaQuery("(max-width: 430px)");
  const [stepCount, setStepCount] = useState(1);
  const { loading } = useSelector((state) => state?.auth);
  const { profile } = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    pageInfoAnalytics("Sign Up Page Viewed", "Sign Up Page");
    TrackPageExit("Sign Up");
  }, []);

  const profileForm = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      adminPhone: "",
      email: "",
      password: "",
      repassword: "",
    },
    validate: {
      firstName: (value) => {
        if (!value) {
          return "First Name Required";
        }
        const nameRegex = /[a-zA-Z].*/;

        return nameRegex.test(value)
          ? null
          : "Admin must contain at least one alphabet and may have numbers";
      },
      lastName: (value) => {
        if (!value) {
          return "Last Name Required";
        }
        const nameRegex = /[a-zA-Z].*/;

        return nameRegex.test(value)
          ? null
          : "Admin must contain at least one alphabet and may have numbers";
      },
      adminPhone: (value) => {
        if (!value) {
          return "Required";
        }
      },
      email: (value) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value)
          ? null
          : "Please enter a valid Admin email address";
      },
      password: (value) => {
        if (!value) {
          return "Required";
        }
        // Regular expression to check if the password contains at least one letter and one number
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).+$/;

        // Check if the password meets the length requirement
        const isLengthValid = value.length >= 8;

        return passwordRegex.test(value) && isLengthValid
          ? null
          : "Password must contain at least one letter, one number, and be at least 8 characters long";
      },
      repassword: (value, values) =>
        value !== values.password ? "Passwords did not match" : null,
    },
  });

  const businessForm = useForm({
    initialValues: {
      companyName: "",
      businessEmail: "",
      contactPhone: "",
    },
    validate: {
      companyName: (value) => {
        if (!value) {
          return "Required";
        }
        // Regular expression to check if the value contains at least one alphabet and may have numbers
        const nameRegex = /[a-zA-Z].*/;

        return nameRegex.test(value)
          ? null
          : "Business must contain at least one alphabet and may have numbers";
      },
      contactPhone: (value) => {
        if (!value) {
          return "Required";
        }
      },
      businessEmail: (value) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(value)
          ? null
          : "Please enter a valid Business email address";
      },
    },
  });

  const agencyForm = useForm({
    initialValues: {
      agencyName: "",
      industry: "",
    },
    validate: {
      agencyName: (value) => {
        if (!value) {
          return "Required";
        }
        // Regular expression to check if the value contains at least one alphabet and may have numbers
        const nameRegex = /[a-zA-Z].*/;

        return nameRegex.test(value)
          ? null
          : "Admin must contain at least one alphabet and may have numbers";
      },
      industry: (value) => {
        if (!value) {
          return "Required";
        }
      },
    },
  });

  const handleProfileSubmit = async () => {
    const validationResults = await profileForm.validate();
    if (profileForm.validate().hasErrors) {
      const firstName = validationResults?.errors?.firstName;
      const lastName = validationResults?.errors?.lastName;
      const adminPhone = validationResults?.errors?.adminPhone;
      const email = validationResults?.errors?.email;
      const password = validationResults?.errors?.password;
      const repassword = validationResults?.errors?.repassword;

      if (
        firstName ||
        lastName ||
        adminPhone ||
        email ||
        password ||
        repassword
      ) {
        return;
      }
    } else {
      const adminPayload = {
        name: `${profileForm?.values?.firstName} ${profileForm?.values?.lastName}`.trim(),
        firstName: profileForm?.values?.firstName,
        lastName: profileForm?.values?.lastName,
        adminPhone: profileForm?.values?.adminPhone,
        email: profileForm?.values?.email,
        password: profileForm?.values?.password,
        repassword: profileForm?.values?.repassword,
        rememberme: false,
      };
      // sign up api calll here
      try {
        const response = await signUp(adminPayload);

        if (response?.status === 200 || response?.response?.status === 201) {
          setNextBtnDisable(true);
          profileForm.reset();
        } else if (response?.response?.status === 500) {
          console.error("Server error: Unable to process the request.");
        }
      } catch (error) {
        console.log("🚀 ~ handleProfileSubmit ~ error:", error);
      }
    }
  };

  const handleBusinessSubmit = async () => {
    const validationResults = await businessForm.validate();
    if (businessForm.validate().hasErrors) {
      const companyName = validationResults?.errors?.companyName;
      const contactPhone = validationResults?.errors?.contactPhone;
      const businessEmail = validationResults?.errors?.businessEmail;

      if (companyName || contactPhone || businessEmail) {
        return;
      }
    } else {
      setStepCount(3);
    }
  };

  const handleAgencySubmit = async () => {
    const validationResults = await agencyForm.validate();
    if (agencyForm.validate().hasErrors) {
      const agencyName = validationResults?.errors?.agencyName;
      const industry = validationResults?.errors?.industry;
      if (agencyName || industry) {
        return;
      }
    } else {
      const businessPayload = {
        companyName: businessForm?.values?.companyName,
        businessEmail: businessForm?.values?.businessEmail,
        contactPhone: businessForm?.values?.contactPhone,
        agencyName: agencyForm?.values?.agencyName,
        industry: agencyForm?.values?.industry,
        agreeTerms: true,
      };

      try {
        registerBusiness(businessPayload, navigate);
      } catch (error) {
        console.error("Error during signUp:", error);
      }
    }
  };

  useEffect(() => {
    if (location?.state?.token) {
      setStepCount(2);
    }
  }, [location?.state]);

  // console.log("🚀 ~ AdminProfilePage ~ form:", form?.errors?.adminPhone);
  const renderStep1 = () => (
    <Step1
      classes={classes}
      loading={loading}
      form={profileForm}
      navigate={navigate}
      isMobile={isMobile}
      nextBtnDisable={nextBtnDisable}
      handleSubmit={handleProfileSubmit}
      // setStepCount={handleChangeStep}
    />
  );

  const renderStep2 = () => (
    <Step2
      classes={classes}
      form={businessForm}
      navigate={navigate}
      handleSubmit={handleBusinessSubmit}
    />
  );

  const renderStep3 = () => (
    <Step3
      classes={classes}
      form={agencyForm}
      loading={loading}
      handleSubmit={handleAgencySubmit}
    />
  );

  if (getCookie("token")) {
    if (profile?.user?.type && profile?.user?.type !== "staff")
      if (profile?.business) return <Navigate to="/selectprice" />;
  }

  // if (getCookie("token")) return <Navigate to="/" />;

  return (
    <>
      <AdminProfile
        step={stepCount}
        renderStep1={renderStep1}
        renderStep2={renderStep2}
        renderStep3={renderStep3}
      />
    </>
  );
};

export default AdminProfilePage;

const Step1 = ({
  classes,
  form,
  navigate,
  handleSubmit,
  loading,
  nextBtnDisable,
  isMobile,
}) => {
  return (
    <Box>
      {isMobile && (
        <Image
          fit="fill"
          className={classes.logo}
          src="/assets/images/logo2.png"
        />
      )}
      <Text className={classes.stepTitle}>
        Step 01/<span className={classes.stepSpan}>03</span>
      </Text>
      <Text className={classes.title}>Create Admin Profile</Text>
      <Text fz={20} ff={"Inter"} color="#262626">
        Let’s get you all set up so you can access your personal account.{" "}
      </Text>
      <Box>
        <SimpleGrid cols={isMobile ? 1 : 2}>
          <TextInput
            mt={"lg"}
            id="signup-name"
            label="First Name"
            variant="default"
            placeholder="EG.abc"
            {...form.getInputProps("firstName")}
            size={"lg"}
          />
          <TextInput
            mt={"lg"}
            id="signup-name"
            label="Last Name"
            variant="default"
            placeholder="EG.abc"
            {...form.getInputProps("lastName")}
            size={"lg"}
          />
        </SimpleGrid>

        <NumberInput
          mt={"lg"}
          size={"lg"}
          type="number"
          hideControls
          placeholder="+12"
          id="number-input-demo"
          label="Admin Phone Number"
          {...form.getInputProps("adminPhone")}
        />

        <TextInput
          mt={"lg"}
          variant="default"
          id="signup-email"
          label="Admin Email"
          placeholder="EG.abc@gmail.com"
          {...form.getInputProps("email")}
          size={"lg"}
        />
        <PasswordInput
          mt={"lg"}
          label="Password"
          variant="default"
          id="signup-password"
          placeholder="Eg.A*****"
          {...form.getInputProps("password")}
          size={"lg"}
        />
        <PasswordInput
          mt={"lg"}
          id="signup-confirm-password"
          label="Re-enter Password"
          variant="default"
          placeholder="Eg.A*****"
          {...form.getInputProps("repassword")}
          size={"lg"}
        />
      </Box>
      <Text fz={20} mt={10} ff={"Inter"} color="#262626">
        This information will be securely saved as per the{" "}
        <strong>
          <Link to="/term-conditions" className={classes.links}>
            Terms of Service
          </Link>{" "}
        </strong>
        &{" "}
        <strong>
          <Link to="/privacy-policy" className={classes.links}>
            Privacy Policy
          </Link>
        </strong>
      </Text>
      <Box mt={10} className={classes.googleBtn}>
        <GoogleLogin
          width={"250px"}
          size="large"
          text=""
          logo_alignment="center"
          onSuccess={(credentialResponse) => {
            googleLogin(
              {
                client_id: credentialResponse?.clientId,
                jwtToken: credentialResponse?.credential,
              },
              navigate
            );
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </Box>
      <SimpleGrid cols={2}>
        <Button
          id="signup-button"
          type="submit"
          color="gray"
          className={classes.hepl_btn}
          onClick={() => {
            navigate("/contact-us");
          }}
        >
          Help?
        </Button>

        <Button
          id="signup-button"
          type="submit"
          color="yellow"
          loading={loading}
          disabled={loading || nextBtnDisable}
          className={classes.login_btn}
          // onClick={() => setStepCount(2)}
          onClick={handleSubmit}
        >
          Next Step
        </Button>
      </SimpleGrid>
    </Box>
  );
};

const Step2 = ({ classes, form, navigate, handleSubmit }) => {
  return (
    <Box className={classes.bgHeight}>
      <Text className={classes.stepTitle}>
        Step 02/<span className={classes.stepSpan}>03</span>
      </Text>
      <Text className={classes.title}>Create Business Profile</Text>
      <Text fz={20} ff={"Inter"} color="#262626">
        Let’s get your business profile setup
      </Text>
      <Box>
        <TextInput
          mt={"lg"}
          id="signup-name"
          label="Business Name"
          variant="default"
          placeholder="Business Name"
          {...form.getInputProps("companyName")}
          size={"lg"}
        />
        <TextInput
          mt={"lg"}
          id="signup-email"
          label="Business Email"
          variant="default"
          placeholder="Business Email"
          {...form.getInputProps("businessEmail")}
          size={"lg"}
        />
        <NumberInput
          mt={"lg"}
          size={"lg"}
          hideControls
          placeholder="Business Phone Number"
          id="number-input-demo"
          label="Business Phone Number"
          {...form.getInputProps("contactPhone")}
        />
      </Box>
      <Text fz={20} mt={10} ff={"Inter"} color="#262626">
        This information will be securely saved as per the{" "}
        <strong>
          <Link to="/term-conditions" className={classes.links}>
            Terms of Service
          </Link>{" "}
        </strong>
        &{" "}
        <strong>
          <Link to="/privacy-policy" className={classes.links}>
            Privacy Policy
          </Link>
        </strong>
      </Text>
      <SimpleGrid cols={2}>
        <Button
          id="signup-button"
          // loading={loading}
          type="submit"
          color="yellow"
          className={classes.hepl_btn}
          onClick={() => {
            navigate("/contact-us");
          }}
        >
          Help?
        </Button>

        <Button
          id="signup-button"
          // loading={loading}
          type="submit"
          color="yellow"
          className={classes.login_btn}
          onClick={handleSubmit}
        >
          Next Step
        </Button>
      </SimpleGrid>
    </Box>
  );
};

const Step3 = ({ classes, form, handleSubmit, loading }) => {
  return (
    <Box className={classes.bgHeight}>
      <Text className={classes.stepTitle}>
        Step 03/<span className={classes.stepSpan}>03</span>
      </Text>
      <Text className={classes.title}>Agency Setup</Text>
      <Text fz={20} ff={"Inter"} color="#262626">
        Please tell us about your agency
      </Text>
      <Box>
        <TextInput
          mt={"lg"}
          id="signup-name"
          label="Agency Name"
          variant="default"
          placeholder="Agency Name"
          {...form.getInputProps("agencyName")}
          size={"lg"}
        />
        <Select
          label="Agency Type"
          id="select-demo"
          mt={"lg"}
          size={"lg"}
          rightSection={<IoIosArrowDown size={16} />}
          placeholder="Agency Type"
          {...form.getInputProps("industry")}
          data={[
            "Group Home",
            "Assisted Living",
            "Medical Transportation",
            "Nursing Home",
            "Medical Clinic",
          ]}
        />
      </Box>
      <Text fz={20} mt={10} ff={"Inter"} color="#262626">
        This information will be securely saved as per the{" "}
        <strong>
          <Link to="/term-conditions" className={classes.links}>
            Terms of Service
          </Link>{" "}
        </strong>
        &{" "}
        <strong>
          <Link to="/privacy-policy" className={classes.links}>
            Privacy Policy
          </Link>
        </strong>
      </Text>
      <Button
        id="signup-button"
        loading={loading}
        type="submit"
        color="yellow"
        className={classes.login_btn}
        onClick={handleSubmit}
      >
        Create Accout
      </Button>
    </Box>
  );
};
