import {
  Box,
  Button,
  Container,
  Flex,
  Menu,
  Select,
  Text,
} from "@mantine/core";

import { useDisclosure, useToggle } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddButton from "../../../components/AddButton";
import { IconSettingsPlus } from "@tabler/icons-react";
import NoteModal from "../../../components/NoteModal";
import NoteTypeSelectModal from "../../../components/NoteSelectTypeModal";
import NoteTable from "../../../components/NoteTable";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import Topbar from "../../../components/Topbar";
import {
  getAllFacilitiesModal,
  getAllFacilitiesNotePage,
  getAllFacilityTypes,
} from "../../../services";
import { getAllNoteTypes, getNotes } from "../../../services/note";
import { getAllPatients, getAllPatientsModal } from "../../../services/patient";
import { getAllStaff } from "../../../services/staff";
import { getAllPlans } from "../../../services/supportplan";
import { DatePickerInput } from "@mantine/dates";
import { FilterIcon } from "../../../components/Svgs/FilterIcon";
import moment from "moment";
import useStyles from "./style";
import { TrackPageView } from "../../../config/mixpanelConfig";
import { FaSort } from "react-icons/fa6";

const Note = (props) => {
  const { notes, loading } = useSelector((state) => state?.note);
  const { profile } = useSelector((state) => state.profile);
  const [data, setData] = useState(null);
  const [view, setView] = useState(false);
  const [isopen, setOpen] = useState(false);
  const [noteType, setNoteType] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const [dateRangeVal, setDateRangeVal] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [oneDayValue, setOneDayValue] = useState(null);

  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("descending");

  let userType = profile?.user?.type;
  const navigate = useNavigate();
  const { classes } = useStyles();

  useEffect(() => {
    getAllPlans();
    getAllNoteTypes();
    getAllFacilityTypes();
    getAllPatientsModal("withDeleted");
    getAllFacilitiesModal("withDeleted");
    getAllStaff("all");
    // getAllFacilitiesNotePage("allNotePage");

    TrackPageView("Note");
  }, []);

  useEffect(() => {
    getNotes(currentPage, null, true, dateRange, sortField, sortOrder);
  }, [currentPage, dateRange]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // useEffect(() => {
  //   if (!isopen) {
  //     getAllStaff("all");
  //   }
  // }, [isopen]);

  const handleDateRange = (dates) => {
    if (dates[0] == null && dates[1] == null) {
      setDateRangeVal([]);
    } else {
      setDateRangeVal(dates);
    }
  };

  const handleDateChange = (date) => {
    setOneDayValue(date);
  };

  // const handleSaveDateRange = () => {
  //   let startDate = moment(tempDateRange[0]).startOf("day");
  //   let endDate = moment(tempDateRange[1]).endOf("day");
  //   setDateRange([startDate, endDate]);
  // };

  const handleSaveDateRange = () => {
    if (dateRangeVal?.length) {
      let startDate = moment(dateRangeVal[0]).startOf("day");
      let endDate = moment(dateRangeVal[1]).endOf("day");
      setDateRange([startDate, endDate]);
    } else if (oneDayValue) {
      let startDate = moment(oneDayValue).startOf("day");
      let endDate = moment(oneDayValue).endOf("day");
      setDateRange([startDate, endDate]);
    }
  };

  const handleSortChange = (field, order) => {
    setSortField(field);
    setSortOrder(order);
  };

  const handleCancelDateRange = () => {
    setDateRangeVal([]);
    setDateRange(null);
    setDropdownOpen(false);
  };


  return (
    <Container fluid className={classes.content} px={20}>
      <NoteModal
        data={data}
        view={view}
        opened={isopen}
        setView={setView}
        noteType={noteType}
        title={"Add New Note"}
        close={() => {
          setOpen(false);
          setData(null);
          setNoteType(null);
        }}
        sortField={sortField}
          sortOrder={sortOrder}
      />
      <NoteTypeSelectModal
        save={setOpen}
        opened={opened}
        title={"Select Note Type"}
        onclose={(nType) => {
          close();
          setNoteType(nType);
        }}
      />
      <Topbar title={"All Notes"} />
      <Box className={classes.btn}>
        <Flex align={"center"} gap={10}>
          <Box className={classes.FilterIcon}>
            <Menu
              width={350}
              shadow="md"
              opened={dropdownOpen}
              position="bottom-start"
              onClose={() => setDropdownOpen(false)}
            >
              <Menu.Target title="Filter">
                <Box
                  className={classes.FilterIcon}
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <FilterIcon />
                </Box>
              </Menu.Target>

              <Menu.Dropdown>
                <Text size="md" weight={500} ml={5} mt={10} mb={5}>
                  Filter
                </Text>
                <DatePickerInput
                  size="sm"
                  clearable
                  m={".5rem"}
                  type="range"
                  value={dateRangeVal}
                  disabled={oneDayValue}
                  label="Select Date Range"
                  onChange={handleDateRange}
                  placeholder="Select Date Range"
                />
                <DatePickerInput
                  clearable
                  size="sm"
                  m={".5rem"}
                  value={oneDayValue}
                  label="Select Date"
                  placeholder="Select Date"
                  onChange={handleDateChange}
                  disabled={dateRangeVal?.length}
                />
                <Box className={classes.filterBtn}>
                  <Button
                    size="sm"
                    radius={8}
                    color="gray"
                    variant="outline"
                    onClick={handleCancelDateRange}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    radius={8}
                    color="yellow"
                    disabled={loading}
                    onClick={handleSaveDateRange}
                  >
                    Save
                  </Button>
                </Box>
              </Menu.Dropdown>
            </Menu>
          </Box>
          <Box className={classes.FilterIcon}>
            <Menu
              width={350}
              shadow="md"
              opened={sortOpen}
              position="bottom-start"
              onClose={() => setSortOpen(false)}
            >
              <Menu.Target title="Sort">
                <Box
                  className={classes.FilterIcon}
                  onClick={() => setSortOpen(!sortOpen)}
                >
                  <FaSort size={30} />
                </Box>
              </Menu.Target>

              <Menu.Dropdown>
                <Text size="md" weight={500} ml={5} mt={10} mb={5}>
                  Sorting
                </Text>
                <Select
                  label="Sort By"
                  placeholder="Sort by"
                  data={[
                    { label: "Created Date", value: "createdAt" },
                    { label: "Note Date", value: "noteDate" },
                    { label: "Edited Date", value: "updatedAt" },
                  ]}
                  value={sortField}
                  onChange={(value) => handleSortChange(value, sortOrder)}
                />
                <Select
                  label="Sort Order"
                  placeholder="Sort Order"
                  data={[
                    { label: "Ascending", value: "ascending" },
                    { label: "Descending", value: "descending" },
                  ]}
                  value={sortOrder}
                  onChange={(value) => handleSortChange(sortField, value)}
                />
                <Box className={classes.filterBtn}>
                  <Button
                    size="sm"
                    radius={8}
                    color="yellow"
                    disabled={loading}
                    onClick={() =>
                      getNotes(
                        currentPage,
                        null,
                        true,
                        dateRange,
                        sortField,
                        sortOrder
                      )
                    }
                  >
                    Save
                  </Button>
                </Box>
              </Menu.Dropdown>
            </Menu>
          </Box>
        </Flex>
        <Box className={classes.rightBTN}>
          <AddButton
            title={"Add New Note"}
            onClick={() => {
              getAllStaff("all");
              open();
            }}
          />

          {(profile?.user?.type !== "staff" ||
            (profile?.user?.type === "staff" &&
              profile?.user?.role === "admin")) && (
            <IconSettingsPlus
              size={40}
              stroke={1}
              color="black"
              className={classes.btnIcon}
              onClick={() => navigate("/home/notetype")}
            />
          )}
        </Box>
      </Box>
      <Box className={classes.noteTable}>
        <NoteTable
          openModal={(data) => {
            setData(data);
            setOpen(true);
          }}
          setView={(data) => {
            setView(true);
          }}
          loading={loading}
          sortField={sortField}
          sortOrder={sortOrder}
        />
        <CustomPagination
          itemsPerPage={10}
          data={notes?.count}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </Box>
    </Container>
  );
};

export default Note;
